import { Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAddUserMutation,
  useGetUserAccessLevelsQuery,
  useGetUserAccessRequiredDocumentsQuery,
} from "api/api-users-v2";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import React, { useState } from "react";
import { toast } from "react-toastify";
import type { RequiredDoc } from "types";
import { validationSchema } from "./addUserFormFields";

interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  description: string;
  items: Item[];
  template?: string;
  yearly_set_count?: number;
  count_unique_uploaded: number;
}

interface Props {
  account_id: string;
  isPartner?: boolean;
  callBack?: () => void;
}

const AddAccountUser: React.FC<Props> = ({
  account_id,
  isPartner = false,
  callBack,
}) => {
  const PASSPORT = "ID/Passport of Authorised Signatories";
  const LOA = "Letter of Authorization";

  // This gives a list of all the documents that are required for the user to upload depending on their country. Note: its not filtered according to access level yet
  const { data: docs, refetch } = useGetUserAccessRequiredDocumentsQuery(
    account_id,
    {
      skip: !Boolean(account_id),
    }
  );

  const { data: user_access_levels } = useGetUserAccessLevelsQuery();

  const [addUser, { error, isError, isLoading }] = useAddUserMutation();
  const [files, setFiles] = useState<
    {
      [document_type: string]: File;
    }[]
  >([]);

  // If Director, all is checked by default and greyed out. Only passport is required. Forget about the other permissions for now, only authorized signatory
  // If non Director AND authorized signatory is ticked, we need both the passport and the LOA. Permissions are not greyed out for non Director
  // If non Director AND authorized signatory is NOT ticked, we just need the passport. Permissions are not greyed out for non Director

  // This function shapes the 'questions' to be used in the DocumentUploadController
  const docDictionaryMaker = (
    role: string,
    permissions: Record<string, string | boolean>[]
  ): Item[] => {
    const dataShaper = (data: RequiredDoc[]): Item[] =>
      data?.map((doc) => ({
        ...doc,
        name: toCamelCase(doc.display_name),
        label: doc.display_name,
        value: doc.display_name,
        items: dataShaper(doc.items),
        template: doc.template,
        yearly_set_count: doc.yearly_set_count,
        count_unique_uploaded: doc.count_unique_uploaded ?? 0,
      }));

    switch (role) {
      case "Director":
        return (
          dataShaper(
            docs?.filter((doc) => {
              return doc.display_name === PASSPORT;
            }) ?? []
          ) ?? []
        );
      case "Non-Director":
        if (
          permissions
            ?.map((permission) => Object.keys(permission)?.[0])
            ?.includes("authorized-signatory") === false ||
          permissions?.[0]?.["authorized-signatory"] === false
        ) {
          return dataShaper(
            docs?.filter((doc) => {
              return doc.display_name === PASSPORT;
            }) ?? []
          );
        }
        return dataShaper(
          docs?.filter((doc) => {
            return doc.display_name === PASSPORT || doc.display_name === LOA;
          }) ?? []
        );
      default:
        return [];
    }
  };

  const initialData =
    files?.map((file) => ({
      id: Object.keys(file)?.[0],
      name: Object.keys(file)?.[0],
      document_type: Object.keys(file)?.[0],
      url: URL.createObjectURL(Object.values(file)[0] as File),
      submitted: "",
      submitted_to_sf: false,
      year: null,
    })) ?? [];

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        mobile: "",
        title: "",
        email: "",
        role: "Director",
        permissions: [
          {
            "authorized-signatory": "true",
          },
        ],
        to_invite: false,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={console.log}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid
              container
              spacing={2}
              justifyContent="start"
              alignItems="center"
            >
              <Grid item xs={12} lg={6}>
                <Input
                  name="first_name"
                  label="First Name"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="last_name"
                  label="Last Name"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="title"
                  label="Job Title"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="mobile"
                  label="Phone Number"
                  type="phone"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6} mt={5}>
                {!isPartner && (
                  <CheckboxField
                    label="Send Invitation Email"
                    name="to_invite"
                    value={formikProps.values.to_invite}
                    onClick={(value: boolean) =>
                      formikProps.setFieldValue("to_invite", value)
                    }
                    {...formikProps}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack>
                  <LabelViewOnly
                    label="Role in the company"
                    sx={{ fontWeight: "bolder" }}
                    required
                    {...formikProps}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="role"
                      name="role"
                      value={formikProps.values.role}
                      onChange={(e) => {
                        formikProps.setFieldValue("role", e.target.value);
                      }}
                      sx={{ flexWrap: "nowrap" }}
                    >
                      <FormControlLabel
                        value="Non-Director"
                        control={<Radio />}
                        label="Non-Director"
                      />
                      <FormControlLabel
                        value="Director"
                        control={<Radio />}
                        label="Director"
                      />
                    </RadioGroup>
                  </FormControl>
                  {Boolean(formikProps.errors.role) && (
                    <FormHelperText error={Boolean(formikProps.errors.role)}>
                      {formikProps.errors.role}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack>
                  <LabelViewOnly
                    label="Permission(s) on Incomlend Platform"
                    sx={{ fontWeight: "bolder" }}
                    required
                    {...formikProps}
                  />
                  <FormGroup>
                    {user_access_levels?.map((access_level) => (
                      <FormControlLabel
                        name="permissions"
                        control={
                          <Checkbox
                            checked={
                              formikProps.values.role === "Director" ||
                              formikProps.values.permissions
                                ?.map(
                                  (permission) => Object.keys(permission)?.[0]
                                )
                                ?.includes(access_level.id)
                            }
                          />
                        }
                        label={access_level.name}
                        disabled={formikProps.values.role === "Director"}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "permissions",
                            (e.target as HTMLInputElement).checked
                              ? [
                                  ...formikProps.values.permissions,
                                  {
                                    [access_level.id]: "true",
                                  },
                                ]
                              : formikProps.values.permissions.filter(
                                  (permission) =>
                                    Object.keys(permission)?.[0] !==
                                    access_level.id
                                ),
                            true
                          );
                        }}
                      />
                    ))}
                  </FormGroup>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textDecoration: "underline",
                      }}
                    >
                      List of Required Documents
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={refetch}>
                      <Sync />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <DocumentUploadController
                      data={docDictionaryMaker(
                        formikProps.values.role,
                        formikProps.values.permissions
                      )}
                      formikProps={formikProps}
                      initialData={initialData}
                      onUpload={(data, _, document_type) => {
                        setFiles((prev) => [
                          ...prev,
                          {
                            [document_type]: data?.file,
                          },
                        ]);
                      }}
                      onDelete={(fileName) => {
                        setFiles((prev) =>
                          prev.filter(
                            (file) => Object.keys(file)?.[0] !== fileName
                          )
                        );
                      }}
                      viewDisabled
                      onView={() => {}}
                      enableCount={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={2} ml="auto">
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => {
                    const director =
                      formikProps?.values?.role === "Director"
                        ? "true"
                        : "false";
                    const permissions = formikProps?.values?.permissions;
                    const { first_name, last_name, title, email, mobile } =
                      formikProps?.values;
                    // If partner, we don't want to send an email
                    const to_invite = isPartner
                      ? "false"
                      : formikProps?.values?.to_invite
                        ? "true"
                        : "false";
                    const requestBody = {
                      account_id,
                      first_name,
                      last_name,
                      title,
                      email,
                      mobile: mobile?.replace(/\s/g, ""),
                      files,
                      director,
                      to_invite,
                      permissions,
                    };
                    addUser(requestBody)
                      .unwrap()
                      .then(() => {
                        toast.success("User added successfully");
                        callBack && callBack();
                      })
                      .catch((err) =>
                        toast.error(`Error adding user: ${err?.message}`)
                      );
                  }}
                >
                  Add
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddAccountUser;
