import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, FormHelperText, Grid } from "@mui/material";
import {
  useGetCountriesQuery,
  useGetPartnerEligibleCountiesQuery,
} from "api/api-misc";

import { BUYER, CL_DEFAULT, SUPPLIER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CompanySelector from "components/Company/Registration/CompanySelector";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import {
  AddPartnerType,
  IBuyerAdditionalDetails,
  addPartnerDefaults,
  validationSchemaAddPartners,
} from "helpers/formFields/tradingRelationship";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import type { Partner, SearchCompany } from "types";
import BuyerAdditionalDetails from "./BuyerAdditionalDetails";

import AutoComplete from "components/Common/AutoComplete";
import Accordion from "../Accordion";
import AddPartnerDeletion from "./Deletion";
import AddPartnerSubmission from "./Submission";
import styles from "./addpartner.module.scss";
const AddPartner = ({ disabled }: { disabled: boolean }) => {
  const formikProps = useFormikContext<RegistrationFormFields>();
  const { data: countries } = useGetCountriesQuery();
  const { companyType, list, name, registrationNumber } = formikProps.values;
  const partnerType =
    companyType?.toLowerCase() === SUPPLIER.toLowerCase()
      ? BUYER.toLowerCase()
      : "seller";

  const {
    data: partnerEligibleCountries,
    isFetching: isFetchingPartnerEligibleCountries,
  } = useGetPartnerEligibleCountiesQuery(partnerType);

  const { t } = useTranslation();

  const [buyerAdditionalDetailsData, setBuyerAdditionalDetailsData] = useState<
    any[]
  >([]);

  const [foundCompanies, setFoundCompanies] = useState<any>([]);

  const [alert, setAlert] = useState<boolean>(false);
  const [isVendorAvailable, setIsVendorAvailable] = useState(true);
  const [buyerSubmittedIdenfy, setBuyerSubmittedIdenfy] =
    useState<boolean>(true);
  const [buyerDetailsSubmitted, setBuyerDetailsSubmitted] =
    useState<boolean>(true);

  const getIso3 = (countryName: string) =>
    countries?.find((country) => country.name === countryName)?.id;

  const [open, setOpen] = useState<boolean[]>([]);

  const handleClick = (index: number) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  useEffect(() => {
    if (alert && isVendorAvailable) {
      toast(`${t("buyer-not-found")}`, { type: "error" });
      setAlert(false);
    }
  }, [alert, isVendorAvailable]);
  const isMobile = useIsMobile();
  const addToList = ({
    item,
    addPartnerFormikProps,
  }: {
    item: SearchCompany;
    addPartnerFormikProps: FormikProps<AddPartnerType>;
  }) => {
    const buyerData: Partner = {
      registration_number:
        item.registration_number ||
        addPartnerFormikProps.values.registration_number,
      name: item.name,
      country: addPartnerFormikProps.values.country,
      iso3: getIso3(addPartnerFormikProps.values.country) ?? "",
      is_identified: true,
      showDelete: true,
    };
    if (companyType?.toLowerCase() === SUPPLIER.toLowerCase())
      buyerData["requested_amount"] = CL_DEFAULT;
    const combinedData = list.map((item) => {
      const additionalDetails = buyerAdditionalDetailsData.find(
        (details) => details.registration_number === item.registration_number
      );
      return additionalDetails ? { ...item, ...additionalDetails } : item;
    });
    const dataToSet = [...combinedData, buyerData];

    addPartnerFormikProps.resetForm();
    const newList = dataToSet.filter((item) => item.showDelete);
    formikProps?.setFieldValue("list", newList, true);
    document.getElementById("modal-header")?.scrollIntoView(true);
    setFoundCompanies([]);
    setBuyerSubmittedIdenfy(true);
    setBuyerDetailsSubmitted(false);
  };

  const handleAdditionalDetails = (
    buyerAdditionalDetails: IBuyerAdditionalDetails
  ) => {
    if (list.length > 0) {
      const lastIndex = list.length - 1;
      const lastElement = list[lastIndex];

      const updatedElement = { ...lastElement, ...buyerAdditionalDetails };

      const updatedList = [...list.slice(0, lastIndex), updatedElement];

      formikProps.setFieldValue("list", updatedList);

      buyerAdditionalDetailsData[lastIndex] = buyerAdditionalDetails;
      setBuyerDetailsSubmitted(true);

      setOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        newOpen[lastIndex] = false;
        return newOpen;
      });
    }
  };

  useEffect(() => {
    if (list.length === 0) {
      setBuyerSubmittedIdenfy(true);
    }
  }, [list]);

  return (
    <Grid container alignItems="center" spacing={1}>
      {list.length > 0 &&
        list.map((item, i) => {
          return (
            <Grid item xs={12} key={item.registration_number}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Accordion
                    sx={{
                      marginBottom: "0vh",
                      borderRadius: "10px !important",
                    }}
                    accordionSummaryProps={{
                      expandIcon: (
                        <ExpandMoreIcon onClick={() => handleClick(i)} />
                      ),
                    }}
                    data={[
                      {
                        title: (
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={() => handleClick(i)}
                          >
                            <Grid item xs={12} lg={3}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <LabelViewOnly
                                    label={`${t("company-full-legal-name")}`}
                                    labelClassName={styles.labelClass}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {item.name}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <LabelViewOnly
                                    label={`${t("registration-number")}`}
                                    labelClassName={styles.labelClass}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {item.registration_number}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={12}>
                                  <LabelViewOnly
                                    label={`${t("country-of-incorporation")}`}
                                    labelClassName={styles.labelClass}
                                    {...formikProps}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {item.country}
                                </Grid>
                              </Grid>
                            </Grid>
                            {!isMobile && (
                              <Grid item xs={12} lg={1.5}>
                                <AddPartnerDeletion
                                  list={list}
                                  index={i}
                                  item={item}
                                  setBuyerDetailsSubmitted={
                                    setBuyerDetailsSubmitted
                                  }
                                  setBuyerAdditionalDetailsData={
                                    setBuyerAdditionalDetailsData
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        ),
                        content: (
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <BuyerAdditionalDetails
                                onSave={handleAdditionalDetails}
                                idx={i}
                              />
                            </Grid>
                            {isMobile && (
                              <Grid item xs={12} lg={1.5} mt={2}>
                                <AddPartnerDeletion
                                  list={list}
                                  index={i}
                                  item={item}
                                  setBuyerDetailsSubmitted={
                                    setBuyerDetailsSubmitted
                                  }
                                  setBuyerAdditionalDetailsData={
                                    setBuyerAdditionalDetailsData
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        ),
                      },
                    ]}
                    expanded={open[i]}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      {(buyerDetailsSubmitted === true || list.length === 0) && (
        <Grid item xs={12}>
          <Formik
            initialValues={addPartnerDefaults}
            validationSchema={validationSchemaAddPartners}
            enableReinitialize
            onSubmit={console.log}
          >
            {(addPartnerFormikProps) => {
              return (
                <Form>
                  <Grid container spacing={isMobile ? 1 : 0}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={isMobile ? 2 : 0}
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <Grid item xs={12} lg={3}>
                          <Input
                            labelClassName={
                              disabled
                                ? styles.labelClassGrey
                                : styles.labelClass
                            }
                            name="name"
                            label={t("company-full-legal-name")}
                            placeholder={`${t("company-name")}`}
                            className={styles.formInput}
                            style={{ width: "100%" }}
                            {...addPartnerFormikProps}
                            disabled={disabled}
                          />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Input
                            labelClassName={
                              disabled
                                ? styles.labelClassGrey
                                : styles.labelClass
                            }
                            name="registration_number"
                            label={`${t("registration-number")}`}
                            placeholder={`${t("registration-number")}`}
                            className={styles.formInput}
                            style={{ width: "100%" }}
                            {...addPartnerFormikProps}
                            disabled={disabled}
                          />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          {isFetchingPartnerEligibleCountries && (
                            <SkeletonLoad bars={1} />
                          )}
                          {!isFetchingPartnerEligibleCountries &&
                            partnerEligibleCountries && (
                              <Fragment>
                                <LabelViewOnly
                                  label={`${t("country")}`}
                                  sx={{ marginBottom: "5px !important" }}
                                />
                                <AutoComplete
                                  data={partnerEligibleCountries}
                                  name="country"
                                  labelKey={"name" as any}
                                  value={addPartnerFormikProps.values.country}
                                  placeholder={`${t("select-country")}`}
                                  onChange={(event: any, newValue: any) => {
                                    addPartnerFormikProps.setFieldValue(
                                      "country",
                                      newValue?.name,
                                      true
                                    );
                                  }}
                                  fullWidth
                                  {...addPartnerFormikProps}
                                />
                              </Fragment>
                            )}
                          {Boolean(addPartnerFormikProps.errors.country) && (
                            <FormHelperText
                              error={Boolean(
                                addPartnerFormikProps.errors.country
                              )}
                            >
                              {addPartnerFormikProps.errors.country}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={1.5}
                          margin={
                            isObjectEmpty(addPartnerFormikProps.errors)
                              ? "auto 0 0 0"
                              : "auto 0"
                          }
                        >
                          {!Boolean(foundCompanies.length) && (
                            <AddPartnerSubmission
                              company_iso3={
                                getIso3(addPartnerFormikProps.values.country) ??
                                ""
                              }
                              registrationNumber={registrationNumber}
                              name={name}
                              companyType={companyType}
                              buyerAdditionalDetailsData={
                                buyerAdditionalDetailsData
                              }
                              setBuyerSubmittedIdenfy={setBuyerSubmittedIdenfy}
                              setBuyerDetailsSubmitted={
                                setBuyerDetailsSubmitted
                              }
                              setIsVendorAvailable={setIsVendorAvailable}
                              setFoundCompanies={setFoundCompanies}
                              setOpen={setOpen}
                              parentFP={formikProps}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {buyerSubmittedIdenfy === false &&
                      isVendorAvailable &&
                      Boolean(foundCompanies.length) && (
                        <CompanySelector
                          onClick={(item) =>
                            addToList({ item, addPartnerFormikProps })
                          }
                          sx={{ marginTop: "10vh" }}
                          retry={() => setFoundCompanies([])}
                          data={foundCompanies}
                          registrationNumber={
                            addPartnerFormikProps.values.registration_number
                          }
                          country={addPartnerFormikProps.values.country}
                          companyName={addPartnerFormikProps.values.name}
                          isOwnCompany={false}
                          onCancel={() => {
                            setFoundCompanies([]);
                            setBuyerSubmittedIdenfy(true);
                            setBuyerDetailsSubmitted(false);
                          }}
                        />
                      )}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};
export default AddPartner;
