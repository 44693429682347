import { CheckCircle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Modal from "components/Common/Modal";
import { type SetStateAction, useEffect, useState } from "react";

import { toast } from "react-toastify";

interface AdditionalScriptModalProps {
  tempValues?: any;
  item: string;
  call: Function;
  onEnd: (params?: any) => void;
  modalOnEnd: (params?: any) => void;
  title?: string;
  body?: JSX.Element;
  params?: Record<string, any>;
  modalParams?: Record<string, any>;
  modalCall?: Function;
  modalLoader?: boolean;
  showHelperModal: boolean;
  setShowHelperModal: React.Dispatch<SetStateAction<boolean>>;
}

const modalOverlaySx = {
  backgroundColor: "white !important",
  width: "50%",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4ch",
  padding: "2ch",
  height: "inherit !important",
  fontWeight: "inherit !important",
};
const modalSx = {
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
};

const ScriptModals: React.FC<AdditionalScriptModalProps> = ({
  title,
  body,
  params,
  modalParams,
  call,
  modalCall,
  onEnd,
  modalLoader,
  modalOnEnd,
  item,
  showHelperModal,
  setShowHelperModal,
  tempValues,
}) => {
  const [showCompletionModal, setShowCompletionModal] =
    useState<boolean>(false);

  useEffect(() => {
    console.log("showCompletionModal", showCompletionModal);
  }, [showCompletionModal]);

  return (
    <>
      <Modal
        Header={
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Typography fontWeight="bold">{`${title}`}</Typography>
          </Stack>
        }
        message={body}
        sx={modalSx}
        height="auto"
        modalContentSx={{ height: "auto" }}
        open={showHelperModal}
        modalOverlaySx={modalOverlaySx}
        messageContainerProps={{ justifyContent: "center" }}
        modalFooterSx={{
          maxWidth: "100%",
          justifyContent: "space-between",
        }}
        onClose={() => setShowHelperModal(false)}
        closeButton
        primary={{
          variant: "contained",
          fullWidth: true,
          children: "Confirm",
          loading: modalLoader,
          onClick: () => {
            const additionalCall = () =>
              modalCall && modalCall(modalParams)?.hasOwnProperty("requestId")
                ? modalCall(modalParams).unwrap()
                : modalCall && Promise.resolve(modalCall(modalParams));
            modalCall !== undefined
              ? call(params)
                  .unwrap()
                  .then((res: any) => {
                    toast.success(`Success on ${item}`);
                    onEnd(res);
                    additionalCall()
                      .then(() => {
                        toast.success(
                          `Success on ${item}'s additional function.`
                        );
                        modalOnEnd(res);
                        setShowHelperModal(false);
                        setShowCompletionModal(true);
                        console.log(
                          "technically should be here",
                          showCompletionModal
                        );
                      })
                      .catch((err: { message: any }) => {
                        toast(
                          `An error occured on ${item}'s additional function: ${err?.message}`,
                          {
                            type: "error",
                          }
                        );
                        setShowHelperModal(false);
                        setShowCompletionModal(true);
                      });
                  })
                  .catch((err: { message: any }) =>
                    toast(`An error occured on ${item}: ${err?.message}`, {
                      type: "error",
                    })
                  )
              : call(params)
                  .unwrap()
                  .then((res: any) => {
                    toast.success(`Success on ${item}`);
                    onEnd(res);
                    setShowHelperModal(false);
                  })
                  .catch((err: { message: any }) =>
                    toast(`An error occured on ${item}: ${err?.message}`, {
                      type: "error",
                    })
                  )
                  .finally(() => setShowCompletionModal(true));
          },
        }}
        secondary={{
          variant: "outlined",
          fullWidth: true,
          color: "error",
          children: `Cancel`,
          onClick: () => setShowHelperModal(false),
        }}
      />
      <Modal
        open={showCompletionModal}
        Header={
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <CheckCircle color="success" />
            <Typography fontWeight="bold">{`${title} - Completed`}</Typography>
          </Stack>
        }
        message={<>{`${tempValues}`}</>}
        sx={modalSx}
        height="auto"
        modalContentSx={{ height: "auto" }}
        modalOverlaySx={modalOverlaySx}
        modalFooterSx={{
          maxWidth: "100%",
          justifyContent: "space-between",
        }}
        onClose={() => setShowCompletionModal(false)}
        closeButton
        primary={{
          variant: "contained",
          fullWidth: true,
          children: "Done",
          color: "success",
          onClick: () => {
            setShowCompletionModal(false);
          },
        }}
      />
    </>
  );
};

export default ScriptModals;
