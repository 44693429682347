import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, Grid, Slide, Typography, useTheme } from "@mui/material";
import { useGetPartnersQuery, useUpdateStepsMutation } from "api/api-accounts";

import { BUYER, SUPPLIER } from "codes";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import CompanyTypeSelector from "components/Company/Registration/CompanyTypeSelector";
import styles from "components/TradingRelation/addpartner.module.scss";
import BuyerInfo from "components/TradingRelation/BuyerInfo";
import SellerInfo from "components/TradingRelation/SellerInfo";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/hooks";
import { setSteps } from "redux/reducers/stepsReducer";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { ProcessStep } from "types";

const AddPartnerPage = () => {
  const formikProps: FormikProps<RegistrationFormFields> = useFormikContext();
  const companyType = formikProps?.values?.companyType;
  const list = formikProps?.values?.list;
  const companyId = formikProps?.values?.companyId;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const [isDisabled, setIsDisabled] = useState(true);
  const partnerType =
    companyType?.toLowerCase() === SUPPLIER.toLowerCase()
      ? `${t("buyer")}`
      : `${t("supplier")}`;
  const partners =
    companyType?.toLowerCase() === SUPPLIER.toLowerCase()
      ? BUYER.toLowerCase()
      : "seller";

  const [updateSteps, { isLoading }] = useUpdateStepsMutation();
  const dispatch = useAppDispatch();

  const { data: currentPartners = [] } = useGetPartnersQuery(companyId, {
    skip: !Boolean(companyId),
  });

  const isSupplier =
    companyType === t("supplier") ||
    companyType?.toLowerCase() === SUPPLIER?.toLowerCase();
  useEffect(() => {
    if (
      formikProps.values.accept1 === true &&
      formikProps.values.accept2 === true &&
      formikProps.values.accept3 === true
    ) {
      setIsDisabled(false);
    }
  }, [formikProps.values]);
  const InfoField = {
    [SUPPLIER.toLowerCase()]: (
      <BuyerInfo disabled={isDisabled && !Boolean(currentPartners?.length)} />
    ),
    [BUYER.toLowerCase()]: (
      <SellerInfo
        isMobile={isMobile}
        disabled={isDisabled && !Boolean(currentPartners?.length)}
      />
    ),
    "": (
      <CompanyTypeSelector
        onClick={() =>
          updateSteps({
            company_id: formikProps.values.companyId,
            form: formikProps.values,
            step: 1,
          }).catch((error) => {
            const errorFile = error?.message;
            toast(`Something went wrong : ${errorFile}`, {
              type: "error",
            });
          })
        }
      />
    ),
  };

  const saveAndContinue = (value: ProcessStep) => {
    updateSteps({
      company_id: value.data.companyId,
      step: value.step,
      form: value.data,
    })
      .unwrap()
      .then((res) => {
        dispatch(setSteps(res));
        document.getElementById("modal-header")?.scrollIntoView(true);
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      });
  };

  const stepBack = (value: ProcessStep) => {
    updateSteps({
      company_id: value.data.companyId,
      step: value.step - 1,
      form: value.data,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setSteps({
            step: value.step - 1,
            form: value.data,
          })
        );
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      });
  };

  return (
    <Slide in direction="left">
      <Box
        sx={{
          [theme.breakpoints.down("sm")]: {
            maxWidth: "88vw",
          },
        }}
      >
        <Grid container justifyContent="center" marginTop="2vw">
          {currentPartners?.length === 0 && (
            <Grid item xs={12} id="trform-checkbox">
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography textAlign="center" variant="h4">
                    {`${t("review-agree-terms-conditions")}`}
                  </Typography>
                </Grid>
                {/* Translations pending */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={6} textAlign="start">
                      <Grid item xs={12}>
                        <CheckboxField
                          label={`${
                            isSupplier
                              ? t("transaction-record-with-buyer")
                              : "Buyer has a minimum 1-year transaction record with the Supplier."
                          }`}
                          name="accept1"
                          value={formikProps.values.accept1}
                          disabled={!isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxField
                          label={`${
                            isSupplier
                              ? t("payments-to-incomlend")
                              : "Buyer will make payments directly to Incomlend."
                          }`}
                          name="accept2"
                          value={formikProps.values.accept2}
                          disabled={!isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxField
                          label={`${
                            isSupplier
                              ? t("arrange-call-incomlend-buyer")
                              : "Buyer agrees to arrange a call with Incomlend."
                          }`}
                          name="accept3"
                          value={formikProps.values.accept3}
                          disabled={!isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={styles.divider}>
            <Divider />
          </Grid>

          <Grid
            container
            id="trform-body"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className={isDisabled ? styles.grey : undefined}
                >
                  <Typography
                    textAlign="center"
                    fontWeight="bolder"
                    fontSize={isMobile ? "1.5em" : "2em"}
                    variant="h3"
                  >
                    {`${t("add-your-buyer", {
                      partnerType,
                    })}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop="0 !important"
                  className={isDisabled ? styles.grey : undefined}
                >
                  <Typography textAlign="center" fontWeight="bolder">
                    {t("we-need-to-identify", { partnerType })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              InfoField[
                isSupplier ? SUPPLIER.toLowerCase() : companyType?.toLowerCase()
              ]
            }
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={isMobile ? 1 : 0}
            >
              <Grid item xs={10} lg={3} margin={isMobile ? "auto" : 0}>
                <LoadingButton
                  sx={secondaryButtonStyle}
                  loading={isLoading}
                  onClick={() => {
                    formikProps.setFieldValue("crStage", 3, true);
                    stepBack({
                      status: "incomplete",
                      step: 1,
                      data: formikProps.values,
                    });
                  }}
                  fullWidth
                  variant="outlined"
                >
                  {`${t("back")}`}
                </LoadingButton>
              </Grid>

              <Grid item xs={10} lg={3} margin={isMobile ? "auto" : 0}>
                <LoadingButton
                  disabled={!isObjectEmpty(formikProps.errors) || !list.length}
                  loading={isLoading}
                  onClick={() => {
                    //carry list to step-3 for supplier-driven because, credit limit needs to be included on invite-partners
                    saveAndContinue({
                      status: "complete",
                      step: 2,
                      data: {
                        ...formikProps.values,
                        list,
                      },
                    });
                  }}
                  variant="contained"
                  fullWidth
                >
                  {`${t("next")}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};
export default AddPartnerPage;
