import EmailIcon from "@mui/icons-material/Email";
import { Box, Slide } from "@mui/material";

import { useGetExternalUsersQuery, useGetInternalUsersQuery } from "api/api-it";
import Layout from "components/Common/Layout";
import InvitedUsers from "components/Users/InvitedUsers";
import InviteUser from "components/Users/InviteUser";
import UserManagementTabs from "components/Users/UserManagement";
import UsersTable from "components/Users/UserManagement/UsersTable";
import useIsMobile from "hooks/useIsMobile";

import { useEffect, useState } from "react";
import type { OverridableStringUnion } from "types";

interface IUserIT {
  departments: string[];
  first_name: string;
  groups: string[];
  is_active: string;
  last_login: Date;
  last_name: string;
  id: string;
  username: string;
  contact_id: string;
}

const ITUserManagement = () => {
  const isMobile = useIsMobile();
  const [userType, setUserType] =
    useState<OverridableStringUnion<"Internal" | "Clients" | "All">>(
      "Internal"
    );

  const {
    data: internalUsers,
    refetch: refetchInternalUsers,
    isFetching: isFetchingInternalUsers,
  } = useGetInternalUsersQuery(null);
  const {
    data: externalUsers,
    refetch: refetchExternalUsers,
    isFetching: isFetchingExternalUsers,
  } = useGetExternalUsersQuery(null);

  const [inviteuserMode, setInviteUserMode] = useState<boolean>(false);

  const USERS_TABLE_DATA: Record<
    string,
    { data: IUserIT[]; refetch: () => void; isFetching: boolean }
  > = {
    Internal: {
      data: internalUsers ?? [],
      refetch: refetchInternalUsers,
      isFetching: isFetchingInternalUsers,
    },
    Clients: {
      data: externalUsers ?? [],
      refetch: refetchExternalUsers,
      isFetching: isFetchingExternalUsers,
    },
    All: {
      data: [...(internalUsers ?? []), ...(externalUsers ?? [])],
      refetch: () => [refetchInternalUsers(), refetchExternalUsers()],
      isFetching: isFetchingInternalUsers || isFetchingExternalUsers,
    },
  };

  const headerLength = Object.keys(
    (internalUsers || externalUsers)?.[0] ?? {}
  )?.length;

  useEffect(() => {
    USERS_TABLE_DATA[userType]?.refetch();
  }, [userType]);

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={inviteuserMode ? "Invite User" : "Users Table"}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 6,
            mid: 0,
            right: 3,
            syncAction: USERS_TABLE_DATA[userType]?.refetch,
            justifyContent: "space-between",
            alignItems: "center",
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
          }}
          primary={{
            onClick: () => setInviteUserMode(!inviteuserMode),
            isCancel: inviteuserMode,
            startIcon: inviteuserMode ? null : <EmailIcon />,
            children: inviteuserMode ? "Cancel" : "Invite User",
            variant: "contained",
            fullWidth: true,
          }}
          mode="default"
        >
          {inviteuserMode && (
            <InviteUser setInviteUserMode={setInviteUserMode} />
          )}
          {!inviteuserMode && (
            <UserManagementTabs
              tabs={[
                <UsersTable
                  users={USERS_TABLE_DATA}
                  userType={userType}
                  setUserType={setUserType}
                  headerLength={headerLength}
                />,
                <InvitedUsers />,
              ]}
            />
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default ITUserManagement;
