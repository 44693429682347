import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { Badge, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import PermissionChangerDrawer from "./PermissionChangerDrawer";

interface AdminRole {
  [permission_name: string]: boolean;
}
interface AdminRoles extends Record<string, AdminRole> {
  ACCOUNT_ADMIN: AdminRole;
  MARKETING_ADMIN: AdminRole;
  OPERATION_ADMIN: AdminRole;
  ORIGINATION_ADMIN: AdminRole;
  RISK_ADMIN: AdminRole;
  ORIGINATION_MEMBER: AdminRole;
  MARKETING_MEMBER: AdminRole;
  IT_ADMIN: AdminRole;
  IT_MEMBER: AdminRole;
  RISK_MEMBER: AdminRole;
  COMPLIANCE_ADMIN: AdminRole;
  COMPLIANCE_MEMBER: AdminRole;
  FUNDING_ADMIN: AdminRole;
  FUNDING_MEMBER: AdminRole;
  FINANCE_ADMIN: AdminRole;
  FINANCE_MEMBER: AdminRole;
  OPERATION_MEMBER: AdminRole;
  ACCOUNT_MEMBER: AdminRole;
  LEGAL_ADMIN: AdminRole;
  LEGAL_MEMBER: AdminRole;
  GROUP_TEST: AdminRole;
}

const PermissionChanger = ({
  permissions,
  group_name,
}: {
  permissions: AdminRole;
  group_name: string;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const activePermissions = Object.entries(permissions).filter(
    ([_, value]) => value
  ).length;

  return (
    <Grid item xs={12} lg={3} key={group_name}>
      <Grid container>
        <Grid
          item
          xs={12}
          onClick={handleClick}
          sx={{
            borderRadius: 1,
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: "pointer",
          }}
        >
          <Badge
            color="error"
            badgeContent={activePermissions}
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                  width: "100%",
                }}
              >
                {group_name}
              </Typography>
              <PanToolAltIcon />
            </Stack>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pl: 2 }}>
            <PermissionChangerDrawer
              open={open}
              setOpen={setOpen}
              title={group_name}
              permissions={permissions}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PermissionChanger;
