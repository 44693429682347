import { Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useGetEligibilityByIsoQuery } from "api/api-legal";
import { useGetCountriesQuery } from "api/api-misc";
import MainCard from "components/Common/Cards/MainCard";
import VariableInput from "components/Common/VariableInput";
import groupBy from "helpers/groupBy";
import countrycodes from "helpers/phone-lib.json";
import toProperCase from "helpers/propercase";
import type { MapSummary } from "types";

const SummaryGoverningLaws = () => {
  const formikProps = useFormikContext<MapSummary>();
  const jurisdiction =
    formikProps.values.jurisdiction ?? formikProps?.values?.buyer_country_iso3;

  const { data: countryLegalDetails } = useGetEligibilityByIsoQuery(
    jurisdiction ?? "",
    {
      skip: !jurisdiction,
    }
  );

  const {
    data: countries,
    isFetching: isFetchingCountries,
    refetch,
  } = useGetCountriesQuery();

  const countryName = countries?.find(
    (country) => country.id === jurisdiction
  )?.name;
  const countryFlag = countryName
    ? countrycodes
        .find((country) => country.name?.endsWith(countryName))
        ?.name?.split(" ")[0]
    : "";

  const {
    assignment_notice_signed_by_buyer,
    assignment_restriction,
    credit_note_clause_buyer_confirmation,
    set_off_clause_buyer_confirmation,
    legal_shared_in_invoice,
    legal_comments,
  } = formikProps?.values;

  return (
    <MainCard
      title={
        <Stack
          direction={{
            xs: "column",
            lg: "row",
          }}
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h3">{`Governing Laws : ${countryFlag ?? ""} ${countryName}`}</Typography>
          {countryLegalDetails?.conditions?.length === 0 ? (
            <Chip
              label="No Legal Guidelines"
              color="primary"
              size="small"
              variant="outlined"
              sx={{ px: 2 }}
            />
          ) : (
            <></>
          )}
        </Stack>
      }
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Stack justifyContent="start" alignItems="start" spacing={1}>
          {countryLegalDetails?.conditions &&
            countryLegalDetails?.conditions?.length > 0 &&
            Object.entries(
              groupBy(countryLegalDetails?.conditions, "party")
            )?.map(([party, conditions]) => {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontWeight="bold">
                      {`As a ${toProperCase(party)}`}
                    </Typography>
                  </Grid>
                  {conditions?.map((condition) => (
                    <>
                      <Grid item xs={12}>
                        <Typography>{condition?.condition}</Typography>
                      </Grid>
                      {Boolean(condition?.documents?.length) && (
                        <li>
                          {condition.documents?.map((doc) => (
                            <li>{doc.display_name}</li>
                          ))}
                        </li>
                      )}
                    </>
                  ))}
                </Grid>
              );
            })}
          <Stack width="100%" mx={2} my={1}>
            <Typography fontWeight="bold">Legal Comments</Typography>
            <VariableInput
              label=""
              value={legal_comments ?? "-- No Additional Comments --"}
              disabled
              noHover
              multiline
              rows={4}
              name="legal_comment"
              fullWidth
            />
          </Stack>

          <Grid container alignItems="center" justifyContent="start">
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} alignItems="center" my={1}>
                <Typography fontWeight="bold">
                  Notice of Assignment signed by buyer
                </Typography>
                <Chip
                  color={
                    assignment_notice_signed_by_buyer ? "success" : "error"
                  }
                  label={assignment_notice_signed_by_buyer ? "Yes" : "No"}
                  size="small"
                  sx={{ px: 2 }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} my={1}>
                <Typography fontWeight="bold" alignItems="center">
                  Credit Note Clause in Buyer Confirmation
                </Typography>

                <Chip
                  color={
                    credit_note_clause_buyer_confirmation ? "success" : "error"
                  }
                  label={credit_note_clause_buyer_confirmation ? "Yes" : "No"}
                  size="small"
                  sx={{ px: 2 }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} my={1}>
                <Typography fontWeight="bold" alignItems="center">
                  Set-off Clause in Buyer Confirmation
                </Typography>

                <Chip
                  color={
                    set_off_clause_buyer_confirmation ? "success" : "error"
                  }
                  label={set_off_clause_buyer_confirmation ? "Yes" : "No"}
                  size="small"
                  sx={{ px: 2 }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} my={1}>
                <Typography fontWeight="bold" alignItems="center">
                  To be shared in Invoice verification details
                </Typography>

                <Chip
                  color={legal_shared_in_invoice ? "success" : "error"}
                  label={legal_shared_in_invoice ? "Yes" : "No"}
                  size="small"
                  sx={{ px: 2 }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} my={1}>
                <Typography fontWeight="bold" alignItems="center">
                  Assignment restrictions
                </Typography>
                <Typography
                  sx={{
                    padding: "1px 2ch",
                    color: assignment_restriction?.length
                      ? "primary.main"
                      : "info.main",
                  }}
                >
                  {assignment_restriction?.length
                    ? assignment_restriction?.join(" , ")
                    : "None"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      }
      sx={{ width: "100%" }}
    />
  );
};
export default SummaryGoverningLaws;
