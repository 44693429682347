import {
  Button,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useAddDirectorMutation } from "api/api-accounts";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface AddDirectorData {
  entity_id: string;
  entity_type: string;
  fullname: string;
  function: string;
  address: string;
}

interface AddDirectorProps {
  entityId: string;
  entityType: string;
  callback: () => void;
  onClose: () => void;
  isLocked?: boolean;
}

const AddDirector: React.FC<AddDirectorProps> = ({
  entityId,
  entityType,
  callback,
  onClose,
  isLocked = false,
}) => {
  const theme = useTheme();
  const [fullname, setFullname] = useState<string>("");
  const [directorFunction, setDirectorFunction] = useState<string>("");
  const [registeredAddress, setRegisteredAddress] = useState<string>("");

  const [addDirector, { isLoading }] = useAddDirectorMutation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newDirector: AddDirectorData = {
      entity_id: entityId,
      entity_type: entityType,
      fullname: fullname,
      function: directorFunction,
      address: registeredAddress,
    };

    addDirector({
      account_id: entityId,
      data: newDirector,
    })
      .unwrap()
      .then(() => {
        toast.success("Director added successfully");
        callback();
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  return (
    <Slide in direction="left">
      <Paper
        elevation={3}
        sx={{ padding: theme.spacing(4), maxWidth: 500, margin: "auto" }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Typography variant="h3" component="h2" gutterBottom>
              Add a new director
            </Typography>
            <Divider />

            <Typography mb={1}>Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              sx={{ mb: 2 }}
              disabled={isLocked || isLoading}
            />
            <Typography mb={1}>Function</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={directorFunction}
              onChange={(e) => setDirectorFunction(e.target.value)}
              sx={{ mb: 2 }}
              disabled={isLocked || isLoading}
            />
            <Typography mb={1}>Address</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={registeredAddress}
              onChange={(e) => setRegisteredAddress(e.target.value)}
              sx={{ mb: 2 }}
              disabled={isLocked || isLoading}
            />

            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                variant="contained"
                color="error"
                disabled={isLoading}
                fullWidth
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLocked || isLoading}
                fullWidth
              >
                Add Director
              </Button>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Slide>
  );
};

export default AddDirector;
