import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
  type StackOwnProps,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#001F54",
  whiteSpace: 'nowrap',
  overflow: 'auto',
}));
interface Props {
  minDay: number;
  date: Date;
  isDrawer?: boolean;
  drawerOpen?: boolean;
  setDrawerOpen?: (open: boolean) => void;
  direction?: StackOwnProps['direction'];
  disabled?: boolean;
}

export default function DayCounter({
  minDay,
  date,
  setDrawerOpen = (arg) => { },
  isDrawer,
  drawerOpen = false,
  direction = 'row',
  disabled = false,
}: Props) {
  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const target = date.getTime();
    const difference = target - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const theme = useTheme();

  useEffect(() => {
    if (disabled) {
      return
    }
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, [date]);
  return (
    <Stack direction={direction} spacing={1} alignItems="center">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor:
            timeLeft.days < minDay
              ? theme.palette.error.main
              : theme.palette.primary.main,
          borderRadius: 1.5,
          paddingX: 2,
          paddingY: 1,
          color: "#FFFFFF",
          width: { xs: "100%", lg: "inherit" },
          whiteSpace: "nowrap",
        }}
      >
        <Typography variant="h4">
          {`${timeLeft.days.toString().padStart(2, "0")} : 
                  ${timeLeft.hours.toString().padStart(2, "0")} : 
                  ${timeLeft.minutes.toString().padStart(2, "0")} : 
                  ${timeLeft.seconds.toString().padStart(2, "0")}`}
        </Typography>
        <Typography variant="body2">
          Days&nbsp;&nbsp; Hrs&nbsp;&nbsp; Mins&nbsp;&nbsp; Secs
        </Typography>
      </Box>
      {isDrawer ? (
        <CustomButton
          onClick={() => setDrawerOpen(!drawerOpen)}
          variant="outlined"
          sx={{ width: { xs: "100%", lg: "inherit" } }}
        >
          Timer settings
        </CustomButton>
      ) : null}
    </Stack>
  );
}
