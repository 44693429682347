import { Chip, Stack, Typography } from "@mui/material";
import { useExportLeadToSalesforceMutation } from "api/api-accounts";
import Modal from "components/Common/Modal";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import type { Account } from "types";
import ExportLeadDataContainer from "./ExportLeadDataContainer";

const modalOverlaySx = {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2ch",
  padding: "2ch",
  height: "inherit !important",
  fontWeight: "inherit !important",
  backgroundColor: "#ffffff !important",
};
const modalSx = {
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  "&:focus-visible": {
    outline: "none",
  },
};

const ExportLead: React.FC<{
  lead: Account;
  open: boolean;
  hasTradePartners: boolean;
  onClose: () => void;
}> = ({ lead, open, onClose, hasTradePartners }) => {
  const [exportToSf] = useExportLeadToSalesforceMutation();

  return (
    <Modal
      sx={modalSx}
      modalContentSx={{ height: "auto", p: 5 }}
      Header={
        <Stack direction="row" alignItems="center" spacing={1} pb={2}>
          <Typography fontSize="1.5em" fontWeight="bold">
            Export to Salesforce
          </Typography>
          <Chip
            label={
              lead?.missing_values_before_sf_export?.can_export
                ? "Ready to Export"
                : "Not Ready to Export"
            }
            color={
              lead?.missing_values_before_sf_export?.can_export
                ? "success"
                : "error"
            }
            size="small"
            sx={{ fontWeight: "bold" }}
            variant="outlined"
          />
        </Stack>
      }
      message={
        <ExportLeadDataContainer
          lead={lead}
          hasTradePartners={hasTradePartners}
        />
      }
      open={open}
      modalOverlaySx={modalOverlaySx}
      onClose={onClose}
      primary={{
        children: "Export",
        disabled: !lead?.missing_values_before_sf_export?.can_export,
        onClick: () =>
          lead?.id &&
          exportToSf(lead?.id)
            .unwrap()
            .then(() => toast.success("Lead Exported to Salesforce"))
            .catch((err) =>
              toast.error(`Error Exporting Lead to Salesforce: ${err?.message}`)
            ),
      }}
      width={isMobile ? "90%" : "60%"}
      height="auto"
      secondary={{
        children: "Cancel",
        onClick: onClose,
        color: "error",
      }}
      closeButton
    />
  );
};
export default ExportLead;
