import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useAddConditionsMutation,
  useGetRequiredLegalDocumentsQuery,
} from "api/api-legal";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import AutoComplete from "components/Common/AutoComplete";
import DataTablev2 from "components/Common/DataTablev2";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import toProperCase from "helpers/propercase";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import type { CountryEligibility, LegalCondition } from "types";
import EditEligibilityCondition from "./EditEligibilityCondition";
import styles from "./eligibility.module.scss";

const EligibilityCondition = ({ iso3 }: { iso3: string }) => {
  const formikProps = useFormikContext<
    CountryEligibility & { newCondition: LegalCondition }
  >();
  const [addConditions] = useAddConditionsMutation();
  const theme = useTheme();

  useEffect(() => {}, [formikProps.values?.conditions?.length]);
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const { data: documentList, isFetching: isFetchingDocumentList } =
    useGetRequiredLegalDocumentsQuery(null);
  const [editEligibility, setEditEligibility] = useState<{
    state: boolean;
    data: LegalCondition | undefined;
  }>({ state: false, data: undefined });
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const [open, setOpen] = useState(false);

  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "3ch",
        borderRadius: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} xl={12}>
          <Typography fontSize="1.5em" fontWeight="bold">
            Conditions
          </Typography>
        </Grid>
        {formikProps?.values.conditions &&
          Boolean(formikProps?.values.conditions?.length) && (
            <Grid item xs={12} lg={12} xl={12}>
              <DataTablev2
                defaultColumnWidth={200}
                rowsPerPage={rowsPerPage}
                data={formikProps?.values.conditions}
                toBeHidden={["required_document_id", "condition_id"]}
                customColumns={{
                  party: {
                    party: "Party",
                    minWidth: 100,
                    renderCell: ({ value }: { value: string }) => {
                      return (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "1em",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: value
                              ? theme.vars.palette.primary.main
                              : theme.vars.palette.secondary.main,
                          }}
                        >
                          {toProperCase(value) ?? "Unknown"}
                        </Typography>
                      );
                    },
                  },
                  document_name: {
                    document_name: "Document",
                    minWidth: 150,
                    renderCell: ({ value }: { value: string }) => {
                      return (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "1em",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 300,
                            whiteSpace: "nowrap",
                            color: value
                              ? theme.vars.palette.primary.main
                              : theme.vars.palette.secondary.main,
                          }}
                        >
                          {value ?? "Unknown"}
                        </Typography>
                      );
                    },
                  },
                  condition: {
                    condition: "Condition",
                    minWidth: 1000,
                    renderCell: ({ value }: { value: string }) => {
                      return (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: value
                              ? theme.vars.palette.primary.main
                              : theme.vars.palette.secondary.main,
                          }}
                        >
                          {value ?? "Unknown"}
                        </Typography>
                      );
                    },
                  },
                }}
                onRowClicked={(field) =>
                  setEditEligibility({
                    state: true,
                    data: formikProps?.values.conditions.find(
                      (item) => item.condition_id === field.condition_id
                    ),
                  })
                }
              />
            </Grid>
          )}
        <EditEligibilityCondition
          iso3={iso3}
          editParams={editEligibility}
          setEditParams={setEditEligibility}
        />
        <Grid item xs={12} lg={12} xl={12}>
          <Typography
            sx={{
              bgcolor: "#a2a6c338",
              textAlign: "center",
              outline: "1px dashed #a2a6c3",
              borderRadius: "10px",
              lineHeight: "10ch",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <AddCircleOutlineIcon />
              <Grid item>Add Conditions</Grid>
            </Stack>
          </Typography>
        </Grid>

        <Modal
          message={
            <Grid item margin="auto">
              <Grid container>
                <Grid item xs={10} lg={6} xl={6}>
                  <LabelViewOnly
                    label="Condition applicable for:"
                    labelClassName={styles.labelClass}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="party"
                      name="newCondition.party"
                      value={formikProps.values.newCondition.party}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "newCondition.party",
                          e.target.value
                        );
                      }}
                      row
                    >
                      <FormControlLabel
                        value="seller"
                        control={<Radio />}
                        label="Supplier"
                      />
                      <FormControlLabel
                        value="buyer"
                        control={<Radio />}
                        label="Buyer"
                      />
                      <FormControlLabel
                        value="both"
                        control={<Radio />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelViewOnly
                    label="Documents (Multi-select)"
                    labelClassName={styles.labelClass}
                  />
                  {isFetchingDocumentList && !documentList?.length && (
                    <SkeletonLoad bars={1} />
                  )}
                  {!isFetchingDocumentList && documentList?.length && (
                    <AutoComplete
                      labelKey="display_name"
                      data={documentList}
                      name="newCondition.documents"
                      placeholder="If required for the condition, else leave this blank"
                      multiple
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="newCondition.condition"
                    label="Condition"
                    multiline
                    sx={{
                      ".MuiInputBase-root": {
                        height: "22vh",
                        backgroundClip: "border-box",
                        borderRadius: "10px",
                        textarea: {
                          height: "20vh !important",
                          overflow: "scroll !important",
                        },
                      },
                    }}
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          sx={modalSx}
          width={isMobile ? "90%" : "40%"}
          height="auto"
          modalContentSx={{ height: "auto" }}
          open={open}
          modalOverlaySx={modalOverlaySx}
          modalFooterSx={{ maxWidth: "100%" }}
          onClose={() => setOpen(false)}
          primary={{
            variant: "contained",
            onClick: () => {
              let conditions: LegalCondition[];
              const { newCondition } = formikProps.values;
              const documents = newCondition.documents;
              if (documents?.length) {
                if (newCondition.party === "both") {
                  conditions = documents?.map((item) => ({
                    party: "buyer",
                    required_document_id: item.id,
                    condition: newCondition.condition,
                  })) as LegalCondition[];
                  conditions.push(
                    ...(documents?.map((item) => ({
                      party: "seller",
                      required_document_id: item.id,
                      condition: newCondition.condition,
                    })) as LegalCondition[])
                  );
                } else {
                  conditions = documents?.map((item) => ({
                    party: newCondition.party,
                    required_document_id: item.id,
                    condition: newCondition.condition,
                  })) as LegalCondition[];
                }
              } else {
                if (newCondition.party === "both")
                  conditions = [
                    {
                      party: "buyer",
                      condition: newCondition.condition,
                    },
                    {
                      party: "seller",
                      condition: newCondition.condition,
                    },
                  ];
                else
                  conditions = [
                    {
                      party: newCondition.party,
                      condition: newCondition.condition,
                    },
                  ];
              }

              iso3 &&
                addConditions({
                  country: iso3,
                  conditions,
                })
                  .unwrap()
                  .then(() => {
                    toast("Draft submitted successfully", {
                      type: "success",
                    });
                    setOpen(false);
                  })
                  .catch((err) => {
                    toast(`An error occured: ${err?.message}`, {
                      type: "error",
                    });
                    setOpen(false);
                  });
            },
            children: "Confirm",
          }}
          closeButton
          secondary={{
            variant: "outlined",
            onClick: () => setOpen(false),
            children: `Cancel`,
          }}
        />
      </Grid>
    </Paper>
  );
};
export default EligibilityCondition;
