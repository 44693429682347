import { Cancel, CheckCircle } from "@mui/icons-material";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { BUYER, PRODUCTS, SELLER } from "codes";
import { VITE_EXPERIMENTAL_TERMSHEET } from "experimental_features";
import { useFormikContext } from "formik";
import * as React from "react";
import AvailableCreditLimit from "./AvailableCreditLimit";
import LeadAdditionalInformation from "./LeadAdditionalInformation";
import LeadRegistrationDetails from "./LeadRegistrationDetails";
import type { LeadSections } from "./LeadSectionTabs";
import LeadTermSheetInfo from "./LeadTermSheetInfo";
import useLeadStatus from "./utils/useLeadStatus";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanelDiv = styled("div")`
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
`;

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelDiv
      role="tabpanel"
      hidden={value !== index}
      id={`leade-partner-tabpanel-${index}`}
      aria-labelledby={`leade-partner-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, flexGrow: 1 }}>{children}</Box>}
    </TabPanelDiv>
  );
}

function a11yProps(index: number) {
  return {
    id: `leade-partner-tab-${index}`,
    "aria-controls": `leade-partner-tabpanel-${index}`,
  };
}

export default function LeadTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const formikProps = useFormikContext<LeadSections>();
  const { profile, account_product } = formikProps.values;
  const LEAD_IS_BUYER = profile === BUYER?.toLowerCase();
  const { partners, syncAction, ...account } = formikProps.values;
  const [
    isLeadRegistrationFilledUp,
    isLeadAdditionalFilledUp,
    isLeadTermSheetFilledUp,
    isCLRequestDisabled
  ] = useLeadStatus(account);

  // Change the condition once BE bug regarding primary key for(reverse_factoring) is fixed
  const showTermsheet =
    account_product &&
    ((account_product.toLowerCase() === PRODUCTS.factoring &&
      profile === SELLER.toLowerCase()) ||
      (["reverse_factoring", "reverse factoring"].includes(
        account_product.toLowerCase()
      ) &&
        LEAD_IS_BUYER)) &&
    // This is an experimental feature, should not be rendered in production
    VITE_EXPERIMENTAL_TERMSHEET;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lead-partner-tabs"
        >
          <Tab
            label="Registration Details"
            {...a11yProps(0)}
            icon={
              isLeadRegistrationFilledUp ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )
            }
            iconPosition="end"
          />
          <Tab
            label="Additional Details"
            {...a11yProps(1)}
            icon={
              isLeadAdditionalFilledUp ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )
            }
            iconPosition="end"
          />
          {LEAD_IS_BUYER && <Tab label="Credit Limit" {...a11yProps(2)} />}
          {showTermsheet && (
            <Tab
              label="Term Sheet Info"
              {...a11yProps(LEAD_IS_BUYER ? 3 : 2)}
              icon={
                isLeadTermSheetFilledUp ? (
                  <CheckCircle color="success" />
                ) : (
                  <Cancel color="error" />
                )
              }
              iconPosition="end"
            />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <LeadRegistrationDetails />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <LeadAdditionalInformation />
      </CustomTabPanel>
      {LEAD_IS_BUYER && (
        <CustomTabPanel value={value} index={2}>
          <AvailableCreditLimit buyer={account} disabled={isCLRequestDisabled} navigateTab={setValue} />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={LEAD_IS_BUYER ? 3 : 2}>
        <LeadTermSheetInfo />
      </CustomTabPanel>
    </Box>
  );
}
