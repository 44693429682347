import { Box, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import groupBy from "helpers/groupBy";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface MAP_SECTIONS {
  name: string;
  component: React.ComponentType;
  disabled: boolean;
  parent: string;
}

interface SectionsMenuProps {
  sections: MAP_SECTIONS[];
  activeTab: number;
  children?: React.ReactNode;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function SectionsMenu({
  sections,
  children,
  activeTab,
  handleChange,
}: SectionsMenuProps) {
  const theme = useTheme();

  const GROUPED_MAP_SECTIONS = groupBy(sections, "parent");

  const SECTION_START_INDEXES: Record<number, number> = {};
  let cumulativeLength = 0;

  Object.entries(GROUPED_MAP_SECTIONS).forEach(([key, value], index) => {
    SECTION_START_INDEXES[index] = cumulativeLength;
    cumulativeLength += value.length;
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "grey.300",
        display: "flex",
        borderRadius: 1,
        height: { xs: "100%", lg: "auto" },
        py: 1,
        px: 1,
        boxShadow: 1,
        flexDirection: {
          xs: "column-reverse",
          lg: "row",
        },
      }}
    >
      <Paper
        sx={{ width: 180, maxWidth: "100%", bgcolor: "grey.300", boxShadow: 0 }}
      >
        <MenuList
          sx={{
            textAlign: "center",
          }}
        >
          {Object.entries(GROUPED_MAP_SECTIONS)?.flatMap(
            ([parent, sections], section_index) => [
              <MenuItem
                key={parent}
                sx={{
                  bgcolor: "grey.300",
                  "&:hover": {
                    bgcolor: "grey.300",
                    cursor: "default",
                  },
                }}
              >
                <ListItemText
                  primary={parent}
                  primaryTypographyProps={{
                    fontWeight: "bolder",
                  }}
                  sx={{
                    textDecoration: "underline",
                  }}
                />
              </MenuItem>,
              <MenuList
                key={`${parent}-list`}
                sx={{
                  textAlign: "center",
                }}
              >
                {sections.map((section, index) => {
                  const actual_index =
                    SECTION_START_INDEXES[section_index] + index;
                  return (
                    <MenuItem
                      key={section.name}
                      onClick={(e) => {
                        handleChange(e, actual_index);
                      }}
                      sx={{
                        color:
                          activeTab === actual_index ? "error.main" : "inherit",
                      }}
                    >
                      <ListItemText
                        primary={section.name}
                        primaryTypographyProps={{
                          fontWeight:
                            activeTab === actual_index ? "bold" : "inherit",
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </MenuList>,
              <Divider key={`${parent}-divider`} />,
            ]
          )}
        </MenuList>
      </Paper>
      {children}
    </Box>
  );
}
