import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SyncIcon from "@mui/icons-material/Sync";
import {
  type ButtonProps,
  Divider,
  Grid,
  type GridProps,
  type GridSize,
  IconButton,
  Tooltip,
  Typography,
  type TypographyProps,
} from "@mui/material";
import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import useIsMobile from "hooks/useIsMobile";
import Breadcrumbs, { type BreadcrumbsProps } from "../Breadcrumbs";
import LayoutBody from "./LayoutBody";
import LayoutSideBar from "./LayoutSideBar";
import styles from "./layout.module.scss";
export interface LayoutProps {
  /**
   * Pattern and design of the layout to be followed
   */
  mode?: "global" | "default" | "sidebar" | "split";
  /**
   * Content inside the layout
   */
  children?: React.ReactNode;
  /**
   * Contents to house inside the layout
   */
  layoutSideBarChildren?: JSX.Element | JSX.Element[];
  /**
   * Header child
   */
  LayoutHeader?: JSX.Element | JSX.Element[];
  headerConfig?: {
    left?: GridSize;
    mid?: GridSize;
    right?: GridSize;
    syncAction?: () => void;
    startComponent?: React.ReactNode;
    endComponent?: React.ReactNode;
    xs?: {
      left?: GridSize;
      mid?: GridSize;
      right?: GridSize;
    };
    md?: {
      left?: GridSize;
      mid?: GridSize;
      right?: GridSize;
    };
    alignItems?: React.CSSProperties["alignItems"];
    justifyContent?: React.CSSProperties["justifyContent"];
  };
  /**
   * Direction of the sidebar
   */
  sideBarDirection?: "left" | "right";
  /**
   * Components to be on the top of the layout
   */
  header?: JSX.Element | JSX.Element[];
  /**
   * TODO
   */
  footer?: JSX.Element | JSX.Element[];
  /**
   * Render primary or secondary buttons
   */
  RenderButtons?: any;
  /**
   * The title of the layout
   */
  title?: string | React.ReactNode;
  /**
   * Primary button props
   */
  primary?: ButtonProps & {
    isCancel?: boolean;
    isLoading?: boolean;
  };
  /**
   * Secondary button props
   */
  secondary?: ButtonProps & {
    isCancel?: boolean;
    isLoading?: boolean;
  };
  /**
   * If the layout houses viewonly forms only. This is used to determine whether any form validations are to be performed on the layout.
   */

  /**Custom classname */
  className?: string;
  /**Custom sidebar classname */
  sidebarClassName?: string;
  /**Custom body classname */
  bodyClassName?: string;
  /**Custom header component options, `eg:textHeadingOptions:{level:5}` */
  textHeadingOptions?: TypographyProps & { level?: number };
  /**Back button, usually used for mobile screens */
  backArrow?: boolean;
  backLink?: string;
  backAction?: () => void;
  noDivider?: boolean;
  subtitleOptions?: TypographyProps;
  subtitle?: React.ReactNode | string;
  layoutBodyStyles?: React.CSSProperties;
  /** AlignItems argument for the Grid component for the Title, Layout Header and Rendered Button */
  alignItems?: string;
  collapsable?: boolean;
  breadcrumbs?: BreadcrumbsProps;
  sx?: GridProps["sx"];
  layoutGridContainerProps?: GridProps;
  id?: string;
}

const Layout = (props: LayoutProps) => {
  const isMobile = useIsMobile();
  const {
    header,
    footer,
    mode = "default",
    layoutSideBarChildren,
    sideBarDirection = "left",
    children,
    RenderButtons = null,
    title,
    primary,
    secondary,

    className = "",
    sidebarClassName = "",
    bodyClassName = "",
    subtitleOptions,
    subtitle,
    collapsable,
    textHeadingOptions = {
      level: 3,
    },
    LayoutHeader,
    layoutBodyStyles,
    backArrow = isMobile,
    backAction,
    noDivider = false,
    headerConfig = {
      left: 4,
      mid: 4,
      right: 4,
      xs: { left: 4, mid: 4, right: 4 },
      md: { left: 4, mid: 4, right: 4 },
    },
    alignItems = "start",
    backLink = "/dashboard",
    breadcrumbs,
    layoutGridContainerProps,
    id = "layout-container",
    sx,
  } = props;

  const goBack = () => {
    backAction ? backAction() : window?.open(backLink, "_self");
  };

  const RenderButtonsDefault = () => {
    const multiplier = secondary && primary ? 0.5 : 1;

    return (
      <Grid container spacing={1} justifyContent="flex-end" id={id}>
        {secondary && (
          <Grid item xs={12 * multiplier}>
            <LoadingButton
              color={secondary?.isCancel ? "error" : "primary"}
              loading={secondary?.isLoading}
              onMouseDown={(event: any) => {
                event.preventDefault();
              }}
              variant="outlined"
              {...secondary}
            />
          </Grid>
        )}
        {primary && (
          <Grid item xs={12 * multiplier}>
            <LoadingButton
              onMouseDown={(event: any) => {
                event.preventDefault();
              }}
              loading={primary?.isLoading}
              color={primary?.isCancel ? "error" : "primary"}
              variant="contained"
              {...primary}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const Layouts = {
    sidebar: (
      <div className={`${styles["container-sidebar"]} ${className}`} id={id}>
        {header && <header className={styles.header}>{header}</header>}
        <LayoutBody style={layoutBodyStyles}>{children}</LayoutBody>
        <LayoutSideBar
          sideBarDirection={sideBarDirection}
          sidebarClass={sidebarClassName}
          collapsable={collapsable}
        >
          {layoutSideBarChildren as JSX.Element | JSX.Element[]}
        </LayoutSideBar>
        <footer className={styles.footer}>{footer}</footer>
      </div>
    ),
    split: (
      <Grid
        container
        className={`${styles["container-split"]} ${className}`}
        id={id}
      >
        <Grid item xs={12}>
          {header && <header className={styles.header}>{header}</header>}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <LayoutSideBar
                sideBarDirection={sideBarDirection}
                sidebarClass={sidebarClassName}
              >
                {layoutSideBarChildren as JSX.Element | JSX.Element[]}
              </LayoutSideBar>
            </Grid>
            <Grid item xs={6} className={bodyClassName}>
              <LayoutBody style={layoutBodyStyles}>{children}</LayoutBody>
            </Grid>
          </Grid>
          <footer className={styles.footer}>{footer}</footer>
        </Grid>
      </Grid>
    ),
    global: (
      <Grid
        container
        spacing={2}
        className={`${styles.container} ${className}`}
        id={id}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className={`${styles.column} ${styles.title}`}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item lg={6} xs={12}>
              <Typography>{title as string}</Typography>
            </Grid>
            <Grid item lg={3} xs={12}>
              {RenderButtons && <RenderButtons />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    ),
    default: (
      <Grid
        container
        spacing={2}
        className={`${styles.container} ${className}`}
        id={id}
        sx={sx}
        {...layoutGridContainerProps}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems={headerConfig.alignItems ?? alignItems}
            justifyContent={headerConfig.justifyContent ?? "space-between"}
          >
            <Grid
              item
              lg={headerConfig?.left}
              xs={headerConfig.xs?.left ?? headerConfig?.left}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2 }} alignItems="center">
                    {backArrow && (
                      <Grid item>
                        <Tooltip title={`Back to ${backLink}`}>
                          <IconButton onClick={goBack}>
                            <ArrowBackIosIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    {headerConfig?.startComponent}
                    <Grid item>
                      <Typography
                        {...textHeadingOptions}
                        variant={
                          textHeadingOptions?.level
                            ? (`h${textHeadingOptions?.level}` as TypographyProps["variant"])
                            : "h1"
                        }
                        data-testid="layout-title"
                      >
                        {title as string}
                      </Typography>
                    </Grid>
                    {headerConfig?.endComponent}
                    {headerConfig?.syncAction && (
                      <Grid item>
                        <IconButton onClick={headerConfig?.syncAction}>
                          <SyncIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {Boolean(LayoutHeader) && (
              <Grid
                item
                lg={headerConfig?.mid}
                xs={headerConfig.xs?.mid ?? headerConfig?.mid}
              >
                {LayoutHeader}
              </Grid>
            )}

            {(secondary || primary) && (
              <Grid
                item
                lg={headerConfig?.right}
                xs={headerConfig.xs?.right ?? headerConfig?.right}
              >
                <RenderButtonsDefault />
              </Grid>
            )}
          </Grid>
        </Grid>
        {subtitle && (
          <Grid item xs={12}>
            {typeof subtitle === "string" ? (
              <Typography {...subtitleOptions}>{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </Grid>
        )}
        {breadcrumbs && (
          <Grid item xs={12}>
            <Breadcrumbs {...breadcrumbs} />
          </Grid>
        )}
        <Grid item xs={12}>
          {noDivider ? <></> : <Divider />}
        </Grid>
        <Grid item xs={12} height={"100%"}>
          {children}
        </Grid>
      </Grid>
    ),
  };
  return Layouts[mode];
};
Layout.displayName = "Layout";
export default Object.assign(Layout, {
  Sidebar: LayoutSideBar,
  Body: LayoutBody,
});
