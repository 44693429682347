import InfoIcon from "@mui/icons-material/Info";
import {
  Grid,
  styled,
  SxProps,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { currencyFormatter } from "helpers/currencyFormatter";
import React from "react";
import LabelViewOnly from "../LabelViewOnly";

const EnhancedField = styled(TextField)<TextFieldProps>(() => ({
  backgroundColor: "white",

  margin: 0,
  "&:focus": {
    border: "#ced4da",
    backgroundColor: "white",
  },
  "&:focusVisible": {
    outlineColor: "#393939",
  },
  "&:hover": {
    backgroundColor: "white",
  },

  ".Mui-disabled": {
    backgroundColor: "#ced4da",
    color: "#393939",
    WebkitTextFillColor: "#393939 !important",
  },
  ".Mui-focusVisible": {
    outlineColor: "#393939",
  },
  "&.white": {},
}));
const EnhancedPlain = styled(InputBase)<InputBaseProps>(() => ({
  border: "none",
  color: "#393939",
  ".Mui-focusVisible": {
    outlineColor: "#393939",
  },
  ".Mui-disabled": {
    backgroundColor: "#ced4da",
    color: "#393939",
    WebkitTextFillColor: "#393939 !important",
  },
  "&.white": {
    backgroundColor: "white",
  },
}));

export interface InputViewOnlyFieldProps {
  /**The read-only value to be displayed*/
  value: string;
  /**
   * Input field label
   */
  label: string | React.ReactNode;
  name?: string;
  /**Format of the input, if its money, then the display will be formatted accordingly */
  format?: "money" | "";
  /**Determine whether the input is plain/ has to show with formatting*/
  plain?: boolean;
  /**
   * Input field type
   */
  type?: string;
  /**
   * Custom style
   */
  customStyle?: string;
  /**
   * If true, the input element will be disabled.
   */
  disabled?: boolean;
  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**Custom classname */
  className?: string;
  tabIndex?: number;
  labelClassName?: string;
  sx?: SxProps;
  helpText?: string;
}

export default function InputViewOnlyField(props: InputViewOnlyFieldProps) {
  const {
    format,
    label,
    plain,
    value,
    type,
    disabled = true,
    fullWidth = false,
    className,
    name,
    tabIndex,
    labelClassName,
    helpText,
    sx,
  } = props;
  const [inputValue, setInputValue] = React.useState(value);

  React.useEffect(() => {
    if (format === "money") {
      const newValue = currencyFormatter({ amount: parseFloat(value) });
      return setInputValue(newValue);
    }
  }, [format, value]);

  return (
    <>
      {label && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="start"
        >
          <Grid item>
            <LabelViewOnly labelClassName={labelClassName} label={label} />
          </Grid>
          {Boolean(helpText) && (
            <Tooltip
              enterTouchDelay={0}
              title={<Grid container>{helpText}</Grid>}
            >
              <Grid item>
                <InfoIcon style={{ marginTop: "6px" }} />
              </Grid>
            </Tooltip>
          )}
        </Grid>
      )}

      {plain ? (
        <EnhancedPlain
          tabIndex={tabIndex}
          type={type || "text"}
          value={inputValue}
          name={name}
          fullWidth={fullWidth}
          className={className}
          sx={sx}
        />
      ) : (
        <EnhancedField
          tabIndex={tabIndex}
          value={inputValue}
          name={name}
          variant="outlined"
          margin="dense"
          type={type || "text"}
          error={false}
          disabled={disabled}
          fullWidth={fullWidth}
          className={className}
          sx={sx}
        />
      )}
    </>
  );
}
