import { Stack, Typography, useTheme } from "@mui/material";

const DealFeature = ({
  title,
  content,
  index,
}: {
  readonly title: string;
  content: string | number | undefined;
  index: number;
}) => {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      sx={{
        bgcolor: "#a2a6c391",
        border: "2px dashed #6087ac",
        borderRadius: 1,
        pb: 1,
        pt: 1,
        px: 1,
      }}
    >
      <Typography
        sx={{
          bgcolor:
            index % 2 === 0
              ? theme.palette.error.main
              : theme.palette.primary.main,

          color: "white.main",
          fontSize: "1.1em",
          fontWeight: "bold",
          lineHeight: "1em",
          height: "3em",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          m: "auto",
          width: "100%",
          pl: 1,
          borderRadius: 1,
          display: "flex",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color:
            index % 2 === 0
              ? theme.palette.error.main
              : theme.palette.primary.main,

          fontSize: "1em",
          fontWeight: "bold",
          lineHeight: "2em",
          width: "100%",
          pl: 1,
          textAlign: "center",
        }}
      >
        {content === "" || !content ? "Incomplete" : content}
      </Typography>
    </Stack>
  );
};
export default DealFeature;
