import SyncIcon from "@mui/icons-material/Sync";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  useGetAccountByNameQuery,
  useGetDocumentsQuery,
} from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";

import toCamelCase from "helpers/camelCase";
import type { RequiredDoc } from "types";
import InternalDocumentList from "./InternalList";

const AccountTypeDocuments = ({
  account_internal_name,
}: {
  account_internal_name: string;
}) => {
  const { data: account } = useGetAccountByNameQuery(account_internal_name, {
    skip: !Boolean(account_internal_name),
  });
  const {
    data: pendingDocs,
    isFetching,
    refetch,
  } = useGetDocumentsQuery(
    {
      account_id: account?.id ?? "",
    },
    { skip: !Boolean(account) }
  );
  const flattenedDocListForComparison = pendingDocs?.data
    ?.map((item) => {
      if (item.items && item.items.length) return item.items;
      return item;
    })
    .flat();
  const initialValues =
    flattenedDocListForComparison
      ?.map((item) => item.display_name)
      ?.reduce((a, item) => {
        return Object.assign(a, {
          [toCamelCase(item)]: null,
        });
      }, {}) ?? {};
  const docDictionary: RequiredDoc[] =
    pendingDocs?.data?.map((doc) => ({
      ...doc,
      name: toCamelCase(doc.display_name),
      label: doc.display_name,
      value: doc.display_name,
      items: doc.items.map((sub) => ({
        ...sub,
        name: toCamelCase(sub.display_name),
        label: sub.display_name,
        value: sub.display_name,
      })),
    })) ?? [];

  const MANDATORY_DOCS = docDictionary.filter((doc) => doc.is_mandatory);
  const OPTIONAL_DOCS = docDictionary.filter((doc) => !doc.is_mandatory);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1em",
            px: 2,
            textDecoration: "underline",
          }}
        >
          Mandatory Documents
        </Typography>
        <IconButton onClick={refetch}>
          <SyncIcon />
        </IconButton>
      </Stack>
      {isFetching && !account && <SkeletonLoad bars={10} />}
      {account && (
        <InternalDocumentList
          initialValues={initialValues}
          docs={MANDATORY_DOCS}
          entity_id={account?.id}
        />
      )}
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "1em",
          px: 2,
          textDecoration: "underline",
        }}
      >
        Optional Documents
      </Typography>
      {isFetching && !account && <SkeletonLoad bars={10} />}
      {account && (
        <InternalDocumentList
          initialValues={initialValues}
          docs={OPTIONAL_DOCS}
          entity_id={account?.id}
          optional
        />
      )}
    </Stack>
  );
};
export default AccountTypeDocuments;
