import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { VITE_ORIGINATION_TAB } from "experimental_features";
import { PERMISSIONS } from "permissions";
import * as React from "react";
import { useAppSelector } from "redux/hooks";
import Compliance from "./Compliance";
import Configuration from "./Configuration";
import FactSheetStatus from "./Factsheet";
import Legal from "./Legal";
import MapDocuments from "./MapDocuments";
import SectionsMenu from "./MapSectionsHOC";
import CCPresentation from "./Origination/CCPresentation";
import Risk from "./Risk";
import Summary from "./Summary";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `map-sections-tab-${index}`,
    "aria-controls": `map-sections-tabpanel-${index}`,
  };
}
const TabPanelDiv = styled("div")`
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
`;

const TAB_ORDER: Record<string, number> = {
  summary: 0,
  risk: 1,
  legal: 2,
  compliance: 3,
  origination: 4,
};
const MapSections = () => {
  const tab = location.search?.split("=")[1];
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const [value, setValue] = React.useState(tab ? TAB_ORDER[tab] : 0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <TabPanelDiv
        role="tabpanel"
        hidden={value !== index}
        id={`map-sections-tabpanel-${index}`}
        aria-labelledby={`map-sections-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: {
                xs: 0,
                lg: 3,
              },

              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.divider,
              borderRadius: 1,
              flexGrow: 1,
              mx: 1,
              justifyContent: "center",
              boxShadow: 1,
              [theme.breakpoints.down("sm")]: {
                maxHeight: "60vh",
                overflow: "auto",
              },
            }}
          >
            {children}
          </Box>
        )}
      </TabPanelDiv>
    );
  }

  const MAP_SECTIONS: Array<{
    name: string;
    component: React.ComponentType;
    disabled: boolean;
    parent: string;
  }> = [
    {
      name: "Summary",
      component: Summary,
      disabled: false,
      parent: "GENERAL",
    },
    {
      name: "Documents",
      component: MapDocuments,
      disabled: false,
      parent: "GENERAL",
    },
    ...(VITE_ORIGINATION_TAB
      ? [
          {
            name: "Origination",
            component: CCPresentation,
            disabled:
              !USER_PERMISSIONS.includes(PERMISSIONS.map_access_compliance) &&
              !USER_PERMISSIONS.includes(PERMISSIONS.map_view_compliance),
            parent: "PRE-CC",
          },
        ]
      : []),
    {
      name: "Compliance",
      component: Compliance,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_compliance) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_compliance),
      parent: "PRE-CC",
    },
    {
      name: "Legal",
      component: Legal,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_legal) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_legal),
      parent: "PRE-CC",
    },
    {
      name: "Risk",
      component: Risk,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_risk) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_risk),
      parent: "PRE-CC",
    },
    {
      name: "Investment Memo",
      component: FactSheetStatus,
      disabled: false,
      parent: "POST-CC",
    },
    ...(USER_PERMISSIONS.includes(PERMISSIONS.map_admin)
      ? [
          {
            name: "Configuration",
            component: Configuration,
            disabled: false,
            parent: "MAINTENANCE",
          },
        ]
      : []),
  ];

  return (
    <SectionsMenu
      sections={MAP_SECTIONS}
      handleChange={handleChange}
      activeTab={value}
    >
      {MAP_SECTIONS.map((section, index) => (
        <TabPanel key={section.name} value={value} index={index}>
          <section.component />
        </TabPanel>
      ))}
    </SectionsMenu>
  );
};
export default MapSections;
