import { ArrowCircleRight, Info, Map } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import history from "helpers/history";

import moment from "moment";
import React from "react";

type ScoreType = "Low" | "Medium Low" | "Medium" | "Medium High" | "High";

interface MapTable {
  map_id: string;
  buyer: string;
  seller: string;
  relationship_id: string;
  score: ScoreType;
  next_monitoring_date: Date;
}

interface MonitoringListProps {
  map: MapTable;
  overdue?: boolean;
}

const MonitoringList: React.FC<MonitoringListProps> = (props) => {
  const { map, overdue } = props;

  return (
    <ListItem
      onClick={() =>
        history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}/${map.map_id}`)
      }
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="go-to-map"
          onClick={() =>
            history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}/${map.map_id}`)
          }
        >
          <ArrowCircleRight color="error" />
        </IconButton>
      }
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        cursor: "pointer",
        my: 0.5,
        ":hover": {
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.50) inset",
        },
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10) inset",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "error.main" }}>
          <Map />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Supplier:</Typography>
            <Typography color="error">{`${map.seller}`}</Typography>
            <Typography>{` - `}</Typography>
            <Typography fontWeight="bold">Buyer:</Typography>
            <Typography color="error">{`${map.buyer}`}</Typography>
            <Typography fontWeight="bold">{`${map.map_id}`}</Typography>
            <Typography>{overdue ? "overdue by" : "due in"}</Typography>
            <Typography color="error">{`${
              overdue
                ? moment(moment()).diff(
                    moment(map.next_monitoring_date),
                    "days"
                  )
                : moment(map.next_monitoring_date).diff(moment(), "days") + 1
            } days.`}</Typography>
            <Tooltip
              title={`Next monitoring date: ${moment(map.next_monitoring_date).format("DD/MM/YY")}`}
            >
              <Info />
            </Tooltip>
          </Stack>
        }
      />
    </ListItem>
  );
};

export default MonitoringList;
