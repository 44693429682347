import { CL_APPROVED } from "codes";
import { useEffect, useState } from "react";
import type { PartnerData } from "types";

const additionalInformationFilled = (partner: PartnerData) => {
  return partner?.industry !== "" &&
    partner?.goods_category !== "" &&
    partner?.annual_revenue !== null &&
    partner?.annual_revenue !== undefined
}

const tradeDetailsFilled = (partner: PartnerData) => {
  return partner?.average_invoice_amount !== null &&
    partner?.average_invoice_amount !== undefined &&
    partner?.average_payment_terms !== "" &&
    partner?.number_of_past_paid_transaction !== null &&
    partner?.number_of_past_paid_transaction !== undefined
}

const creditLimitApproved = (partner: PartnerData) => {
  return partner?.credit_limit !== null &&
    partner?.credit_limit !== undefined &&
    partner?.credit_limit.approved_amount !== null &&
    partner?.credit_limit.approved_amount !== undefined &&
    partner?.credit_limit.status === CL_APPROVED
}

const usePartnerStatus = (partner: PartnerData) => {
  const [
    isPartnerAdditionalInformationFilledUp,
    setisPartnerAdditionalInformationFilledUp,
  ] = useState(additionalInformationFilled(partner));
  const [
    isPartnerTradeDetailsInformationFilledUp,
    setisPartnerTradeDetailsInformationFilledUp,
  ] = useState(tradeDetailsFilled(partner));
  const [
    isPartnerCreditLimitApproved,
    setisPartnerCreditLimitApproved,
  ] = useState(creditLimitApproved(partner));

  useEffect(() => {
    setisPartnerAdditionalInformationFilledUp(additionalInformationFilled(partner));
    setisPartnerTradeDetailsInformationFilledUp(tradeDetailsFilled(partner));
    setisPartnerCreditLimitApproved(creditLimitApproved(partner))
  }, [partner]);
  return {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
    isPartnerCreditLimitApproved
  };
};

export default usePartnerStatus;
