import { Grid, Stack, Typography } from "@mui/material";
import { useGetFxRatesQuery } from "api/api-vendorsv2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import groupBy from "helpers/groupBy";

import NoData from "components/Common/NoData";
import moment from "moment";
import React from "react";
import FxTable from "./FxTable";

interface FxRatesProps {
  from: Date | string | undefined;
  to: Date | string | undefined;
}
const FxRates: React.FC<FxRatesProps> = ({ from, to }) => {
  const { data: fxRates, isLoading } = useGetFxRatesQuery({
    from: moment(from).format("YYYY-MM-DD"),
    to: moment(to).format("YYYY-MM-DD"),
  });

  const BY_DATE = groupBy(fxRates ?? [], "date");
  const LATEST_BY_ONE_WEEK = Object.entries(BY_DATE)
    .slice(0, 7)
    .reduce(
      (acc, [date, fxRate]) => {
        acc[date] = fxRate;
        return acc;
      },
      {} as Record<string, typeof fxRates>
    );

  return (
    <Grid container spacing={2}>
      {isLoading && <SkeletonLoad bars={10} />}
      {!isLoading && fxRates?.length === 0 && (
        <NoData text="FX Rates Unavailable" />
      )}

      {!isLoading &&
        fxRates &&
        Object.entries(LATEST_BY_ONE_WEEK).map(([date, fxRate]) => (
          <Grid item xs={12} key={date}>
            <Stack spacing={2}>
              <Typography variant="h4">
                {moment(date)?.format("ddd, MMM Do YYYY")}
              </Typography>
              {fxRate && <FxTable fxRates={fxRate} />}
            </Stack>
          </Grid>
        ))}
    </Grid>
  );
};

export default FxRates;
