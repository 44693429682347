import Drawer from "@mui/material/Drawer";
import type { MAP_PARTY, MAP_USE_CASE } from "api/api-maps";
import * as React from "react";
import ViewTemplate from "./ViewTemplate";

interface MapItemTemplate {
  use_case: MAP_USE_CASE;
  version: string;
  party: MAP_PARTY;
  ipl_product: string;
  template_name: string;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  templates: [MapItemTemplate | undefined, MapItemTemplate | undefined];
  setTemplates: React.Dispatch<
    React.SetStateAction<
      [MapItemTemplate | undefined, MapItemTemplate | undefined]
    >
  >;
}

export default function TemplatesDrawer({
  open,
  setOpen,
  templates,
  setTemplates,
}: Props) {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
      if (!open) {
        setTemplates([undefined, undefined]);
      }
    };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <ViewTemplate templates={templates} />
      </Drawer>
    </div>
  );
}
