import { Alert, Checkbox, Grid, Stack, Typography } from "@mui/material";
import {
  useEditCheckListCompanyMutation,
  useGetCreditReportQuery,
} from "api/api-compliance";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import { isMobile } from "react-device-detect";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[] | boolean;
  };
}

interface ScreeningStatusDataType {
  [section: string]: {
    [field: string]: string[];
  };
}

interface Props {
  checklist: MapTemplate;
  accountType: string;
  isLocked?: boolean;
  screeningStatusData?: ScreeningStatusDataType;
}

const IncomlendInternalComplianceRequirements: React.FC<Props> = ({
  checklist,
  accountType,
  isLocked,
  screeningStatusData,
}) => {
  const mapName = useContext(MapContext);
  const [patchValue] = useEditCheckListCompanyMutation();
  const { data: creditReportValues, isLoading: valuesLoading } =
    useGetCreditReportQuery(
      {
        map_name: mapName ?? "",
        account_type: accountType,
      },
      { skip: !mapName }
    );

  const handlePatchValue = async (key: string, formikProps: any) => {
    patchValue({
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      data: {
        map_metadata_id: formikProps.values[key]?.id,
        source: "",
        value: !formikProps.values[key]?.data ? "true" : "false",
      },
    });
  };

  const getLabel = (name: string) => {
    const email = creditReportValues?.finalversion?.Email?.name;
    const website = creditReportValues?.finalversion?.Website?.name;

    switch (true) {
      case name?.toLowerCase().includes("email"):
        return email ? `Email(${email})` : "Email";
      case name?.toLowerCase().includes("website"):
        return website ? `Website(${website})` : "Website";
      default:
        return name;
    }
  };

  const renderAlerts = () => {
    return Object.entries(screeningStatusData || {})?.map(
      ([section, fields]) => (
        <Alert severity="warning" sx={{ mt: 2 }} key={section}>
          <Typography variant="subtitle1">{section}:</Typography>
          <ul>
            {Object.entries(fields)?.map(([field, missingItems]) => (
              <li key={field}>
                {field}: {missingItems?.join(", ")}
              </li>
            ))}
          </ul>
        </Alert>
      )
    );
  };

  return (
    <>
      {valuesLoading ? (
        <SkeletonLoad bars={10} />
      ) : (
        <Formik
          initialValues={{ ...checklist }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {(formikProps) => (
            <Form>
              <Stack spacing={1} justifyContent="space-between">
                {Object.entries(formikProps.values).map(([key, value]) => (
                  <Grid item xs={10} key={key}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Grid item xs={10}>
                        <LabelViewOnly
                          label={getLabel(value.name)}
                          sx={{ fontWeight: 500 }}
                          helpText={
                            getLabel(value.name) === "Email" ||
                            getLabel(value.name) === "Website"
                              ? "Email/Website does not have a final version"
                              : ""
                          }
                        />
                      </Grid>
                      <Checkbox
                        name={`${[key]}.data`}
                        checked={formikProps.values[key]?.data as boolean}
                        sx={{ padding: isMobile ? 0 : 1 }}
                        disabled={
                          isLocked ||
                          getLabel(value.name) === "Email" ||
                          getLabel(value.name) === "Website"
                        }
                        onChange={() => {
                          const newValue = !formikProps.values[key]?.data;
                          formikProps.setFieldValue(key, {
                            ...formikProps.values[key],
                            data: newValue,
                          });
                          handlePatchValue(key, formikProps);
                        }}
                      />
                    </Stack>
                  </Grid>
                ))}
                {!valuesLoading && renderAlerts()}
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default IncomlendInternalComplianceRequirements;
