import React from "react";
import { Box, Container, Stack } from "@mui/material";
import Drop from "components/Common/Drop";
import CreateTicket from "components/Ticket/CreateTicket";
import type { OverridableStringUnion } from "types";
import CreateProject from "components/Project/CreateProject";
import CreateSprint from "components/Sprint/CreateSprint";

interface CreateProps {
  type: OverridableStringUnion<"Ticket" | "Sprint" | "Project" >;
  typeSetter?: (value: string) => void;
}

const CreateTask: React.FC<CreateProps> = ({ type, typeSetter }) => {
  const createDropdownOptions = [
    {
      value: "ticket",
      name: "Ticket",
    },
    {
      value: "sprint",
      name: "Sprint",
    },
    {
      value: "project",
      name: "Project",
    },
  ];

  return (
    <Box width="100%">
      <Container>
        <Stack>
          {typeSetter && (
            <Drop
              label="Type"
              multilevel={false}
              data={createDropdownOptions}
              name="createType"
              value={type}
              keyValue="name"
              setValue={typeSetter}
            />
          )}
          <Box
            width="100%"
            sx={{
              marginBlock: "4px",
              border: "2px solid",
              borderRadius: "10px",
              paddingBlock: "10px",
            }}
          >
            <Container>
              <Stack>
                {type === "Ticket" && <CreateTicket />}
                {type === "Sprint" && <CreateSprint />}
                {type === "Project" && <CreateProject />}
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
export default CreateTask;
