import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoData from "components/Common/NoData";
import moment from "moment";
import React, { Fragment } from "react";

interface SOFRRate {
  name: string;
  type: string;
  date: Date;
  rate: string;
  created_by: string;
  last_modified_date: Date;
}
interface CMA_SOFRRate {
  price: string;
  term: string;
  timestamp: string;
}

interface Rates {
  sofrRatesFixing: CMA_SOFRRate[];
}

interface ResultsStrip {
  date: string;
  rates: Rates;
  average30day: number;
  average90day: number;
  average180day: number;
  index: number;
  overnight: string;
}

interface SOFRTableProps {
  data: SOFRRate[];
  showAll?: boolean;
}
const SOFRTable: React.FC<SOFRTableProps> = ({ data, showAll = false }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        bgcolor: "background.default",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
              }}
            >
              Date
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
              }}
            >
              Price
            </TableCell>
            {showAll && (
              <Fragment>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Created By
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Last Modified Date
                </TableCell>
              </Fragment>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <NoData text={"No Rates Fetched"} />
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 &&
            data.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{moment(row.date).format("DD/MM/YYYY")}</TableCell>
                <TableCell
                  sx={{
                    textAlign: showAll ? "left" : "right",
                  }}
                >
                  {row.rate}
                </TableCell>
                {showAll && (
                  <Fragment>
                    <TableCell>{row.created_by}</TableCell>
                    <TableCell>
                      {moment(row.last_modified_date).format("DD/MM/YYYY")}
                    </TableCell>
                  </Fragment>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SOFRTable;
