import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetCCOnboardingStatusesQuery } from "api/api-credit-committee";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreditCommittee from "components/CreditCommittee";
import CCFilter from "components/CreditCommittee/Filters";
import groupBy from "helpers/groupBy";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useState } from "react";

import NoData from "components/Common/NoData";
import type { SalesOpportunity } from "types";
import styles from "../sales.module.scss";

const StatusesPage = () => {
  const theme = useTheme();
  const { data, isFetching, refetch, isSuccess } =
    useGetCCOnboardingStatusesQuery(null);
  const isMobile = useIsMobile();

  const MONTH_ABBR = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getRemainingDays = (month: string) => {
    const d = moment();
    const year = d.get("year");
    const currentDay = d.get("date");
    const currentMonth = d.get("M") + 1;
    const targetMonth = MONTH_ABBR.indexOf(month) + 1;

    const daysInTargetMonth = moment(
      `${year}-${targetMonth}`,
      "YYYY-MM"
    ).daysInMonth();
    const daysInCurrentMonth = moment(
      `${year}-${currentMonth}`,
      "YYYY-MM"
    ).daysInMonth();

    return (
      daysInTargetMonth +
      daysInCurrentMonth * (targetMonth - currentMonth) -
      currentDay
    );
  };
  function sortedByMonths(data: any[]) {
    const groupedObject = groupBy(data, "etaMonth");
    return Object.keys(groupedObject)
      .sort((a, b) => {
        const getIndex = (month: string) =>
          MONTH_ABBR.indexOf(month?.slice(0, 3));

        return getIndex(a) > 0 ? getIndex(a) - getIndex(b) : 13;
      })
      .reduce(
        (r, k) => ((r[k] = groupedObject[k]), r),
        {} as Record<string, any[]>
      );
  }
  const [filteredData, setFilteredData] = useState<
    SalesOpportunity[] | undefined
  >(data);

  useEffect(() => {
    if (!filteredData) setFilteredData(data);
  }, [isSuccess]);
  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      <Layout
        title="Onboarding Statuses"
        backArrow={false}
        textHeadingOptions={{
          fontWeight: "bolder",
          level: 2,
          fontSize: isMobile ? "1.5em" : "1.5em",
        }}
        headerConfig={{
          syncAction: refetch,
          left: 4,
          mid: 8,
          right: 0,
          xs: {
            left: 12,
            mid: 0,
            right: 0,
          },
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        className={styles.layout}
        LayoutHeader={
          <Stack
            direction="row"
            spacing={3}
            justifyContent="start"
            alignItems="center"
          >
            <Typography
              textAlign="start"
              fontWeight="bold"
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                py: 0.5,
                px: 2,
                borderRadius: 1,
              }}
            >
              {`${data?.length ?? 0} deal${data && data?.length > 1 ? "s" : ""}`}
            </Typography>
          </Stack>
        }
        mode="default"
      >
        <Stack spacing={1}>
          <Grid
            container
            spacing={1}
            justifyContent="end"
            alignItems="center"
          >
            {data && (
              <Grid item xs={12}>
                <CCFilter
                  data={data}
                  setFilteredData={setFilteredData}
                  filteredData={filteredData}
                />
              </Grid>
            )}
            <Grid item lg={1.3} xs={4}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: "#9542ee",
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: 1,
                  height: "auto",
                  py: 0.5,
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="0.6em"
                  color="#fff"
                >
                  Opportunity ID
                </Typography>
              </Avatar>
            </Grid>

            <Grid item lg={1} xs={4}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: "#fcc473",
                  width: "100%",
                  border: "1px solid black",
                  color: theme.palette.primary.main,
                  borderRadius: 1,
                  height: "auto",
                  py: 0.5,
                }}
              >
                <Typography fontWeight="bold" fontSize="0.6em">
                  Insurer
                </Typography>
              </Avatar>
            </Grid>
            <Grid item lg={1} xs={4}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: "#0472ca",
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: 1,
                  height: "auto",
                  py: 0.5,
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="0.6em"
                  color="#fff"
                >
                  Sales Ower
                </Typography>
              </Avatar>
            </Grid>
          </Grid>
          {isFetching && !filteredData && <SkeletonLoad bars={10} />}
          {!isFetching && filteredData && !Boolean(filteredData?.length) && (
            <NoData text="No Opportunities Found" height="60vh" />
          )}

          {!isFetching &&
            filteredData &&
            Boolean(filteredData?.length) &&
            Object.entries(sortedByMonths(filteredData)).map(
              ([etaMonth, items]) => {
                return (
                  <Accordion
                    defaultExpanded
                    sx={{
                      ".Mui-expanded": {
                        margin: "1ch 0 !important",
                        minHeight: "10px !important",
                      },
                    }}
                    key={`${etaMonth}-accord`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${etaMonth}-content`}
                      id={`${etaMonth}-header`}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        width="100%"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <Grid item>
                          <WarningIcon
                            color="warning"
                            sx={{
                              visibility:
                                etaMonth !== "null" ? "hidden" : "visible",
                              display: "flex",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography textAlign="start" fontWeight="bold">
                            {etaMonth}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography textAlign="start">
                            {etaMonth !== "null" &&
                              `${getRemainingDays(
                                etaMonth?.slice(0, 3)
                              )} days left`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            textAlign="start"
                            sx={{
                              border: `1px solid ${theme.palette.primary.main}`,
                              py: 0.5,
                              px: 2,
                              borderRadius: 1,
                            }}
                            fontWeight="bold"
                          >
                            {`${items?.length} deal${
                              items?.length > 1 ? "s" : ""
                            }`}
                          </Typography>
                        </Grid>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      {items?.map((item) => (
                        <CreditCommittee
                          item={item}
                          key={item.salesOpportunityId?.name}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
        </Stack>
      </Layout>
    </Box>
  );
};
export default StatusesPage;
