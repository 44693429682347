import { Stack, Typography } from "@mui/material";
import NoData from "components/Common/NoData";
import React from "react";

const CallSection: React.FC<{
  title?: string;
  name?: string;
  product?: string;
  interviewees?: string[];
  attendees?: string[];
  date?: Date;
  call_summary?: string | TrustedHTML;
}> = ({
  title = "",
  name = "",
  product = "",
  interviewees = [],
  attendees = [],
  date = "",
  call_summary = "",
}) => {
  return (
    <Stack spacing={2} width="100%">
      <Typography color="error" fontWeight="bold" fontSize="1.2em">
        {title} Call
      </Typography>
      <Stack spacing={1} width="100%">
        <Typography fontWeight="bold">{name}</Typography>
        <Stack spacing={1} width="100%" direction="row">
          <Typography>IPL Participants:</Typography>
          <Typography color={attendees?.length ? "primary" : "error"}>
            {`${attendees?.length ? attendees?.join(", ") : "None assigned"}`}
          </Typography>
          <Typography>/ Call Date: </Typography>
          <Typography color={date !== "" ? "primary" : "error"}>
            {`${date !== "" ? date : "Not Scheduled"}`}
          </Typography>
        </Stack>
        <Stack spacing={1} width="100%" direction="row">
          <Typography>{`Interviewees: `}</Typography>
          <Typography
            color={interviewees?.length ? "primary" : "error"}
          >{`${interviewees?.length ? interviewees?.join(", ") : "None scheduled"}`}</Typography>
        </Stack>
        <Stack spacing={1} width="100%" direction="row">
          <Typography>{`Product: `}</Typography>
          <Typography
            color={product ? "primary" : "error"}
          >{`${product ?? "None specified"}`}</Typography>
        </Stack>
      </Stack>
      <Stack spacing={1} width="100%">
        <Typography fontWeight="bold">{title} Background</Typography>
        {call_summary ? (
          <div dangerouslySetInnerHTML={{ __html: call_summary }} />
        ) : (
          <NoData
            text="-- Incomplete --"
            sx={{
              bgcolor: "#C0C0C0",
              fontSize: "1.2em",
              fontWeight: "bold",
              lineHeight: "3em",
              width: "98%",
              pl: 1,
              mt: "0.5em",
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CallSection;
