import LoadingButton from "@mui/lab/LoadingButton";
import { Chip, Stack, Typography } from "@mui/material";
import { CL_APPROVED, CL_REJECTED } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import isObjectEmpty from "helpers/isObjectEmpty";
import { pxToRem } from "helpers/pxToRem";
import moment from "moment";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import type { CLDetails } from "types";
import CofaceResponseDrawer from "./Coface/CofaceResponseJSONdisplay";
import type { Coface_JSON } from "./Coface/coface_response";

const InsurerObject: React.FC = () => {
  const formikProps = useFormikContext<CLDetails>();

  const [open, setOpen] = useState(false);

  const { values } = formikProps;

  const {
    id,
    sf_link,
    currency,
    coface_amount,
    salesLead,
    coface_found,
    requested_to_coface_by,
    rating,
    coface_rating_description,
    internalStatus,
    coface_json,
    coface_last_update,
  } = values;

  const COFACE_REQUEST_PENDING =
    internalStatus !== CL_APPROVED &&
    internalStatus !== CL_REJECTED &&
    (!requested_to_coface_by || requested_to_coface_by === "Not Available");
  const { company, orderResults } =
    coface_json && "icon" in coface_json
      ? (coface_json.icon as Coface_JSON)
      : {};

  return (
    <MainCard
      sx={{
        minWidth: pxToRem(330),
        width: "100%",
      }}
      headerSx={{
        px: 2,
        py: 2,
      }}
      title={
        <Typography
          fontWeight="bold"
          fontSize={isMobile ? "1.1em" : "1.3em"}
          variant="h3"
        >
          Coface Indication
        </Typography>
      }
      footerSx={{
        mt: "auto",
      }}
      footer={
        coface_json && !isObjectEmpty(coface_json) ? (
          <Stack spacing={1} justifyContent="flex-end" width={"100%"}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              ml="auto"
            >
              <LoadingButton
                sx={{
                  textAlign: "end",
                }}
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                View Response from Coface
              </LoadingButton>
            </Stack>
            <Typography textAlign="end">
              Last updated:{" "}
              {coface_last_update
                ? moment(coface_last_update).utc().format("YYYY-MM-DD HH:mm:ss")
                : "Not Available"}
            </Typography>
          </Stack>
        ) : undefined
      }
    >
      {COFACE_REQUEST_PENDING && (
        <NoData
          text={`Needs to Request Coface - Please click on 'Request Coface' on the top of this page.`}
        />
      )}
      {!COFACE_REQUEST_PENDING && (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Coface Status:</Typography>

            <Chip
              label={
                coface_found ? "Coface Identified" : "Coface Not Available"
              }
              color={coface_found ? "success" : "error"}
              variant="outlined"
              size="small"
            />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Requested To Coface By:</Typography>
            <Typography>{requested_to_coface_by ?? "Not Available"}</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Coface Rating:</Typography>
            <Typography>{`${rating ? `${rating}/10` : "Not Available"}`}</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">
              Coface Rating Description:
            </Typography>
            <Typography>{`${
              coface_rating_description
                ? `${coface_rating_description}`
                : "Not Available"
            }`}</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Coface Amount:</Typography>
            <Typography>{`${currency ?? "USD"} ${currencyFormatter({
              amount: coface_amount ?? 0,
            })}`}</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Address Selected:</Typography>
            <Typography>
              {company
                ? `
            ${company?.addressLine}, 
            ${company?.cityName},
            ${company?.postalCode},
            ${company?.country?.name}
            `
                : "Not Available"}
            </Typography>
          </Stack>

          {coface_json && (
            <CofaceResponseDrawer
              open={open}
              setOpen={setOpen}
              value={coface_json as Coface_JSON}
            />
          )}
        </Stack>
      )}
    </MainCard>
  );
};

export default InsurerObject;
