import React from "react";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";

interface Project {
  name: string;
  description: string;
}

const CreateProject = () => {
  const formikProps = useFormikContext<Project>();

  return (
    <React.Fragment>
      <Input name="name" label="Title" required {...formikProps} />
      <Input name="description" label="Description" {...formikProps} multiline />
    </React.Fragment>
  );
};

export default CreateProject;
