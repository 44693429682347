import { Box, Grid, Slide, Typography } from "@mui/material";
import Layout from "components/Common/Layout";
import useIsMobile from "hooks/useIsMobile";
import DataTablev2 from "components/Common/DataTablev2";
import { useRef } from "react";
import useWidth from "helpers/useWidth";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { BILLINGS_LINK, DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK } from "codes";
import StatusDisplay from "components/Billings/StatusDisplay";
import moment from "moment";
import {
  useGetCustomerTermsheetBillingListQuery,
  useGetTermsheetBillingListQuery,
} from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";
import NoData from "components/Common/NoData";
import type { Account, TermsheetInvoice } from "types";
import history from "helpers/history";

const Billings: React.FC = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const textSx = {
    fontSize: "1.1em!important",
    lineHeight: "2em",
    height: "1.8em!important",
    overflow: "hidden",
    maxWidth: "76%",
    textOverflow: "ellipsis",
    minWidth: isMobile ? "100%" : "10vw",
  };
  const { data: curr_user, isFetching: isFetchingUser } =
    useGetCurrentUserQuery();
  const isInternal = curr_user?.is_internal;
  const defaultColumnWidth = width ? (width / (isInternal ? 8 : 6)) * 1.2 : 200;
  const {
    data: bills,
    isFetching,
    refetch,
  } = useGetTermsheetBillingListQuery(null, {
    skip: !isInternal,
  });
  const {
    data: customerBills,
    refetch: refetchCustomerBills,
    isFetching: isFetchingCustomerBills,
  } = useGetCustomerTermsheetBillingListQuery(null, {
    skip: isInternal,
  });
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const billsToDisplay = isInternal ? bills : customerBills;
  const isFetchingBills = isInternal ? isFetching : isFetchingCustomerBills;

  return (
    <Slide in direction="left">
      <Box flexGrow={1}>
        <Layout
          title="Finance Billings"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: () => {
              if (isInternal) {
                refetch();
              } else {
                refetchCustomerBills();
              }
            },
            left: 12,
            mid: 0,
            right: 0,
          }}
          mode="default"
          alignItems="center"
        >
          <Grid item xs={12} ref={ref}>
            <Grid container spacing={1}>
              {isFetchingBills && <SkeletonLoad bars={10} />}
              {!isFetchingBills &&
                billsToDisplay &&
                billsToDisplay?.length > 0 && (
                  <DataTablev2
                    rowsPerPage={rowsPerPage}
                    defaultColumnWidth={defaultColumnWidth}
                    data={billsToDisplay}
                    toBeHidden={isInternal ? [] : ["internal_name", "lead"]}
                    customColumns={{
                      internal_name: {
                        internal_name: "Bill Id",
                        type: "text",
                        link: {
                          href: (row: TermsheetInvoice) =>
                            `${INCOMLEND_INTERNAL_LINK}${BILLINGS_LINK}/${row?.internal_name}`,
                          target: "_self",
                        },
                      },
                      reference_number: {
                        reference_number: "Ref No",
                        type: "text",
                        link: isInternal
                          ? undefined
                          : {
                              href: (row: TermsheetInvoice) =>
                                `${BILLINGS_LINK}/${row?.id}`,
                              target: "_self",
                            },
                      },
                      lead: {
                        lead: "Customer",
                        type: "text",
                        minWidth: 260,
                        renderCell: ({ value }: { value: Account }) => (
                          <Typography sx={textSx}>{value.name}</Typography>
                        ),
                      },
                      amount_paid: {
                        amount_paid: "Amount",
                        type: "text",
                      },
                      paid_at: {
                        paid_at: "Paid On",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Typography
                            sx={textSx}
                            color={value ? "primary" : "grey"}
                          >
                            {value
                              ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                              : "None"}
                          </Typography>
                        ),
                      },
                      approved_at: {
                        approved_at: "Payment Approved On",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Typography
                            sx={textSx}
                            color={value ? "primary" : "grey"}
                          >
                            {value
                              ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                              : "None"}
                          </Typography>
                        ),
                      },
                      invoice_status: {
                        invoice_status: "Status",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Grid
                            container
                            justifyContent={isMobile ? "left" : "center"}
                          >
                            <Grid item xs={12}>
                              <StatusDisplay status={value} />
                            </Grid>
                          </Grid>
                        ),
                      },
                    }}
                    onRowClicked={(row) => {
                      isInternal
                        ? history.push(
                            `${INCOMLEND_INTERNAL_LINK}${BILLINGS_LINK}/${row?.internal_name}`
                          )
                        : history.push(`${BILLINGS_LINK}/${row?.id}`);
                    }}
                  />
                )}
              {!isFetchingBills &&
                (!billsToDisplay || billsToDisplay?.length === 0) && (
                  <NoData text="No termsheets found" sx={{ margin: "auto" }} />
                )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};

export default Billings;
