import equal from "fast-deep-equal";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { AccountInputDetailed } from "./types";

interface CreditReportField {
  name: string;
  id: string;
}

interface CreditReportResponse {
  [source: string]: {
    [field_name: string]: CreditReportField;
  };
}

const useGetDiscrepancies = (
  creditReportValues?: CreditReportResponse,
  accountInput?: AccountInputDetailed
) => {
  const [discrepanciesFinal, setDiscrepancies] = useState<string[]>([]);

  const formatValue = (value: any): string => {
    if (value instanceof Date) return moment(value).format("yyyy-MM-DD");
    if (typeof value === "object" && value !== null)
      return JSON.stringify(value);
    return value;
  };

  const normalizeValue = (value: any, isDate: boolean = false): string => {
    const formattedValue = formatValue(value);
    if (isDate) return formattedValue;
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return formattedValue?.toLowerCase().replace(/\s+/g, "") || "";
  };

  const checkDiscrepanciesWithAccountInput = (fields: {
    [field_name: string]: CreditReportField;
  }): string[] => {
    if (!accountInput || !creditReportValues) return [];

    return Object.entries(accountInput).reduce<string[]>(
      (result, [key, value]) => {
        if (value !== undefined && value !== null) {
          const isDate = key.includes("Date");
          const normalizedAccountValue = normalizeValue(value, isDate);
          const normalizedFieldValue = normalizeValue(
            fields[key]?.name,
            isDate
          );
          if (
            //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            fields[key]?.name &&
            normalizedAccountValue !== normalizedFieldValue
          ) {
            result.push(key);
          }
        }
        return result;
      },
      []
    );
  };

  const checkDiscrepanciesBetweenReports = (): string[] => {
    if (!creditReportValues) return [];

    const discrepanciesSet = new Set<string>();
    const allFields = new Set<string>();

    Object.entries(creditReportValues).forEach(([source, fields]) => {
      if (source !== "finalversion") {
        Object.keys(fields).forEach((field) => allFields.add(field));
      }
    });

    allFields.forEach((field) => {
      const values = new Set<string>();

      Object.entries(creditReportValues).forEach(([source, fields]) => {
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (source !== "finalversion" && fields[field]?.name) {
          values.add(
            normalizeValue(fields[field]?.name, field.includes("Date"))
          );
        }
      });

      if (values.size > 1) {
        discrepanciesSet.add(field);
      }
    });

    return Array.from(discrepanciesSet);
  };

  const cycleThroughValues = useMemo(() => {
    if (!creditReportValues) return [];

    const discrepancies = new Set<string>();

    Object.values(creditReportValues).forEach((fields) => {
      checkDiscrepanciesWithAccountInput(fields).forEach((discrepancy) =>
        discrepancies.add(discrepancy)
      );
    });

    checkDiscrepanciesBetweenReports().forEach((discrepancy) =>
      discrepancies.add(discrepancy)
    );

    // Remove discrepancies if finalversion has a value
    discrepancies.forEach((field) => {
      const nameValue = creditReportValues?.finalversion?.[field]?.name;
      //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (nameValue) discrepancies.delete(field);
    });

    return Array.from(discrepancies);
  }, [creditReportValues, accountInput]);

  useEffect(() => {
    // Update discrepancies only if they are different
    if (!equal(discrepanciesFinal, cycleThroughValues))
      setDiscrepancies(cycleThroughValues);
  }, [cycleThroughValues, discrepanciesFinal]);

  return discrepanciesFinal;
};
export default useGetDiscrepancies;
