import { Grid } from "@mui/material";
import { TourProvider, type StepType } from "@reactour/tour";
import { useGetMapVersionQuery } from "api/api-maps";
import { INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import Layout from "components/Common/Layout";
import MapSections from "components/Map/Sections";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import DetailsStrip from "./DetailsStrip";

export const MapContext = createContext<string | undefined>(undefined);
export const MapCurrentVersion = createContext<string | undefined>(undefined);

const MapGuider: StepType[] = [
  {
    selector: "#product-selection-for-map",
    content: "Choose the appropriate product type for this deal",
  },
];

const Map = () => {
  const { id = "" } = useParams();
  const { data: currentVersion } = useGetMapVersionQuery(id);

  return (
    <MapContext.Provider value={id}>
      <MapCurrentVersion.Provider value={currentVersion?.current_version}>
        <TourProvider steps={MapGuider}>
          <Layout
            title={<DetailsStrip />}
            headerConfig={{
              left: 12,
              mid: 3,
              right: 12,
              xs: {
                left: 12,
                mid: 12,
                right: 0,
              },
              alignItems: "start",
            }}
            backArrow={false}
            breadcrumbs={{
              data: [
                {
                  name: "Maps",
                  link: `${INCOMLEND_INTERNAL_LINK}${MAPS}`,
                },
                {
                  name: id,
                  link: `${window.location.pathname}`,
                  active: true,
                },
              ],
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              maxWidth="xl"
              margin="auto"
            >
              <MapSections />
            </Grid>
          </Layout>
        </TourProvider>
      </MapCurrentVersion.Provider>
    </MapContext.Provider>
  );
};
export default Map;
