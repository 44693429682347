import { Map, PriorityHigh, Unpublished } from "@mui/icons-material";

import {
  Divider,
  Grid,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetMapsQuery } from "api/api-maps";
import { INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import InfoCard, { type InfoCardProps } from "components/Common/InfoCard";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import React from "react";
import MonitoringList from "./MonitoringList";

interface MemberInfoCard extends InfoCardProps {
  isFetching: boolean;
}
const RiskManagementDashboard: React.FC = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const { data: maps, isFetching } = useGetMapsQuery(null);

  let overviewData: MemberInfoCard[] = [
    {
      title: "Supplier-Buyer Maps",
      subtitle: `${maps?.length ?? 0} maps`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <Map />,
      bottomColorBand: `1ch solid ${theme.palette.primary.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
    {
      title: "High Risk Maps",
      subtitle: `${maps?.filter((map) => map.score?.toLowerCase() === "high")?.length ?? 0} maps with high risk`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <PriorityHigh />,
      bottomColorBand: `1ch solid ${theme.palette.error.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
    {
      title: "Incomplete Maps",
      subtitle: `${maps?.filter((map) => !map.score)?.length ?? 0} of ${maps?.length ?? 0} incomplete`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <Unpublished />,
      bottomColorBand: `1ch solid ${theme.palette.secondary.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
  ];

  const overDue = maps?.filter(
    (map) =>
      map.score?.toLowerCase() === "high" &&
      moment(map.next_monitoring_date).isBefore(moment()) &&
      moment(map.next_monitoring_date).isAfter(moment().subtract(30, "days"))
  );

  const dueInAMonth = maps?.filter(
    (map) =>
      map.score?.toLowerCase() === "high" &&
      moment(map.next_monitoring_date).isBefore(
        moment().subtract(30, "days")
      ) &&
      moment(map.next_monitoring_date).isAfter(moment())
  );
  const upcomingHighRiskMaps = maps?.filter(
    (map) =>
      map.score?.toLowerCase() === "high" &&
      moment(map.next_monitoring_date).isAfter(moment().subtract(30, "days"))
  );
  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={1}>
        {overviewData &&
          overviewData.map((item, i) => {
            const { isFetching, ...rest } = item;
            if (isFetching)
              return <SkeletonLoad bars={3} key={item?.className} />;
            return (
              <Grid key={item?.className} item xs={12} lg={4}>
                <InfoCard {...rest} />
              </Grid>
            );
          })}
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3">Next Monitoring Dates</Typography>
        <Divider />
        {overDue && (
          <Stack spacing={1}>
            <Typography variant="h4" color="error.main">
              Overdue Maps
            </Typography>
            <List sx={{ width: "100%", maxWidth: "90%" }} dense>
              {overDue?.map((map) => (
                <MonitoringList key={map?.map_id} map={map} overdue />
              ))}
            </List>
          </Stack>
        )}
        {dueInAMonth && (
          <Stack spacing={dueInAMonth?.length === 0 ? 0 : 1}>
            <Typography variant="h4" color="error.main">
              {`Maps Due in a Month (Deadline: ${moment().add(30, "days").format("DD/MM/YY")})`}
            </Typography>
            <List sx={{ width: "100%", maxWidth: "90%" }} dense>
              {dueInAMonth?.map((map) => (
                <MonitoringList key={map?.map_id} map={map} />
              ))}
            </List>
          </Stack>
        )}
        {dueInAMonth?.length === 0 && (
          <NoData text="No Maps Due in a month" sx={{ maxWidth: "90%" }} />
        )}
        {upcomingHighRiskMaps && (
          <Stack spacing={1}>
            <Typography variant="h4" color="primary.main">
              Upcoming High Risk Maps
            </Typography>
            <List sx={{ width: "100%", maxWidth: "90%" }} dense>
              {upcomingHighRiskMaps?.map((map) => (
                <MonitoringList key={map?.map_id} map={map} />
              ))}
            </List>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default RiskManagementDashboard;
