import { Grid, Stack, Typography, useTheme } from "@mui/material";

interface MapConfigData {
  id: string;
  name: string;
  status: string;
  options: Record<string, any>;
}

const MapConfiguration = (data: MapConfigData) => {
  const theme = useTheme();

  return (
    <Stack spacing={1} px={1}>
      <Grid item xs={12}>
        <Typography
          fontWeight="bold"
          fontSize="1em"
          color={theme.palette.error.main}
        >
          {data.name}
        </Typography>
      </Grid>
      {/* {Object.values(data.options).map((val) => (
        <Grid item xs={12} key={val}>
          <Typography
            fontWeight={"bold"}
            fontSize={"0.9em"}
            color={theme.palette.text.primary}
          >
            {val}
          </Typography>
        </Grid>
      ))} */}
    </Stack>
  );
};

export default MapConfiguration;
