import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Slide, Stack, Typography } from "@mui/material";
import {
  useAddSofrRateMutation,
  useGetSofrRatesQuery,
} from "api/api-operations";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import SOFRTable from "components/HealthCheck/SOFRTable";
import useGetCMASofrRates from "hooks/useGetCMASofrRates";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

interface SOFRRate {
  price: string;
  term: string;
  timestamp: string;
}

interface Rates {
  sofrRatesFixing: SOFRRate[];
}

interface ResultsStrip {
  date: string;
  rates: Rates;
  average30day: number;
  average90day: number;
  average180day: number;
  index: number;
  overnight: string;
}

const SOFRPage = () => {
  const isMobile = useIsMobile();
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const IS_DASHBOARD_ADMIN = USER_PERMISSIONS?.includes(
    PERMISSIONS.dashboard_admin
  );
  const IS_MONDAY = moment().isoWeekday() === 1;
  const SOFR_DATE_LIMIT = moment()
    .subtract(IS_MONDAY ? 3 : 1, "days")
    .format("YYYY-MM-DD");
  const SOFR_RANGE_FROM = moment()
    .subtract(10, "days")
    .startOf("day")
    .format("YYYY-MM-DD");

  const {
    data: sofrRates,
    isLoading,
    refetch: refetchSofr,
  } = useGetSofrRatesQuery({
    from: SOFR_RANGE_FROM,
    to: SOFR_DATE_LIMIT,
  });
  const IS_SOFR_UPDATED = moment(sofrRates?.[0]?.date).isSame(
    SOFR_DATE_LIMIT,
    "day"
  );
  const [updateSofr, { isLoading: isUpdating }] = useAddSofrRateMutation();

  const { data: cmaSOFR, isFetching, fetchSofrRates } = useGetCMASofrRates();

  return (
    <Slide in direction="left">
      <Box flexGrow={1}>
        <Layout
          title="SOFR Rates"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 12,
            mid: 0,
            right: 0,
            syncAction: refetchSofr,
          }}
          mode="default"
          alignItems="center"
        >
          {/**transaction list */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="start"
                  alignItems="end"
                >
                  <Grid item xs={12} lg={12}>
                    {!IS_SOFR_UPDATED && IS_DASHBOARD_ADMIN && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="error">
                          Update pending for{" "}
                          {moment(SOFR_DATE_LIMIT).format("Do MMM 'YY")}
                        </Typography>
                        <LoadingButton
                          variant="contained"
                          loading={isFetching || isUpdating}
                          onClick={() => {
                            fetchSofrRates()
                              .then(() => {
                                const sofrRate = cmaSOFR.find((sofr) => {
                                  return (
                                    moment(sofr.date).format("YYYY-MM-DD") ===
                                    SOFR_DATE_LIMIT
                                  );
                                });
                                if (sofrRate)
                                  updateSofr({
                                    date: moment(sofrRate.date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    rate:
                                      parseFloat(
                                        sofrRate.rates.sofrRatesFixing.find(
                                          (rate) => rate.term === "3M"
                                        )?.price ?? "0.00"
                                      ) ?? 0,
                                  })
                                    .unwrap()
                                    .then(() => {
                                      toast.success(
                                        "SOFR rates updated successfully"
                                      );
                                    })
                                    .catch((e) =>
                                      toast.error(
                                        `Failed to save SOFR rates: ${e?.message}`
                                      )
                                    );
                                else
                                  toast.error(
                                    "SOFR rates unavailable for today"
                                  );
                              })
                              .catch((e) =>
                                toast.error(
                                  `Failed to fetch SOFR rates: ${e?.message}`
                                )
                              );
                          }}
                        >
                          Update
                        </LoadingButton>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isLoading && <SkeletonLoad bars={10} />}
                    {!isLoading && sofrRates && (
                      <SOFRTable data={sofrRates} showAll />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default SOFRPage;
