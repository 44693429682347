import {
  Typography,
  Stack,
  Select,
  MenuItem,
  Pagination,
  List,
  ListItem,
  Button,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useTheme,
} from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import { useState } from "react";
import {
  APPROVED,
  DEFAULT_ROWS,
  PENDING_EXTERNAL,
  REJECTED,
  INVOICE_CANCELLED,
  TERMSHEET_CANCELLED,
  INCOMLEND_INTERNAL_LINK,
  TERMSHEETS_LINK,
  LEADS,
} from "codes";
import ArticleIcon from "@mui/icons-material/Article";
import StatusDisplay, { roleBasedBg } from "./StatusDisplay";
import moment from "moment";
import type { TermSheetListItem } from "types";
import history from "helpers/history";
import { useGetCurrentUserQuery } from "api/api-users-v2";

interface TermsheetToDoProps {
  termsheetTodos: TermSheetListItem[];
  syncAction: () => void;
}

const TermsheetToDo: React.FC<TermsheetToDoProps> = ({
  termsheetTodos,
  syncAction,
}) => {
  const [resultsCount, setResultsCount] = useState(DEFAULT_ROWS);
  const [page, setPage] = useState(1);
  const termsheetData = termsheetTodos;
  const theme = useTheme();
  const termsheetsPaginated = termsheetData?.slice(
    (page - 1) * resultsCount,
    page * resultsCount
  );
  const { data: curr_user } = useGetCurrentUserQuery();
  const isInternal = curr_user?.is_internal;

  function daysUntilExpiry(expiry_date: string): number {
    const currentDate = moment();
    const expiryDate = moment(expiry_date);
    const duration = moment.duration(expiryDate.diff(currentDate));
    return Math.ceil(duration.asDays());
  }
  const viewTermsheetHandler = (termsheet: TermSheetListItem) => {
    const params = new URLSearchParams();
    if (isInternal) {
      if ([REJECTED, INVOICE_CANCELLED, TERMSHEET_CANCELLED].includes(termsheet.termsheet_status)) {
        history.push(
          `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${termsheet?.partner?.id}`
        );
      } else {
        params.set("partner", termsheet?.partner?.id);
        history.push(
          `${INCOMLEND_INTERNAL_LINK}${LEADS}/${termsheet?.lead?.internal_name}?${params.toString()}`
        );
      }
    } else {
      history.push(`${TERMSHEETS_LINK}/${termsheet?.id}`);
    }
  };
  return (
    <MainCard
      title={
        <Typography
          variant="h4"
          color="primary.main"
          sx={{ textDecoration: "underline" }}
        >
          TERMSHEETS TO-DO
        </Typography>
      }
      headerConfig={{
        syncAction: syncAction,
      }}
      subheader={
        <Stack spacing={Number(termsheetTodos?.length) === 0 ? 0 : 1}>
          <Typography variant="body2" fontStyle="italic">
            Click on the termsheet or view button to view more details.
          </Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">Showing</Typography>
            <Select
              value={resultsCount}
              onChange={(e) => setResultsCount(e.target.value as number)}
              variant="standard"
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography variant="body2">
              of {termsheetTodos?.length || 0} Termsheets
            </Typography>
          </Stack>
        </Stack>
      }
      footer={
        <Pagination
          count={Math.ceil((termsheetTodos?.length || 0) / resultsCount)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(e, p) => setPage(p)}
        />
      }
    >
      <List
        sx={{
          width: "100%",
          maxWidth: "90%",
        }}
        dense
      >
        {!termsheetTodos.length && (
          <ListItem>
            <ListItemText
              primary={
                <Typography align="center" fontSize="1rem">
                  No Termsheets to Work on
                </Typography>
              }
            />
          </ListItem>
        )}
        {Boolean(termsheetTodos.length) && termsheetsPaginated?.map((ts, index) => (
          <ListItem
            onClick={() => viewTermsheetHandler(ts)}
            secondaryAction={
              <Stack direction="row" spacing={1} alignItems="flex-end">
                {ts.termsheet_status === APPROVED && (
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ minWidth: 75 }}
                  >
                    {`View Bill`}
                  </Button>
                )}
                {ts?.termsheet_status === PENDING_EXTERNAL &&
                  ts?.expiry_info?.expiry_date &&
                  ts?.expiry_info?.is_active &&
                  moment(ts?.expiry_info?.expiry_date).isAfter(moment()) && (
                    <Stack sx={{ minWidth: 65 }}>
                      <Typography color="error">Expires in</Typography>
                      <Typography color="error">{`${daysUntilExpiry(ts?.expiry_info?.expiry_date)} days`}</Typography>
                    </Stack>
                  )}
                <Button
                  variant="outlined"
                  fullWidth
                  size="small"
                  onClick={() => viewTermsheetHandler(ts)}
                >
                  View
                </Button>
              </Stack>
            }
            sx={{
              border: "1px solid",
              borderColor: "divider",
              paddingBlock: "12px",
              borderRadius: 1,
              cursor: "pointer",
              my: 0.5,
              ":hover": {
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.50) inset",
              },
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10) inset",
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{ bgcolor: roleBasedBg("RM", ts.termsheet_status, theme) }}
              >
                <ArticleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Stack direction="row" spacing={3} alignItems="center">
                  <Typography>{ts.lead.name}</Typography>
                  <StatusDisplay
                    status={ts.termsheet_status}
                    style={{ width: "fit-content", minWidth: 110 }}
                  />
                  {ts.comment && (
                    <Stack>
                      <Typography>
                        last updated by {ts.comment?.created_by}
                      </Typography>
                      <Typography>
                        at{" "}
                        {moment(ts.comment?.created_at).format(
                          "DD/MM/YYYY  HH:mm:ss"
                        )}
                      </Typography>
                    </Stack>
                  )}
                   {!ts.comment && (
                    <Stack>
                      <Typography>
                        last updated by {ts?.submitted_by}
                      </Typography>
                      <Typography>
                        at{" "}
                        {moment(ts?.last_update).format(
                          "DD/MM/YYYY  HH:mm:ss"
                        )}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              }
            />
          </ListItem>
        ))}
      </List>
    </MainCard>
  );
};

export default TermsheetToDo;
