import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, type AxiosRequestConfig } from "axios";
import { unAuthorizedLogout } from "./unauthorizedLogout";

const getToken = () => JSON.parse(localStorage.getItem("token") as string);

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = {
      baseUrl: "",
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    any,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    if (url.includes("/Incomlend/")) {
      return { error: { status: "403", data: "NOT_ALLOWED" } };
    }
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          Authorization: `Bearer ${getToken()?.value}`,
          ...headers,
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError<{ error: string; message: string }>;
      if (err.response?.status === 401) unAuthorizedLogout();
      return {
        error: {
          status: err.response?.status,
          //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          message: err.response?.data?.message || err.message,
        },
        data: err.response?.data as any,
      };
    }
  };
