import { Cancel, Close, PlusOne, Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useAddNodeMutation,
  useDeleteNodeMutation,
  useGetDepartmentMembersQuery,
  useGetDepartmentsQuery,
  useGetNodeRootTagsQuery,
  useGetOrgChartPaletteQuery,
  useGetReportingLineQuery,
  useUpdateNodeMutation,
} from "api/api-human-resources";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import Modal from "components/Common/Modal";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { PERMISSIONS } from "permissions";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

interface Node {
  id: string;
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  node_id: string;
}

interface UpdateNode {
  id: string;
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  tag?: string;
  department_tag?: string;
}

interface Props {
  currentNode: Node;
  setCurrentNode: React.Dispatch<React.SetStateAction<Node | undefined>>;
  orgChart: Node[];
  update: () => void;
}

const modalOverlaySx = {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2ch",
  padding: "3ch",
  height: "inherit !important",
  fontWeight: "inherit !important",
  backgroundColor: "#ffffff !important",
};
const modalSx = {
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  "&:focus-visible": {
    outline: "none",
  },
};

const OrgchartNodeModal: React.FC<Props> = (props) => {
  const { currentNode, setCurrentNode, orgChart, update } = props;
  const theme = useTheme();
  const [addNode, { isLoading: isAddingNode }] = useAddNodeMutation();
  const [updateNode, { isLoading: isUpdating }] = useUpdateNodeMutation();
  const [deleteNode, { isLoading: isDeleting }] = useDeleteNodeMutation();
  const { data: orgchartPalette = [] } = useGetOrgChartPaletteQuery();

  const HIDDEN_KEYS = ["id", "node_id", "tag"];

  const { data: rootTags, refetch: refetchRootTags } = useGetNodeRootTagsQuery(
    {
      tag: currentNode?.id ?? "",
      level: currentNode?.level,
    },
    {
      skip: !currentNode || !currentNode?.level,
    }
  );
  const { department, country, entity, associated_departments, is_management } =
    rootTags ?? {};

  const { data: REPORTING_LINE, refetch: refetchReportingLine } =
    useGetReportingLineQuery(
      {
        deparment_tag: department?.tag ?? "",
        tag: currentNode?.id ?? "",
      },
      {
        skip: !currentNode || !department,
      }
    );
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState?.user_permission
  );
  const IS_AUTHORIZED = USER_PERMISSIONS.includes(
    PERMISSIONS?.manage_org_chart
  );
  const rootlevels = [0, 1, 2];

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const { data: departments = [], refetch: refetchDepartments } =
    useGetDepartmentsQuery();
  const { data: DEPARTMENT_MEMBERS = [], refetch: refetchDepartmentMembers } =
    useGetDepartmentMembersQuery(department?.tag ?? "", {
      skip: !department?.tag,
    });

  const NODE_TAGS = DEPARTMENT_MEMBERS?.map((item) => ({
    baseTag: item?.tag?.split(/(\d+)/),
    level: item?.level,
  }))
    ?.map(({ baseTag, level }) => ({
      tag: baseTag?.[0],
      number: Number(baseTag?.[1] ?? 0),
      level,
    }))
    ?.sort((a, b) => a.number - b.number)
    ?.pop();

  const BASE_TAG = NODE_TAGS?.tag;
  const BASE_NUMBER = (NODE_TAGS?.number ?? 0) + 1;
  const BASE_LEVEL = NODE_TAGS?.level
    ? NODE_TAGS?.level
    : (currentNode?.level ?? 3);
  const SUBORDINATE_TAG = `${BASE_TAG}${BASE_NUMBER}`;

  const refetch = () => {
    refetchRootTags();
    refetchReportingLine();
    refetchDepartments();
    refetchDepartmentMembers();
  };

  return (
    <Formik
      initialValues={{
        tag: currentNode?.id,
        name: currentNode?.name,
        title: currentNode?.title,
        level: currentNode?.level,
        department: department,
        country: country,
        entity: entity,
        color: currentNode?.color,
        member_layout: currentNode?.member_layout,
        node_id: currentNode?.node_id,
        reporting_line: REPORTING_LINE ?? {},
        department_name: department?.name,
        department_tag: department?.tag,
        suboordinate: {
          name: "",
          title: "",
        },
      }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, setFieldValue, dirty, touched, setTouched }) => (
        <Form>
          <Modal
            sectionDivider
            message={
              <Grid item xs={12} mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <VariableInput
                      name="tag"
                      label="Tag"
                      value={values.tag}
                      handleSave={(value) => {
                        setFieldValue("tag", value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <VariableInput
                      name="name"
                      label="Name"
                      value={values.name}
                      handleSave={(value) => {
                        setFieldValue("name", value);
                      }}
                      required
                    />
                  </Grid>
                  {Boolean(values.level) && values.level > 2 && (
                    <Grid item xs={6}>
                      <VariableInput
                        name="title"
                        label="Title"
                        value={values.title}
                        handleSave={(value) => {
                          setFieldValue("title", value);
                        }}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <VariableInput
                      name="level"
                      label="Level"
                      type="number"
                      value={values.level}
                      noHover={values.level < 2}
                      placeholder={values.level < 2 ? "Root Level" : ""}
                      handleSave={(value) => {
                        setFieldValue("level", value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <VariableDropdown
                      name="department_name"
                      label="Department"
                      data={departments}
                      value={values.department_name}
                      setFieldValue={setFieldValue}
                      handleSave={(value) => {
                        const deparment_tag = departments.find(
                          (item) => item.name === value
                        )?.tag;
                        setFieldValue("department_tag", deparment_tag);
                        setFieldValue("department_name", value);
                        setTouched({ ...touched, department_tag: true });
                      }}
                      required
                      disabled={values.level < 2 || is_management}
                      noHover={values.level < 2 || is_management}
                      keyValue="name"
                    />
                  </Grid>
                  {IS_AUTHORIZED && (
                    <Grid item xs={12}>
                      <LabelViewOnly label="Reporting Line" />
                      <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {REPORTING_LINE &&
                          Object.entries(REPORTING_LINE).map(
                            ([key, node]) =>
                              node && (
                                <Typography key={key}>{node?.name}</Typography>
                              )
                          )}
                      </Breadcrumbs>
                    </Grid>
                  )}
                  {IS_AUTHORIZED && (
                    <Grid item xs={12}>
                      <Stack
                        spacing={0.5}
                        justifyContent="start"
                        alignItems="start"
                      >
                        <Typography fontWeight="bold">
                          Change Color
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="start"
                          alignItems="center"
                        >
                          {orgchartPalette?.map((color) => (
                            <Avatar
                              key={color}
                              sx={{
                                backgroundColor: color,
                                width: "2em",
                                height: "2em",
                                cursor:
                                  color === values?.color
                                    ? "not-allowed"
                                    : "pointer",
                                border:
                                  color === values?.color
                                    ? `2px solid ${theme.palette.white.main}`
                                    : "none",
                                my: "0.5em !important",
                              }}
                              variant="rounded"
                              onClick={() => {
                                color !== values?.color &&
                                  setFieldValue("color", color);
                              }}
                            >
                              {" "}
                            </Avatar>
                          ))}
                        </Stack>
                      </Stack>
                    </Grid>
                  )}

                  {Array.isArray(values?.member_layout) && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontWeight="bold">Members</Typography>
                        </Grid>
                        {values?.member_layout &&
                          (values?.member_layout as string[])?.map(
                            (member: string) => {
                              const teamMember = orgChart?.find(
                                (item) => item.id === member
                              );

                              return (
                                <Grid item key={member}>
                                  <Badge
                                    badgeContent={teamMember?.id}
                                    color="info"
                                  >
                                    <Chip
                                      label={teamMember?.name}
                                      variant="outlined"
                                      deleteIcon={
                                        IS_AUTHORIZED ? (
                                          <Cancel
                                            sx={{
                                              fill: theme.palette.error.main,
                                            }}
                                          />
                                        ) : undefined
                                      }
                                      onDelete={
                                        IS_AUTHORIZED
                                          ? () => {
                                              if (!teamMember?.node_id)
                                                return toast.error(
                                                  "Node ID is required to delete this node"
                                                );
                                              deleteNode(teamMember?.node_id)
                                                .unwrap()
                                                .then(() => {
                                                  toast.success(
                                                    "Node deleted successfully"
                                                  );
                                                  update();
                                                  setCurrentNode(undefined);
                                                })
                                                .catch((err) => {
                                                  toast.error(
                                                    `Error deleting node: ${
                                                      err?.message ??
                                                      "Unknown error"
                                                    }`
                                                  );
                                                });
                                            }
                                          : undefined
                                      }
                                    />
                                  </Badge>
                                </Grid>
                              );
                            }
                          )}
                        {IS_AUTHORIZED && (
                          <Grid item>
                            <Chip
                              label={checked ? "Close" : "Add Member"}
                              variant="filled"
                              icon={checked ? <Close /> : <PlusOne />}
                              color={checked ? "error" : "primary"}
                              onClick={() => setChecked((prev) => !prev)}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Fade
                            in={checked}
                            timeout={500}
                            mountOnEnter
                            unmountOnExit
                          >
                            <Box
                              sx={{
                                display: "flex",
                                transition: "all 0.5s ease-in-out",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "80%",
                                padding: 2,
                              }}
                            >
                              <Paper sx={{ width: "100%", p: 2 }}>
                                <Stack spacing={1}>
                                  <Input
                                    name="suboordinate.name"
                                    label="Name"
                                    placeholder="Full Name"
                                    fullWidth
                                    value={values.suboordinate?.name}
                                    required
                                  />
                                  <Input
                                    name="suboordinate.title"
                                    label="Title"
                                    placeholder="Title"
                                    value={values.suboordinate?.title}
                                    fullWidth
                                    required
                                  />
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="end"
                                  >
                                    <Button
                                      color="error"
                                      onClick={handleChange}
                                    >
                                      Close
                                    </Button>
                                    <LoadingButton
                                      color="success"
                                      variant="contained"
                                      loading={isAddingNode}
                                      onClick={() => {
                                        addNode({
                                          tag: SUBORDINATE_TAG,
                                          name: values.suboordinate?.name ?? "",
                                          title:
                                            values.suboordinate?.title ?? "",
                                          level: BASE_LEVEL,
                                          color: values.color,
                                          member_layout: [],
                                          department_tag: department?.tag,
                                          country_tag: country?.tag,
                                          entity_tag: entity?.tag,
                                          reporting_to: currentNode?.id,
                                        })
                                          .unwrap()
                                          .then(() => {
                                            update();
                                            handleChange();
                                            toast.success(
                                              `Member added at Level ${BASE_LEVEL}, reporting to ${currentNode?.name}, Department: ${department?.name}`
                                            );
                                          })
                                          .catch((err) => {
                                            toast.error(
                                              `Failed to add member: ${err?.message ?? "Unknown error"}`
                                            );
                                          });
                                      }}
                                    >
                                      Add
                                    </LoadingButton>
                                  </Stack>
                                </Stack>
                              </Paper>
                            </Box>
                          </Fade>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {IS_AUTHORIZED && !rootlevels.includes(values?.level) && (
                    <Grid item xs={12} lg={12}>
                      <Alert
                        severity="error"
                        sx={{
                          ".MuiAlert-action": {
                            alignItems: "center",
                          },
                        }}
                        action={
                          <LoadingButton
                            color="error"
                            variant="contained"
                            loading={isDeleting}
                            disabled={isDeleting || !IS_AUTHORIZED}
                            onClick={() => {
                              if (!currentNode?.node_id)
                                return toast.error(
                                  "Node ID is required to delete this node"
                                );
                              deleteNode(currentNode?.node_id)
                                .unwrap()
                                .then(() => {
                                  toast.success("Node deleted successfully");
                                  update();
                                  setCurrentNode(undefined);
                                })
                                .catch((err) => {
                                  toast.error(
                                    `Error deleting node: ${
                                      err?.message ?? "Unknown error"
                                    }`
                                  );
                                });
                            }}
                          >
                            Remove
                          </LoadingButton>
                        }
                      >
                        <AlertTitle sx={{ fontWeight: "bold" }}>
                          Danger Zone
                        </AlertTitle>
                        Remove{" "}
                        <strong>{` ${currentNode?.name}(${department?.name}) `}</strong>
                        from the Organization Chart
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
            sx={modalSx}
            Header={
              <Stack spacing={2} mb={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    sx={{
                      backgroundColor: currentNode?.color,
                      width: "2em",
                      height: "2em",
                      border: `2px solid ${theme.palette.divider}`,
                    }}
                    variant="rounded"
                  >
                    {" "}
                  </Avatar>
                  <Typography fontSize="1.5em" fontWeight="bold">
                    {currentNode?.name}
                  </Typography>
                  <IconButton onClick={refetch}>
                    <Sync />
                  </IconButton>
                </Stack>
                {!rootlevels.includes(values?.level) && (
                  <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Typography>{country?.name ?? "Country"}</Typography>
                    <Typography
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "20ch",
                      }}
                    >
                      {entity?.name ?? "Entity"}
                    </Typography>
                    <Typography fontWeight="bold">
                      {department?.name ?? "Department"}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Stack>
            }
            width={isMobile ? "90%" : "40%"}
            height="50vh"
            open={Boolean(currentNode)}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ maxWidth: "100%" }}
            onClose={() => setCurrentNode(undefined)}
            closeButton
            modalHeaderSx={{ backgroundColor: "transparent" }}
            secondary={{
              children: "Close",
              onClick: () => setCurrentNode(undefined),
              color: "error",
            }}
            primary={{
              children: "Update",
              disabled: !dirty || !IS_AUTHORIZED || isUpdating,
              loading: isUpdating,
              onClick: () => {
                const payload: UpdateNode = {
                  id: currentNode.node_id,
                  tag: values.tag,
                  name: values.name,
                  title: values.title,
                  level: values.level,
                  color: values.color,
                  member_layout: values.member_layout,
                };
                if (touched.department_tag)
                  payload["department_tag"] = values.department_tag;
                currentNode.node_id &&
                  updateNode(payload)
                    .unwrap()
                    .then(() => {
                      toast.success("Node updated successfully");
                      update();
                      refetch();
                      setCurrentNode(undefined);
                    })
                    .catch((err) => {
                      toast.error(
                        `Error updating node: ${err?.message ?? "Unknown error"}`
                      );
                    });
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default OrgchartNodeModal;
