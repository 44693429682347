import { Divider, Stack } from "@mui/material";
import { useGetSummariesQuery } from "api/api-compliance";
import MainCard from "components/Common/Cards/MainCard";
import { MapContext } from "pages/maps/map";
import { useContext } from "react";
import Summary from "./MapSummaries/Summary";

const MapSummary = () => {
  const mapName = useContext(MapContext);
  const { data: summariesData } = useGetSummariesQuery(mapName ?? "", {
    skip: mapName === undefined,
  });

  const supplierData = summariesData?.supplier;
  const buyerData = summariesData?.buyer;
  const dealData = summariesData?.deal;

  return (
    <MainCard
      title="Summaries"
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Stack spacing={1}>
          <Summary
            title="Deal Summary"
            subject="deal"
            data={dealData}
            readonly
          />
          <Divider />
          <Summary
            title="Supplier Summary"
            subject="supplier"
            data={supplierData}
            readonly
          />
          <Divider />
          <Summary
            title="Buyer Summary"
            subject="buyer"
            data={buyerData}
            readonly
          />
        </Stack>
      }
    ></MainCard>
  );
};

export default MapSummary;
