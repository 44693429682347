import { Avatar, IconButton, Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import CompanyUserManagement from "components/Onboard/User";
import { useFormikContext } from "formik";
import * as React from "react";
import type { Account, AccountRelationship, PartnerData } from "types";
import AdditionalInformation from "../Details/AdditionalInformation";
import RegistrationDetails from "../Details/RegistrationDetails";

import { Sync } from "@mui/icons-material";
import { useGetBankAccountsQuery } from "api/api-banks";
import { useGetUsersQuery } from "api/api-users-v2";
import { BUYER, SELLER, SUPPLIER } from "codes";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AccountDocuments from "./AccountDocuments";
import AccountPartnerAccordion from "./AccountPartnerAccordion";
import BankAccountsList from "./BankAccounts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export interface AccountSections extends Account, AccountRelationship {
  partners: PartnerData[];
  partner_id: string;
  syncAction: () => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `account-tab-${index}`,
    "aria-controls": `account-tabpanel-${index}`,
  };
}

export default function AccountSectionTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formikProps = useFormikContext<AccountSections>();
  const { partners, syncAction, ...account } = formikProps.values;
  const { id } = account;
  const partnerType = account?.profile === SELLER ? BUYER : SUPPLIER;
  const { data: users = [], isFetching: isFetchingUsers } = useGetUsersQuery(
    id,
    {
      skip: !Boolean(id),
    }
  );
  const {
    data: bankAccounts,
    refetch: refetchBanks,
    isFetching,
  } = useGetBankAccountsQuery(id, {
    skip: !id,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "60vw",
        [theme.breakpoints.down("sm")]: {
          minWidth: "90vw",
        },
      }}
      id="account-tabs-box"
      flexGrow={1}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="account section tabs"
          variant="fullWidth"
          centered
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab
            label={
              <Stack spacing={1} direction="row">
                <Typography>Users</Typography>
                <Avatar
                  sx={{
                    bgcolor: users?.length ? "success.main" : "error.main",
                    width: "1.2em",
                    height: "1.2em",
                    padding: "0 2ch",
                  }}
                  variant="rounded"
                >
                  <Typography>{users?.length}</Typography>
                </Avatar>
              </Stack>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Stack spacing={1} direction="row">
                <Typography>{`${partnerType}(s)`}</Typography>
                <Avatar
                  sx={{
                    bgcolor: partners?.length ? "success.main" : "error.main",
                    width: "1.2em",
                    height: "1.2em",
                    padding: "0 2ch",
                  }}
                  variant="rounded"
                >
                  <Typography>{partners?.length}</Typography>
                </Avatar>
              </Stack>
            }
            {...a11yProps(2)}
          />
          <Tab label="Documents" {...a11yProps(3)} />
          <Tab
            label={
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography>Banks</Typography>
                <Avatar
                  sx={{
                    bgcolor: bankAccounts?.length
                      ? "success.main"
                      : "error.main",
                    width: "1.2em",
                    height: "1.2em",
                    padding: "0 2ch",
                  }}
                  variant="rounded"
                >
                  <Typography>{bankAccounts?.length ?? 0}</Typography>
                </Avatar>
                <IconButton onClick={refetchBanks}>
                  <Sync />
                </IconButton>
              </Stack>
            }
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Stack spacing={3}>
          <RegistrationDetails isPartner={true} />
          <AdditionalInformation />
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isFetchingUsers && <SkeletonLoad bars={3} />}
        {!isFetchingUsers && users && Boolean(users?.length) && (
          <CompanyUserManagement companyId={id} users={users} isPartner />
        )}
        {!isFetchingUsers && !Boolean(users?.length) && (
          <NoData
            text="No Users Found"
            sx={{ minWidth: "60vw", margin: "auto" }}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {partners &&
          Boolean(partners?.length) &&
          partners?.map((partner) => (
            <AccountPartnerAccordion
              key={partner.id}
              sync={syncAction}
              partner={partner}
            />
          ))}
        {!partners ||
          (!Boolean(partners?.length) && (
            <NoData
              text="No Partners Found"
              sx={{ minWidth: "60vw", margin: "auto" }}
            />
          ))}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <AccountDocuments type={account?.profile} account_id={account?.id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <BankAccountsList companyId={account?.id} />
      </CustomTabPanel>
    </Box>
  );
}
