import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Typography, useTheme } from "@mui/material";
import {
  useAddAccountProfileMutation,
  useUpdateStepsMutation,
} from "api/api-accounts";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import { SELLER, SUPPLIER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import SelectorCard from "components/Company/Registration/SelectorCard";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/hooks";
import { setProfile } from "redux/reducers/appStatesReducer";
import { setSteps } from "redux/reducers/stepsReducer";

const SelectProfile = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const theme = useTheme();
  const formikProps: FormikProps<RegistrationFormFields> = useFormikContext();
  const { values } = formikProps;
  const dispatch = useAppDispatch();
  const [updateSteps] = useUpdateStepsMutation();
  const [addAccountProfile, { isLoading }] = useAddAccountProfileMutation();

  const companyType =
    formikProps.values.companyType === t("supplier") ||
    formikProps.values.companyType === SUPPLIER
      ? SUPPLIER
      : formikProps.values.companyType;
  const companyTypeForBackend =
    formikProps.values.companyType === t("supplier") ||
    formikProps.values.companyType === SUPPLIER
      ? "seller"
      : formikProps.values.companyType?.toLowerCase();
  const {
    data: countries,
    isFetching,
    isSuccess,
  } = useGetCountriesByTypeQuery(companyTypeForBackend, {
    skip: !Boolean(values?.companyType),
  });

  const selectorCards = [
    {
      name: `${t("supplier")}`,
      bottomColorBand: `10px solid ${theme.palette.primary.main}`,
      textColor: theme.palette.primary.main,
    },
    {
      name: `${t("buyer")}`,
      bottomColorBand: `10px solid ${theme.palette.error.main}`,
      textColor: theme.palette.error.main,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          fontWeight="bold"
          textAlign="center"
          fontSize={isMobile ? "1.4em" : "1.8em"}
        >
          {t("i-am-a")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectorCard
          textActiveColor="#ffffff"
          itemSpacing={isMobile ? 2 : 3}
          cards={selectorCards}
          options={selectorCards.map((item) => item.name)}
          value={companyType}
          setFieldValue={formikProps.setFieldValue}
          name="companyType"
          headingProps={{
            fontSize: isMobile ? "1em" : "1.8em",
            style: { margin: "auto" },
          }}
          style={{
            width: isMobile ? "8em" : "13.75em",
            height: isMobile ? "8em" : "auto",
          }}
        />
      </Grid>

      {isFetching && <SkeletonLoad bars={1} />}
      {!isFetching &&
      isSuccess &&
      countries?.find((country) => country.name === values?.country)
        ? Boolean(values.companyType) && (
            <Grid item lg={4} xs={12} margin="auto">
              <LoadingButton
                fullWidth
                variant="contained"
                loading={isLoading}
                disabled={!Boolean(values?.companyType)}
                data-testid="add-client-profile"
                onClick={() => {
                  addAccountProfile({
                    account_id: values?.companyId,
                    company_type:
                      values?.companyType?.toLowerCase() ===
                      SUPPLIER.toLowerCase()
                        ? SELLER
                        : values?.companyType?.toLowerCase(),
                  })
                    .unwrap()
                    .then(() => {
                      dispatch(
                        setProfile(
                          values?.companyType?.toLowerCase() ===
                            SUPPLIER.toLowerCase()
                            ? SELLER
                            : values?.companyType?.toLowerCase()
                        )
                      );
                      updateSteps({
                        company_id: values?.companyId,
                        form: {
                          ...formikProps.values,
                          crStage: 3,
                        },
                        step: 1,
                      })
                        .unwrap()
                        .then(() => {
                          dispatch(
                            setSteps({
                              form: {
                                ...formikProps.values,
                                crStage: 3,
                              },
                              step: 1,
                            })
                          );
                        })
                        .catch((error) => {
                          const message = error?.message;
                          toast(`Something went wrong : ${message}`, {
                            type: "error",
                          });
                        });
                    })
                    .catch((error) => {
                      const message = error?.message;
                      toast(`Something went wrong : ${message}`, {
                        type: "error",
                      });
                    });
                }}
              >
                Next
              </LoadingButton>
            </Grid>
          )
        : !isFetching &&
          Boolean(values.companyType) && (
            <Grid item lg={10} xs={12} margin="auto" mt={2}>
              <Typography
                textAlign="center"
                fontWeight="bold"
                fontSize="1.2em"
                data-testid="client-eligibility-error"
              >{`Unfortunately, Incomlend cannot provide invoice financing for ${values?.country} as a ${companyType}. We will inform you once ${values?.country} is available.`}</Typography>
            </Grid>
          )}
    </Grid>
  );
};
export default SelectProfile;
