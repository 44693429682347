import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Stack, type SxProps, alpha, styled, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import Drop, { type DropDownProps } from "../Drop";
import LabelViewOnly from "../FormFields/LabelViewOnly";
import UtilityBar from "../UtilityBar";
import styles from "./vi-dd.module.scss";

const VariableDiv = styled("div")<{ open: boolean; noHover?: boolean }>(
  ({ theme, open, noHover }) => ({
    paddingBottom: "16px",
    paddingLeft: "8px",
    paddingTop: "4px",
    height: open ? "0" : "2em",
    width: open ? "100%" : "-webkit-fill-available",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: noHover
        ? "initial"
        : alpha(theme.palette.secondary.main, 0.5),
      cursor: "text",
    },
  })
);

type T = DropDownProps;
const VariableDropdown = (
  props: T & {
    handleSave?: (value: T["value"]) => void;
    icon?: React.ReactNode;
    iconPosition?: "start" | "end";
    containerSx?: React.CSSProperties;
    noHover?: boolean;
    renderOption?: (option: T["value"]) => React.ReactNode;
    labelSx?: SxProps;
  }
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [toggle, setToggle] = useState<boolean>(false);

  const handleClose = () => {
    props?.handleSave && props.value && props?.handleSave(props.value);
    setAnchorEl(null);
  };

  const iconPosition = props?.iconPosition ?? "start";
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack
      onClick={handleClick}
      direction={props?.direction ?? "row"}
      alignItems={props?.direction === "column" ? "flex-start" : "center"}
    >
      {props.label && (
        <LabelViewOnly
          labelClassName={styles.labelClass}
          label={props.label}
          sx={{
            fontWeight: "bold",
            paddingLeft: "4px",
            "&:hover": {
              cursor: "default",
            },
            ...props?.labelSx,
          }}
        />
      )}
      {!toggle && (
        <VariableDiv
          id={`variable-drop-${props?.name}`}
          open={Boolean(anchorEl)}
          style={props?.containerSx}
          noHover={props?.noHover}
          onClick={() => setToggle(true)}
        >
          {props?.renderOption ? (
            props?.renderOption(props.value)
          ) : (
            <Stack direction="row" spacing={1}>
              {iconPosition === "start" && props?.icon}
              <LabelViewOnly
                {...props}
                label={(props?.value || props?.placeholder) ?? "None"}
                sx={{
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                  color: Boolean(props.value)
                    ? theme.palette.primary.main
                    : `#5a5a5a !important`,
                  cursor: props?.disabled ? "not-allowed" : "text",
                  fontWeight: Boolean(props.value)
                    ? "500 !important"
                    : `300 !important`,
                  textTransform: "initial",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  letterSpacing: "0.01em !important",
                  ...props.sx,
                }}
              />
              {iconPosition === "end" && props?.icon}
            </Stack>
          )}
        </VariableDiv>
      )}

      {toggle && (
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="end"
            ref={dropdownRef}
          >
            <Drop
              {...props}
              label=""
              sx={{ width: "100%", ...props?.sx }}
              optionsListMinHeight={250}
              MenuProps={{ disablePortal: true, ...props?.MenuProps }}
            />
            <UtilityBar
              anchorEl={anchorEl}
              setToggle={setToggle}
              id={`variable-drop-${props?.name}`}
              handleSave={props?.handleSave}
              value={props.value}
            />
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default VariableDropdown;
