import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Slide,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useRegisterBankAccountMutation } from "api/api-banks";
import { useGetCountriesQuery } from "api/api-misc";
import { CURRENCIES } from "codes";
import AutoComplete from "components/Common/AutoComplete";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import history from "helpers/history";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import type { Country } from "types";
import styles from "../onboard.module.scss";
import { bankAccountvalidationSchema, initialValues } from "./formValues";

const AddBankAccount = ({
  setAdditionState,
  account_id,
  isPartner,
}: {
  account_id: string;
  setAdditionState: React.Dispatch<React.SetStateAction<boolean>>;
  isPartner?: boolean;
}) => {
  const { data: countries } = useGetCountriesQuery();
  const location = useLocation();

  const [registerBankAccount, { isLoading: isLoadingRegister }] =
    useRegisterBankAccountMutation();

  const hasMissingValue = (value: any): boolean => {
    if (value === null || value === "") return true;

    if (typeof value === "object") {
      return Object.values(value).some((val) => hasMissingValue(val));
    }

    return false;
  };

  const getIso3 = (value: string) => {
    const selected = countries?.find((item: any) => item.name === value);
    return selected?.id;
  };

  return (
    <Slide in direction="left">
      <Box flexGrow={1} display="flex" margin="auto">
        <Formik
          initialValues={initialValues}
          validationSchema={bankAccountvalidationSchema}
          onSubmit={() => {}}
        >
          {(formikProps) => {
            const setter = (value: string) => {
              const selected = countries?.find(
                (item: any) => item.name === value
              );
              formikProps.setFieldValue("country", selected?.name, true);
              formikProps.setFieldValue("iso3", selected?.id, true);
            };
            const BankAccountDisplay = (
              <Grid
                container
                spacing={3}
                margin="auto"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        style={{ fontWeight: "bold", fontSize: "1.6em" }}
                      >
                        Bank Account Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="Bank Name "
                            required
                            name="bank_name"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="Swift / BIC code "
                            name="swift_code"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="Account Number / IBAN "
                            required
                            name="bank_account_number"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="Beneficiary Name "
                            required
                            name="beneficiary_name"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Drop
                            label="Account Currency "
                            required
                            name="currency"
                            keyValue="name"
                            data={Object.values(CURRENCIES)}
                            fullWidth
                            placeholder={initialValues?.currency}
                            {...formikProps}
                          />
                          {Boolean(formikProps.touched.currency) && (
                            <FormHelperText
                              error={Boolean(formikProps.errors.currency)}
                            >
                              {typeof formikProps.errors.currency === "string"
                                ? formikProps.errors.currency
                                : ""}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        style={{ fontWeight: "bold", fontSize: "1.6em" }}
                      >
                        Bank Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Street 1 "
                        required
                        name="bank_address.address_line1"
                        labelClassName={styles.labelClass}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Street 2 "
                        required
                        name="bank_address.address_line2"
                        labelClassName={styles.labelClass}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="City "
                            required
                            name="bank_address.city"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            label="Postal Code "
                            required
                            name="bank_address.postcode"
                            labelClassName={styles.labelClass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} lg={6}>
                          <AutoComplete
                            data={countries as Country[]}
                            label="Country"
                            required
                            name="bank_address.country"
                            labelKey={"name" as any}
                            value={formikProps.values.bank_address?.country}
                            placeholder={initialValues?.bank_address?.country}
                            onChange={(event: any, newValue: any) => {
                              setter(newValue?.name);
                            }}
                            fullWidth
                          />

                          {Boolean(
                            formikProps.touched.bank_address?.country
                          ) && (
                            <FormHelperText
                              error={Boolean(
                                formikProps.errors.bank_address?.country
                              )}
                            >
                              {typeof formikProps.errors.bank_address
                                ?.country === "string"
                                ? formikProps.errors.bank_address?.country
                                : ""}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        onClick={() => setAdditionState(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        disabled={!formikProps.values.bank_name}
                        loading={isLoadingRegister}
                        type="submit"
                        data-testid="save-bank-account"
                        onClick={() => {
                          const bankAccountData = {
                            name: formikProps.values.bank_name,
                            bank_name: formikProps.values.bank_name,
                            bank_account_number:
                              formikProps.values.bank_account_number,
                            beneficiary_name:
                              formikProps.values.beneficiary_name,
                            currency: formikProps.values.currency,
                            bank_address: {
                              address_line1:
                                formikProps.values.bank_address.address_line1,
                              address_line2:
                                formikProps.values.bank_address.address_line2,
                              city: formikProps.values.bank_address.city,
                              country: formikProps.values.iso3 as string,
                              postcode:
                                formikProps.values.bank_address.postcode,
                            },
                            swift_code: formikProps.values.swift_code,
                          };

                          const saveFunction = registerBankAccount({
                            account_id,
                            data: bankAccountData,
                          })
                            .unwrap()
                            .catch((error) => {
                              const errorFile = error?.message;
                              toast(`Something went wrong : ${errorFile}`, {
                                type: "error",
                              });
                            });
                          saveFunction.then((res: any) => {
                            if ("error" in res) {
                              toast(res.message, { type: "error" });
                            } else {
                              toast(
                                `Your bank information has been registered!`,
                                {
                                  type: "success",
                                }
                              );
                              setAdditionState(false);
                              history.push(location.pathname, {
                                tab: isPartner ? 4 : 2,
                              });
                            }
                          });
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
            return <Form>{BankAccountDisplay}</Form>;
          }}
        </Formik>
      </Box>
    </Slide>
  );
};
export default AddBankAccount;
