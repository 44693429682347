import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import AddYourCompany from "assets/navigationIcons/add-company.svg?react";
import Companies from "assets/navigationIcons/companies.svg?react";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import DashboardIcon from "assets/navigationIcons/dashboard.svg?react";
import EwalletIcon from "assets/navigationIcons/ewallet.svg?react";
import Invoices from "assets/navigationIcons/invoice.svg?react";
import SelectYourAccount from "assets/navigationIcons/select-account.svg?react";
import TradingRelationships from "assets/navigationIcons/tradingRelationships.svg?react";
import Users from "assets/navigationIcons/users.svg?react";
import {
  ADD_COMPANY,
  ADD_PARTNER,
  BILLINGS_LINK,
  COMPANIES,
  DASHBOARD,
  INCOMLEND_INTERNAL,
  NEW_USER,
  ONBOARDED,
  ONBOARDING,
  PRESALES,
  REGISTERED,
  REGISTER_FOR_FINANCING,
  SELECT_ACCOUNT,
  TERMSHEETS_LINK,
  TP_ADDED,
  TR_LINK,
  USERS_LINK,
} from "codes";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/hooks";
import { menuStyling as styling } from "styles/theme";
import INTERNAL_MENUS from "./incomlend-internal-menus";
import { EditNote } from "@mui/icons-material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

interface Item {
  title: string;
  icon: any;
  activeColor: string;
  iconActiveColor: string;
  iconInactiveColor: string;
  circularBackground: boolean;
  link: string;
  strict?: boolean;
  items?: Item[];
  permission?: string;
}

const menuItems = () => {
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const filterCondition = (item: Item) =>
    USER_PERMISSIONS.includes(item.permission as string);

  const filterItem = (item: Item) => {
    if (item.items)
      return {
        ...item,
        items: item.items.filter(filterCondition),
      };
    if (filterCondition(item)) return item;
    return false;
  };

  const INTERNAL_MENU_ITEMS = INTERNAL_MENUS.map(filterItem).filter(
    (x) => x
  ) as Item[];

  const APP_STATE = useAppSelector((state) => state.appState.value);

  const { t } = useTranslation();

  if (APP_PERMISSION === INCOMLEND_INTERNAL) return INTERNAL_MENU_ITEMS;

  switch (APP_STATE) {
    case NEW_USER:
      return [
        {
          title: `${t("add-your-company")}`,
          icon: AddYourCompany,
          ...styling,
          link: ADD_COMPANY,
        },
      ];
    case SELECT_ACCOUNT:
      return [
        {
          title: `Select Your Account`,
          icon: SelectYourAccount,
          ...styling,
          link: "/accounts",
        },
      ];
    case REGISTERED:
      return [
        {
          title: t("dashboard"),
          icon: DashboardIcon,
          ...styling,
          link: `${DASHBOARD}`,
        },
        {
          title: "Cargo Tracking",
          icon: GpsFixedIcon,
          ...styling,
          link: "/cargo-tracking",
        },
        {
          title: "Company Profile",
          icon: Companies,
          ...styling,
          link: COMPANIES,
        },
        {
          title: "Request Financing",
          icon: CreditLimitRequest,
          ...styling,
          link: REGISTER_FOR_FINANCING,
        },
      ];
    case PRESALES:
      return [
        {
          title: t("dashboard"),
          icon: DashboardIcon,
          ...styling,
          link: `${DASHBOARD}`,
        },
        {
          title: "Cargo Tracking",
          icon: GpsFixedIcon,
          ...styling,
          link: "/cargo-tracking",
        },
        {
          title: "Company Profile",
          icon: Companies,
          ...styling,
          link: COMPANIES,
        },
        {
          title: "Request Financing",
          icon: CreditLimitRequest,
          ...styling,
          link: REGISTER_FOR_FINANCING,
        },
      ];
    case TP_ADDED:
      return [
        {
          title: t("dashboard"),
          icon: DashboardIcon,
          ...styling,
          link: `${DASHBOARD}`,
        },
        {
          title: "Cargo Tracking",
          icon: GpsFixedIcon,
          ...styling,
          link: "/cargo-tracking",
        },
        {
          title: "Company Profile",
          icon: Companies,
          ...styling,
          link: COMPANIES,
        },
        {
          title: "Request Financing",
          icon: CreditLimitRequest,
          ...styling,
          link: REGISTER_FOR_FINANCING,
        },
      ];
    case ADD_PARTNER:
      return [
        {
          title: t("trade-partners"),
          icon: TradingRelationships,
          ...styling,
          link: TR_LINK,
        },
      ];
    case ONBOARDING:
      return [
        {
          title: t("dashboard"),
          icon: DashboardIcon,
          ...styling,
          link: DASHBOARD,
        },
        {
          title: "Cargo Tracking",
          icon: GpsFixedIcon,
          ...styling,
          link: "/cargo-tracking",
        },
        {
          title: "Company Profile",
          icon: Companies,
          ...styling,
          link: COMPANIES,
        },
        {
          title: "Termsheets",
          icon: EditNote,
          ...styling,
          link: TERMSHEETS_LINK
        },
        {
          title: "Billings",
          icon: AccountBalanceWalletIcon,
          ...styling,
          link: BILLINGS_LINK
        },
        {
          title: t("trade-partners"),
          icon: TradingRelationships,
          ...styling,
          link: TR_LINK,
        },
        {
          title: "Users",
          icon: Users,
          ...styling,
          link: USERS_LINK,
        },
      ];
    case ONBOARDED:
      return [
        {
          title: t("dashboard"),
          icon: DashboardIcon,
          ...styling,
          link: DASHBOARD,
        },
        {
          title: "Cargo Tracking",
          icon: GpsFixedIcon,
          ...styling,
          link: "/cargo-tracking",
        },
        {
          title: "Company Profile",
          icon: Companies,
          ...styling,
          link: COMPANIES,
        },

        {
          title: t("trade-partners"),
          icon: TradingRelationships,
          ...styling,
          link: TR_LINK,
        },
        {
          title: t("invoices"),
          icon: Invoices,
          ...styling,
          link: "/invoices",
        },
        {
          title: "E-wallet",
          icon: EwalletIcon,
          ...styling,
          link: "/ewallet",
        },
        {
          title: "Users",
          icon: Users,
          ...styling,
          link: USERS_LINK,
        },
      ];

    default:
      return [
        {
          title: `${t("add-your-company")}`,
          icon: AddYourCompany,
          ...styling,
          link: ADD_COMPANY,
        },
      ];
  }
};
// eslint-disable-next-line
export default menuItems;
