import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  Stack,
  type SxProps,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  type CSSProperties,
  type Dispatch,
  type SetStateAction,
} from "react";

import type { CustomColumn } from "../..";
import { sort } from "../../processor";

export interface DataTableHeaderProps<T> {
  customColumns: CustomColumn;
  item: string;
  filteredData: T[];
  setFilteredData: Dispatch<SetStateAction<T[]>>;
  headers: string[];
  order: Record<string, any>;
  setOrder: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps;
  headerTextColor?: string;
  defaultColumnWidth?: CSSProperties["width"];
}

export function Header<T extends Record<string, any> = {}>({
  customColumns,
  item,
  filteredData,
  setFilteredData,
  headers,
  order,
  setOrder,
  selectAll,
  setSelectAll,
  sx,
  headerTextColor,
  defaultColumnWidth,
}: DataTableHeaderProps<T>) {
  const onChecked = () => setSelectAll(!selectAll);
  const type =
    customColumns?.[item] && customColumns?.[item]?.type
      ? customColumns?.[item]?.type
      : "text";

  // if (item === "hasCheckBox")
  //   return (
  //     <Checkbox
  //       checked={selectAll}
  //       onChange={onChecked}
  //       size="small"
  //       id="select-all-box"
  //     />
  //   );

  const sorter = (order: "ASC" | "DSC") =>
    sort(filteredData, setFilteredData, type, item, headers, order);
  const ascend = () => {
    customColumns?.[item]?.sortComparator
      ? customColumns?.[item]?.sortComparator(filteredData)
      : sorter("ASC");

    setOrder({ [item]: "ASC" });
  };
  const descend = () => {
    customColumns?.[item]?.sortComparator
      ? customColumns?.[item]?.sortComparator(filteredData)
      : sorter("DSC");
    setOrder({ [item]: "DSC" });
  };

  return (
    <TableCell
      key={`${item}`}
      component="div"
      style={{ borderRight: "1px solid #e0e0e0", width: "100%" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width={customColumns?.[item]?.minWidth ?? defaultColumnWidth}
      >
        <Stack width="100%">
          <Tooltip title={customColumns?.[item]?.[item] ?? item.toUpperCase()}>
            <Typography
              sx={{
                margin: 0,
                fontSize: "0.75rem",
                textAlign: "start",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                color: headerTextColor,
                ...sx,
              }}
            >
              {customColumns?.[item]?.[item] ?? item.toUpperCase()}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack>
          <ArrowDropUp
            color={order[item] === "ASC" ? "disabled" : "primary"}
            fontSize="small"
            onClick={ascend}
            sx={{ visibility: type !== "component" ? "visible" : "hidden" }}
          />
          <ArrowDropDown
            color={order[item] === "DSC" ? "disabled" : "primary"}
            fontSize="small"
            onClick={descend}
            sx={{ visibility: type !== "component" ? "visible" : "hidden" }}
          />
        </Stack>
      </Stack>
    </TableCell>
  );
}
