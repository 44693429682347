import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SyncIcon from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Slider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useTrackCargoQuery } from "api/api-vendorsv2";
import { SHIPMENT_STATUSES } from "codes";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { Form, Formik } from "formik";
import moment from "moment";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { ShipmentData } from "types";
import styles from "./cargodisplay.module.scss";

const CargoDisplay = ({ shipmentId }: { shipmentId: string }) => {
  const { data, isFetching, refetch } = useTrackCargoQuery(shipmentId, {
    skip: !Boolean(shipmentId),
  });
  const normalise = (value: number, MIN: number, MAX: number) => {
    const result = ((value - MIN) * 100) / (MAX - MIN);
    return Math.trunc(result);
  };
  const theme = useTheme();

  const PrettoSlider = styled(Slider)({
    color: theme.palette.primary.main,
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-mark": {
      width: "20px",
      height: "20px",
      borderRadius: "20px",
      backgroundColor: "#333",
      opacity: 1,
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      display: "hidden",
      color: theme.palette.error.main,
      padding: 0,
      width: 25,
      height: 25,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: theme.palette.error.main,
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  const initialValues: ShipmentData = {
    carrier: "",
    containers: [
      {
        id: "",
        isoCode: "",
      },
    ],
    currentCarrierVoyageNumber: "",
    currentPortId: 0,
    currentPortName: "",
    currentPortUnlocode: "",
    finalPortActualArrivalUtc: null,
    finalPortAnchorageActualArrivalUtc: null,
    finalPortCountry: "",
    finalPortId: 0,
    finalPortLocalTimeOffset: 0,
    finalPortName: "",
    finalPortPredictiveArrivalUtc: null,
    finalPortPredictiveArrivalUtcLast: null,
    finalPortTerminalActualArrivalName: "",
    finalPortTerminalActualArrivalSMDG: null,
    finalPortTerminalActualArrivalUtc: null,
    finalPortUnlocode: "",
    lastCarrierUpdatedTimestamp: "",
    nextPortDistanceToGo: null,
    nextPortId: null,
    nextPortName: null,
    nextPortPredictiveArrivalUtc: null,
    nextPortUnlocode: null,
    originPortActualDepartureUtc: "",
    originPortCountry: "",
    originPortId: 0,
    originPortLocalTimeOffset: 0,
    originPortName: "",
    originPortPredictiveDepartureUtc: "",
    plannedEta: "",
    scac: "",
    shipmentId: "",
    shipmentStatus: 0,
    tags: [],
    transportDocumentId: "",
    transportDocumentType: "",
    transportationPlan: {
      events: [],
      locations: [],
      routes: [],
      transportLegs: [],
      vessels: [],
    },
    vesselArea: "",
    vesselIMONumber: 0,
    vesselName: "",
    vesselShipId: 0,
    vesselVoyageStatus: "",
    webViewUrl: "",
  };

  return (
    <Paper
      elevation={10}
      sx={{
        height: "auto",
        borderRadius: 3,
        padding: 3,
        mt: 2,
      }}
    >
      <Formik
        initialValues={{ ...initialValues, ...data }}
        onSubmit={() => {}}
        enableReinitialize
      >
        {(formikprops) => {
          const currentLocationCountry =
            formikprops.values.transportationPlan.locations.find(
              (location) =>
                location.locationName === formikprops.values.currentPortName
            )?.locationCountry;
          const marks = [
            {
              value: 0,
              label: `${formikprops.values.originPortName},  ${formikprops.values.originPortCountry}`,
            },
            {
              value: normalise(
                moment(formikprops.values.lastCarrierUpdatedTimestamp).unix(),
                moment(
                  formikprops.values.originPortPredictiveDepartureUtc
                ).unix(),
                moment(formikprops.values.plannedEta).unix()
              ),
              label: `${formikprops.values.currentPortName}, ${currentLocationCountry}`,
            },
            {
              value: 100,
              label: `${formikprops.values.finalPortName}, ${formikprops.values.finalPortCountry}`,
            },
          ];
          return (
            <Form>
              <Stack spacing={5}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography fontWeight={800} fontSize="1.3em">
                        Shipment Details
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        variant="outlined"
                        fullWidth
                        startIcon={<SyncIcon />}
                        onClick={refetch}
                        sx={secondaryButtonStyle}
                        loading={isFetching}
                      >
                        Sync
                      </LoadingButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Bill of Lading"
                      />
                      <LabelViewOnly
                        label={formikprops.values.transportDocumentId}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Origin"
                      />
                      <LabelViewOnly
                        label={`${formikprops.values.originPortName},  ${formikprops.values.originPortCountry}`}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Container Details"
                      />
                      <Grid container spacing={1}>
                        <Grid item>
                          <HorizontalSplitIcon />
                        </Grid>
                        <Grid item>
                          X {formikprops.values.containers.length}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Carrier"
                      />
                      <LabelViewOnly label={formikprops.values.carrier} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Destination"
                      />
                      <LabelViewOnly
                        label={`${formikprops.values.finalPortName}, ${formikprops.values.finalPortCountry}`}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {Boolean(formikprops.values.containers.length) &&
                        formikprops.values.containers.map((container) => (
                          <Grid container spacing={1}>
                            <Grid item>
                              <LocalOfferIcon
                                sx={{ transform: "rotateZ(90deg)" }}
                              />
                            </Grid>
                            <Grid item>{container.id}</Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Container>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography fontWeight={800} fontSize="1.3em">
                        Shipment Status
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Current Port Name"
                      />
                      <LabelViewOnly
                        label={`${
                          formikprops.values.currentPortName ?? "N/A"
                        }, ${currentLocationCountry ?? "N/A"}`}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Current Status"
                      />
                      <LabelViewOnly
                        label={`${
                          SHIPMENT_STATUSES[formikprops.values.shipmentStatus]
                        }
                    `}
                      />
                    </Grid>

                    <Grid item xs={12} margin="auto" mt={1}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Track Progress"
                      />
                    </Grid>
                    <Grid item xs={10} margin="auto">
                      <PrettoSlider
                        min={0}
                        max={100}
                        defaultValue={normalise(
                          moment(
                            formikprops.values.lastCarrierUpdatedTimestamp
                          ).unix(),
                          moment(
                            formikprops.values.originPortPredictiveDepartureUtc
                          ).unix(),
                          moment(formikprops.values.plannedEta).unix()
                        )}
                        step={10}
                        marks={marks}
                        valueLabelDisplay="on"
                        disabled
                        sx={{
                          color: `${theme.palette.primary.main}  !important`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};
export default CargoDisplay;
