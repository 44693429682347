import {
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useAddShareholderMutation } from "api/api-accounts";
import { useGetComplianceProvidersQuery } from "api/api-compliance";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ShareholderType } from "../types";

interface AddShareholderData {
  entity_id: string;
  entity_type: string;
  shareholder_type: ShareholderType | null;
  shareholder_name: string;
  address: string;
  percentage: number;
  source: string;
}

interface AddShareholderProps {
  entityId: string;
  entityType: string;
  shareholderType: ShareholderType | null;
  callback: () => void;
  onClose: () => void;
  isLocked?: boolean; // Add isLocked prop
}

const AddShareholder: React.FC<AddShareholderProps> = ({
  entityId,
  entityType,
  shareholderType,
  callback,
  onClose,
  isLocked = false, // Default isLocked to false
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [percentage, setPercentage] = useState<number | undefined>(undefined);
  const [source, setSource] = useState<string>("");
  const { data: creditReportProviders } = useGetComplianceProvidersQuery();

  const [addShareholder, { isLoading }] = useAddShareholderMutation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLocked) return; // Prevent submission if the form is locked

    const newShareholder: AddShareholderData = {
      entity_id: entityId,
      entity_type: entityType,
      shareholder_type: shareholderType,
      shareholder_name: name,
      address: address,
      percentage: percentage ?? 0,
      source: source,
    };

    addShareholder({
      account_id: entityId,
      data: newShareholder,
    })
      .unwrap()
      .then(() => {
        toast.success("Shareholder added successfully");
        callback();
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setPercentage(isNaN(value) ? undefined : value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSource(event.target.value as string);
  };

  return (
    <Slide in direction="left">
      <Paper
        elevation={3}
        sx={{ padding: theme.spacing(4), maxWidth: 500, margin: "auto" }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Typography variant="h3" component="h2" gutterBottom>
              Add a new shareholder
            </Typography>
            <Divider />
            <Typography>Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={name}
              placeholder="Enter Name"
              onChange={(e) => setName(e.target.value)}
              disabled={isLocked || isLoading} // Disable if locked or loading
            />

            <Typography>Address</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Enter Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled={isLocked || isLoading} // Disable if locked or loading
            />

            <Typography>Percentage</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Enter Percentage"
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={percentage !== undefined ? percentage : ""}
              onChange={(e) =>
                setPercentage(
                  e.target.value === "" ? undefined : parseFloat(e.target.value)
                )
              }
              onBlur={handleBlur}
              disabled={isLocked || isLoading} // Disable if locked or loading
            />

            <Typography>Source</Typography>
            <Select
              labelId="credit-report-source"
              id="credit-report-source"
              value={source}
              fullWidth
              placeholder="Select Source"
              variant="outlined"
              onChange={handleChange}
              disabled={isLocked || isLoading} // Disable if locked or loading
            >
              <MenuItem value="" disabled>
                Select Source
              </MenuItem>
              {creditReportProviders?.map((provider) => (
                <MenuItem value={provider} key={provider}>
                  {provider}
                </MenuItem>
              ))}
            </Select>

            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                variant="contained"
                color="error"
                disabled={isLocked || isLoading} // Disable if locked or loading
                fullWidth
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLocked || isLoading || !name || !percentage} // Disable if locked, loading, or required fields are missing
                fullWidth
              >
                Add Shareholder
              </Button>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Slide>
  );
};

export default AddShareholder;
