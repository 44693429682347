import { Divider, Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useEffect } from "react";

import Modal from "components/Common/Modal";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { CustomColumn } from "../..";
import { filterAction, getColumns } from "../../processor";
import FilterChip from "../FilterChip";
import SingleDateFilter from "./SingleDateFilter";
import SingleRadio from "./SingleRadio";
import SingleRangeFilter from "./SingleRangeFilter";
import SingleTextAc from "./SingleTextAc";
import { modalStyling } from "./styling";

interface DataTableFilterModalProps<T> {
  data: T[];
  initialData: T[];
  open: boolean;
  handleClose: () => void;
  filters: Record<string, any>;
  setFilters: Dispatch<SetStateAction<Record<string, any>>>;
  setFilteredData: Dispatch<SetStateAction<T[]>>;
  customColumns: CustomColumn;
  clearFilters: () => void;
  columnsToHide?: string[];
  onFilterChange: (filters: Record<string, any>) => void;
}

const FilterModal = <T extends Record<string, any> = {}>({
  data,
  initialData,
  open,
  handleClose,
  setFilteredData,
  filters,
  setFilters,
  columnsToHide,
  customColumns,
  clearFilters,
  onFilterChange,
}: DataTableFilterModalProps<T>) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { messageContainerProps, modalContentSx, modalOverlaySx, modalSx } =
    modalStyling({ isMobile, theme });

  const headers = getColumns(initialData).filter(
    (item) => !columnsToHide?.includes(item)
  );

  useEffect(() => {
    setFilteredData(filterAction(filters, initialData));
    onFilterChange(filters);
  }, [filters]);

  //filter to textfields, numberfields and datefields referencing from customcolumns
  const customColumnsExist = customColumns && !isObjectEmpty(customColumns);
  let textHeaders = headers;
  let numberHeaders: Record<string, any>[] = [{}];
  let dateHeaders: Record<string, any>[] = [{}];
  let boolHeaders: string[] = [];
  if (customColumnsExist) {
    textHeaders = Object.entries(customColumns)
      .filter(
        (item) =>
          (item[1]["type"] === undefined || item[1]["type"] === "text") &&
          !Boolean(item[1].noFilter)
      )
      .map((item) => item[0]);

    const unTyped = headers.filter(
      (header) => !Object.keys(customColumns).includes(header)
    );

    textHeaders = [...textHeaders, ...unTyped];
    boolHeaders = Object.entries(customColumns)
      .filter(
        (item) => item[1]["type"] === "boolean" && !Boolean(item[1].noFilter)
      )
      .map((item) => item[0]);
    numberHeaders = Object.entries(customColumns)
      .filter(
        (item) => item[1]["type"] === "number" && !Boolean(item[1].noFilter)
      )
      .map((item) => {
        return { name: item[0] };
      });

    dateHeaders = Object.entries(customColumns)
      .filter(
        (item) => item[1]["type"] === "date" && !Boolean(item[1].noFilter)
      )
      .map((item) => {
        return { name: item[0] };
      });
  }

  const FilterBody = () => {
    return (
      <>
        {!isObjectEmpty(filters) && (
          <>
            <Grid item xs={12} lg={11}>
              <FilterChip
                filters={filters}
                clearFilters={clearFilters}
                customColumns={customColumns}
              />
            </Grid>
            <Grid item xs={10}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid item xs={12} lg={10}>
          <Grid container spacing={1}>
            {textHeaders &&
              textHeaders.map((header) => (
                <Grid item lg={4} xs={12} key={header}>
                  <SingleTextAc
                    header={header}
                    data={[
                      ...new Set(initialData.map((option) => option[header])),
                    ]}
                    filters={filters}
                    setFilters={setFilters}
                    customFieldName={
                      customColumnsExist
                        ? customColumns?.[header]?.[header]
                        : ""
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {Boolean(boolHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="bool-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container>
                {boolHeaders &&
                  boolHeaders.map((header) => (
                    <Grid item lg={4} xs={12} key={header}>
                      <SingleRadio
                        header={header}
                        data={initialData}
                        filters={filters}
                        setFilters={setFilters}
                        boolTitles={
                          customColumns?.[header]?.boolTitles ?? [
                            "True",
                            "False",
                          ]
                        }
                        customFieldName={
                          customColumnsExist
                            ? customColumns?.[header]?.[header]
                            : ""
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </>
        )}
        {Boolean(numberHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="number-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container>
                {numberHeaders.map((header, i) => {
                  const numberArray = initialData.map((item) => {
                    if (typeof item[header?.name] === "number")
                      return item[header?.name];
                    return Number(item[header?.name]?.replaceAll(",", ""));
                  });

                  return (
                    <Grid item xs={12} key={header?.name}>
                      <SingleRangeFilter
                        label={header?.name}
                        customColumns={customColumns}
                        filters={filters}
                        setFilters={setFilters}
                        range={[
                          Math.min(...numberArray),
                          Math.max(...numberArray),
                        ]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
        {Boolean(dateHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="date-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={1}>
                {dateHeaders.map((header, i) => {
                  const standardize = (val: string) =>
                    moment(val).format("YYYY-MM-DD");

                  const dateSet = data
                    .filter((x) => x[header?.name])
                    .map((item) => moment(standardize(item[header?.name])))
                    .filter((item) => item.isValid());

                  const minima = moment.min(dateSet).toDate();
                  const maxima = moment.max(dateSet).toDate();

                  return (
                    <Fragment key={header?.name}>
                      <Grid item xs={12}>
                        <SingleDateFilter
                          filters={filters}
                          customColumns={customColumns}
                          range={[minima, maxima]}
                          label={header?.name}
                          setFilters={setFilters}
                          disabled={Boolean(!dateSet.length)}
                        />
                      </Grid>
                      {dateHeaders.length > 1 && (
                        <Grid item xs={10}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };
  const Header = (
    <Grid item xs={12} lg={12} sx={{ margin: "1ch 2vh" }} id="filter-container">
      <Typography variant="h3" fontWeight="bold">
        Filters
      </Typography>
    </Grid>
  );

  return (
    <Modal
      width={isMobile ? "95vw" : "80vw"}
      height={isMobile ? "100vh" : "80vh"}
      modalContentSx={modalContentSx}
      Header={Header}
      message={<FilterBody />}
      open={open}
      onClose={handleClose}
      messageContainerProps={messageContainerProps}
      closeButton
      modalOverlaySx={modalOverlaySx}
      sx={modalSx}
    />
  );
};
export default FilterModal;
