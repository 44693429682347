import {
  useGetCategoriesQuery,
  useGetMapSummaryQuery,
  useGetTemplatesV2Query,
  type MAP_TYPE,
} from "api/api-maps";
import Layout from "components/Common/Layout";
import { MapContext, MapCurrentVersion } from "pages/maps/map";

import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import ConfigurationTabs from "./Sections";

interface MapTemplate_V2 {
  item_template_name: string;
  version: string;
  template: {
    [field_name: string]: {
      id: string;
      name: string;
      type: string;
      log_id: string;
      format: string;
      data: string | number | string[];
    };
  };
}

const Configuration: React.FC = () => {
  const id = useContext(MapContext);
  const currentMapVersion = React.useContext(MapCurrentVersion);

  const {
    data: scoreCardTemplate,
    isFetching: isFetchingScoreCardTemplate,
    refetch: refetchScoreCardTemplate,
  } = useGetCategoriesQuery(id ?? "", {
    skip: !id,
  });
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const {
    data: financialTemplate = {} as MapTemplate_V2,
    refetch: refetchFinancialTemplate,
  } = useGetTemplatesV2Query(
    {
      party: "buyer",
      use_case: "financials" as MAP_TYPE,
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );

  const {
    data: companyBackground = {} as MapTemplate_V2,
    refetch: refetchCompanyBackground,
  } = useGetTemplatesV2Query(
    {
      party: "buyer",
      use_case: "background" as MAP_TYPE,
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );
  const {
    data: transactions = {} as MapTemplate_V2,
    refetch: refetchTransactions,
  } = useGetTemplatesV2Query(
    {
      party: "buyer",
      use_case: "transactions" as MAP_TYPE,
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );

  const {
    buyers_assessment = [],
    suppliers_assessment = [],
    trading_relationship = [],
  } = scoreCardTemplate ?? {};

  const sections = [
    {
      title: "Company Background - Client Call",
      data: [
        ...Object.values(
          (companyBackground as MapTemplate_V2)?.template ?? {}
        ).map((item) => {
          return {
            id: item.id,
            name: item.name,
            status: "company_background_client_call",
            data: {
              name: item.name,
              options: [],
            },
          };
        }),
      ],
      isFetching: false,
    },
    {
      title: "Financials - Client Call",
      data: [
        ...Object.values(
          (financialTemplate as MapTemplate_V2)?.template ?? {}
        ).map((item) => {
          return {
            id: item.id,
            name: item.name,
            status: "financials_client_call",
            data: {
              name: item.name,
              options: [],
            },
          };
        }),
      ],
      isFetching: false,
    },
    {
      title: "Transaction Details",
      data: [
        ...Object.values((transactions as MapTemplate_V2)?.template ?? {}).map(
          (item) => {
            return {
              id: item.id,
              name: item.name,
              status: "transaction_details",
              data: {
                name: item.name,
                options: [],
              },
            };
          }
        ),
      ],
      isFetching: false,
    },
    {
      title: "Scoring",
      data: [
        ...buyers_assessment.map((item) => {
          return {
            id: item.map_metadata_id,
            name: item.item,
            status: "buyers_assessment",
            data: {
              name: item.item,
              options: item.item_descriptions.map((desc) => desc.name),
            },
          };
        }),
        ...suppliers_assessment.map((item) => {
          return {
            id: item.map_metadata_id,
            name: item.item,
            status: "suppliers_assessment",
            data: {
              name: item.item,
              options: item.item_descriptions.map((desc) => desc.name),
            },
          };
        }),
        ...trading_relationship.map((item) => {
          return {
            id: item.map_metadata_id,
            name: item.item,
            status: "trading_relationship",
            data: {
              name: item.item,
              options: item.item_descriptions.map((desc) => desc.name),
            },
          };
        }),
      ],
      isFetching: isFetchingScoreCardTemplate,
    },
  ];

  const syncAction = () => {
    refetchCompanyBackground();
    refetchFinancialTemplate();
    refetchTransactions();
    refetchScoreCardTemplate();
  };

  return (
    <Layout
      title="Field Configuration"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 4,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
      headerConfig={{
        syncAction,
        left: 10,
        mid: 0,
        right: 2,
        xs: {
          left: 12,
          mid: 0,
          right: 12,
        },
        alignItems: "center",
      }}
      mode="default"
    >
      <ConfigurationTabs sections={sections} />
    </Layout>
  );
};

export default Configuration;
