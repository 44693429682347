import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DragFile from "assets/dragFile.svg?react";
import PdfAdobe from "assets/pdfAdobe.svg?react";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";
import Dropzone, { FileWithPath } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styles from "./multiupload.module.scss";

const DragDropTicketAttachments = ({
  uploadedFiles,
  setUploadedFiles,
  onClick,
}: {
  uploadedFiles: any[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<any[]>>;
  onClick: () => void;
}) => {
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dropzone
      accept={{
        "application/pdf": [".pdf"],
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
        "image/jpg": [".jpg"],
      }}
      onDrop={(files) =>
        setUploadedFiles((prevFiles) => [...prevFiles, ...files])
      }
    >
      {({ getRootProps, getInputProps }) => (
        <Grid item xs={12}>
          <Card
            {...getRootProps()}
            sx={{
              background: "transparent",
              boxShadow: "none",
              border: "none",
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                ":last-child": {
                  paddingBottom: "1ch",
                },
              }}
            >
              <Grid container margin="auto" ref={ref}>
                <Grid item xs={12} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        spacing={2}
                      >
                        <Grid item className={styles.dropzoneText}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item xs={2}>
                              <DragFile style={{ margin: "auto" }} />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontSize: isMobile ? "1em" : "1em",
                                }}
                              >
                                {`${t("drag-and-drop")} `}
                                <Link
                                  style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                >
                                  {`${t("browse")} `}
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: isMobile ? "0.8em" : "1em",
                            }}
                          >
                            {`${t("document-type-be")} `} <b>PDF</b>,{" "}
                            <b>JPEG</b> {`${t("or")}`} <b>PNG</b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: isMobile ? "0.8em" : "1em",
                            }}
                          >
                            {t("max-upload-size")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {/* Uploaded Files tray */}
                          <Grid container spacing={1} maxWidth="50vw">
                            {uploadedFiles.map((file, i) => (
                              <Grid
                                item
                                key={
                                  (file as FileWithPath).path ||
                                  (file as File).name
                                }
                                xs={4}
                              >
                                <Tooltip
                                  title={
                                    (file as FileWithPath).path ||
                                    (file as File).name
                                  }
                                >
                                  <Chip
                                    sx={{
                                      borderRadius: "0.5ch",
                                      bgcolor: "#ffffff",
                                      padding: "3ch 0ch",
                                      ".MuiChip-deleteIcon": {
                                        color: "#C10230",
                                      },
                                      minWidth:
                                        uploadedFiles?.length === 1
                                          ? "15vw"
                                          : "6vw",
                                    }}
                                    label={
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Grid item xs={3}>
                                          <PdfAdobe />
                                        </Grid>
                                        <Grid item xs={8}>
                                          <Typography
                                            sx={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              maxWidth: "80%",
                                              textOverflow: "ellipsis",
                                              fontSize: "1.125em",
                                              lineHeight: "2.25em",
                                              letterSpacing: "inherit",
                                            }}
                                          >
                                            {(file as FileWithPath).path ||
                                              (file as File).name}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    }
                                    variant="outlined"
                                    onDelete={() => {
                                      setUploadedFiles((files) =>
                                        files.filter((file, idx) => idx !== i)
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Submit Button */}
                {uploadedFiles.length > 0 && (
                  <Grid
                    item
                    alignContent="center"
                    justifyContent="center"
                    margin="2vh auto"
                  >
                    <Button
                      onClick={onClick}
                      style={{
                        margin: "auto",
                      }}
                      variant="contained"
                      fullWidth
                      sx={{ textTransform: "initial !important" }}
                    >
                      {`${t("upload-selected-file")}`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Dropzone>
  );
};
export default DragDropTicketAttachments;
