import { Grid, Typography } from "@mui/material";
import {
  IS_LOGIN,
  IS_LOGIN_ONBOARDED,
  IS_REQUESTING_NEW_PASS,
  IS_VERIFYING_NEW_PASS,
  IS_VERIFYING_OTP,
  IS_VERIFYING_TOTP,
} from "codes";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";

import { useResendOtpMutation } from "api/api-auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./login.module.scss";

const signUp = () => {
  history.push("/signup");
};

export const AuthScreens = ({ state }: { state: string }): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [resendOtp] = useResendOtpMutation();

  const email = (history.location.state as any)?.email ?? "";

  switch (state) {
    case IS_VERIFYING_NEW_PASS:
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.5em" : "2.875em"}
            >
              {t("reset-your-password")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center">
              {t("otp-to-authenticate")}
            </Typography>
          </Grid>
        </Grid>
      );
    case IS_REQUESTING_NEW_PASS:
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.5em" : "2.875em"}
            >
              {t("reset-password")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center">
              {t("enter-new-password-and-confirm")}
            </Typography>
          </Grid>
        </Grid>
      );
    case IS_VERIFYING_OTP:
      return (
        <Grid container spacing={isMobile ? 3 : 1}>
          <Grid item xs={11} lg={12} margin="auto">
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.3em" : "2.875em"}
            >
              {t("thank-you-for-your-interest-in-incomlend")}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={isMobile ? "5vh" : 0}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={11} lg={12} margin="auto">
                <Typography textAlign="center">
                  {t("check-your-inbox-enter-otp")}
                </Typography>
              </Grid>
              <Grid item xs={11} lg={12} margin="auto">
                <Typography textAlign="center">
                  {t("check-the-junkspam")}{" "}
                  {Boolean(email) && (
                    // eslint-disable-next-line
                    <a
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() =>
                        resendOtp({ email: email ?? "" })
                          .unwrap()
                          .then(() =>
                            toast(`${t("otp-sent")}`, { type: "success" })
                          )
                          .catch((e) =>
                            toast(`Error: ${e?.message}`, { type: "error" })
                          )
                      }
                    >
                      {t("resend-the-otp")}
                    </a>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    case IS_VERIFYING_TOTP:
      return (
        <Grid container spacing={isMobile ? 3 : 1}>
          <Grid item xs={11} lg={12} margin="auto">
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.3em" : "2.875em"}
            >
              Verify Your Identity
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={isMobile ? "5vh" : 0}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={11} lg={12} margin="auto">
                <Typography textAlign="center">
                  You're trying to Log In to Incomlend. To make sure your Incomlend account is secure, we have to verify your identity.
                </Typography>
              </Grid>
              <Grid item xs={11} lg={12} margin="auto">
                <Typography textAlign="center">
                  Use the authenticator app on your device to generate a 6-digit verification code.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    case IS_LOGIN_ONBOARDED:
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.5em" : "2.875em"}
            >
              {t("your-account-has-been-activated")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center">{t("can-now-login")}</Typography>
          </Grid>
        </Grid>
      );
    case IS_LOGIN:
      return (
        <Grid item xs={12}>
          <Grid container spacing={1} id="login-content">
            <Grid item xs={12}>
              <Typography
                variant="h2"
                textAlign="center"
                fontWeight="bolder"
                fontSize={isMobile ? "1.5em" : "2.4em"}
              >
                {t("login")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontSize: isMobile ? "1em" : "1.2em",
                    }}
                    textAlign="center"
                  >{`${t("dont-have-account")}`}</Typography>
                </Grid>
                <Grid item>
                  <span
                    className={styles.signInLink}
                    onClick={signUp}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        signUp && signUp();
                      }
                    }}
                  >
                    {`${t("sign-up")}`}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    default:
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              fontSize={isMobile ? "1.5em" : "2.875em"}
            >
              Login
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography textAlign="center">{`${t(
                  "dont-have-account"
                )}`}</Typography>
              </Grid>
              <Grid item>
                <span
                  className={styles.signInLink}
                  onClick={signUp}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      signUp && signUp();
                    }
                  }}
                >
                  {`${t("sign-up")}`}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
  }
};
