import { Add } from "@mui/icons-material";
import { Box, Slide } from "@mui/material";
import { useGetOrgChartQuery } from "api/api-human-resources";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AddOrgchartNodeModal from "components/HumanResources/AddOrgchartNodeModal";
import OrgchartNodeModal from "components/HumanResources/OrgchartNodeModal";
import { createData, levels } from "helpers/orgchartGenerator";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOrganization from "highcharts/modules/organization";
import HighchartsSankey from "highcharts/modules/sankey";
import useIsMobile from "hooks/useIsMobile";
import { PERMISSIONS } from "permissions";
import { useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import styles from "./orgchart.module.scss";

const OrgChartPage = () => {
  const isMobile = useIsMobile();
  //level 0 consists of departments
  //level 1 consists of incorporated countries
  //level 2 consists of branches
  const { data: orgChart = [], refetch, isFetching } = useGetOrgChartQuery();

  const [currentNode, setCurrentNode] = useState<any>();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  HighchartsSankey(Highcharts);
  HighchartsOrganization(Highcharts);
  HighchartsExporting(Highcharts);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const data = createData(orgChart);

  const options: Highcharts.Options = {
    chart: {
      height: window.innerHeight * 1.5,
      inverted: true,
      width: chartComponentRef.current?.chart.fullscreen.isOpen
        ? window.innerWidth
        : window.innerWidth * 0.75,
      scrollablePlotArea: {
        minHeight: 700,
      },
    },

    title: {
      text: "",
    },

    accessibility: {
      point: {
        descriptionFormat:
          "{add index 1}. {toNode.name}" +
          "{#if (ne toNode.name toNode.id)}, {toNode.id}{/if}, " +
          "reports to {fromNode.id}",
      },
    },

    series: [
      {
        type: "organization",
        name: "Incomlend Group",
        keys: ["from", "to"],
        data,
        levels,
        nodes: orgChart,
        colorByPoint: false,
        color: "#007ad0",
        dataLabels: {
          color: "white",
        },
        borderColor: "black",
        nodeWidth: 65,
      },
    ],
    tooltip: {
      outside: true,
    },
    exporting: {
      allowHTML: true,
      sourceWidth: 800,
      sourceHeight: 600,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              const node_id = orgChart.find(
                (item) => item.id === (this as any)?.id
              )?.node_id;
              const currentObject = { ...this, node_id };
              setCurrentNode(currentObject);
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState?.user_permission
  );
  const IS_AUTHORIZED = USER_PERMISSIONS.includes(
    PERMISSIONS?.manage_org_chart
  );

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title="Incomlend Group Org Chart"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            left: 8,
            mid: 0,
            right: 2,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
            justifyContent: "space-between",
            syncAction: refetch,
          }}
          primary={
            IS_AUTHORIZED
              ? {
                  startIcon: <Add />,
                  children: "Add Member",
                  onClick: () => setOpenCreateModal(true),
                  fullWidth: true,
                }
              : undefined
          }
          mode="default"
        >
          {!Boolean(orgChart.length) && isFetching && (
            <SkeletonLoad bars={10} />
          )}
          {Boolean(orgChart.length) && !isFetching && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
              containerProps={{
                className: styles["highcharts-data-table"],
                style: { height: "100%" },
              }}
              allowChartUpdate
            />
          )}
        </Layout>

        <OrgchartNodeModal
          currentNode={currentNode}
          setCurrentNode={setCurrentNode}
          orgChart={orgChart}
          update={refetch}
        />
        <AddOrgchartNodeModal
          open={openCreateModal}
          setOpen={setOpenCreateModal}
          orgChart={orgChart}
          update={refetch}
        />
      </Box>
    </Slide>
  );
};
export default OrgChartPage;
