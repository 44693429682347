import { Avatar, Stack, useTheme } from "@mui/material";
import React, { Fragment } from "react";

interface CriteriaCardTemplateProps {
  children: JSX.Element;
}

const CriteriaCardTemplate: React.FC<CriteriaCardTemplateProps> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Stack
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: 1,
          aspectRatio: "4/3",
          height: "297mm",
          width: "210mm",
        }}
      >
        <Avatar
          variant="square"
          sx={{
            bgcolor: theme.palette.primary.main,
            width: "100%",
            height: "2%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          {" "}
        </Avatar>

        <Stack direction="row">
          <Avatar
            variant="square"
            sx={{
              bgcolor: theme.palette.error.main,
              height: "284.5mm",
              width: "2%",
            }}
          >
            {" "}
          </Avatar>
          {children}
        </Stack>
        <Stack direction="row" height={"2%"}>
          <Avatar
            variant="square"
            sx={{
              bgcolor: theme.palette.primary.main,
              width: "70%",
              height: "100%",
              borderBottomLeftRadius: 10,
            }}
          >
            {" "}
          </Avatar>
          <Avatar
            variant="square"
            sx={{
              bgcolor: theme.palette.error.main,
              width: "30%",
              height: "100%",

              borderBottomRightRadius: 10,
            }}
          >
            {" "}
          </Avatar>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CriteriaCardTemplate;
