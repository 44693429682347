import { Add } from "@mui/icons-material";
import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetGeneratedTaxInvoicesQuery } from "api/api-operations";
import { INCOMLEND_INTERNAL_LINK, TAX_INVOICES_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateTaxInvoice from "components/TaxInvoice/Create";
import { currencyFormatter } from "helpers/currencyFormatter";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef, useState } from "react";
import useDefaultTableDimensions from "redux/useDefaultTableDimensions";

interface Payee {
  customer_name: string;
  customer_address: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}

interface GeneratedInvoice {
  id: string;
  sf_invoice_name: string;
  sf_tax_invoice_id: string;
  invoice_reference_number: string;
  internal_name: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  total_amount: number;
  gst_amount: number;
  balance_due: number;
  tax_invoice_type: string;
  transaction_type: string;
  invoice_date: Date;
  customer: Payee;
}

const TaxInvoicesPage = () => {
  const isMobile = useIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const {
    data: taxInvoices = [],
    isLoading,
    refetch,
  } = useGetGeneratedTaxInvoicesQuery();

  const { defaultColumnWidth: defaultColumnWidth, defaultRows: rowsPerPage } =
    useDefaultTableDimensions(taxInvoices, width);

  const [createTaxInvoice, setCreateTaxInvoice] = useState(false);
  return (
    <Slide in direction="left">
      <Box flexGrow={1}>
        <Layout
          title={createTaxInvoice ? "Create Tax Invoice" : `Tax Invoices`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 0,
            mid: 0,
            right: 3,
          }}
          mode="default"
          alignItems="center"
          primary={{
            children: createTaxInvoice ? "Cancel" : "Create",
            startIcon: createTaxInvoice ? undefined : <Add />,
            fullWidth: true,
            onClick: () => setCreateTaxInvoice(!createTaxInvoice),
            color: createTaxInvoice ? "error" : "primary",
          }}
        >
          {/**transaction list */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12} ref={ref}>
                {createTaxInvoice ? (
                  <CreateTaxInvoice onEnd={() => setCreateTaxInvoice(false)} />
                ) : (
                  <Grid container spacing={1}>
                    {isLoading && <SkeletonLoad bars={10} />}
                    {!isLoading && taxInvoices && (
                      <DataTablev2
                        rowsPerPage={rowsPerPage}
                        defaultColumnWidth={defaultColumnWidth}
                        data={taxInvoices}
                        customColumns={{
                          sf_invoice_name: {
                            sf_invoice_name: "SF Invoice Name",
                          },
                          invoice_reference_number: {
                            invoice_reference_number: "Invoice Ref",
                            minWidth: 220,
                          },
                          supplier_invoice_reference_number: {
                            supplier_invoice_reference_number:
                              "Supplier Inv. Ref.",
                            minWidth: 120,
                          },
                          total_amount: {
                            total_amount: "Total Amount (USD)",
                            renderCell: ({ value }: { value: number }) => {
                              return (
                                <Typography>
                                  {currencyFormatter({
                                    amount: Number(value),
                                    useSymbol: "$",
                                  })}
                                </Typography>
                              );
                            },
                            minWidth: 150,
                          },
                          balance_due: {
                            balance_due: "Balance Due",
                            renderCell: ({ value }: { value: number }) => {
                              return (
                                <Typography>
                                  {currencyFormatter({
                                    amount: Number(value),
                                    useSymbol: "$",
                                  })}
                                </Typography>
                              );
                            },
                            minWidth: 120,
                          },
                          customer: {
                            customer: "Customer",
                            type: "component",
                            renderCell: ({ value }: { value: Payee }) => {
                              return (
                                <Typography
                                  color={
                                    value?.customer_name ? "primary" : "grey"
                                  }
                                >
                                  {value?.customer_name ?? "None"}
                                </Typography>
                              );
                            },
                            minWidth: 220,
                          },
                        }}
                        toBeHidden={[
                          "tax_rate_percentage",
                          "sf_tax_invoice_id",
                          "exchange_rate",
                          "invoice_amount",
                          "currency",
                          "gst_amount",
                          "tax_invoice_type",
                          "transaction_type",
                          "invoice_date",
                          "internal_name",
                        ]}
                        onRowClicked={(row) =>
                          history.push(
                            `${INCOMLEND_INTERNAL_LINK}${TAX_INVOICES_LINK}/${row.internal_name}`
                          )
                        }
                      />
                    )}
                    {!isLoading && taxInvoices && taxInvoices.length === 0 && (
                      <NoData
                        text="No tax invoices generated yet"
                        sx={{ margin: "auto" }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default TaxInvoicesPage;
