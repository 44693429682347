import { Grid, Stack } from "@mui/material";
import {
  useDeleteDocMutation,
  useGetAccountDocumentsQuery,
  useGetDocumentUrlQuery,
} from "api/api-accounts";
import { useAddCreditReportDocumentMutation } from "api/api-compliance";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Form, Formik } from "formik";
import groupBy from "helpers/groupBy";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { CreditReportDocument, Item } from "./types";

interface CreditReportUploadProps {
  data: CreditReportDocument;
  accountId: string;
  provider: string;
  onDelete?: () => void;
  onUpload?: () => void;
  isLocked?: boolean;
}

const CreditReportUpload: React.FC<CreditReportUploadProps> = ({
  data,
  accountId,
  provider,
  onDelete,
  onUpload,
  isLocked,
}) => {
  const [addUploadCreditReport] = useAddCreditReportDocumentMutation();
  const { data: initialData, isFetching } = useGetAccountDocumentsQuery(
    accountId,
    {
      skip: !Boolean(accountId),
    }
  );

  const initialValues = {
    documentFile: data?.filename ?? "",
    documentType: "credit_report",
    documentCategory: data?.document_type ?? "",
  };
  const groupedCreditReports = groupBy(initialData ?? [], "document_type");

  const items: Item[] = [
    {
      id: provider,
      name: `${provider?.toUpperCase()} Credit Report`,
      label: `Upload Credit Report for ${provider?.toUpperCase()}`,
      value: "",
      description: `${provider} Credit Report`,
      items: [],
      yearly_set_count: 1,
      count_unique_uploaded: groupedCreditReports?.[provider]?.length ?? 0,
    },
  ];

  const [show, setShow] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>("");
  const [deleteDoc, { isLoading: isLoadingDeleteDocs }] =
    useDeleteDocMutation();

  const { data: documentUrl } = useGetDocumentUrlQuery(
    {
      account_id: accountId,
      document_id: documentId ?? "",
    },
    { skip: !Boolean(documentId) }
  );

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const handleSubmit = (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => {
    addUploadCreditReport({
      account_id: accountId,
      data: {
        file: data.file,
        document_type: provider,
        document_category: "credit-report",
      },
    })
      .unwrap()
      .then((response) => {
        toast(`Uploaded ${response.name} successfully!`, {
          type: "success",
        });
        onUpload?.();
      })
      .catch((error: any) => {
        toast(`Failed to upload document: ${error.message}`, { type: "error" });
      });
  };

  const validateForm = (values: any) => {
    const errors: any = {};
    return errors;
  };

  const fetchDocument = async (id: string) => {
    setDocumentId(id);
    onOpen();
  };

  const deleteDocument = (id: string) =>
    deleteDoc({ account_id: accountId, id })
      .unwrap()
      .then(() => {
        toast(`Document deleted successfully!`, { type: "success" });
        onDelete?.();
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong: ${errorFile}`, {
          type: "error",
        });
      });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validate={validateForm}
    >
      {(formikProps) => (
        <Form>
          <Stack direction="column" spacing={2}>
            <DocumentUploadController
              data={items}
              enableCount={false}
              formikProps={formikProps}
              initialData={initialData ?? []}
              onUpload={handleSubmit}
              onView={(fileName: string, id: string, idx: number) => {
                fetchDocument(id);
              }}
              onDelete={(fileName: string, id: string, idx: number) => {
                deleteDocument(id);
              }}
              sx={{ minHeight: "10vh" }}
              containerSx={{ mt: 0 }}
              disabled={isLocked}
              deleteDisabled={isLocked}
            />
            <Modal
              modalOverlaySx={{ borderRadius: "2ch" }}
              width="30%"
              height="100%"
              closeButton
              message={
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    {documentUrl?.url?.includes(".pdf?") ? (
                      <Grid item xs={12} id="modal-docview">
                        <DocView uri={documentUrl?.url} staticView />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <img
                          src={documentUrl?.url ?? ""}
                          alt={documentUrl?.url}
                          width="100%"
                          height="auto"
                          style={{
                            borderRadius: "2ch",
                            margin: "auto",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
              open={show}
              onClose={onClose}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CreditReportUpload;
