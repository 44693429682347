import { type Dispatch, type SetStateAction, useRef, useState } from "react";

import Settings from "@mui/icons-material/Settings";
import { Avatar, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import auth from "helpers/auth";

import {
  useFetchUserProfilePicQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";

import SkeletonLoad from "components/Common/SkeletonLoad";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({
  handleRightDrawerToggle,
}: {
  handleRightDrawerToggle: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  auth();
  const { data: user, isFetching } = useGetCurrentUserQuery();

  const { data: profilePic, status } = useFetchUserProfilePicQuery(undefined, {
    skip: !Boolean(user),
    refetchOnFocus: true,
  });

  const [notification, setNotification] = useState(false);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    handleRightDrawerToggle((profile) => !profile);
  };

  return (
    <>
      {isFetching && <SkeletonLoad bars={1} />}
      {!isFetching && (
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.background.default,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.dividerChannel,
              background: `${theme.palette.background.default}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          data-testid="profile-section"
          icon={
            <Avatar
              src={profilePic?.url}
              sx={{
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.primary,
              }}
              ref={anchorRef}
              aria-haspopup="true"
              color="inherit"
            >{`${user?.first_name?.charAt(0)?.toUpperCase()}
            ${user?.last_name?.charAt(0)?.toUpperCase()}`}</Avatar>
          }
          label={<Settings color="primary" />}
          variant="outlined"
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
      )}
    </>
  );
};

export default ProfileSection;
