import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";
import { useAddContactMutation } from "api/api-users-v2";
import { ROLE_ADMIN } from "codes";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { defaultValues, validationSchema } from "./addContactFormFields";

const AddAuthorizedSignatory = ({
  companyId,
  isPartner,
  setAdditionState,
  onEnd,
  module,
}: {
  companyId: string;
  isPartner: boolean;
  setAdditionState?: React.Dispatch<React.SetStateAction<boolean>>;
  onEnd?: () => void;
  module?: string;
}) => {
  const [addUser, { error, isError, isLoading }] = useAddContactMutation();

  const { data: company } = useGetAccountQuery(companyId, {
    skip: !Boolean(companyId),
  });

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {(formikProps) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="first_name"
                        label="First Name"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="last_name"
                        label="Last Name"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="title"
                        label="Job Position"
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        tabIndex={5}
                        name="mobile"
                        label={`${"Phone Number"}`}
                        placeholder={`${"phone-number"}`}
                        fullWidth
                        type="phone"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="email"
                        label="Email"
                        type="email"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} lg={12} margin="auto">
                  <Grid container spacing={2}>
                    <Grid item xs={5} lg={3}>
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        data-testid="add-user-button"
                        loading={isLoading}
                        onClick={() => {
                          const newUser: any & {
                            type?: "IDVerification" | undefined;
                          } = {
                            email: formikProps.values.email ?? "",
                            first_name: formikProps.values.first_name ?? "",
                            last_name: formikProps.values.last_name ?? "",
                            mobile: formikProps.values.mobile ?? "",
                            title: formikProps.values.title ?? "",
                            account_id: company?.id ?? "",
                            role: formikProps.values.roles?.toLowerCase(),
                            is_authorized:
                              formikProps.values.roles?.toLowerCase() ===
                              ROLE_ADMIN.toLowerCase()
                                ? true
                                : formikProps.values.is_authorized,
                            is_partner: isPartner,
                          };

                          if (module === "compliance") {
                            newUser["type"] = "IDVerification";
                          }

                          company &&
                            addUser(newUser)
                              .unwrap()
                              .then((res) => {
                                if (
                                  !isLoading &&
                                  !isError &&
                                  !res.hasOwnProperty("error")
                                ) {
                                  setAdditionState && setAdditionState(false);
                                  toast(
                                    `Successfully created user: ${formikProps.values.first_name} ${formikProps.values.last_name}`,
                                    { type: "success" }
                                  );
                                  if (onEnd) {
                                    onEnd();
                                  }
                                } else
                                  toast(
                                    `Error adding user: ${(error as any).data}`,
                                    { type: "error" }
                                  );
                              })
                              .catch((e) => {
                                toast(e?.message, { type: "error" });
                              });
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default AddAuthorizedSignatory;
