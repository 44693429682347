import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const assignColor = (value: number) => {
  if (value < 26) return "error";
  if (value < 76) return "warning";
  return "success";
};
export default function LinearWithValueLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        {...props}
        value={props.value ?? 0}
        color={assignColor(props.value ?? 0)}
      />
    </Box>
  );
}
