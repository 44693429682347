import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormHelperText,
  Grid,
  type SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import Input from "components/Common/Input";
import Layout, { type LayoutProps } from "components/Common/Layout";
import { Form, Formik, type FormikErrors } from "formik";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import { useTranslation } from "react-i18next";
import { defaultValues } from "./formFields";
import styles from "./signup.module.scss";

type DisabledOrIgnoredFieldsType = {
  [x: string]: boolean;
};

export interface InvitedUserProps {
  /**Write JSDoc */
  sidePanel?: any;
  onSubmit: (values: any) => any;
  login?: (values?: any) => void;
  CustomHeader?: JSX.Element;
  layoutFooter?: JSX.Element;
  userType?: "internal" | "external";
  error?: string;
  tnc?: {
    checkboxLabel?: string;
    linkText?: string;
    tncUrl?: string;
    action?: (e: React.ChangeEvent<HTMLInputElement>, props: any) => void;
  };

  className?: string;
  layoutProps?: LayoutProps;
  validationSchema?: any;
  helpTexts?: Record<string, any>;
  Footer?: () => JSX.Element;
  InputSx?: SxProps;
  additionalField?: React.ReactNode;
  initialFormValues?: Record<string, any>;
  ignoredFields?: DisabledOrIgnoredFieldsType;
  disabledFields?: DisabledOrIgnoredFieldsType;
  headingText?: string;
}

const InvitedUserSignup = (props: InvitedUserProps) => {
  const { t } = useTranslation();
  const {
    sidePanel,
    onSubmit,
    login,

    tnc = {
      checkboxLabel: "Agree to Incomlend Pte Ltd",
      linkText: "Terms and Conditions*",
    },
    error = "",
    layoutFooter,
    className,
    layoutProps,
    validationSchema,
    Footer,
    InputSx,
    CustomHeader = <></>,
    additionalField,
    helpTexts = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      title: "",
      confirmPassword: "",
      recaptchaCheck: "",
      agreementCheck: "",
      marketingCheck: "",
    },
    initialFormValues = defaultValues,
    ignoredFields,
    disabledFields,
    headingText,
    userType,
  } = props;

  const isMobile = useIsMobile();

  const theme = useTheme();

  const inputFieldSx = {
    ".MuiTextField-root": {
      borderRadius: theme.shape.borderRadius,
      height: "3.5em",
      mt: 0.1,
      ".MuiInputBase-root": {
        "&::before": {
          borderBottom: "1px solid transparent",
        },
        ".MuiNativeSelect-select": {
          pl: 2,
        },

        ">input": {
          height: "80%",
        },
      },
      ".MuiButtonBase-root": {
        mr: 1,
      },
    },
  };
  return (
    <Layout
      mode={isMobile ? "default" : "split"}
      noDivider={isMobile}
      layoutSideBarChildren={sidePanel}
      backArrow={false}
      className={className}
      footer={layoutFooter}
      {...layoutProps}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={isMobile ? 1 : 0}
        maxWidth="100%"
        padding={0}
      >
        {CustomHeader}
        {/* Title */}
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Grid container spacing={{ lg: 2, xs: 1 }}>
            <Grid item xs={12}>
              <Typography
                textAlign={isMobile ? "center" : "start"}
                fontWeight="bolder"
                fontSize={isMobile ? "1.7em" : "2.875em"}
                variant="h1"
              >
                {`${headingText || "Create your free account"}`}
              </Typography>
            </Grid>
            {!Boolean(headingText) && (
              <Grid item xs={12}>
                <Typography textAlign={isMobile ? "center" : "start"}>
                  {t("already-have-an-account")}{" "}
                  <span
                    className={styles.signInLink}
                    onClick={login}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        login && login();
                      }
                    }}
                  >
                    {t("sign-in")}
                  </span>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Title end*/}
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Formik
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={1}
                    margin="0 auto 0 auto"
                    justifyContent="center"
                  >
                    {/* Row 1 */}
                    <Grid
                      item
                      lg={11}
                      xs={11}
                      mr={{ lg: "auto" }}
                      m={{ xs: "auto" }}
                    >
                      <Grid container spacing={1}>
                        {!ignoredFields?.firstName && (
                          <Grid item xs={12} md={6} lg={6}>
                            <Input
                              sx={inputFieldSx}
                              labelClassName={styles.labelClassName}
                              helpText={helpTexts["firstName"]}
                              tabIndex={1}
                              name="firstName"
                              label={`${t("first-name")}`}
                              required
                              disabled={disabledFields?.firstName}
                              placeholder={`${t("first-name")}`}
                              fullWidth
                              style={{ width: "100%" }}
                              {...formikProps}
                            />
                          </Grid>
                        )}
                        {!ignoredFields?.lastName && (
                          <Grid item xs={12} md={6} lg={6}>
                            <Input
                              sx={inputFieldSx}
                              labelClassName={styles.labelClassName}
                              helpText={helpTexts["lastName"]}
                              tabIndex={2}
                              name="lastName"
                              label={`${t("last-name")}`}
                              required
                              placeholder={`${t("last-name")}`}
                              disabled={disabledFields?.lastName}
                              fullWidth
                              style={{ width: "100%" }}
                              {...formikProps}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* Row 1 end*/}
                    {/* Row 2 */}
                    {!ignoredFields?.email && (
                      <Grid
                        item
                        lg={11}
                        xs={11}
                        mr={{ lg: "auto" }}
                        m={{ xs: "auto" }}
                      >
                        <Input
                          sx={inputFieldSx}
                          labelClassName={styles.labelClassName}
                          helpText={helpTexts["email"]}
                          tabIndex={3}
                          name="email"
                          label={`${t("email")}`}
                          required
                          disabled={disabledFields?.email}
                          placeholder={`${t("email")}`}
                          type="email"
                          fullWidth
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                    )}
                    {/* Row 2 end*/}
                    {/* Custom Fields */}
                    {additionalField && (
                      <Grid item xs={12} margin="auto">
                        {additionalField}
                      </Grid>
                    )}
                    {/* Row 3 */}
                    {/* Row 3 end*/}
                    <Grid
                      item
                      lg={11}
                      xs={11}
                      mr={{ lg: "auto" }}
                      m={{ xs: "auto" }}
                    >
                      <Grid container spacing={1}>
                        {!ignoredFields?.phoneNumber && (
                          <Grid item xs={12} md={6} lg={6}>
                            <Input
                              sx={inputFieldSx}
                              required
                              labelClassName={styles.labelClassName}
                              helpText={helpTexts["phoneNumber"]}
                              tabIndex={4}
                              name="phoneNumber"
                              label={`${t("phone-number")}`}
                              disabled={disabledFields?.title}
                              placeholder="Eg: +65 <your-phone-number>"
                              fullWidth
                              type="phone"
                              style={{ width: "100%" }}
                              {...formikProps}
                            />
                          </Grid>
                        )}
                        {!ignoredFields?.title && (
                          <Grid item xs={12} md={6} lg={6}>
                            <Input
                              sx={inputFieldSx}
                              labelClassName={styles.labelClassName}
                              helpText={helpTexts["title"]}
                              tabIndex={5}
                              name="title"
                              label={`${t("job-position")}`}
                              disabled={disabledFields?.title}
                              placeholder={`${t("job-position")}`}
                              fullWidth
                              style={{ width: "100%" }}
                              {...formikProps}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* Row 4 */}
                    <Grid
                      item
                      lg={11}
                      xs={11}
                      mr={{ lg: "auto" }}
                      m={{ xs: "auto" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Input
                            sx={inputFieldSx}
                            labelClassName={styles.labelClassName}
                            helpText={helpTexts["password"]}
                            tabIndex={7}
                            name="password"
                            placeholder={`${t("password")}`}
                            label={`${t("password")}`}
                            required
                            type="password"
                            style={{ width: "100%" }}
                            fullWidth
                            {...formikProps}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Input
                            sx={inputFieldSx}
                            labelClassName={styles.labelClassName}
                            helpText={helpTexts["confirmPassword"]}
                            tabIndex={8}
                            name="confirmPassword"
                            placeholder={`${t("confirm-password")}`}
                            label={`${t("confirm-password")}`}
                            required
                            type="password"
                            style={{ width: "100%" }}
                            fullWidth
                            {...formikProps}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Row 4 end*/}
                    {/* Row 5 -- a column*/}
                    {userType === "external" && (
                      <Grid
                        item
                        lg={11}
                        xs={11}
                        mr={{ lg: "auto" }}
                        m={{ xs: "auto" }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="start"
                                  spacing={0.5}
                                >
                                  <Grid item>
                                    <Input
                                      labelClassName={styles.check}
                                      helpText={helpTexts["agreementCheck"]}
                                      tabIndex={9}
                                      onCheckboxChange={(e) =>
                                        tnc?.action &&
                                        tnc?.action(e, formikProps)
                                      }
                                      label={tnc?.checkboxLabel}
                                      type="checkbox"
                                      name="agreementCheck"
                                      data-testid="agreementCheck"
                                      {...formikProps}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className={styles.signInLink}
                                    onClick={() =>
                                      window.open(tnc?.tncUrl, "_blank")
                                    }
                                  >
                                    {tnc?.linkText}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {formikProps.touched?.agreementCheck &&
                                formikProps.errors?.agreementCheck && (
                                  <Grid item>
                                    <FormHelperText
                                      error={Boolean(
                                        formikProps.errors?.agreementCheck
                                      )}
                                    >
                                      {
                                        (
                                          formikProps.errors as FormikErrors<{
                                            agreementCheck: boolean;
                                          }>
                                        )?.agreementCheck
                                      }
                                    </FormHelperText>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            paddingTop="0 !important"
                            margin="auto"
                          >
                            <Input
                              labelClassName={styles.check}
                              helpText={helpTexts["marketingCheck"]}
                              tabIndex={10}
                              label={`${t("receive-our-marketing-updates")}`}
                              type="checkbox"
                              name="marketingCheck"
                              data-testid="marketingCheck"
                              fullWidth
                              {...formikProps}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* Row 5 end*/}
                  </Grid>

                  {error !== "" && (
                    <Grid
                      item
                      lg={11}
                      xs={11}
                      mr={{ lg: "auto" }}
                      m={{ xs: "auto" }}
                    >
                      <FormHelperText error={error !== ""}>
                        {error}
                      </FormHelperText>
                    </Grid>
                  )}

                  <Grid item xs={11} margin="3ch auto">
                    <Grid container>
                      <Grid item xs={12} lg={8} margin="auto">
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          loading={formikProps.isSubmitting}
                          data-testid="sign-up-button"
                        >
                          {`${t("sign-up")}`}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  {Footer && (
                    <Grid item margin="3ch 0">
                      <Footer />
                    </Grid>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      {/* Form end */}
    </Layout>
  );
};

export default InvitedUserSignup;
