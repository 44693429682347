import React from "react";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { Grid, Stack } from "@mui/material";
import DateInput from "components/Common/DateInput";

interface Sprint {
  name: string;
  start_date: string;
  end_date: string;
}

const CreateSprint = () => {
  const formikProps = useFormikContext<Sprint>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Stack>
          <Input name="name" label="Name" required {...formikProps} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack>
          <DateInput
            name="start_date"
            label="start date"
            required
            {...formikProps}
            value={
              formikProps.values.start_date !== undefined && formikProps.values.start_date !== ''
                ? new Date(formikProps.values.start_date)
                : undefined
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack>
          <DateInput
            name="end_date"
            label="end date"
            required
            {...formikProps}
            value={
              formikProps.values.end_date !== undefined && formikProps.values.end_date !== ''
                ? new Date(formikProps.values.end_date)
                : undefined
            } />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CreateSprint;
