import React from "react";
import { Stack } from "@mui/material";
import { useGetMonthlyInvoiceStatsQuery } from "api/api-operations";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AvailableInvoiceChart from "components/DashboardInternal/AvailableInvoiceChart";
import DailyAUM from "components/DashboardInternal/DailyAUM";
import DailyInvoiceChart from "components/DashboardInternal/DailyInvoiceChart";
import MonthlyInvoiceChart from "components/DashboardInternal/MonthlyInvoiceChart";
import TermsheetToDo from "components/Termsheets/TermsheetToDo";
import { useGetDashboardTermsheetListQuery } from "api/api-accounts";
import { VITE_EXPERIMENTAL_TERMSHEET } from "experimental_features";

function FinanceDashboard() {
  const { data: monthlyInvoiceStats, isFetching } =
    useGetMonthlyInvoiceStatsQuery(null);

  const {
    data: termsheetTodos,
    isFetching: isFetchingTermsheetTodos,
    refetch: refetchTermsheetTodos,
  } = useGetDashboardTermsheetListQuery(null);

  if (isFetching) return <SkeletonLoad bars={10} />;
  if (!monthlyInvoiceStats) return <NoData text="No Data to be displayed" />;
  return (
    <React.Fragment>
      {VITE_EXPERIMENTAL_TERMSHEET && isFetchingTermsheetTodos && (
        <SkeletonLoad bars={5} />
      )}
      {!isFetchingTermsheetTodos &&
        termsheetTodos &&
        VITE_EXPERIMENTAL_TERMSHEET && (
          <TermsheetToDo
            termsheetTodos={termsheetTodos}
            syncAction={refetchTermsheetTodos}
          />
        )}
      <MainCard
        title="Invoice Statistics"
        headerSx={{
          py: 1,
          px: 2,
        }}
        contentSX={{
          margin: "auto",
          backgroundColor: "grey.300",
        }}
      >
        <Stack spacing={2} p={2}>
          <MainCard
            sx={{
              bgcolor: "background.default",
            }}
          >
            <MonthlyInvoiceChart />
          </MainCard>
          <MainCard
            sx={{
              bgcolor: "background.default",
            }}
          >
            <DailyInvoiceChart />
          </MainCard>
          <MainCard
            sx={{
              bgcolor: "background.default",
            }}
          >
            <AvailableInvoiceChart />
          </MainCard>
          <MainCard
            contentSX={{
              px: 15,
              bgcolor: "background.default",
            }}
          >
            <DailyAUM />
          </MainCard>
        </Stack>
      </MainCard>
    </React.Fragment>
  );
}

export default FinanceDashboard;
