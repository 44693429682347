import { Stack, Typography } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { pxToRem } from "helpers/pxToRem";
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";
import type { CLDetails } from "types";

interface CLRequestInfoObjectProps {
  salesId: string;
  disabled: boolean;
}

const CLRequestInfoObject: React.FC<CLRequestInfoObjectProps> = ({
  salesId,
  disabled,
}) => {
  const formikProps = useFormikContext<CLDetails>();

  const { dirty, values } = formikProps;

  const { currency, requestedBy } = values;

  return (
    <MainCard
      sx={{
        minWidth: pxToRem(330),
        width: "100%",
      }}
      headerSx={{
        px: 2,
        py: 2,
      }}
      title={
        <Typography
          fontWeight="bold"
          fontSize={isMobile ? "1.1em" : "1.3em"}
          variant="h3"
        >
          Credit Limit Request
        </Typography>
      }
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="bold">Requested Date:</Typography>
          <Typography>
            {(formikProps.values.dateRequested as string)
              ? moment(formikProps.values.dateRequested)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss")
              : "Not Available"}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="bold">Requested By:</Typography>
          <Typography>{requestedBy ?? "Not Available"}</Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="bold">Requested Amount:</Typography>
          <Typography>
            {`${currency ?? "USD"} ${currencyFormatter({
              amount: formikProps.values.reqAmount,
            })}`}
          </Typography>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default CLRequestInfoObject;
