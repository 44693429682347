import { Typography, useTheme } from "@mui/material";
import type { CSSProperties } from "react";
import {
  APPROVED,
  IN_PROGRESS,
  PENDING_INTERNAL,
  REJECTED,
  REJECTED_INTERNAL,
  PENDING_EXTERNAL,
  TERMSHEET_CANCELLED,
  INVOICE_CANCELLED,
} from "codes";
import { useAppSelector } from "redux/hooks";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { PERMISSIONS } from "permissions";
import type { Theme } from "@mui/material/styles";

export type TermSheetViewingRole = "HOD" | "RM" | "CUSTOMER";

export const roleBasedBg = (
  role: TermSheetViewingRole,
  status: string,
  theme: Theme
) => {
  switch (status) {
    case REJECTED_INTERNAL:
      return role === "RM" ? "orange" : "purple";
    case PENDING_INTERNAL:
      return role === "RM" ? "purple" : "orange";
    case PENDING_EXTERNAL:
      return role === "CUSTOMER" ? "orange" : "purple";
    case IN_PROGRESS:
      return "orange";
    case REJECTED:
      return theme.palette.error.main;
    case TERMSHEET_CANCELLED:
      return theme.palette.error.main;
    case INVOICE_CANCELLED:
      return theme.palette.error.main;
    case APPROVED:
      return theme.palette.success.main;
    default:
      return "";
  }
};

const StatusDisplay = ({
  status,
  style,
}: {
  status: string;
  style?: CSSProperties;
}) => {
  const theme = useTheme();
  const { data: curr_user, isFetching: isFetchingUser } =
    useGetCurrentUserQuery();
  const isInternal = curr_user?.is_internal;
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const internalRole: TermSheetViewingRole = USER_PERMISSIONS.includes(
    PERMISSIONS.termsheet_approver
  )
    ? "HOD"
    : "RM";
  const currentRole: TermSheetViewingRole = isInternal
    ? internalRole
    : "CUSTOMER";

  const FINAL_STATUS: Record<string, { value: string; background: string }> = {
    [REJECTED_INTERNAL]: {
      value: "Rejected",
      background: roleBasedBg(currentRole, REJECTED_INTERNAL, theme),
    },
    [PENDING_INTERNAL]: {
      value: "Pending Approval",
      background: roleBasedBg(currentRole, PENDING_INTERNAL, theme),
    },
    [PENDING_EXTERNAL]: {
      value: "Pending Approval",
      background: roleBasedBg(currentRole, PENDING_EXTERNAL, theme),
    },
    [IN_PROGRESS]: {
      value: "In Progress",
      background: roleBasedBg(currentRole, IN_PROGRESS, theme),
    },
    [APPROVED]: {
      value: "Approved",
      background: roleBasedBg(currentRole, APPROVED, theme),
    },
    [REJECTED]: {
      value: "Rejected",
      background: roleBasedBg(currentRole, REJECTED, theme),
    },
    [INVOICE_CANCELLED]: {
      value: "Cancelled",
      background: roleBasedBg(currentRole, INVOICE_CANCELLED, theme)
    },
    [TERMSHEET_CANCELLED]: {
      value: "Cancelled",
      background: roleBasedBg(currentRole, TERMSHEET_CANCELLED, theme)
    }
  };

  return (
    <Typography
      style={{
        background: FINAL_STATUS[status]?.background,
        padding: "6px 16px",
        borderRadius: theme.shape.borderRadius * 2.5,
        color: "white",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
        cursor: "not-allowed",
        fontSize: "0.8em",
        ...style,
      }}
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
      }}
    >
      {FINAL_STATUS[status]?.value}
    </Typography>
  );
};
export default StatusDisplay;
