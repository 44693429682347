import { Box, Grid, Typography } from "@mui/material";
import { useGetOnboardingStatusesQuery } from "api/api-origination";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { ACCOUNTS, DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK } from "codes";
import CLDisplay from "components/CLRequest/CLDisplay";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useIsMobile from "hooks/useIsMobile";
import styles from "../sales.module.scss";

interface OnboardingTableParams {
  seller: string;
  seller_internal_name: string;
  seller_country: string;
  seller_is_identified: boolean | null;
  seller_details_completed: boolean;
  seller_documents_completed: boolean;
  seller_has_bank_account: boolean;
  buyer: string;
  buyer_internal_name: string;
  buyer_details_completed: boolean;
  buyer_documents_completed: boolean;
  buyer_is_identified: boolean | null;
  buyer_country: string;
  onboarding_status: boolean;
}

const OnboardingsPage = () => {
  const { data, isFetching, refetch } = useGetOnboardingStatusesQuery(null);
  const isMobile = useIsMobile();
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      <Layout
        title="Onboardings"
        backArrow={false}
        textHeadingOptions={{
          fontWeight: "bolder",
          level: 2,
          fontSize: isMobile ? "1.5em" : "1.5em",
        }}
        headerConfig={{
          syncAction: refetch,
          left: 8,
          mid: 0,
          right: 2,
          xs: {
            left: 12,
            mid: 0,
            right: 0,
          },
          alignItems: "center",
        }}
        className={styles.layout}
        mode="default"
      >
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && data && (
          <DataTablev2
            data={data}
            toBeHidden={[
              "buyer_is_identified",
              "seller_is_identified",
              "seller_internal_name",
              "buyer_internal_name",
            ]}
            defaultColumnWidth={200}
            rowsPerPage={rowsPerPage}
            customColumns={{
              internal_status: {
                internal_status: "CL Status",
                renderCell: (params: any) => (
                  <Grid container justifyContent={isMobile ? "left" : "center"}>
                    <Grid item>
                      <CLDisplay status={params.value} />
                    </Grid>
                  </Grid>
                ),
              },
              onboarding_status: {
                onboarding_status: "Onboarding Status",
                minWidth: 200,
                type: "boolean",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      fontSize: "1em",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {params.value ? "Submitted" : "Pending"}
                  </Typography>
                ),
              },
              buyer: {
                buyer: "Buyer",
                minWidth: 200,
                link: {
                  href: (params: OnboardingTableParams) =>
                    `${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${params.buyer_internal_name}`,
                  target: "_self",
                },
              },
              buyer_country: {
                buyer_country: "Buyer Country",
                minWidth: 200,
                headerProps: { sx: { maxWidth: "100%" } },
              },
              buyer_details_completed: {
                buyer_details_completed: "Buyer Details Completed",
                minWidth: 200,
                type: "boolean",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      fontSize: "1em",
                      maxWidth: "100%",
                      textAlign: "center",
                    }}
                  >
                    {params.value ? "Completed" : "Pending"}
                  </Typography>
                ),
              },
              seller_has_bank_account: {
                seller_has_bank_account: "Supplier Bank Account",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      fontSize: "1em",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {params.value ? "Completed" : "Pending"}
                  </Typography>
                ),
              },
              buyer_documents_completed: {
                buyer_documents_completed: "Buyer Documents Completed",
                minWidth: 200,
                type: "boolean",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      fontSize: "1em",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {params.value ? "Completed" : "Pending"}
                  </Typography>
                ),
              },
              seller: {
                seller: "Supplier",
                minWidth: 200,
                link: {
                  href: (params: OnboardingTableParams) =>
                    `${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${params.seller_internal_name}`,
                  target: "_self",
                },
              },

              seller_country: {
                seller_country: "Supplier Country",
                minWidth: 200,
                headerProps: { sx: { maxWidth: "100%" } },
              },
              seller_details_completed: {
                seller_details_completed: "Supplier Details Completed",
                minWidth: 200,
                type: "boolean",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    {params.value ? "Completed" : "Pending"}
                  </Typography>
                ),
              },
              seller_documents_completed: {
                seller_documents_completed: "Supplier Documents Completed",
                minWidth: 200,
                type: "boolean",
                renderCell: (params: any) => (
                  <Typography
                    sx={{
                      background: params.value ? "#9FCE63" : "red",
                      padding: "6px 16px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      fontWeight: "600",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    {params.value ? "Completed" : "Pending"}
                  </Typography>
                ),
              },
            }}
          />
        )}
      </Layout>
    </Box>
  );
};
export default OnboardingsPage;
