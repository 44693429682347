import { Sync } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import {
  useGetMapItemTemplateByVersionQuery,
  type MAP_PARTY,
  type MAP_USE_CASE,
} from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import toProperCase from "helpers/propercase";
import { toast } from "react-toastify";

interface MapItemTemplate {
  use_case: MAP_USE_CASE;
  version: string;
  party: MAP_PARTY;
  ipl_product: string;
  template_name: string;
}

const TemplateCard: React.FC<{
  template: MapItemTemplate;
  diff?: MapItemTemplate | undefined;
}> = ({ template, diff }) => {
  const {
    data: item,
    isFetching,
    refetch,
  } = useGetMapItemTemplateByVersionQuery({
    version: template.version,
    item_name: template.template_name,
  });

  const { data: diffTemplate, isFetching: isDiffFetching } =
    useGetMapItemTemplateByVersionQuery(
      {
        version: diff?.version ?? "",
        item_name: diff?.template_name ?? "",
      },
      {
        skip: !diff,
      }
    );

  const handleCopy = () => {
    navigator.clipboard.writeText(`${JSON.stringify(item?.template, null, 1)}`);
    toast("Copied to clipboard!", { type: "success", autoClose: 100 });
  };
  let finalItem: Record<string, any> = item?.template || {};

  if (diff) {
    finalItem = {
      ...finalItem,
      ...Object.keys(diffTemplate?.template || {}).reduce(
        (acc, key) => {
          if (!(key in (item?.template ?? {}))) {
            acc[key] = { removed: "" };
          }
          return acc;
        },
        {} as Record<string, any>
      ),
    };
  }
  return (
    <MainCard
      content
      title={
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography fontWeight="bold">
            {`${toProperCase(template?.ipl_product ?? "")}-${toProperCase(template?.use_case ?? "")}${template?.party ? `(${toProperCase(template?.party ?? "")})` : ""}v${template?.version}`}
          </Typography>
          <IconButton onClick={refetch}>
            <Sync />
          </IconButton>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Stack>
      }
      headerSx={{ px: 2, py: 1 }}
      contentSX={{ px: 2, py: 1 }}
    >
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && item && (
        <Stack spacing={2}>
          {finalItem &&
            Object.entries(finalItem).map(([key, value]) => {
              const diffValue = (
                diffTemplate?.template as Record<string, any>
              )?.[key];

              const isDifferent = diff !== undefined && diffValue === undefined;

              return (
                <Stack key={key} direction="row" spacing={1} alignItems="start">
                  {Object.prototype.hasOwnProperty.call(value, "removed") && (
                    <Avatar
                      sx={{ bgcolor: "#cf222e", width: "2em", height: "1em" }}
                      variant="square"
                    >
                      {" "}
                    </Avatar>
                  )}
                  <Typography
                    sx={{
                      background: isDifferent ? "#aceeb0" : "transparent",
                    }}
                    color={
                      Object.prototype.hasOwnProperty.call(value, "removed")
                        ? "error"
                        : "text.primary"
                    }
                  >
                    {key}
                  </Typography>
                  {!Object.prototype.hasOwnProperty.call(value, "removed") && (
                    <div>
                      <pre>{JSON.stringify(value, null, 1)}</pre>
                    </div>
                  )}
                </Stack>
              );
            })}
        </Stack>
      )}
    </MainCard>
  );
};

export default TemplateCard;
