import CssBaseline from "@mui/material/CssBaseline";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import Loader from "components/Common/Loader";

import * as Sentry from "@sentry/react";
import { ELIGIBILITY_LINK, URL } from "codes";
import { detect } from "detect-browser";
import history from "helpers/history";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import useServiceWorkerListener from "hooks/useServiceWorkerListener";
import { theme } from "styles/theme";
import Error from "./pages/error";

const Root = () => {
  useServiceWorkerListener(); // Listen for service worker messages
  const browser = detect();
  const env = import.meta.env.VITE_ENV;

  if (browser && (env === "local" || env === "dev")) {
    console.log(browser.name);
    console.log(browser.version);
    console.log(browser.os);
  }

  const currentUrl = window.location.href;
  if (currentUrl.includes(ELIGIBILITY_LINK))
    history.push(
      "https://invoice-financing-solutions.incomlend.com/eligibility-quiz/"
    );

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Error
          error={error as Error}
          componentStack={componentStack}
          resetError={resetError}
          scope="Component root"
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", "Component root");
      }}
    >
      <Helmet>
        <link rel="canonical" href={URL} />
        <meta
          name="robots"
          content={import.meta.env.VITE_ENV === "main" ? "all" : "none"}
        />

        {/* <!-- Google tag (gtag.js) --> */}
        {import.meta.env.VITE_ENV === "main" && (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-FEKLHMDFMD"
          ></script>
        )}
        {import.meta.env.VITE_ENV === "main" && (
          <script>
            {` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-FEKLHMDFMD'); `}
          </script>
        )}

        {import.meta.env.VITE_ENV === "main" && (
          <script type="text/javascript">
            {`_linkedin_partner_id = "4150074";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
          </script>
        )}
        {import.meta.env.VITE_ENV === "main" && (
          <script type="text/javascript">
            {`(function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
              })(window.lintrk);`}
          </script>
        )}
        {import.meta.env.VITE_ENV === "main" && (
          <noscript>
            {`<img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=4150074&fmt=gif"
              />
            `}
          </noscript>
        )}
      </Helmet>

      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loader open />}>
          <Outlet />
        </Suspense>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
        />
      </CssVarsProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Root;
