import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useGetIndustriesQuery } from "api/api-company";
import { BUYER, SELLER, SUPPLIER } from "codes";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Goods from "components/Company/Goods";
import { Form, Formik, useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { RegistrationFormFields } from "helpers/formFields";
import {
  BuyerAdditionalDetailsDefaults,
  validationSchemaBuyerAdditionalDetails,
} from "helpers/formFields/tradingRelationship";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import styles from "./addpartner.module.scss";

const BuyerAdditionalDetails = ({
  onSave,
  idx,
}: {
  onSave: (formData: any) => void;
  idx: number;
}) => {
  const isMobile = useIsMobile();
  const parentFP = useFormikContext<RegistrationFormFields>();
  const { t } = useTranslation();
  const DAYS = [
    { name: "<30 (days)" },
    { name: "30 (days)" },
    { name: "45 (days)" },
    { name: "60 (days)" },
    { name: "90 (days)" },
    { name: "120 (days)" },
    { name: ">120 (days)" },
  ];
  const { list } = parentFP.values;
  const item = list[idx];

  const valueCleaner = (val: string): number => {
    return Number(val?.replaceAll(",", ""));
  };
  const formatNumber = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };
  const { data: industries, isFetching: isFetchingIndustries } =
    useGetIndustriesQuery();
  const profile = useAppSelector((state) => state.appState.profile);
  const partnerType = profile === SELLER ? BUYER : SUPPLIER;
  const partner = profile === SELLER ? BUYER : SUPPLIER;

  return (
    <Grid container alignItems="center" spacing={1}>
      {Boolean(list.length) &&
      item.industry !== undefined &&
      item.goods_category !== undefined &&
      item.annual_revenue !== undefined &&
      item.number_of_past_paid_transaction !== undefined &&
      item.average_invoice_amount !== undefined &&
      item.average_payment_terms !== undefined &&
      item.any_delays_of_payment_with_buyer !== undefined &&
      item.any_credit_note !== undefined ? (
        <Grid item xs={12} key={item.registration_number}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Industry"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {item.industry}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Goods Category"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {item.goods_category}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label={`Annual Turnover of the the ${partnerType} (in USD equivalent)`}
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  USD{" "}
                  {currencyFormatter({
                    amount: item.annual_revenue ?? 0,
                  })}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Number of Past Paid Transactions"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {formatNumber(item.number_of_past_paid_transaction ?? 0)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Average Invoice Amount (in USD equivalent)"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  USD{" "}
                  {currencyFormatter({
                    amount: item.average_invoice_amount ?? 0,
                  })}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Average Payment Terms"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {item.average_payment_terms}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Any delays in payment with Buyer"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {item.any_delays_of_payment_with_buyer ? "Yes" : "No"}
                </Grid>
              </Grid>
            </Grid>

            {item.any_delays_of_payment_with_buyer && (
              <Grid item xs={12} lg={12}>
                <Input
                  labelClassName={styles.labelClass}
                  sx={{
                    ".MuiInputBase-root": {
                      height: "11vh",
                      textarea: {
                        height: "10vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  name="reason_of_any_delays_of_payment_with_buyer"
                  label={`Reason for Delays in Payment with ${partner}`}
                  placeholder="Enter Comments"
                  className={styles.formInput}
                  disabled={true}
                  value={item.reason_of_any_delays_of_payment_with_buyer}
                  multiline
                  style={{ width: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <LabelViewOnly
                    label="Any credit note"
                    required
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={12}>
                  {item.any_credit_note ? "Yes" : "No"}
                </Grid>
              </Grid>
            </Grid>
            {item.any_credit_note && (
              <Grid item xs={12} lg={12}>
                <Input
                  labelClassName={styles.labelClass}
                  sx={{
                    ".MuiInputBase-root": {
                      height: "11vh",
                      textarea: {
                        height: "10vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  name="reason_for_credit_note"
                  label="Reason for Credit Note"
                  placeholder="Enter Reason"
                  className={styles.formInput}
                  disabled={true}
                  value={item?.reason_for_credit_note}
                  multiline
                  style={{ width: "100%" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Formik
            initialValues={BuyerAdditionalDetailsDefaults}
            validationSchema={validationSchemaBuyerAdditionalDetails}
            enableReinitialize
            onSubmit={console.log}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        {industries && industries?.length && (
                          <Grid item xs={12}>
                            <>
                              {isFetchingIndustries && (
                                <SkeletonLoad bars={1} />
                              )}
                              {!isFetchingIndustries && (
                                <Drop
                                  label="Industry"
                                  required
                                  labelClassName={styles.labelClass}
                                  name="industry"
                                  style={{ width: "100%" }}
                                  keyValue="name"
                                  data={industries}
                                  placeholder="Select Industry"
                                  {...formikProps}
                                />
                              )}
                              {Boolean(
                                formikProps.errors.industry &&
                                  formikProps.touched.industry
                              ) && (
                                <FormHelperText
                                  error={Boolean(formikProps.errors.industry)}
                                >
                                  {formikProps.errors.industry}
                                </FormHelperText>
                              )}
                            </>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Goods
                            industry={formikProps.values.industry ?? ""}
                            disabled={false}
                            formikProps={formikProps}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={isMobile ? 2 : 0}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Grid item xs={12}>
                          <Input
                            labelClassName={styles.labelClass}
                            name="annual_revenue"
                            label={`Annual Turnover of the the ${partnerType} (in USD equivalent)`}
                            required
                            type="money"
                            textfieldProps={{
                              InputProps: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    USD
                                  </InputAdornment>
                                ),
                              },
                            }}
                            style={{ width: "100%" }}
                            {...formikProps}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            labelClassName={styles.labelClass}
                            name="number_of_past_paid_transaction"
                            label="Number of Past Paid Transactions"
                            required
                            type="number"
                            style={{
                              width: "100%",
                            }}
                            {...formikProps}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            labelClassName={styles.labelClass}
                            name="average_invoice_amount"
                            label="Average Invoice Amount (in USD equivalent)"
                            required
                            type="money"
                            textfieldProps={{
                              InputProps: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    USD
                                  </InputAdornment>
                                ),
                              },
                            }}
                            style={{ width: "100%" }}
                            {...formikProps}
                          />
                          {Boolean(
                            formikProps.errors.average_invoice_amount &&
                              formikProps.touched.average_invoice_amount
                          ) && (
                            <FormHelperText
                              error={Boolean(
                                formikProps.errors.average_invoice_amount
                              )}
                            >
                              {formikProps.errors.average_invoice_amount}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          margin={
                            isObjectEmpty(formikProps.errors)
                              ? "auto 0 0 0"
                              : "auto 0"
                          }
                        ></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Drop
                        label="Average Payment Terms"
                        required
                        name="average_payment_terms"
                        keyValue="name"
                        data={DAYS}
                        fullWidth
                        value={formikProps.values.average_payment_terms}
                        error={Boolean(
                          formikProps.errors.average_payment_terms
                        )}
                        {...formikProps}
                      />
                      {Boolean(
                        formikProps.errors.average_payment_terms &&
                          formikProps.touched.average_payment_terms
                      ) && (
                        <FormHelperText
                          error={Boolean(
                            formikProps.errors.average_payment_terms
                          )}
                        >
                          {formikProps.errors.average_payment_terms}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label="Any credit note"
                        sx={{ fontSize: "0.8em!important" }}
                        required
                        {...formikProps}
                      />
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="any_credit_note"
                          name="any_credit_note"
                          value={formikProps.values.any_credit_note}
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              "any_credit_note",
                              e.target.value === "true"
                            );
                          }}
                          sx={{ flexWrap: "nowrap" }}
                          row
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {Boolean(formikProps.errors.any_credit_note) && (
                        <FormHelperText
                          error={Boolean(formikProps.errors.any_credit_note)}
                        >
                          {formikProps.errors.any_credit_note}
                        </FormHelperText>
                      )}
                    </Grid>
                    {formikProps.values.any_credit_note && (
                      <Grid item xs={12}>
                        <Input
                          labelClassName={styles.labelClass}
                          sx={{
                            background: "#ffffff",
                            ":hover": {
                              background: "#ffffff",
                            },
                            ".MuiInputBase-root": {
                              height: "11vh",
                              textarea: {
                                height: "10vh !important",
                                overflow: "scroll !important",
                              },
                            },
                          }}
                          name="reason_for_credit_note"
                          label="Reason for Credit Note"
                          placeholder="Enter Reason"
                          className={styles.formInput}
                          multiline
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LabelViewOnly
                        labelClassName={styles.labelClass}
                        label={`Any delays in payment with ${partnerType}`}
                        sx={{ fontSize: "0.8em!important" }}
                        required
                        {...formikProps}
                      />
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="any_delays_of_payment_with_buyer"
                          name="any_delays_of_payment_with_buyer"
                          value={
                            formikProps.values.any_delays_of_payment_with_buyer
                          }
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              "any_delays_of_payment_with_buyer",
                              e.target.value === "true"
                            );
                          }}
                          sx={{ flexWrap: "nowrap" }}
                          row
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>

                      {Boolean(
                        formikProps.errors.any_delays_of_payment_with_buyer
                      ) && (
                        <FormHelperText
                          error={Boolean(
                            formikProps.errors.any_delays_of_payment_with_buyer
                          )}
                        >
                          {formikProps.errors.any_delays_of_payment_with_buyer}
                        </FormHelperText>
                      )}
                    </Grid>
                    {formikProps.values.any_delays_of_payment_with_buyer && (
                      <Grid item xs={12}>
                        <Input
                          labelClassName={styles.labelClass}
                          sx={{
                            background: "#ffffff",
                            ":hover": {
                              background: "#ffffff",
                            },
                            ".MuiInputBase-root": {
                              height: "11vh",
                              textarea: {
                                height: "10vh !important",
                                overflow: "scroll !important",
                              },
                            },
                          }}
                          name="reason_of_any_delays_of_payment_with_buyer"
                          label={`Reason for Delays in Payment with ${partner}`}
                          placeholder="Enter Reason"
                          className={styles.formInput}
                          multiline
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={isMobile ? 2 : 0}
                        justifyContent="space-between"
                        alignItems="start"
                        style={{ paddingTop: "20px" }}
                      >
                        <Grid item xs={12} lg={1}></Grid>
                        <Grid
                          item
                          xs={12}
                          lg={1.5}
                          margin={
                            isObjectEmpty(formikProps.errors)
                              ? "auto 0 0 0"
                              : "auto 0"
                          }
                          sx={{ mt: 2 }}
                        >
                          <Button
                            disabled={
                              formikProps.values.average_payment_terms === ""
                            }
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              formikProps.setTouched(
                                {
                                  annual_revenue: true,
                                  number_of_past_paid_transaction: true,
                                  average_invoice_amount: true,
                                  average_payment_terms: true,
                                  any_delays_of_payment_with_buyer: true,
                                  reason_of_any_delays_of_payment_with_buyer:
                                    true,
                                  any_credit_note: true,
                                },
                                true
                              );
                              formikProps.validateForm().then((res) => {
                                if (isObjectEmpty(res)) {
                                  const updatedValues = {
                                    ...formikProps.values,
                                    annual_revenue: valueCleaner(
                                      formikProps.values.annual_revenue
                                    ),
                                    average_invoice_amount: valueCleaner(
                                      formikProps.values.average_invoice_amount
                                    ),
                                    number_of_past_paid_transaction: Number(
                                      formikProps.values
                                        .number_of_past_paid_transaction
                                    ),
                                    reg_no: item.registration_number,
                                  };
                                  onSave(updatedValues);
                                  toast(
                                    `The ${partner} is successfully uploaded.`,
                                    {
                                      type: "success",
                                    }
                                  );
                                } else {
                                  toast(
                                    "Your input is invalid. Please update it.",
                                    {
                                      type: "error",
                                    }
                                  );
                                }
                              });
                            }}
                          >
                            {`${t("save")}`}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};

export default BuyerAdditionalDetails;
