import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useEditMapSummaryMutation } from "api/api-maps";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import type { MapSummary } from "types";

const CalendarMutiSelector = () => {
  const formikProps = useFormikContext<MapSummary>();
  const theme = useTheme();
  const { map_name } = formikProps.values;
  const [editMapSummary] = useEditMapSummaryMutation();

  const editor = (field: string, value: any, friendlyLabel: string) => {
    editMapSummary({
      map_name,
      data: { [field]: value },
    })
      .unwrap()
      .then(() => {
        toast.success(`${friendlyLabel} Updated`);
      })
      .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  const selectMonthStyling = (month: string) => {
    return {
      border: "1px solid black",
      width: "100%",
      textAlign: "centre",
      height: "5vh",
      borderRadius: 1,
      bgcolor: formikProps.values.seasonal_business?.includes(month)
        ? theme.palette.error.main
        : formikProps.values.seasonal_business?.includes(month)
          ? theme.palette.primary.main
          : "transparent",
      color: formikProps.values.seasonal_business?.includes(month)
        ? theme.palette.background.default
        : theme.palette.primary.main,
      "span:nth-child(1)": {
        padding: 0,
      },
      "span:nth-child(2)": {
        margin: "auto",
      },
    };
  };
  const handleMonthChange = (state: boolean, month: string) => {
    if (state) {
      const months = formikProps.values.seasonal_business ?? [];
      formikProps.setFieldValue("seasonal_business", [...months, month]);
      editor(
        "seasonal_business",
        [...months, month],
        "Seasonal Business Months Updated"
      );
    } else {
      const months =
        formikProps.values.seasonal_business ?? [].filter((x) => x !== month);
      formikProps.setFieldValue("seasonal_business", months);
      editor("seasonal_business", months, "Seasonal Business Months Updated");
    }
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} margin="auto">
          <FormLabel component="legend">
            <Typography fontWeight="bold">Peak Season Months</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={12} margin="auto">
          <FormGroup>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
              sx={{ ml: 0 }}
            >
              <Grid item xs={3} margin="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Jan"
                      checked={formikProps.values.seasonal_business?.includes(
                        "Jan"
                      )}
                      sx={{
                        svg: { display: "none" },
                      }}
                    />
                  }
                  label="Jan"
                  sx={selectMonthStyling("Jan")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Jan")
                  }
                />
              </Grid>
              <Grid item xs={3} margin="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Feb"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Feb"
                      )}
                    />
                  }
                  label="Feb"
                  sx={selectMonthStyling("Feb")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Feb")
                  }
                />
              </Grid>
              <Grid item xs={3} margin="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Mar"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Mar"
                      )}
                    />
                  }
                  label="Mar"
                  sx={selectMonthStyling("Mar")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Mar")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Apr"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Apr"
                      )}
                    />
                  }
                  label="Apr"
                  sx={selectMonthStyling("Apr")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Apr")
                  }
                />
              </Grid>
              {/* Row 2 */}
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="May"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "May"
                      )}
                    />
                  }
                  label="May"
                  sx={selectMonthStyling("May")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "May")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Jun"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Jun"
                      )}
                    />
                  }
                  label="Jun"
                  sx={selectMonthStyling("Jun")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Jun")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Jul"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Jul"
                      )}
                    />
                  }
                  label="Jul"
                  sx={selectMonthStyling("Jul")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Jul")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Aug"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Aug"
                      )}
                    />
                  }
                  label="Aug"
                  sx={selectMonthStyling("Aug")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Aug")
                  }
                />
              </Grid>
              {/* Row 3 */}
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Sep"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Sep"
                      )}
                    />
                  }
                  label="Sep"
                  sx={selectMonthStyling("Sep")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Sep")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Oct"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Oct"
                      )}
                    />
                  }
                  label="Oct"
                  sx={selectMonthStyling("Oct")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Oct")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Nov"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Nov"
                      )}
                    />
                  }
                  label="Nov"
                  sx={selectMonthStyling("Nov")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Nov")
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Dec"
                      sx={{
                        svg: { display: "none" },
                      }}
                      checked={formikProps.values.seasonal_business?.includes(
                        "Dec"
                      )}
                    />
                  }
                  label="Dec"
                  sx={selectMonthStyling("Dec")}
                  onChange={(e) =>
                    handleMonthChange((e.target as any).checked, "Dec")
                  }
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};
export default CalendarMutiSelector;
