import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useEditCallScheduleMutation } from "api/api-risk";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import { toast } from "react-toastify";

interface AddLinkProps {
  open: boolean;
  onClose: () => void;
  accountType: string;
}

const AddLink: React.FC<AddLinkProps> = ({ open, onClose, accountType }) => {
  const [editCallSchedule] = useEditCallScheduleMutation();
  const mapId = useContext(MapContext);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h3">Add Call Recording Link</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Formik
        initialValues={{ reference: "" }}
        onSubmit={(values) => {
          mapId &&
            editCallSchedule({
              map_id: mapId,
              account_type: accountType,
              data: {
                reference: values.reference,
              },
            })
              .unwrap()
              .then(() => toast.success("Date of call updated"))
              .catch(() => toast.error("Failed to update date of call"));
          return;
        }}
      >
        <Form>
          <DialogContent dividers>
            <Stack spacing={2}>
              <Input
                label=""
                fullWidth
                placeholder="Enter your dropbox link..."
                name="reference"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit">
              Add
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddLink;
