import {
  Avatar,
  Chip,
  Divider,
  Grid,
  Stack,
  type Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useEditMapSummaryMutation,
  useGetIncomlendProductsQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import { ACCOUNTS, INCOMLEND_INTERNAL_LINK } from "codes";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Icons from "components/Company/Registration/SelectorCard/Cards";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { MapContext } from ".";

interface DetailsStripProps {
  // Define your component's props here
}

const ProductIcon = ({ product }: { product: string }) => {
  switch (product) {
    case "factoring":
      return (
        <Icons.SupplierIcon active={true} activeColor="white" size="15px" />
      );
    case "reverse_factoring":
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
    default:
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
  }
};
const getBackgroundColor = (theme: Theme, product: string) => {
  switch (product) {
    case "factoring":
      return "primary";
    case "reverse_factoring":
      return "error";
    default:
      return "primary";
  }
};
const DetailsStrip: React.FC<DetailsStripProps> = (props) => {
  const theme = useTheme();
  const id = useContext(MapContext);
  const { data: mapData, isLoading } = useGetMapSummaryQuery(id ?? "", {
    skip: !id,
  });
  const linkBarStyles = {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: "bold",
    width: "fit-content",
    fontSize: "0.6em",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
      padding: "1em 2ch",
    },
  };

  const { data: incomlendProducts, refetch: refetchIncomlendProducts } =
    useGetIncomlendProductsQuery();
  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    editMapSummary({
      map_name: id as string,
      data: { [field]: value },
    })
      .unwrap()
      .then(() => {
        toast.success(`${friendlyLabel} Updated`);
      })
      .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  return (
    <Grid container spacing={{ xs: 1, lg: 1 }} alignItems="center">
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                color="primary"
                sx={{
                  fontSize: "1em !important",
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                  color: "#ffffff !important",
                }}
              >
                B
              </Avatar>
            }
            label={`${mapData?.buyer_name}`}
            component="a"
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.buyer_internal_name}`}
            clickable
            sx={{
              ...linkBarStyles,
            }}
          />
        )}
      </Grid>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                color={getBackgroundColor(theme, mapData?.product)}
                sx={{
                  fontSize: "1.4em !important",
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                  color: "#ffffff !important",
                }}
              >
                S
              </Avatar>
            }
            label={`${mapData?.seller_name}`}
            component="a"
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.seller_internal_name}`}
            clickable
            sx={{
              ...linkBarStyles,
            }}
          />
        )}
      </Grid>
      {mapData && (
        <Grid item id="product-selection-for-map">
          <Chip
            variant="filled"
            color={getBackgroundColor(theme, mapData?.product)}
            avatar={
              <Avatar
                color="primary"
                sx={{
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                }}
              >
                <ProductIcon product={mapData?.product} />
              </Avatar>
            }
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    color: "#ffffff",
                    fontWeight: 700,
                    paddingBottom: "0.3em",
                  }}
                >
                  {mapData?.product}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ bgcolor: "#ffffff" }}
                />
                {incomlendProducts && (
                  <Drop
                    id="ipl_product"
                    name="ipl_product"
                    keyValue="product_name"
                    data={incomlendProducts?.map((product) => ({
                      ...product,
                      disabled:
                        (product.product_name === "Reverse Factoring" &&
                          mapData?.product === "factoring") ||
                        ((product.product_name === "Standard Factoring" ||
                          product.product_name === "Enhanced Factoring") &&
                          mapData?.product === "reverse_factoring"),
                    }))}
                    value={mapData?.ipl_product ?? ""}
                    label="Category"
                    placeholder="Select Product Category"
                    direction="row"
                    fullWidth
                    sx={{
                      height: "100% !important",
                      bgcolor: "#808184",
                      ".MuiOutlinedInput-input": {
                        padding: "0 !important",
                        paddingTop: "0.3em !important",
                        paddingLeft: "1em !important",
                        paddingRight: "2em !important",
                        fontWeight: "600 !important",
                        fontSize: "1.8em !important",
                        lineHeight: "1em !important",
                      },
                    }}
                    setValue={(value) => {
                      const id = incomlendProducts?.find(
                        (product) => product.product_name === value
                      )?.id;
                      editor("ipl_product", id, "Product Category");
                    }}
                  />
                )}
              </Stack>
            }
            sx={{
              fontWeight: 700,
              ".MuiChip-label": {
                paddingTop: "4px",
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DetailsStrip;
