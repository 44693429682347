import { Grid, Stack, styled, Switch, Typography } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import Input from "components/Common/Input";
import Layout from "components/Common/Layout";
import { Form, Formik, type FormikValues } from "formik";
import React from "react";
import { isMobile } from "react-device-detect";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

const ConfigurationPage: React.FC = () => {
  const OnOffSwitch = () => (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <Typography>Off</Typography>
      <AntSwitch defaultChecked inputProps={{ "aria-label": "ant design" }} />
      <Typography>On</Typography>
    </Stack>
  );
  return (
    <Layout
      title="Configuration"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
    >
      <MainCard
        title="Term Sheet"
        headerSx={{
          px: 2,
          py: 1,
        }}
      >
        <Formik
          initialValues={
            {
              termsheet_approval_countdown: 0,
              onboarding_submission_countdown: 0,
              annualized_discount_rate: 0,
            } as FormikValues
          }
          onSubmit={console.log}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography>Term Sheet approval countdown</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      {...formik.getFieldProps("termsheet_approval_countdown")}
                      sx={{
                        maxWidth: "50px",
                      }}
                    />
                    <Typography>days</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Onboarding Submission countdown</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      {...formik.getFieldProps(
                        "onboarding_submission_countdown"
                      )}
                      sx={{
                        maxWidth: "50px",
                      }}
                    />
                    <Typography>days</Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography>Annualized Discount Rate</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      {...formik.getFieldProps(
                        "annualized_discount_rate"
                      )}
                      sx={{
                        maxWidth: "50px",
                      }}
                    />
                    <Typography>%</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </MainCard>
    </Layout>
  );
};

export default ConfigurationPage;
