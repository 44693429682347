import { Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetCurrentMapItemTemplatesQuery,
  useGetMapItemTemplatesQuery,
  useGetTemplateVersionQuery,
} from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { MapCurrentVersion } from "pages/maps/map";
import React from "react";
import MapItemsTable from "./MapItemsTable";
import UpgradeMap from "./UpgradeMap";
const Configuration = () => {
  const currentMapVersion = React.useContext(MapCurrentVersion);
  const { data: currentTemplateVersion } = useGetTemplateVersionQuery(null);
  const [version, setVersion] = React.useState<string | null>(
    currentMapVersion ?? null
  );
  const { data: mapItemversions, refetch } = useGetMapItemTemplatesQuery(
    version ?? "",
    {
      skip: !Boolean(version),
    }
  );
  const { data: currentTemplateMapVersion } =
    useGetCurrentMapItemTemplatesQuery(currentMapVersion ?? "", {
      skip: !Boolean(currentMapVersion),
    });

  const theme = useTheme();

  return (
    <Layout
      title={<Typography variant="h3">Map Configuration</Typography>}
      headerConfig={{
        left: 6,
        mid: 6,
        right: 0,
        syncAction: refetch,
        endComponent: (
          <Typography
            sx={{
              background: theme.palette.primary.main,
              color: "background.default",
              fontWeight: "bold",
              py: 0.5,
              px: 1,
              borderRadius: 1,
              mt: 2,
              ml: 1,
            }}
          >
            v{currentMapVersion}
          </Typography>
        ),
      }}
      textHeadingOptions={{
        fontSize: "1.5em",
        fontWeight: "bold",
        level: 2,
      }}
      LayoutHeader={
        <Typography
          sx={{
            color: "grey",
            fontWeight: "bold",
            py: 0.5,
            px: 1,
            borderRadius: 1,
            mt: 2,
            ml: 1,
          }}
        >
          Current Template: v{currentMapVersion}
        </Typography>
      }
    >
      <Stack spacing={2}>
        {currentMapVersion &&
          currentTemplateVersion?.current_version &&
          currentMapVersion !== currentTemplateVersion?.current_version && (
            <UpgradeMap
              mapVersion={currentMapVersion}
              templateVersion={currentTemplateVersion?.current_version}
            />
          )}
        <Divider />
        <MainCard
          contentSX={{
            px: 4,
            py: 2,
          }}
          content
          title={
            <Typography
              sx={{
                fontWeight: "bold",
                borderRadius: 1,
                mt: 2,
                ml: 1,
                fontSize: "1.2em",
              }}
            >
              Templates (Latest Available Template: v
              {currentTemplateVersion?.current_version})
            </Typography>
          }
          headerSx={{ py: 1, px: 2 }}
        >
          <Grid
            container
            spacing={2}
            id="configurarion-section-grid-container-items"
          >
            <Grid item xs={12} lg={4}>
              {!currentTemplateVersion && <SkeletonLoad bars={1} />}
              {currentTemplateVersion && version && (
                <Drop
                  name="templates"
                  label="Choose Version"
                  keyValue="version"
                  direction="row"
                  data={currentTemplateVersion?.version_history?.map(
                    (item) => ({
                      version: item,
                    })
                  )}
                  labelSx={{
                    width: "18ch",
                  }}
                  value={version}
                  sx={{
                    width: "100%",
                  }}
                  setValue={(value) => {
                    setVersion(value);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        minHeight: "200px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={12}>
              {mapItemversions && (
                <MapItemsTable
                  mapItems={Object.entries(mapItemversions?.items ?? {})}
                  currentTemplateMapItems={Object.entries(
                    currentTemplateMapVersion?.items ?? {}
                  )}
                />
              )}
            </Grid>
          </Grid>
        </MainCard>
      </Stack>
    </Layout>
  );
};
export default Configuration;
