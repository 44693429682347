import { Grid, Typography } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, USERS_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import { useRef } from "react";
import type { CompanyUser } from "types";

const CompanyUserManagement = ({
  users,
  companyId,
  isPartner,
  readOnly = false,
}: {
  users: CompanyUser[];
  companyId: string;
  isPartner?: boolean;
  readOnly?: boolean;
}) => {
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headerLength = Object.keys(users?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 2.4 : 200;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} ref={ref}>
        <DataTablev2
          key={users.length}
          defaultColumnWidth={defaultColumnWidth}
          data={users}
          toBeHidden={[
            "opted_marketing_updates",
            "settings",
            "sign_up",
            "last_login",
            "internal_access",
            "id_no",
            "is_default",
            "user_id",
          ]}
          customColumns={{
            first_name: {
              first_name: "First name",
              type: "text",
            },
            last_name: { last_name: "Last name", type: "text" },
            title: {
              title: "Job Title",
              type: "text",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value ? params?.value : "None"}
                </Typography>
              ),
            },
            roles: { roles: "User Roles", type: "text" },
            mobile: { mobile: "Phone", type: "text" },
            email: { email: "Email", type: "text" },
            director: {
              director: "Director",
              type: "text",
              renderCell: (params: { value: any }) => (
                <Typography
                  color={params?.value ? "primary" : "info"}
                  sx={{
                    background: params?.value ? "green" : "red",
                    padding: "5px",
                    borderRadius: "5px",
                    color: "#fff",
                    fontWeight: "bold",
                    minWidth: "100px",
                  }}
                >
                  {params?.value ? "Director" : "Non-Director"}
                </Typography>
              ),
            },
            primary_contact: {
              primary_contact: "Primary Contact",
              type: "text",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value ? "Yes" : "No"}
                </Typography>
              ),
            },
          }}
          onRowClicked={(field: any) =>
            !readOnly &&
            history.push(`${USERS_LINK}/${field.email?.split("@")[0]}`, {
              company_id: companyId,
              contact_id: field.id,
              isPartner,
              callBackUrl: history.location.pathname,
            })
          }
          rowsPerPage={rowsPerPage}
          sx={{
            table: {
              minWidth: "100% !important",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
export default CompanyUserManagement;
