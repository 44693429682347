import axios from "axios";
import { useCallback, useState } from "react";

interface CMA_SOFRRate {
  price: string;
  term: string;
  timestamp: string;
}

interface Rates {
  sofrRatesFixing: CMA_SOFRRate[];
}

interface ResultsStrip {
  date: string;
  rates: Rates;
  average30day: number;
  average90day: number;
  average180day: number;
  index: number;
  overnight: string;
}

const useGetCMASofrRates = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<ResultsStrip[]>([]);

  const fetchSofrRates = useCallback(async (): Promise<
    ResultsStrip[] | undefined
  > => {
    setIsFetching(true);
    try {
      const { data } = await axios.get(
        "https://www.cmegroup.com/services/sofr-strip-rates"
      );
      setData(data?.resultsStrip || []);
      return data?.resultsStrip;
    } catch (error) {
      console.error("Error fetching SOFR rates:", error);
      return undefined;
    } finally {
      setIsFetching(false);
    }
  }, []);

  return { data, isFetching, fetchSofrRates };
};

export default useGetCMASofrRates;
