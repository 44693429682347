import { Approval, Pending } from "@mui/icons-material";
import { Grid, Stack, useTheme } from "@mui/material";
import { useGetCreditLimitsQuery } from "api/api-origination";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import {
  CL_APPROVED,
  CL_REQUESTED,
  CREDIT_LIMITS_LINK,
  INCOMLEND_INTERNAL_LINK,
  MAPS,
} from "codes";
import type { InfoCardProps } from "components/Common/InfoCard";
import InfoCard from "components/Common/InfoCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import history from "helpers/history";
import React from "react";
import { isMobile } from "react-device-detect";
import { v4 as randomUUID } from "uuid";

interface MemberInfoCard extends InfoCardProps {
  isFetching: boolean;
}

const InsurerStatsOverviewData: React.FC = () => {
  const { data: creditiLimits, isFetching } = useGetCreditLimitsQuery(null);
  const theme = useTheme();

  let overviewData: MemberInfoCard[] = [
    {
      title: "Credit Limits",
      subtitle: `${creditiLimits?.filter((cl) => cl.clStatus === CL_APPROVED)?.length ?? 0} of ${creditiLimits?.length ?? 0} credit limits approved`,
      onClick: () =>
        history.push(`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <CreditLimitRequest />,
      bottomColorBand: `1ch solid ${theme.palette.primary.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
    {
      title: "Insurer Approved",
      subtitle: `${creditiLimits?.filter((cl) => cl.rating)?.length ?? 0} of ${creditiLimits?.filter((cl) => cl.clStatus === CL_REQUESTED && cl.coface_found)?.length ?? 0} credit limits approved`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <Approval />,
      bottomColorBand: `1ch solid ${theme.palette.error.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
    {
      title: "Pending Insurer",
      subtitle: `${creditiLimits?.filter((cl) => cl.clStatus === CL_REQUESTED && cl.coface_found && !cl.rating)?.length ?? 0} of ${creditiLimits?.filter((cl) => cl.clStatus === CL_REQUESTED && cl.coface_found)?.length ?? 0} credit limits pending`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${MAPS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <Pending />,
      bottomColorBand: `1ch solid ${theme.palette.secondary.main}`,
      height: isMobile ? 70 : 120,
      isFetching,
    },
  ];
  return (
    <Stack direction="row" spacing={1}>
      {overviewData &&
        overviewData.map((item, i) => {
          const { isFetching, ...rest } = item;
          if (isFetching) return <SkeletonLoad bars={3} key={randomUUID()} />;
          return (
            <Grid key={randomUUID()} item xs={12} lg={4}>
              <InfoCard {...rest} />
            </Grid>
          );
        })}
    </Stack>
  );
};

export default InsurerStatsOverviewData;
