import { ArrowRight } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  type MenuProps,
  Select,
  type SelectProps,
  Stack,
  type StackProps,
  type SxProps,
  Tooltip,
  type TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { type MenuItemData, NestedMenuItem } from "mui-nested-menu";
import { type CSSProperties, useEffect, useState } from "react";

export interface DropDownProps {
  name: string;
  keyValue: string;
  data: any[];
  fullWidth?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  placeholder?: string;
  setValue?: (value: string, prev: string, index?: any) => any;
  /**
   * Used in conditions like form-within-form, where the field value setter is external.
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: any) => void;
  index?: number;
  plain?: boolean;
  value?: string;
  error?: boolean;
  multilevel?: boolean;
  menuItemsData?: MenuItemData;
  menuItemSx?: SxProps;
  MenuProps?: Partial<MenuProps>;
  NestedMenuProps?: Partial<MenuProps>;
  className?: string;
  SelectProps?: SelectProps;
  label?: string;
  required?: boolean;
  helpText?: TooltipProps["title"];
  direction?: StackProps["direction"];
  optionsListMinHeight?: CSSProperties["minHeight"];
  [x: string]: any;
}

const Drop = ({
  name,
  data = [],
  value,
  keyValue,
  fullWidth,
  sx,
  index,
  placeholder = "Select",
  setValue = () => {},
  setFieldValue,
  plain,
  error,
  multilevel = false,
  menuItemSx,
  MenuProps,
  NestedMenuProps,
  disabled,
  className,
  SelectProps,
  required,
  label,
  helpText,
  optionsListMinHeight = 100,
  direction = "column",
  ...rest
}: DropDownProps) => {
  const formikValue =
    !isObjectEmpty(rest) && rest?.hasOwnProperty("values")
      ? rest["values"][name]
      : value;

  const [dropdownValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const [itemSelected, setitemSelected] = useState(false);
  const [menuItemsData, setMenuItemsData] = useState<any>();
  useEffect(() => {
    if (formikValue) setCurrentValue(formikValue);
  }, [formikValue]);
  const handleChange = (event: any) => {
    setitemSelected(true);
    setOpen(false);
    const eTarget = event.target;
    setValue(eTarget.value as string, dropdownValue, index);
    setCurrentValue(eTarget.value as string);

    setFieldValue ? setFieldValue(name, eTarget.value as string) : null;
  };
  const isMobile = useIsMobile();
  const theme = useTheme();
  useEffect(() => {
    if (itemSelected) setOpen(false);
  }, [itemSelected]);

  useEffect(() => {
    if (multilevel) {
      const menuData = {
        label:
          dropdownValue === "" ? placeholder : (dropdownValue ?? placeholder),
        items: data.map((item: any, i: number) => {
          const items = item.items?.map((subItem: any, i: number) => {
            return {
              uid: subItem?.uid ?? `${subItem[keyValue]}-${i}`,
              label: subItem?.label ?? subItem[keyValue],
              display: "none",
              callback: () => {
                setitemSelected(true);
                setOpen(false);

                setValue(subItem[keyValue], dropdownValue, index);
                setCurrentValue(subItem[keyValue]);
                setFieldValue
                  ? setFieldValue(name, subItem[keyValue], true)
                  : null;
              },
              ...subItem,
            };
          });

          return {
            uid: item?.uid ?? `${item[keyValue]}-${i}`,
            label: item?.label ?? item[keyValue],
            display: "block",
            callback: () => {
              setitemSelected(true);
              setOpen(false);
              setValue(item[keyValue], dropdownValue, index);
              setCurrentValue(item[keyValue]);
              setFieldValue ? setFieldValue(name, item[keyValue], true) : null;
            },
            ...item,
            sx: menuItemSx,
            items,
          };
        }),
      };

      setMenuItemsData({
        ...menuData,
        items: menuData.items
          .map((item) => {
            const toReturn = [item];
            if (item.items?.length) toReturn.push(item.items);
            return toReturn.flat();
          })
          .flat(),
      });
    }
  }, [multilevel, menuItemsData?.items?.length, dropdownValue]);
  useEffect(() => {
    if (formikValue !== undefined && formikValue !== dropdownValue)
      setCurrentValue(formikValue);
  }, [formikValue, dropdownValue]);

  const minHeight = data?.length
    ? 38 * data.length > 450
      ? 450
      : 53 * data.length
    : optionsListMinHeight;
  const Label = ({ label }: { label: string }) => (
    <Stack
      alignItems="center"
      justifyContent="start"
      spacing={0.5}
      direction="row"
      mb={0.5}
      height="2.3em"
      width="100%"
    >
      <Typography fontWeight="bold">{label && `${label}`}</Typography>
      {required && <Typography color="error">*</Typography>}
      {Boolean(helpText) && (
        <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
          <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
        </Tooltip>
      )}
      <InputLabel />
    </Stack>
  );
  const commonSx = {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      "> div": {
        height: "auto",
        "> input": {
          height: "0.8em",
          fontSize: "0.8em",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.8em",
      marginTop: label ? "0" : "10px",
      height: "2.9em",
      ">div": {
        height: "2.3em",
        ">input": {
          height: "0.8em",
          fontSize: "0.8em",
        },
      },
    },

    "&.Mui-disabled": {
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: 0.5,
      },
      ">div": {
        color: theme.palette.text.disabled,
        textFillColor: theme.palette.text.disabled,
        WebkitTextFillColor: theme.palette.text.disabled,
        backgroundColor: "rgb(191 191 191 / 50%)",
        borderRadius: 0.5,
      },
      ">select": {
        backgroundColor: "rgba(80, 79, 108, 0.5)",
      },
      backgroundColor: "rgba(80, 79, 108, 0.5)",
      borderRadius: 0.5,
      backgroundClip: "content-box",
    },
  };
  if (multilevel) {
    return (
      <Stack
        direction={direction}
        alignItems={direction === "row" ? "center" : "start"}
        justifyContent="start"
      >
        {label && <Label label={label} />}
        <Tooltip
          title={
            <Typography fontWeight="bold">{`${dropdownValue || placeholder}`}</Typography>
          }
        >
          <Select
            disabled={disabled}
            open={open}
            error={error}
            data-testid={`multiselect-dropdown-${name}`}
            placeholder={placeholder}
            value={dropdownValue}
            displayEmpty
            variant={plain ? "standard" : "outlined"}
            fullWidth={fullWidth}
            MenuProps={MenuProps}
            className={className}
            sx={
              plain
                ? {
                    borderBottom: "none !important",
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                    height: "2.2em",
                    ...commonSx,
                    ...sx,
                  }
                : {
                    marginTop: "8px",
                    verticalAlign: "top",
                    marginBottom: "4px",
                    textTransform: "capitalize",
                    height: "2.2em",
                    ">div": {
                      borderRadius: 1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "76%",
                      textOverflow: "ellipsis",
                    },
                    ...commonSx,
                    ...sx,
                  }
            }
            name={name}
            onChange={handleChange}
            onClick={() => {
              setOpen(!open);
            }}
            {...SelectProps}
          >
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>

            {menuItemsData?.items &&
              menuItemsData?.items.map((item: any, i: number) => {
                if (item.items) {
                  return (
                    <Grid
                      container
                      spacing={1}
                      key={`${item.label}`}
                      paddingLeft="0.5em"
                    >
                      <Grid item xs={12}>
                        <NestedMenuItem
                          rightIcon={<ArrowRight />}
                          label={item.label}
                          parentMenuOpen={open}
                          nonce={undefined}
                          MenuProps={NestedMenuProps}
                          data-testid={`nested-dropdown-${name}`}
                        >
                          {item.items &&
                            item.items.map((subItem: any) => {
                              return (
                                <MenuItem
                                  key={`${subItem.label}`}
                                  value={subItem.label}
                                  onClick={subItem.callback}
                                  data-testid={`nested-menuitem-${subItem[keyValue]}`}
                                >
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      sx={{
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                        maxWidth: "30vw",
                                      }}
                                    >
                                      {subItem[keyValue]}
                                    </Grid>
                                    {item.items.length > 1 && (
                                      <Grid item xs={12}>
                                        <Divider />
                                      </Grid>
                                    )}
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                        </NestedMenuItem>
                      </Grid>
                      <Grid item xs={11}>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                }
                if (
                  dropdownValue &&
                  item.items?.find((val: any) => val.label === dropdownValue)
                ) {
                  const currentItem = item.items?.find(
                    (val: any) => val.label === dropdownValue
                  );

                  return (
                    <MenuItem
                      key={dropdownValue}
                      value={dropdownValue}
                      onClick={currentItem.callback}
                    >
                      {dropdownValue}
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem
                    key={`${item.label}`}
                    value={item.label}
                    sx={{
                      display: item.display,
                    }}
                    onClick={item.callback}
                    data-testid={`dropdown-${name}`}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "76%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item[keyValue]}
                      </Grid>
                      {open && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  </MenuItem>
                );
              })}
          </Select>
        </Tooltip>
      </Stack>
    );
  }
  return (
    <Stack
      direction={direction}
      alignItems="center"
      justifyContent="start"
      sx={{ width: "100%" }}
    >
      {label && <Label label={label} />}
      <Select
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        data-testid={`dropdown-${name}`}
        value={dropdownValue}
        native={isMobile}
        MenuProps={{
          ...MenuProps,
          keepMounted: true,
          disablePortal: true,
          slotProps: {
            paper: {
              sx: {
                minHeight,
              },
            },
          },
        }}
        displayEmpty
        variant={plain ? "standard" : "outlined"}
        fullWidth={fullWidth}
        sx={
          plain
            ? {
                borderBottom: "none !important",
                "&:before": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                height: "2.2em",
                ...commonSx,
                ...sx,
              }
            : {
                marginTop: "8px",
                verticalAlign: "top",
                marginBottom: "4px",
                height: "2.2em",
                ...commonSx,
                ...sx,
              }
        }
        name={name}
        onChange={handleChange}
        {...SelectProps}
      >
        {isMobile ? (
          <option value="" disabled>
            {placeholder}
          </option>
        ) : (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}

        {data &&
          data.map((item: any, i: any) => {
            return isMobile ? (
              <option
                key={`menuitem-${item[keyValue]}`}
                value={item[keyValue]}
                disabled={item.disabled}
                data-testid={`menuitem-${item[keyValue]}`}
              >
                {item[keyValue]}
              </option>
            ) : (
              <MenuItem
                key={`menuitem-${item[keyValue]}`}
                value={item[keyValue]}
                disabled={item.disabled}
                data-testid={`menuitem-${item[keyValue]}`}
              >
                {item[keyValue]}
              </MenuItem>
            );
          })}
      </Select>
    </Stack>
  );
};
export default Drop;
