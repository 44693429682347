import { Grid, Typography, useTheme } from "@mui/material";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, IT_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { Cancel, CheckCircle } from "@mui/icons-material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import React, { useRef } from "react";
import type { OverridableStringUnion } from "types";
import ShowUserGroups from "../ShowUserGroups";

interface IUserIT {
  departments: string[];
  first_name: string;
  groups: string[];
  is_active: string;
  last_login: Date;
  last_name: string;
  id: string;
  username: string;
  contact_id: string;
}

interface UsersAPIResponse {
  data: IUserIT[];
  isFetching: boolean;
  refetch: () => void;
}

type USER_TYPE = OverridableStringUnion<"Internal" | "Clients" | "All">;
interface IUsersTableIT {
  users: Record<USER_TYPE, UsersAPIResponse>;
  userType: USER_TYPE;
  setUserType: (value: USER_TYPE) => void;
  headerLength: number;
}

const initialFilters = {
  is_active: {
    comparator: "is",
    keywords: ["True"],
  },
};
const UsersTable: React.FC<IUsersTableIT> = ({
  users,
  userType,
  setUserType,
  headerLength,
}) => {
  const theme = useTheme();
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  const setter = (value: string) => setUserType(value);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Drop
          label="User Type"
          multilevel={false}
          data={[
            {
              value: "internal",
              name: "Internal",
            },
            {
              value: "external",
              name: "Clients",
            },
            {
              value: "all",
              name: "All",
            },
          ]}
          name="userType"
          value={userType}
          keyValue="name"
          setValue={setter}
        />
      </Grid>

      <Grid item xs={12} ref={ref}>
        {users?.[userType]?.isFetching && <SkeletonLoad bars={10} />}
        {!users?.[userType]?.isFetching &&
          users[userType]?.data &&
          Boolean(users[userType]?.data?.length) && (
            <DataTablev2
              data={users[userType]?.data}
              initialFilters={initialFilters}
              defaultColumnWidth={defaultColumnWidth}
              rowsPerPage={rowsPerPage}
              customColumns={{
                first_name: {
                  first_name: "First name",
                  minWidth: 160,
                  type: "text",
                },
                last_name: { last_name: "Last name", type: "text" },
                username: {
                  username: "Username",
                  minWidth: 330,
                  type: "text",
                },
                groups: {
                  groups: "User Groups",
                  minWidth: 220,
                  type: "text",
                  renderCell: ({ value }: { value: string[] }) => {
                    if (value?.length === 1)
                      return (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1em",
                            p: 1,
                            borderRadius: 1,
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          key={`${0}-u-group`}
                        >
                          {value[0]}
                        </Typography>
                      );
                    return (
                      <ShowUserGroups
                        items={value}
                        title="Multiple Groups"
                        aria="group"
                      />
                    );
                  },
                },
                departments: {
                  departments: "Departments",
                  minWidth: 250,
                  type: "text",
                  renderCell: ({ value }: { value: string[] }) => {
                    if (value?.length === 1)
                      return (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1em",
                            p: 1,
                            borderRadius: 1,
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          key={`${0}-u-department`}
                        >
                          {value[0]}
                        </Typography>
                      );
                    return (
                      <ShowUserGroups
                        items={value}
                        title="Multiple Departments"
                        aria="department"
                      />
                    );
                  },
                },
                mobile: { mobile: "Phone", type: "text" },
                title: {
                  title: "Job position",
                  type: "text",
                },

                opted_marketing_updates: {
                  opted_marketing_updates: "Marketing consent",
                  type: "boolean",
                  renderCell: (params: any) => {
                    const Icon = params.value ? CheckCircle : Cancel;
                    const iconColor = params.value ? "green" : "red";

                    return (
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Icon
                            style={{
                              textAlign: "center",
                              color: iconColor,
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  },
                },
                sign_up: {
                  sign_up: "Sign up",
                  type: "date",
                  renderCell: ({ value }: { value: string }) => (
                    <Typography
                      sx={{
                        fontSize: "1em!important",
                        width: "10vw",
                      }}
                    >
                      {value}
                    </Typography>
                  ),
                },
                last_login: {
                  last_login: "Last login",
                  type: "date",
                  minWidth: 300,
                },
                company_id: {
                  company_id: "Company name",
                  type: "text",
                },
                reg_no: { reg_no: "Registration Number", type: "text" },
                is_active: { is_active: "Active" },
              }}
              toBeHidden={["contact_id"]}
              onRowClicked={(field, columnName) => {
                if (columnName !== "groups" && columnName !== "departments")
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management/${field?.id}/manage`
                  );
              }}
              sx={{
                table: {
                  minWidth: "100% !important",
                },
              }}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default UsersTable;
