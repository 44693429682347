import { Box, Stack, Tab, Tabs } from "@mui/material";
import CCDocs from "components/Risk/CC/CCDocs";
import { PERMISSIONS } from "permissions";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import CCDecision from "../../Risk/CC";
import InsuranceSummary from "../../Risk/Insurance/Summary";
import MapFundingDetails from "../Funding";
import MapApproval from "../Summary/Approval";
import CreditCommitteeApproval from "../Summary/Approval/CreditCommitteeApproval";
import MapSummary from "../Summary/MapSummary";
import RiskRating from "../Summary/RiskRating";
import SecurityDocuments from "../Summary/SecurityDocuments";
import SummaryGoverningLaws from "../Summary/SummaryGoverningLaws";
import Termination from "../Summary/Termination";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`general-mappanel-${index}`}
      aria-labelledby={`general-map-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `general-map-${index}`,
    "aria-controls": `general-mappanel-${index}`,
  };
}

const MapGeneralDetails = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const disabled = !USER_PERMISSIONS.includes(PERMISSIONS.map_admin);
  const DEV_MODE =
    import.meta.env.VITE_ENV === "local" || import.meta.env.VITE_ENV === "dev";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Map general tabs"
        >
          <Tab label="General" {...a11yProps(0)} />
          {DEV_MODE && (
            <Tab
              label="Credit Committee"
              {...a11yProps(1)}
              disabled={disabled}
            />
          )}
          {DEV_MODE && (
            <Tab label="Funding" {...a11yProps(2)} disabled={disabled} />
          )}
          {DEV_MODE && (
            <Tab label="Insurance" {...a11yProps(3)} disabled={disabled} />
          )}
          {DEV_MODE && (
            <Tab label="Documents" {...a11yProps(4)} disabled={disabled} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Stack spacing={2}>
          <MapSummary />
          <CreditCommitteeApproval />
          <MapApproval />
          <RiskRating />
          <SummaryGoverningLaws />
          <Stack
            direction={{
              xs: "column",
              lg: "row",
            }}
            spacing={2}
          >
            <SecurityDocuments />
            <Termination />
          </Stack>
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CCDecision />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MapFundingDetails />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <InsuranceSummary />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <CCDocs />
      </CustomTabPanel>
    </Box>
  );
};
export default MapGeneralDetails;
