import InfoIcon from "@mui/icons-material/Info";
import { Grid, Tooltip } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Layout from "components/Common/Layout";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setEFState } from "redux/reducers/experimentalFeaturesReducer";

const ExperimentalFeatures: React.FC = () => {
  interface ExperimentalFeatures {
    [feature: string]: { value: boolean; condition: string };
  }
  const EXPERIMENTAL_FEATURES = useAppSelector(
    (state) => state.experimentalFeatures.value
  ) as ExperimentalFeatures;
  const dispatch = useAppDispatch();

  return (
    <Layout
      title="Experimental Features"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: "1.5em",
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="start"
      >
        {Object.keys(EXPERIMENTAL_FEATURES).map((key) => {
          return (
            <>
              <Grid item xs={3}>
                <FormControlLabel
                  key={key}
                  control={
                    <Switch checked={EXPERIMENTAL_FEATURES[key].value} />
                  }
                  label={key}
                  onChange={(e, checked) => {
                    dispatch(
                      setEFState({
                        [key]: {
                          value: checked,
                          condition: EXPERIMENTAL_FEATURES[key].condition,
                        },
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip
                  title={EXPERIMENTAL_FEATURES[key].condition}
                  enterTouchDelay={0}
                  leaveDelay={200}
                >
                  <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
                </Tooltip>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default ExperimentalFeatures;
