import { Skeleton, type SkeletonProps, Stack } from "@mui/material";
import { v4 as randomUUID } from "uuid";

interface SkeletonLoad extends SkeletonProps {
  bars?: number;
}
const SkeletonLoad = ({ bars = 5, ...rest }: SkeletonLoad) => {
  const iteration = new Array(bars).fill(true);

  return (
    <Stack
      width="100%"
      useFlexGap
      spacing={1}
      flexWrap="wrap"
      data-testid="skeleton-load"
    >
      {iteration.map(() => (
        <Skeleton
          key={randomUUID()}
          variant="rectangular"
          sx={{ minWidth: 100, minHeight: "5ch" }}
          {...rest}
        />
      ))}
    </Stack>
  );
};
export default SkeletonLoad;
