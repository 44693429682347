import { FormHelperText } from "@mui/material";
import { useGetGoodsQuery } from "api/api-company";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import { FormikProps } from "formik";
import styles from "../company.module.scss";

const Goods = ({
  industry,
  disabled,
  formikProps,
  name = "goods_category",
  industryFieldName = "industry",
  asComparison = false,
  handleSave,
}: {
  industry: string;
  disabled?: boolean;
  formikProps: FormikProps<any>;
  name?: string;
  industryFieldName?: string;
  asComparison?: boolean;
  handleSave?: (value?: string) => void;
}) => {
  const setter = (value: string, prev?: string, index?: number) => {
    formikProps.setTouched({ [name]: true }, true);
    formikProps.setFieldValue(name, value, true);
  };
  const { data: goods, isFetching } = useGetGoodsQuery(industry, {
    skip: !Boolean(industry),
  });

  const fieldName = name?.includes(".") ? name.split(".") : name;
  const BaseComponent = !asComparison ? Drop : VariableDropdown;
  if (isFetching) return <SkeletonLoad bars={1} />;
  if (!isFetching)
    return (
      <>
        <BaseComponent
          labelClassName={styles.labelClass}
          label={!asComparison ? "Goods Category" : ""}
          required={!asComparison}
          value={
            Array.isArray(fieldName)
              ? formikProps.values?.[fieldName[0]]?.[fieldName[1]]
              : formikProps.values?.[name]
          }
          disabled={disabled || industry === ""}
          name={name}
          style={{ width: "100%" }}
          keyValue="name"
          data={goods ?? []}
          setValue={setter}
          placeholder="Select Goods Category"
          error={Boolean(formikProps.errors?.[name])}
          handleSave={handleSave}
          {...formikProps}
        />
        {Boolean(
          formikProps.errors?.[name] && formikProps.touched?.[industryFieldName]
        ) && (
          <FormHelperText error={Boolean(formikProps.errors?.[name])}>
            {formikProps.errors?.[name] as string}
          </FormHelperText>
        )}
      </>
    );
  return <></>;
};
export default Goods;
