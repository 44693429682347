import { Grid, useTheme } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import useRiskScoring from "hooks/useRiskScoring";
import { MapContext } from "pages/maps/map";
import React from "react";
import styles from "../maps.module.scss";

const RiskRating = () => {
  const theme = useTheme();

  const { score, RESULT } = useRiskScoring({
    id: React.useContext(MapContext),
  });

  return (
    <MainCard
      title="Risk Rating"
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <LabelViewOnly
              label="IPL Risk Rating"
              labelClassName={styles.labelClass}
              sx={{ fontSize: "1em", fontWeight: 600 }}
            />
            <LabelViewOnly label={`${score ? Math.trunc(score) : "N/A"}`} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LabelViewOnly
              label="Inherent IPL Risk Category"
              labelClassName={styles.labelClass}
              sx={{ fontSize: "1em", fontWeight: 600 }}
            />
            <LabelViewOnly label={`${"N/A"}`} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LabelViewOnly
              label="Reason for Override to High Risk"
              labelClassName={styles.labelClass}
              sx={{ fontSize: "1em", fontWeight: 600 }}
            />
            <LabelViewOnly label={`${"N/A"}`} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LabelViewOnly
              label="Final Incomlend Risk Category"
              labelClassName={styles.labelClass}
              sx={{ fontSize: "1em", fontWeight: 600 }}
            />
            <LabelViewOnly label={`${RESULT || "N/A"}`} />
          </Grid>
        </Grid>
      }
    />
  );
};
export default RiskRating;
