import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface DownloadButtonProps {
  id: string;
  fileName: string;
  orientation?: "portrait" | "landscape";
}
export const downloadDivAsPdf = async ({
  id,
  fileName,
  orientation = "portrait",
}: DownloadButtonProps) => {
  const div = document.getElementById(id);

  if (div) {
    try {
      const canvas = await html2canvas(div, {
        scale: 5, // Increase the scale to get better quality
      });
      const imgData = canvas.toDataURL("image/jpeg", 1);

      const pdf = new jsPDF({
        orientation,
        unit: "pt",
        format: "a4",
      });
      const imgWidth = 595.28; // A4 width in pt
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, id, "NONE");
      pdf.save(fileName);
    } catch (error) {
      console.error("Failed to generate PDF", error);
    }
  }
};
