import { Grid, Link, Typography } from "@mui/material";
import { DASHBOARD } from "codes";
import moment from "moment";
import { useTranslation } from "react-i18next";
import packageInfo from "../../../package.json";

interface FooterParams {
  textAlign?:
    | "center"
    | "end"
    | "justify"
    | "left"
    | "match-parent"
    | "right"
    | "start"
    | undefined;
  inDashboard?: boolean;
  horizontal?: boolean;
  params?: string;
}
const Footer = ({
  textAlign = "start",
  inDashboard = false,
  horizontal = false,
  params = "",
}: FooterParams) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      component="footer"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item textAlign={textAlign}>
        <Link
          sx={{
            cursor: "pointer",
            fontWeight: 400,
            fontSize: "0.8em",
            textAlign,
          }}
          target="_blank"
          href={`${inDashboard ? DASHBOARD : ""}/terms-of-use${params}`}
        >
          {t("terms-of-use")}
        </Link>{" "}
        |{" "}
        <Link
          sx={{
            cursor: "pointer",
            fontWeight: 400,
            fontSize: "0.8em",
            textAlign,
          }}
          target="_blank"
          href={`${inDashboard ? DASHBOARD : ""}/privacy-policy${params}`}
        >
          {t("privacy-policy")}
        </Link>
      </Grid>
      <Grid item textAlign={textAlign}>
        <Typography
          fontSize={horizontal ? "0.8em" : "0.6em"}
          textAlign={textAlign}
        >
          Incomlend©
          {moment().year()} {params === "?auth=0" && `v${packageInfo?.version}`}
          . All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Footer;
