import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { useEditContactMutation } from "api/api-users-v2";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { validationSchema } from "./addContactFormFields";

const EditAuthorizedSignatory = ({
  accountId,
  contact,
  onEnd,
  module,
}: {
  accountId: string;
  contact: any;
  onEnd?: () => void;
  module?: string;
}) => {
  const [editUser, { error, isError, isLoading }] = useEditContactMutation();

  const initialValues = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    title: contact.title,
    mobile: contact.mobile,
    email: contact.email,
    roles: contact.roles,
    is_authorized: contact.is_authorized,
  };

  const handleFormSubmit = (values: any) => {
    const updatedUser: any & { type?: "IDVerification" | undefined } = {
      email: values.email ?? "",
      first_name: values.first_name ?? "",
      last_name: values.last_name ?? "",
      mobile: values.mobile ?? "",
      title: values.title ?? "",
      account_id: accountId ?? "",
      role: values.roles?.toLowerCase(),
      is_authorized: values.is_authorized,
    };

    if (module === "compliance") {
      updatedUser["type"] = "IDVerification";
    }

    editUser({
      account_id: accountId,
      contact_id: contact?.id,
      data: updatedUser,
    })
      .unwrap()
      .then((res) => {
        if (!isLoading && !isError && !res.hasOwnProperty("error")) {
          toast(
            `Successfully updated contact: ${values.first_name} ${values.last_name}`,
            { type: "success" }
          );
          if (onEnd) {
            onEnd();
          }
        } else {
          toast(`Error updating contact: ${(error as any).data}`, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        toast(e?.message, { type: "error" });
      });
  };

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formikProps) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="first_name"
                        label="First Name"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="last_name"
                        label="Last Name"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="title"
                        label="Job Position"
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        tabIndex={5}
                        name="mobile"
                        label={`${"Phone Number"}`}
                        placeholder={`${"phone-number"}`}
                        fullWidth
                        type="phone"
                        required
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName="labelClass"
                        name="email"
                        label="Email"
                        type="email"
                        required
                        style={{ width: "100%" }}
                        disabled
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} lg={12} margin="auto">
                  <Grid container spacing={2}>
                    <Grid item xs={5} lg={3}>
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        data-testid="edit-user-button"
                        loading={isLoading}
                        onClick={formikProps.submitForm}
                      >
                        Update
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default EditAuthorizedSignatory;
