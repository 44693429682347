import Close from "@mui/icons-material/Close";
import {
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

import toProperCase from "helpers/propercase";
import useIsMobile from "hooks/useIsMobile";
import { CustomColumn } from "../..";
import ToggleSwitch from "./ToggleSwitch";

interface ColumnToggleProps<T> {
  open: boolean;
  handleClose: () => void;
  allHideableColumns: string[];
  setAllHideableColumns: Dispatch<SetStateAction<string[]>>;
  columns: string[];
  customColumns: CustomColumn;
  columnsToHide: string[];
  hideablesPreset?: string[];
  getColumnVisibility: (columns: T) => void;
}
const ColumnToggle = <T extends Record<string, any> = {}>({
  open,
  handleClose,
  allHideableColumns,
  setAllHideableColumns,
  columns,
  customColumns,
  columnsToHide,
  hideablesPreset,
  getColumnVisibility,
}: ColumnToggleProps<T>) => {
  const isMobile = useIsMobile();

  const defaultColumnState = columns.reduce(
    (acc, col) => ({ ...acc, [col]: true }),
    {}
  );
  const hideablePresetColumnState = hideablesPreset?.reduce(
    (acc, col) => ({ ...acc, [col]: false }),
    {}
  );
  const hideableColumnState = allHideableColumns?.reduce(
    (acc, col) => ({ ...acc, [col]: false }),
    {}
  );
  const [columnStates, setColumnStates] = useState<{ [key: string]: any }>({
    ...defaultColumnState,
    ...hideablePresetColumnState,
    ...hideableColumnState,
  });

  const Header = (
    <Typography variant="h3" style={{ margin: "0 1vh" }} fontWeight="bold">
      Columns
    </Typography>
  );
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={12} md={6}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "#ffffff",
              borderRadius: 5,
              p: 2,
              justifyContent: "space-between",
            }}
          >
            {Header && <Grid item>{Header}</Grid>}
            <Grid item sx={{ textAlign: "end" }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  padding: "1ch",
                }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12} margin="auto">
              <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{ p: 2 }}>
                  {columns &&
                    columns
                      .filter((item) => !columnsToHide?.includes(item))
                      .map((col, i) => (
                        <ToggleSwitch
                          key={col}
                          i={i}
                          label={
                            customColumns?.[col]?.[col] ?? toProperCase(col)
                          }
                          name={col}
                          state={columnStates}
                          getColumnVisibility={getColumnVisibility}
                          setAllHideableColumns={setAllHideableColumns}
                          setColumnStates={setColumnStates}
                        />
                      ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ColumnToggle;
