import { Container, Divider, Grid, Typography } from "@mui/material";
import type { MAP_PARTY, MAP_USE_CASE } from "api/api-maps";
import React from "react";
import TemplateCard from "./TemplateCard";

interface MapItemTemplate {
  use_case: MAP_USE_CASE;
  version: string;
  party: MAP_PARTY;
  ipl_product: string;
  template_name: string;
}

interface Props {
  templates: [MapItemTemplate | undefined, MapItemTemplate | undefined];
}

const ViewTemplate: React.FC<Props> = ({ templates }) => {
  const [current, comparing] = templates;
  return (
    <Container>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 10,
          width: "100%",
        }}
      >
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" component="div">
            Templates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
        {current && (
          <Grid item xs={12} sm={comparing ? 5.8 : 12}>
            <TemplateCard template={current} />
          </Grid>
        )}
        {current && comparing && (
          <Grid item xs={12} sm={0.1}>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ height: "100%" }}
            />
          </Grid>
        )}
        {comparing && (
          <Grid item xs={12} sm={current ? 5.8 : 12}>
            <TemplateCard template={comparing} diff={current} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ViewTemplate;
