import {
  Divider,
  List,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useGetCreditLimitsQuery } from "api/api-origination";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { CL_REQUESTED, DEFAULT_ROWS } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import moment from "moment";
import React, { useState } from "react";
import PendingApprovalList from "./PendingApprovalList";
import PendingCofaceRequest from "./PendingCofaceRequest";

const InsurerCommunication: React.FC = () => {
  const { data: creditiLimits, isFetching } = useGetCreditLimitsQuery(null);
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const latestCofaceResponses = creditiLimits?.filter(
    (cl) =>
      cl.clStatus === CL_REQUESTED &&
      cl.rating &&
      moment(cl.insurerResponse).isAfter(moment().subtract(30, "days"))
  );
  const needsCoface = creditiLimits?.filter(
    (cl) => cl.clStatus === CL_REQUESTED && !cl.rating
  );
  const [page, setPage] = useState(1);
  const [resultsCount, setResultsCount] = useState(rowsPerPage);
  const needsCofacePaginated = needsCoface?.slice(
    (page - 1) * resultsCount,
    page * resultsCount
  );
  return (
    <Stack spacing={2}>
      <Typography variant="h3">Coface Requests</Typography>
      <Divider />
      {latestCofaceResponses && (
        <Stack spacing={latestCofaceResponses?.length === 0 ? 0 : 1}>
          <Typography
            variant="h4"
            color="error.main"
            sx={{ textDecoration: "underline" }}
          >
            {`Pending Approval (Since ${moment().subtract(30, "days").format("DD/MM/YY")}) [${latestCofaceResponses?.length}]`}
          </Typography>
          <Typography variant="body2" fontStyle="italic">
            Click on the credit limit request to view more details
          </Typography>
          <List sx={{ width: "100%", maxWidth: "80%" }} dense>
            {latestCofaceResponses?.map((cl) => (
              <PendingApprovalList creditLimit={cl} key={cl?.id} />
            ))}
          </List>
        </Stack>
      )}
      {latestCofaceResponses?.length === 0 && (
        <NoData text="No Coface Responses" />
      )}
      <Divider />
      {needsCoface && (
        <MainCard
          title={
            <Typography
              variant="h4"
              color="primary.main"
              sx={{ textDecoration: "underline" }}
            >
              {`Pending Request to Coface [${needsCoface?.length}]`}
            </Typography>
          }
          subheader={
            <Stack spacing={needsCoface?.length === 0 ? 0 : 1}>
              <Typography variant="body2" fontStyle="italic">
                Click on the credit limit request to view more details.
              </Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2">Showing</Typography>
                <Select
                  value={resultsCount}
                  onChange={(e) => setResultsCount(e.target.value as number)}
                  variant="standard"
                  onClick={(e) => e.stopPropagation()}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                <Typography variant="body2">
                  of {needsCoface?.length} requests
                </Typography>
              </Stack>
            </Stack>
          }
          footer={
            <Pagination
              count={Math.ceil((needsCoface?.length || 0) / resultsCount)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(e, p) => setPage(p)}
            />
          }
        >
          <List
            sx={{
              width: "100%",
              maxWidth: "90%",
            }}
            dense
          >
            {needsCofacePaginated?.map((cl) => (
              <PendingCofaceRequest creditLimit={cl} key={cl?.id} />
            ))}
          </List>
        </MainCard>
      )}
    </Stack>
  );
};

export default InsurerCommunication;
