import { Link, Typography } from "@mui/material";
import type { CustomColumn } from "../..";
import type { DataTableHeaderProps } from "./Header";

const RenderCell = ({
  item,
  column,
  params,
  disabled,
  customColumns,
}: {
  item: Record<string, any>;
  column: string;
  params: any;
  disabled?: boolean;
  customColumns: Record<string, CustomColumn>;
}) => {
  const headerProps: DataTableHeaderProps<typeof item> =
    customColumns?.[column]?.headerProps;
  const onCLickFn = customColumns?.[column]?.headerProps?.onClick;
  return customColumns?.[column]?.renderCell
    ? customColumns[column]?.renderCell({
        ...params,
        disabled,
      })
    : item &&
        typeof item[column] !== "object" &&
        (customColumns?.[column]?.link ? (
          <Link
            sx={{
              overflow: "hidden",
              maxWidth: "76%",
              textOverflow: "ellipsis",
              alignItems: "top",
              display: "flex",
              ...headerProps?.sx,
            }}
            href={customColumns?.[column]?.link?.href(item)}
            target={customColumns?.[column]?.link?.target ?? "_blank"}
          >
            {item[column]}
          </Link>
        ) : (
          <Typography
            sx={{
              overflow: "hidden",
              maxWidth: "76%",
              textOverflow: "ellipsis",
              alignItems: "top",
              ...headerProps?.sx,
            }}
            onClick={() => onCLickFn && onCLickFn(item[column])}
          >
            {item[column]}
          </Typography>
        ));
};
export default RenderCell;
