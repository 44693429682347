import Drawer from "@mui/material/Drawer";
import MainCard from "components/Common/Cards/MainCard";

import { Container } from "@mui/material";
import * as React from "react";
import type { Coface_JSON } from "./coface_response";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: Coface_JSON;
}

export default function CofaceResponseDrawer({ open, setOpen, value }: Props) {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
      if (!open) {
        //function
      }
    };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <MainCard
          content
          headerSx={{ px: 2, py: 1 }}
          contentSX={{ px: 2, py: 1, maxWidth: "50vw", overflowX: "auto" }}
          title="Coface Response"
        >
          <Container>
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </Container>
        </MainCard>
      </Drawer>
    </div>
  );
}
