import { Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetCreditLimitQuery,
  useGetInsurerIdentificationQuery,
  useRejectCreditLimitMutation,
  useRequestCreditLimitMutation,
} from "api/api-origination";
import Modal from "components/Common/Modal";

import { CREDIT_LIMITS_LINK, INCOMLEND_INTERNAL_LINK } from "codes";
import { CLContextProvider } from "components/CLRequest/CLDetailBody/CLContextProvider";
import CLDetailBodyHeader from "components/CLRequest/CLDetailBody/CLDetailBodyHeader";
import CLSectionTabs from "components/CLRequest/CLDetailBody/CLSectionTabs";
import CreditLimitActivityLog from "components/CLRequest/CLDetailBody/CreditLimitActivityLog";
import { initialValues } from "components/CLRequest/CLDetailBody/formvalues";
import ReviewModal from "components/CLRequest/CLRequestModals/ReviewModal";
import CofaceSelect from "components/CLRequest/CofaceSelect";
import Loader from "components/Common/Loader";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import secondaryButtonStyle from "styles/secondaryButtonStyle";

const CLDetail = () => {
  const { push } = history;
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { id } = useParams();

  const {
    data: creditLimit,
    isFetching: isFetchingCLR,
    refetch,
  } = useGetCreditLimitQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const [requestCreditLimit, { isLoading: isLoadingRequestCL }] =
    useRequestCreditLimitMutation();
  const [rejectCreditLimit, { isLoading: isLoadingRejectCL }] =
    useRejectCreditLimitMutation();

  const {
    data: insurers,
    isFetching,
    isUninitialized,
  } = useGetInsurerIdentificationQuery(creditLimit?.id ?? "", {
    skip: !Boolean(creditLimit?.id),
  });
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<{
    visible: boolean;
    status:
      | {
          type:
            | "request"
            | "reject"
            | "contact_creator"
            | "approve"
            | "resubmit";
          cl_id: string;

          buyerName: string;
          id: string;
        }
      | undefined;
  }>({
    visible: false,
    status: undefined,
  });
  const [showIncomlendReviewModal, setShowIncomlendReviewModal] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const [coface_id, setCofaceId] = useState("");

  useEffect(() => {
    if (
      !isUninitialized &&
      insurers &&
      coface_id === "" &&
      insurers?.length > 0
    ) {
      setCofaceId(insurers[0].coface_id);
    }
  }, [isUninitialized, insurers]);
  // Set coface_id to the first insurer's coface_id, if it exists as a default value

  if (!creditLimit && isFetchingCLR) return <SkeletonLoad bars={10} />;
  if (creditLimit && !isFetchingCLR)
    return (
      <CLContextProvider value={creditLimit}>
        <Grid container spacing={2} padding={isMobile ? 0 : "3ch"}>
          <Grid item xs={12}>
            <Typography
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                push(`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`);
              }}
            >{`<- Back to Credit Limits`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CLDetailBodyHeader
              setShowChangeStatusModal={setShowChangeStatusModal}
              setShowIncomlendReviewModal={setShowIncomlendReviewModal}
            />
          </Grid>
          {!creditLimit && isFetchingCLR && <SkeletonLoad bars={10} />}
          {creditLimit && !isFetchingCLR && (
            <Grid item xs={12}>
              <Formik
                initialValues={{ ...initialValues, ...creditLimit }}
                onSubmit={() => {}}
              >
                <Form>
                  {isMobile ? (
                    <Stack spacing={4} direction="row">
                      <Grid container>
                        <Grid xs={12}>
                          <CLSectionTabs />
                        </Grid>
                        <Grid xs={12}>
                          <CreditLimitActivityLog
                            creditLimitId={creditLimit?.id}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  ) : (
                    <Stack spacing={4} direction="row">
                      <CLSectionTabs />
                      <CreditLimitActivityLog creditLimitId={creditLimit?.id} />
                    </Stack>
                  )}
                </Form>
              </Formik>
            </Grid>
          )}
          <Modal
            message={
              showChangeStatusModal.status &&
              creditLimit && (
                <CofaceSelect
                  statusType={showChangeStatusModal?.status?.type}
                  cl_id={showChangeStatusModal?.status?.cl_id}
                  buyerName={showChangeStatusModal?.status?.buyerName}
                  insurers={insurers}
                  buyerRegNo={creditLimit?.buyerRegNo}
                  isFetching={isFetching}
                  setCofaceId={setCofaceId}
                  coface_id={coface_id}
                />
              )
            }
            sx={modalSx}
            width={isMobile ? "90%" : "40%"}
            height="auto"
            modalContentSx={{ height: "auto" }}
            open={showChangeStatusModal.visible}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ maxWidth: "100%" }}
            onClose={() =>
              setShowChangeStatusModal({
                visible: false,
                status: undefined,
              })
            }
            primary={{
              variant: "contained",
              loading:
                showChangeStatusModal?.status?.type === "request"
                  ? isLoadingRequestCL
                  : showChangeStatusModal?.status?.type === "reject"
                    ? isLoadingRejectCL
                    : false,
              onClick: () => {
                if (showChangeStatusModal?.status?.type === "request") {
                  requestCreditLimit({
                    credit_limit_id: showChangeStatusModal?.status?.id,
                    coface_id,
                    comments: "",
                  })
                    .unwrap()
                    .catch((error) => {
                      const errorFile = error?.message;
                      toast(`Something went wrong : ${errorFile}`, {
                        type: "error",
                      });
                    });
                }
                if (showChangeStatusModal?.status?.type === "reject") {
                  rejectCreditLimit({
                    credit_limit_id: showChangeStatusModal?.status?.id,
                    comments: "",
                  })
                    .unwrap()
                    .catch((error) => {
                      const errorFile = error?.message;
                      toast(`Something went wrong : ${errorFile}`, {
                        type: "error",
                      });
                    });
                }
                setShowChangeStatusModal({
                  visible: false,
                  status: undefined,
                });
              },
              children: "Confirm",
            }}
            closeButton
            secondary={{
              variant: "outlined",
              onClick: () =>
                setShowChangeStatusModal({
                  visible: false,
                  status: undefined,
                }),
              sx: {
                ...secondaryButtonStyle,
                bgcolor: theme.palette.error.main,
                color: theme.palette.background.default,
                border: `1px solid ${theme.palette.error.main}`,
                ":hover": {
                  bgcolor: theme.palette.error.main,
                  color: theme.palette.background.default,
                  outline: "transparent",
                  border: `1px solid ${theme.palette.error.main}`,
                },
              },
              children: `Cancel`,
            }}
          />
          <ReviewModal
            showIncomlendReviewModal={showIncomlendReviewModal}
            setShowIncomlendReviewModal={setShowIncomlendReviewModal}
          />
        </Grid>
      </CLContextProvider>
    );
  return <Loader />;
};
export default CLDetail;
