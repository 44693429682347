import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type {
  AddInvoice,
  Invoice,
  OverridableStringUnion,
  SupportingDocument,
} from "types";

interface PickList {
  countries: any[];
  incoterms: any[];
  carriers: any[];
  ports: any[];
}

interface Stats {
  averageDelay: number;
  averageDiscount?: number;
  averageSupplierCost?: number;
  fundingEfficiency?: number;
  supplierAvgDiscount?: number;
  totalAdvanced?: number;
  totalCreditInsurance?: number;
  totalDiscount?: number;
  totalOverdueCost?: number;
  totalPlatformFee?: number;
}

interface WithdrawalDeposit {
  amount: number;
  bank_account_id: string;
  currency: string;
  notes: string;
  remark: string;
  trustee: string;
  type: string;
}
interface ApproveListing {
  invoice_id: string;
  user_type: string;
  status: string;
  details: string;
}
interface Transaction {
  amount: number;
  currency: string;
  debit_credit: string;
  effective_date: string;
  invoice_id: string;
  invoice_name: string;
  invoice_reference: string;
  status: string;
  transaction_id: string;
  transaction_type: string;
}
interface Ewallet {
  account_id: string;
  available_balance: number;
  blocked_withdrawal: boolean;
  currency: OverridableStringUnion<
    "USD" | "SGD" | "HKD" | "GBP" | "EUR" | "AUD"
  >;
  ewallet_id: string;
  position: number;
  position_usd_equiv: number;
  trustee: string;
}
interface SupportingDoc {
  id: string;
  name: string;
}
interface BillingAddress {
  city: string;
  country: string;
  geocodeAccuracy: null | string;
  latitude: null | number;
  longitude: null | number;
  postalCode: string;
  state: null | string;
  street: string;
}

interface Buyer {
  AnnualRevenue: number;
  BillingAddress: BillingAddress;
  Id: string;
  Name: string;
  Un_utilised_adv_amt_all_phases_usd_equiv__c: number;
  Year_of_incorporation__c: number;
  attributes: {
    type: string;
    url: string;
  };
  head_office_country__c: string;
  suspended__c: boolean;
}

interface Marketplace {
  Currency__c: string;
  Id: string;
  attributes: {
    type: string;
    url: string;
  };
}

interface IncomlendProductReference {
  CCYs__c: string;
  Id: string;
  Name: string;
  active__c: boolean;
  attributes: {
    type: string;
    url: string;
  };
  max_invoice_value_USD__c: number;
  minimum_face_value__c: number;
}

interface Attributes {
  type: string;
  url: string;
}

interface SupplierBuyerMap {
  Buyer__c: string;
  Buyer__r: Buyer;
  Credit_Limit_Avalaible__c: number;
  Id: string;
  Incomlend_product__c: string;
  Incomlend_product__r: IncomlendProductReference;
  Map_Currency__c: string;
  Marketplace__c: string;
  Marketplace__r: Marketplace;
  Name: string;
  Recommended_Discount_Rate__c: number;
  Total_Financing_value_alltime__c: number;
  attributes: Attributes;
  buyer_onboarding_stage__c: string;
  buyer_supplier_map_approved__c: boolean;
  insurable__c: boolean;
  is_offer_conditional__c: boolean;
  map_onboarding_stage__c: string;
  number_of_invoices_all_time__c: number;
  offer_conditions_met__c: boolean;
  percentage_of_financing__c: number;
}

interface BuyerForMapProduct {
  Id: string;
  Name: string;
  attributes: {
    type: string;
    url: string;
  };
  suspended__c: boolean;
}

interface IncomlendProduct {
  Id: string;
  Name: string;
  active__c: boolean;
  attributes: {
    type: string;
    url: string;
  };
}

interface MapProduct {
  Buyer__c: string;
  Buyer__r: BuyerForMapProduct;
  Id: string;
  Incomlend_product__c: string;
  Incomlend_product__r: IncomlendProduct;
  Marketplace__c: string;
  Marketplace__r: Marketplace;
  Name: string;
  attributes: {
    type: string;
    url: string;
  };
  buyer_supplier_map_approved__c: boolean;
  insurable__c: boolean;
  map_onboarding_stage__c: string;
}

interface UploadedDoc {
  attachments: string[];
  document_type: string;
}

interface CompanyProfile {
  name: string;
  country_incoporation: string;
  countries_operation: string;
  location_of_hq: string;
  city: string;
  industry: string;
  turnover: number;
  logo: string;
  incorportation_year: number;
  public_profile: string;
  incomlend_product: string;
  total_invoice_number: number;
  credit_limit_given: number;
  credit_limit_available: number;
  credit_limit_used: number;
  payment_history: string;
  relationship_profile: string;
  progress_invoice_amount_usd_equiv: number;
  accepted_incoterms: string;
  max_invoice_value_usd: number;
  suspended: boolean;
  offer_sent_date: string;
  offer_accepted_date: string;
  is_offer_conditional: boolean;
  is_offer_conditions_met: boolean;
  verification_requirements: string;
  currency: string;
  total_advance: number;
  account_onboarding_stage: string;
  recommended_discount_rate: number;
}

type PHASE_TYPES =
  | "prelisting"
  | "on-marketplace"
  | "in-progress"
  | "overdue"
  | "paid"
  | "pass-through";
type PHASE = OverridableStringUnion<PHASE_TYPES>;

export const marketplace = createApi({
  reducerPath: "marketplace",
  tagTypes: [
    "INVOICES",
    "PICKLIST",
    "SUPPORTING_DOCS",
    "EWALLETS",
    "TRANSACTIONS",
    "MAP_DETAILS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/marketplace`,
  }),
  endpoints: (builder) => ({
    getInvoiceListByUser: builder.query<
      Invoice[],
      { account_id: string; partner_id: string; phase: PHASE }
    >({
      query: ({ account_id, partner_id, phase }) => ({
        url: `/accounts/${account_id}/partners/${partner_id}/invoices?phase=${phase}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICES"],
    }),
    getPartnerMarketplaceDetails: builder.query<
      CompanyProfile,
      { account_id: string; partner_id: string }
    >({
      query: ({ account_id, partner_id }) => ({
        url: `/accounts/${account_id}/partners/${partner_id}/map-details`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICES"],
    }),
    getPicklists: builder.query<PickList, null>({
      query: () => ({ url: `/picklists`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["PICKLIST"],
    }),
    getEwallets: builder.query<Ewallet[], string>({
      query: (id) => ({ url: `/accounts/${id}/ewallets`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["EWALLETS"],
    }),
    getTransactions: builder.query<Transaction[], string>({
      query: (id) => ({ url: `/accounts/${id}/transactions`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["TRANSACTIONS"],
    }),
    getStats: builder.query<Stats, string>({
      query: (account_id) => ({
        url: `/accounts/${account_id}/invoices/stats`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getSupportingDocuments: builder.query<
      SupportingDocument[],
      { accountId: string; partnerId: string }
    >({
      query: (params) => ({
        url: `/accounts/${params.accountId}/partners/${params.partnerId}/supporting-documents/${params.partnerId}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getUploadedSupportingDocuments: builder.query<
      {
        document_type: string;
        attachments: string[];
      }[],
      string
    >({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getInvoiceById: builder.query<any, string>({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    addInvoice: builder.mutation<
      { invoice_id: string; supporting_documents: SupportingDoc[] },
      AddInvoice
    >({
      query: (data) => {
        const { account_id, partner_id, invoice_details } = data;
        return {
          url: `/accounts/${account_id}/partners/${partner_id}/invoices`,
          method: "POST",
          data: invoice_details,
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    addInvoiceBatch: builder.mutation<
      { invoice_id: string; supporting_documents: SupportingDoc[] },
      { file: File; partner_id: string; account_id: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("partner_id", data.partner_id);
        const { account_id } = data;
        return {
          url: `/accounts/${account_id}/batch-invoices`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    submitWithdrawal: builder.mutation<
      any,
      { id: string; data: WithdrawalDeposit }
    >({
      query: ({ id, data }) => {
        return {
          url: `/accounts/${id}/withdrawaldeposit`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["EWALLETS"],
      transformResponse: (response) => response?.data,
    }),
    approveListing: builder.mutation<any, { id: string; data: ApproveListing }>(
      {
        query: ({ id, data }) => {
          return {
            url: `/accounts/${id}/approve-listing`,
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["INVOICES"],
        transformResponse: (response) => response?.data,
      }
    ),
    postSupportingDocuments: builder.mutation<
      // need to change the component
      { invoice_id: string; supporting_documents: [] },
      { file: File; supporting_document_id: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("supporting_document_id", data.supporting_document_id);
        return {
          url: `/invoices/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["SUPPORTING_DOCS"],
      transformResponse: (response) => response?.data,
    }),
    getMapProducts: builder.query<
      // not used
      MapProduct,
      { account_id: string; partner_id: string; currency: string }
    >({
      query: ({ account_id, partner_id, currency }) => ({
        url: `/${account_id}/products?partner_id=${partner_id}&currency=${currency}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getAccountBanks: builder.query<any, string>({
      // will be used in withdrawal
      query: (account_id) => ({
        url: `/accounts/${account_id}/banks`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetInvoiceListByUserQuery,
  useGetInvoiceByIdQuery,
  useAddInvoiceMutation,
  useAddInvoiceBatchMutation,
  useGetPicklistsQuery,
  useGetSupportingDocumentsQuery,
  usePostSupportingDocumentsMutation,
  useGetStatsQuery,
  useGetEwalletsQuery,
  useGetTransactionsQuery,
  useSubmitWithdrawalMutation,
  useApproveListingMutation,
  useGetUploadedSupportingDocumentsQuery,
  useGetMapProductsQuery,
  useGetPartnerMarketplaceDetailsQuery,
  useGetAccountBanksQuery,
} = marketplace;
