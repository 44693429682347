import {
  Alert,
  AlertTitle,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import { useGetSummariesQuery } from "api/api-compliance";
import {
  useEditAccountCommentsMutation,
  useEditMapRiskParametersMutation,
  useGetAccountCommentsQuery,
  useGetMapRiskParametersQuery,
  useGetMapSummaryQuery,
  useGetTemplatesV2Query,
  type MAP_PARTY,
} from "api/api-maps";
import { BUYER, SUPPLIER } from "codes";
import { type InputType } from "components/Common/FormFields/InputField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableInput from "components/Common/VariableInput";
import Summary from "components/Map/Summary/MapSummaries/Summary";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import useTradeTableValueGenerator from "hooks/useTradeTableValueGenerator";
import { MapContext, MapCurrentVersion } from "pages/maps/map";
import React from "react";
import styles from "./company-background.module.scss";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface CRITERIA_TYPE extends Record<string, any> {
  [key: string]: MapTemplate;
}

interface CompanyBackgroundProps {
  background_extra_info: string;
  background: CRITERIA_TYPE;
}

const CompanyBackground = ({
  accountType,
  disabled,
}: {
  accountType: string;
  disabled: boolean;
}) => {
  const id = React.useContext(MapContext);
  const theme = useTheme();
  const item_type = "background";
  const { setIsOpen } = useTour();
  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const { data: summariesData } = useGetSummariesQuery(id ?? "", {
    skip: id === undefined,
  });

  const currentMapVersion = React.useContext(MapCurrentVersion);

  const { data: companyBackgroundTemplate, isFetching } =
    useGetTemplatesV2Query(
      {
        party: accountType?.toLowerCase() as MAP_PARTY,
        use_case: item_type,
        ipl_product: mapData?.ipl_product_id ?? "",
        map_version: currentMapVersion,
      },
      {
        skip: !mapData?.ipl_product_id,
      }
    );
  const [editComments] = useEditAccountCommentsMutation();
  const { data: accountComments } = useGetAccountCommentsQuery(
    { map_name: id ?? "", account_type: accountType },
    { skip: !id }
  );

  const BACKGROUND_BY_CRITERIA_ENUM = [
    `As per ${accountType?.toLowerCase() === BUYER?.toLowerCase() ? BUYER : SUPPLIER} Call / Credit Report`,
  ];

  const [editBackground] = useEditMapRiskParametersMutation();
  const { data: mapRiskParams, isFetching: isFetchingMapRiskParams } =
    useGetMapRiskParametersQuery(
      {
        map_name: id ?? "",
        account_type: accountType as "supplier" | "buyer",
        item_type,
      },
      { skip: !id }
    );

  const backgroundPerCriteria = useTradeTableValueGenerator(
    BACKGROUND_BY_CRITERIA_ENUM,
    mapRiskParams,
    companyBackgroundTemplate?.template,
    isFetchingMapRiskParams
  );

  const initialValues: CompanyBackgroundProps = {
    background_extra_info: accountComments?.background_extra_info ?? "",
    background: backgroundPerCriteria,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={console.log}
      enableReinitialize
    >
      {(formikProps) => {
        const { background_extra_info, background } = formikProps.values;
        return (
          <Form>
            <Stack spacing={2}>
              <Stack spacing={3}>
                <Summary
                  title={`Company Summary`}
                  subject={accountType}
                  data={
                    summariesData
                      ? summariesData?.[accountType as "supplier" | "buyer"]
                      : {}
                  }
                  readonly={false}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "1em",
                    p: 2,
                    bgcolor: theme.palette.primary.main,
                    borderRadius: 1,
                    color: theme.palette.background.default,
                  }}
                >
                  Company Background
                </Typography>
                <VariableInput
                  sx={{
                    ".MuiInputBase-root": {
                      height: "15vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "13vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  richText
                  labelClassName={styles.labelClass}
                  name="background_extra_info"
                  placeholder="Enter your observations here..."
                  label=""
                  handleSave={(value) => {
                    id &&
                      editComments({
                        map_name: id,
                        account_type: accountType as "buyer" | "supplier",
                        data: {
                          background_extra_info: value as string,
                        },
                      });
                  }}
                  value={background_extra_info ?? ""}
                  multiline
                  style={{ width: "100%" }}
                  fullWidth
                  disabled={disabled}
                  noHover={disabled}
                  {...formikProps}
                />
              </Stack>

              {!mapData?.ipl_product_id && (
                <Alert severity="error">
                  <AlertTitle>Action Required</AlertTitle>
                  Please select a product category to view Company Background
                  input fields
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setIsOpen(true)}
                  >
                    Take me there
                  </Button>
                </Alert>
              )}

              {mapData?.ipl_product_id && (
                <Table component={Paper}>
                  <TableHead
                    sx={{
                      borderBottom: `3px solid ${theme.palette.error.main}`,
                    }}
                  >
                    <TableRow>
                      <TableCell></TableCell>
                      {BACKGROUND_BY_CRITERIA_ENUM?.map((criteria) => (
                        <TableCell>
                          <Typography fontSize="1em" fontWeight="bold">
                            {criteria}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching && !companyBackgroundTemplate && (
                      <SkeletonLoad bars={10} />
                    )}
                    {!isFetching &&
                      companyBackgroundTemplate?.template &&
                      Object.entries(companyBackgroundTemplate?.template).map(
                        ([key, keyParams]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography fontSize="1em" fontWeight="bold">
                                {key}
                              </Typography>
                            </TableCell>
                            {Object.entries(background).map(
                              ([asPerCategory, observation]) => {
                                const fieldName = `background.[${asPerCategory}].${toCamelCase(key)}`;
                                return (
                                  <TableCell key={asPerCategory}>
                                    {keyParams.type === "formula" ? (
                                      <LabelViewOnly label="formula" />
                                    ) : (
                                      <VariableInput
                                        name={fieldName}
                                        label={undefined}
                                        value={
                                          observation[toCamelCase(key)] as any
                                        }
                                        type={keyParams?.type as InputType}
                                        handleSave={(value) => {
                                          id &&
                                            editBackground({
                                              map_name: id,
                                              account_type: accountType as
                                                | "buyer"
                                                | "supplier",
                                              data: {
                                                map_metadata_id: keyParams?.id,
                                                source: asPerCategory,
                                                value,
                                              },
                                            });
                                        }}
                                        disabled={disabled}
                                        noHover={disabled}
                                        {...formikProps}
                                      />
                                    )}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CompanyBackground;
