import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import VariableDate from "components/Common/VariableDate";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";
import TaxInvoicePreview from "../Preview";

import { useGetCountriesQuery } from "api/api-misc";
import VariableDropdown from "components/Common/VariableDropdown";
import toCamelCase from "helpers/camelCase";
import valueCleaner from "helpers/valueCleaner";

interface TaxInvoiceFees {
  amount_exclude_gst: number;
  description: string;
  quantity: number;
  unit_price: number;
}

interface Payee {
  customer_name: string;
  customer_address: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}
interface GeneratedInvoice {
  id: string;
  sf_invoice_name: string;
  sf_tax_invoice_id: string;
  invoice_reference_number: string;
  internal_name: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  total_amount: number;
  gst_amount: number;
  balance_due: number;
  tax_invoice_type: string;
  transaction_type: string;
  invoice_date: Date;
  customer: Payee;
  supplier_invoice_reference_number: string;
  tax_invoice_fees: TaxInvoiceFees[];
}

const ViewGeneratedTaxInvoice: React.FC<{
  taxInvoice: GeneratedInvoice;
}> = ({ taxInvoice }) => {
  const { data: countries } = useGetCountriesQuery();

  const print = () => {
    const printContents = document.getElementById("tax-invoice")?.innerHTML;
    if (!printContents) return;

    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    const printStyle = `
    <style>
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          background-color: #ededed;
        }
      }
    </style>
  `;

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      // Copy current head and body content to the iframe
      doc.write(`
        <html>
          <head>
            <title>Tax Invoice - ${taxInvoice.sf_tax_invoice_id}</title>
            ${document.head.innerHTML}
            ${printStyle}
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
      doc.close();

      // Ensure iframe's window has the styles applied
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();
    }

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  const downloadDivAsPdf = async () => {
    const div = document.getElementById("tax-invoice");

    if (div) {
      try {
        const canvas = await html2canvas(div, {
          scale: 5, // Increase the scale to get better quality
        });
        const imgData = canvas.toDataURL("image/jpeg", 1);

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        });
        const imgWidth = 595.28; // A4 width in pt
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          0,
          imgWidth,
          imgHeight,
          "tax-invoice",
          "NONE"
        );
        pdf.save(`Tax Invoice - ${taxInvoice.invoice_reference_number}.pdf`);
      } catch (error) {
        console.error("Failed to generate PDF", error);
      }
    }
  };

  const [shrinkFactor, setShrinkFactor] = useState(1);
  const adjustGridWidth = () => {
    return 10 - shrinkFactor * 10;
  };

  return (
    <Formik
      initialValues={{ ...taxInvoice, clientDetails: taxInvoice.customer }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        const totalAmount = formikProps.values.total_amount;
        //   formikProps.values.tax_invoice_fees?.reduce((acc, fee) => {
        //     return acc + fee.unit_price * fee.quantity;
        //   }, 0) - formikProps.values.paidAmount;
        const invoiceAmt = totalAmount;
        const invoiceAmtSgdEquiv =
          invoiceAmt * formikProps.values.exchange_rate;
        const gstAmount =
          totalAmount * (formikProps.values.tax_rate_percentage / 100);
        const totalAmountInclGst = totalAmount + gstAmount;
        const totalAmountSgdEquiv =
          totalAmount * formikProps.values.exchange_rate;
        const gstAmountSgdEquiv = gstAmount * formikProps.values.exchange_rate;
        const totalAmountInclGstSgdEquiv =
          totalAmountInclGst * formikProps.values.exchange_rate;

        return (
          <Form>
            <Stack spacing={2}>
              <MainCard
                title={
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <Typography variant="h3">
                        {`Tax Invoice ID: ${formikProps.values.internal_name}`}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                content
                contentSX={{
                  overflow: "auto",
                }}
                children={
                  <Grid
                    container
                    spacing={2}
                    alignItems="top"
                    sx={{
                      bgcolor: "background.default",
                      minHeight: "700pt",
                      borderRadius: 1,
                      pb: 2,
                    }}
                  >
                    <Grid item xs={12} lg={4 + adjustGridWidth()}>
                      <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="invoice-content"
                              id="invoice-header"
                              sx={{
                                bgcolor: "background.default",
                                borderRadius: 1,
                                boxShadow: 1,
                                fontWeight: 700,
                              }}
                            >
                              Invoice Details
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item lg={12} xs={12}>
                                <VariableDate
                                  name="invoice_date"
                                  value={formikProps.values.invoice_date}
                                  label="Invoice Date"
                                  setFieldValue={(field, value) => {
                                    return formikProps.setFieldValue(
                                      field,
                                      value
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                <VariableInput
                                  name="invoice_reference_number"
                                  value={
                                    formikProps.values.invoice_reference_number
                                  }
                                  label="Invoice Reference Number"
                                  handleSave={(value) => {
                                    formikProps.setFieldValue(
                                      "invoice_reference_number",
                                      value
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                <VariableInput
                                  name="supplier_invoice_reference_number"
                                  value={
                                    formikProps.values
                                      ?.supplier_invoice_reference_number
                                  }
                                  label="Supplier Invoice Reference Number"
                                  handleSave={(value) => {
                                    formikProps.setFieldValue(
                                      "supplier_invoice_reference_number",
                                      value
                                    );
                                  }}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="fees-content"
                              id="fees-header"
                              sx={{
                                bgcolor: "background.default",
                                borderRadius: 1,
                                boxShadow: 1,
                                fontWeight: 700,
                              }}
                            >
                              Fees Breakdown
                            </AccordionSummary>
                            <AccordionDetails>
                              {formikProps.values?.tax_invoice_fees?.map(
                                (fee, index) => (
                                  <Grid
                                    container
                                    key={toCamelCase(
                                      fee.description?.substring(0, 10)
                                    )}
                                    spacing={1}
                                    sx={{
                                      bgcolor: "background.default",
                                      borderRadius: 1,
                                      my: 1,
                                      boxShadow: 1,
                                      p: 1,
                                    }}
                                  >
                                    <Grid item lg={12} xs={12}>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                        }}
                                      >
                                        Item {index + 1}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                      <Divider />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                      <VariableInput
                                        name={`tax_invoice_fees[${index}].description`}
                                        value={fee.description}
                                        label="Description"
                                        handleSave={(value) => {
                                          formikProps.setFieldValue(
                                            `tax_invoice_fees[${index}].description`,
                                            value
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                      {" "}
                                      <VariableInput
                                        name={`tax_invoice_fees[${index}].quantity`}
                                        value={fee.quantity}
                                        label="Quantity"
                                        type="number"
                                        handleSave={(value) => {
                                          formikProps.setFieldValue(
                                            `tax_invoice_fees[${index}].quantity`,
                                            value
                                          );
                                          formikProps.setFieldValue(
                                            `tax_invoice_fees[${index}].total_amount`,
                                            valueCleaner(
                                              `${fee.unit_price * (value as number)}`
                                            )
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                      <VariableInput
                                        name={`tax_invoice_fees[${index}].unit_price`}
                                        value={fee.unit_price}
                                        type="money"
                                        label="Unit Price"
                                        handleSave={(value) => {
                                          formikProps.setFieldValue(
                                            `tax_invoice_fees[${index}].unit_price`,
                                            valueCleaner(`${value}`)
                                          );
                                          formikProps.setFieldValue(
                                            `tax_invoice_fees[${index}].total_amount`,
                                            valueCleaner(
                                              `${fee.quantity * valueCleaner(`${value}`)}`
                                            )
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                      <Divider />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                      <Typography
                                        fontWeight={700}
                                        textAlign="end"
                                      >
                                        Total Amount (Excl GST)
                                      </Typography>
                                      <Typography textAlign="end">
                                        {currencyFormatter({
                                          amount: fee.unit_price * fee.quantity,
                                          currency: "USD",
                                          decimalPlace: 2,
                                        })}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="fees-content"
                              id="fees-header"
                              sx={{
                                bgcolor: "background.default",
                                borderRadius: 1,
                                boxShadow: 1,
                                fontWeight: 700,
                              }}
                            >
                              Client Details
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={1}>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.customer_name"
                                    value={
                                      formikProps.values.customer?.customer_name
                                    }
                                    label="Name"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "customer.customer_name",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.customer_address"
                                    value={
                                      formikProps.values.customer
                                        ?.customer_address
                                    }
                                    label="Address"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "customer.customer_address",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.city"
                                    value={formikProps.values.customer?.city}
                                    label="City"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "customer.city",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.postal_code"
                                    value={
                                      formikProps.values.customer?.postal_code
                                    }
                                    label="Postal Code"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "customer.postal_code",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  {countries && (
                                    <VariableDropdown
                                      data={countries}
                                      keyValue="name"
                                      name="customer.country"
                                      value={
                                        formikProps.values.customer?.country
                                      }
                                      label="Country"
                                      setValue={(value) => {
                                        formikProps.setFieldValue(
                                          "customer.country",
                                          value
                                        );
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.contact_name"
                                    value={
                                      formikProps.values.customer?.contact_name
                                    }
                                    label="Contact Name"
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.contact_email"
                                    value={
                                      formikProps.values.customer?.contact_email
                                    }
                                    label="Contact Email"
                                    type="email"
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.contact_phone_number"
                                    value={
                                      formikProps.values.customer
                                        ?.contact_phone_number
                                    }
                                    label="Contact Phone Number"
                                    type="phone"
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="customer.alternative_email"
                                    value={
                                      formikProps.values.customer
                                        ?.alternative_email
                                    }
                                    type="email"
                                    label="Alternative Email"
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Divider>Transaction Particulars</Divider>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="transaction_type"
                            value={formikProps.values.transaction_type}
                            label="Transaction Type"
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="currency"
                            value={formikProps.values.currency}
                            label="Currency"
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="tax_rate_percentage"
                            value={formikProps.values.tax_rate_percentage}
                            label="Tax Rate %"
                            type="number"
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="exchange_rate"
                            value={formikProps.values.exchange_rate}
                            label="Exchange Rate"
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="invoice_amount"
                            value={invoiceAmt?.toFixed(2)}
                            label="Invoice Amt (USD)"
                            type="money"
                            disabled
                            noHover
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <VariableInput
                            name="invoice_amount_sgd_equiv"
                            value={invoiceAmtSgdEquiv?.toFixed(2)}
                            label="Invoice Amt (SGD)"
                            type="money"
                            currency="SGD"
                            disabled
                            noHover
                          />
                        </Grid>
                        {/* <Grid item lg={12} xs={12}>
                          <Divider>Overrides</Divider>
                        </Grid> */}
                        {/* <Grid item lg={12} xs={12}>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            alignItems={"center"}
                          >
                            <Typography fontWeight={700}>
                              Paid Amount (USD)
                            </Typography>
                            <Input
                              value={formikProps.values.paidAmount}
                              type="money"
                              name={"paidAmount"}
                              label={undefined}
                              onChange={(e) => {
                                formikProps.setFieldValue(
                                  "paidAmount",
                                  e.target.value
                                );
                                formikProps.setFieldValue(
                                  "balanceDue",
                                  totalAmountInclGst -
                                    valueCleaner(`${e.target.value}`)
                                );
                              }}
                            />
                          </Stack>
                        </Grid> */}
                        <Grid item lg={12} xs={12}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography fontWeight={700}>
                              Balance Due
                            </Typography>
                            <VariableInput
                              value={formikProps.values.balance_due}
                              type="money"
                              name="balance_due"
                              label={undefined}
                              disabled
                              noHover
                            />
                          </Stack>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <Divider>Total</Divider>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Typography
                            fontWeight={600}
                            sx={{ mb: 1 }}
                            variant="h4"
                            textAlign="right"
                          >
                            Total Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.9rem",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormatter({
                              amount: totalAmount,
                              currency: "USD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.9rem",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormatter({
                              amount: totalAmountSgdEquiv,
                              currency: "SGD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Typography
                            fontWeight={600}
                            sx={{ mb: 0.5 }}
                            variant="h5"
                            textAlign="right"
                          >
                            GST Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "0.8rem",
                            }}
                            textAlign="right"
                          >
                            {currencyFormatter({
                              amount: gstAmount,
                              currency: "USD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "0.8rem",
                            }}
                            textAlign="right"
                          >
                            {currencyFormatter({
                              amount: gstAmountSgdEquiv,
                              currency: "SGD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Divider>Grand Total</Divider>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Typography
                            fontWeight={600}
                            sx={{ mb: 1 }}
                            variant="h4"
                            textAlign="right"
                          >
                            Total Amount (Incl GST)
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.9rem",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormatter({
                              amount: totalAmountInclGst,
                              currency: "USD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.9rem",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormatter({
                              amount: totalAmountInclGstSgdEquiv,
                              currency: "SGD",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={0.2}>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ height: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={10} lg={7.7 - adjustGridWidth()}>
                      <TaxInvoicePreview
                        invoice={formikProps.values as any}
                        invoiceId={formikProps.values.sf_invoice_name}
                        paidAmount={0}
                        balanceDue={formikProps.values.balance_due}
                        shrinkFactor={shrinkFactor}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ViewGeneratedTaxInvoice;
