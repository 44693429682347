import { Typography, useTheme } from "@mui/material";
import { BILLING_STATUSES } from "codes";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

const StatusDisplay = ({
  status,
  style,
}: {
  status: string;
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { PAID, PAYMENT_PENDING, EXPIRED } = BILLING_STATUSES;

  const FINAL_STATUS: Record<string, any> = {
    [EXPIRED]: {
      value: `${t("credit-limit-rejected")}`,
      background: "red",
    },
    [PAYMENT_PENDING]: {
      value: "Pending",
      background: "orange",
    },
    [PAID]: {
      value: `${t("credit-limit-approved")}`,
      background: "#9FCE63",
    },
  };

  return (
    <Typography
      style={{
        background: FINAL_STATUS[status]?.background ?? "orange",
        padding: "6px 16px",
        borderRadius: theme.shape.borderRadius * 2.5,
        color: "white",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
        cursor: "not-allowed",
        fontSize: "0.8em",
        ...style,
      }}
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
      }}
    >
      {FINAL_STATUS[status]?.value ?? "Pending"}
    </Typography>
  );
};
export default StatusDisplay;
