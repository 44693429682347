import { Typography, useTheme, type SxProps } from "@mui/material";

import React from "react";
import type { OverridableStringUnion } from "types";

type INVITE_STATUS = OverridableStringUnion<
  "PENDING" | "ACCEPTED" | "REJECTED"
>;
interface InviteStatusIndicationProps {
  status: INVITE_STATUS;
  sx?: SxProps;
}

const InviteStatusIndication: React.FC<InviteStatusIndicationProps> = ({
  status,
  sx,
}) => {
  const FINAL_STATUS: Record<string, any> = {
    PENDING: {
      value: "Pending",
      background: "orange",
    },
    ACCEPTED: {
      value: "Accepted",
      background: "#9FCE63",
    },
    REJECTED: {
      value: "Rejected",
      background: "red",
    },
  };
  const theme = useTheme();
  return (
    <Typography
      sx={{
        background: FINAL_STATUS[status]?.background ?? "orange",
        padding: "6px 16px",
        borderRadius: theme.shape.borderRadius * 2.5,
        color: "white.main",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
        cursor: "not-allowed",
        fontSize: "0.8em",
        ...sx,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
      }}
    >
      {FINAL_STATUS[status]?.value ?? "Pending"}
    </Typography>
  );
};

export default InviteStatusIndication;
