import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { useEditCreditLimitMutation } from "api/api-origination";
import MainCard from "components/Common/Cards/MainCard";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { pxToRem } from "helpers/pxToRem";
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import type { CLDetails } from "types";
import styles from "../cl.module.scss";

interface CLDecisionObject {
  salesId: string;
  disabled: boolean;
}

const CLObject: React.FC<CLDecisionObject> = ({ salesId, disabled }) => {
  const formikProps = useFormikContext<CLDetails>();
  const [editCLDetails, { isLoading }] = useEditCreditLimitMutation();

  const { dirty, values } = formikProps;

  const { id, sf_link, currency, requestedBy } = values;

  return (
    <MainCard
      sx={{
        minWidth: pxToRem(330),
        width: "100%",
      }}
      headerSx={{
        px: 2,
        py: 2,
      }}
      title={
        <Typography
          fontWeight="bold"
          fontSize={isMobile ? "1.1em" : "1.3em"}
          variant="h3"
        >
          Credit Limit Decision
        </Typography>
      }
      footer={
        <>
          <Grid
            item
            lg={2}
            xs={12}
            alignItems="centre"
            justifyContent="flex-end"
            ml="auto"
          >
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isLoading}
              onClick={() => {
                if (!disabled) {
                  const credit_limit_request_internal_amount = Number(
                    `${formikProps.values.credit_limit_request_internal_amount}`.replaceAll(
                      ",",
                      ""
                    )
                  );
                  salesId &&
                    editCLDetails({
                      id,
                      status: formikProps.values.clStatus,
                      comment: formikProps.values.comment,
                      sales_lead: salesId,
                      credit_limit_request_internal_amount,
                    })
                      .unwrap()
                      .then(() => {
                        toast.success("Credit Limit Details Updated");
                        formikProps.resetForm({ values });
                      })
                      .catch((error) => {
                        const errorFile = error?.message;
                        toast(`Something went wrong : ${errorFile}`, {
                          type: "error",
                        });
                      });
                }
              }}
              disabled={!dirty}
            >
              Save
            </LoadingButton>
          </Grid>
        </>
      }
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="bold">Decision Date:</Typography>
          <Typography>
            {values.decision_at
              ? (moment(values.decision_at)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss") as unknown as string)
              : "Not Available"}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="bold">Decision by:</Typography>
          <Typography>{values.decision_by as unknown as string}</Typography>
        </Stack>

        <Input
          labelClassName={styles.labelClass}
          name="credit_limit_request_internal_amount"
          label="Approved Amount"
          disabled={formikProps.values.clStatus === "CL_APPROVED"}
          placeholder="Approved Amount"
          textfieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  {currency ?? "USD"}
                </InputAdornment>
              ),
            },
          }}
          type="money"
          style={{ width: "100%" }}
        />
      </Stack>
    </MainCard>
  );
};

export default CLObject;
