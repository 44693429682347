import { EditNote, Email, Settings } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ConstructionIcon from "@mui/icons-material/Construction";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import GroupIcon from "@mui/icons-material/Group";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import MapIcon from "@mui/icons-material/Map";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ScienceIcon from "@mui/icons-material/Science";
import TimelineIcon from "@mui/icons-material/Timeline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import CRMFunctionsIcon from "assets/navigationIcons/crm-functions.svg?react";
import InvoiceStatusIcon from "assets/navigationIcons/invoice-status.svg?react";
import LeadsTableIcon from "assets/navigationIcons/leads-table.svg?react";
import LeadsIcon from "assets/navigationIcons/leads.svg?react";
import LeavesIcon from "assets/navigationIcons/leaves.svg?react";
import OrgChart from "assets/navigationIcons/org-chart.svg?react";
import SOFR from "assets/navigationIcons/sofr.svg?react";
import SupportTicket from "assets/navigationIcons/ticket-support.svg?react";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import {
  ACCOUNTS,
  BALANCES_LINK,
  BILLINGS_LINK,
  CAMPAIGN_TESTING,
  CREDIT_LIMITS_LINK,
  EMAILS_LINK,
  FUNDER_LINK,
  INCOMLEND_INTERNAL_LINK,
  IT_LINK,
  LEADS,
  LEADS_TABLE,
  LEGAL_LINK,
  MAPS,
  ORG_CHART_LINK,
  SOFR_LINK,
  SUPPORT,
  TAX_INVOICES_LINK,
  TERMSHEETS_LINK,
} from "codes";
import { VITE_EXPERIMENTAL_TERMSHEET } from "experimental_features";
import { PERMISSIONS } from "permissions";
import { menuStyling as styling } from "styles/theme";

interface Item {
  title: string;
  icon: any;
  activeColor: string;
  iconActiveColor: string;
  iconInactiveColor: string;
  circularBackground: boolean;
  link: string;
  match?: (x: string) => boolean;
  items?: Item[];
  permission?: string;
}

export default [
  {
    title: "Dashboard",
    icon: AssessmentIcon,
    link: `${INCOMLEND_INTERNAL_LINK}`,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}`,
    ...styling,
    permission: PERMISSIONS.show_dashboard,
  },
  {
    title: "Tasks",
    icon: SupportTicket,
    link: `${INCOMLEND_INTERNAL_LINK}${SUPPORT}`,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${SUPPORT}`,
    ...styling,
    permission: PERMISSIONS.show_support_tickets,
  },
  {
    title: "CRM Functions",
    icon: CRMFunctionsIcon,
    link: ``,
    match: (path) => path === "",
    ...styling,

    items: [
      {
        title: "Credit Limits",
        icon: CreditLimitRequest,
        link: `${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`,
        match: (path) =>
          path === `${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}` ||
          path.startsWith(`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}/`),
        ...styling,
        permission: PERMISSIONS.show_credit_limits,
      },
      {
        title: "Accounts",
        icon: GroupIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}`,
        ...styling,
        permission: PERMISSIONS.show_accounts,
      },
      {
        title: "Termsheets",
        icon: EditNote,
        link: `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}`,
        ...styling,
        permission: PERMISSIONS.show_termsheets,
      },
      {
        title: "Billings",
        icon: AccountBalanceWalletIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${BILLINGS_LINK}`,
        ...styling,
        permission: PERMISSIONS.show_billings,
      },
      {
        title: "Leads",
        icon: LeadsIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${LEADS}`,
        ...styling,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${LEADS}`,
        permission: PERMISSIONS.show_leads,
      },
      {
        title: "Maps",
        icon: MapIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${MAPS}`,
        ...styling,

        permission: PERMISSIONS.show_maps,
      },
      {
        title: "Users",
        ...styling,
        icon: LeadsTableIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${LEADS_TABLE}`,
        ...styling,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${LEADS_TABLE}`,
        permission: PERMISSIONS.show_maps,
      },
      {
        title: "Countries",
        icon: FlagCircleIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}`,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}`,
        ...styling,

        permission: PERMISSIONS.show_countries,
      },
      {
        title: "Balances",
        icon: PriceChangeIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${BALANCES_LINK}`,
        ...styling,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${BALANCES_LINK}`,
        permission: PERMISSIONS.show_balances,
      },

      {
        title: "Tax Invoices",
        icon: RequestPageIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${TAX_INVOICES_LINK}`,
        ...styling,
        match: (path) =>
          path === `${INCOMLEND_INTERNAL_LINK}${TAX_INVOICES_LINK}`,
        permission: PERMISSIONS.show_tax_invoices,
      },
      {
        title: "Emails",
        icon: Email,
        link: `${INCOMLEND_INTERNAL_LINK}${EMAILS_LINK}`,
        ...styling,
        permission: PERMISSIONS.show_emails,
      },
    ],
  },
  {
    title: "CRM Reports",
    icon: AssessmentIcon,
    match: (path) => path === "",
    link: ``,
    ...styling,

    items: [
      {
        title: "Opportunities Pipeline",
        icon: AssessmentIcon,
        link: `${INCOMLEND_INTERNAL_LINK}/onboarding-statuses`,
        match: (path) =>
          path === `${INCOMLEND_INTERNAL_LINK}/onboarding-statuses`,
        ...styling,

        permission: PERMISSIONS.show_statuses,
      },
      {
        title: "Onboarding Pipeline",
        icon: TimelineIcon,
        link: `${INCOMLEND_INTERNAL_LINK}/onboardings`,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}/onboardings`,
        ...styling,

        permission: PERMISSIONS.show_onboardings,
      },
      {
        title: "Invoice Statuses",
        icon: InvoiceStatusIcon,
        link: `${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}`,
        ...styling,
        permission: PERMISSIONS.show_invoice_statuses,
      },
      {
        title: "Platform Usage",
        icon: DataUsageIcon,
        link: `${INCOMLEND_INTERNAL_LINK}/platform-usage`,
        ...styling,
        permission: PERMISSIONS.show_platform_usage,
      },
      {
        title: "Credit Limit Pipelines",
        icon: AssessmentIcon,
        link: `${INCOMLEND_INTERNAL_LINK}/credit-limits-opportunities`,
        match: (path) =>
          path === `${INCOMLEND_INTERNAL_LINK}/credit-limits-opportunities`,
        ...styling,
        permission: PERMISSIONS.show_cl_report,
      },
      {
        title: "SOFR Rates",
        icon: SOFR,
        link: `${INCOMLEND_INTERNAL_LINK}${SOFR_LINK}`,
        ...styling,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${SOFR_LINK}`,
        permission: PERMISSIONS.show_balances,
      },
    ],
  },
  {
    title: "Campaign Testing",
    icon: ScienceIcon,
    link: `${INCOMLEND_INTERNAL_LINK}${CAMPAIGN_TESTING}`,
    ...styling,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${CAMPAIGN_TESTING}`,
    permission: PERMISSIONS.show_campaign_testing,
  },
  {
    title: "Leaves",
    icon: LeavesIcon,
    link: `${INCOMLEND_INTERNAL_LINK}/leaves`,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}/leaves`,
    ...styling,
    permission: PERMISSIONS.show_leaves,
  },
  {
    title: "Organization Chart",
    icon: OrgChart,
    link: `${INCOMLEND_INTERNAL_LINK}${ORG_CHART_LINK}`,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${ORG_CHART_LINK}`,
    ...styling,

    permission: PERMISSIONS.show_organization_chart,
  },
  {
    title: "User Management",
    icon: ConstructionIcon,
    link: `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management`,
    ...styling,
    permission: PERMISSIONS.show_user_management,
  },
  {
    title: "Permissions",
    icon: VpnKeyIcon,
    link: `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/groups`,
    ...styling,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/groups`,
    permission: PERMISSIONS.show_user_management,
  },
  // {
  //   title: "Experimental Features",
  //   icon: ScienceIcon,
  //   link: `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/experimental-features`,
  //   ...styling,
  //   match : (path) => path === link,
  //   permission: PERMISSIONS.show_user_management,
  // },
  {
    title: "How-To Guides",
    icon: MenuBookIcon,
    link: `${INCOMLEND_INTERNAL_LINK}/guides`,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}/guides`,
    ...styling,
    permission: PERMISSIONS.show_guides,
  },
  VITE_EXPERIMENTAL_TERMSHEET
    ? {
        title: "Configuration",
        icon: Settings,
        link: `${INCOMLEND_INTERNAL_LINK}/configuration`,
        ...styling,
        match: (path) => path === `${INCOMLEND_INTERNAL_LINK}/configuration`,
        permission: PERMISSIONS.show_dashboard,
      }
    : {},
  {
    title: "Scripts",
    icon: HistoryEduIcon,
    link: `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/scripts`,
    ...styling,
    match: (path) => path === `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/scripts`,
    permission: PERMISSIONS.show_scripts,
  },
] as Item[];
