import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import useIsMobile from "hooks/useIsMobile";
import * as React from "react";

import Header from "./Header";
import ProfileSidebar from "./ProfileSidebar";
import Sidebar from "./Sidebar";

const drawerWidth: number = 260;

const getActiveDrawer = (openMenu: boolean, openProfile: boolean) => {
  const openMenuMultiplier = openMenu ? 1 : 0;
  const openProfileMultiplier = openProfile ? 1 : 0;
  return openMenuMultiplier + openProfileMultiplier;
};

const Main = styled("main", {
  shouldForwardProp: (prop) =>
    prop !== "openMenu" && prop !== "theme" && prop !== "openProfile",
})<{ openMenu: boolean; openProfile: boolean }>(
  ({ theme, openMenu, openProfile }) => ({
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    minHeight: "calc(100vh - 88px)",
    flexGrow: 1,
    padding: "20px",
    marginTop: "88px",
    marginRight: "20px",
    marginLeft: openProfile ? "20px" : 0,
    borderRadius: "12px",
    transition: theme.transitions.create(
      "margin",
      openMenu || openProfile
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    ),
    zIndex: 1099, // Set a higher z-index than the drawer
    [theme.breakpoints.up("md")]: {
      marginLeft: openMenu ? 0 : -(drawerWidth - 20),
      marginRight: openProfile ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth * getActiveDrawer(openMenu, openProfile) + 20 * getActiveDrawer(openMenu, openProfile)}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  })
);
export default function Dashboard({
  children,
  mastheader,
}: {
  children: React.ReactNode;
  mastheader?: React.ReactNode;
}) {
  const isMobile = useIsMobile();
  const [openMenu, setOpenMenu] = React.useState(!isMobile);
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleLeftDrawerToggle = () => {
    setOpenMenu(!openMenu);
  };
  const handleRightDrawerToggle = () => {
    setOpenProfile(!openProfile);
  };
  const theme = useTheme();

  return (
    <Box
      sx={{ display: "flex", bgcolor: theme.palette.background.default }}
      data-testid="dashboard-bounding-box"
    >
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: openMenu ? theme.transitions.create("width") : "none",
        }}
        data-testid="app-bar"
      >
        <Toolbar sx={{ bgcolor: theme.palette.background.default }}>
          <Header
            drawerOpen={openMenu}
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            profileOpen={openProfile}
            handleRightDrawerToggle={handleRightDrawerToggle}
            data-testid="header"
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={openMenu}
        drawerToggle={handleLeftDrawerToggle}
        data-testid="sidebar"
      />

      {/* main content */}
      <Main
        theme={theme}
        openMenu={openMenu}
        openProfile={openProfile}
        data-testid="main-content"
      >
        <Box
          sx={{
            bgcolor: theme.palette.background.default,
            p: { lg: 5, xs: 1 },
            pt: { lg: 1 },
            borderRadius: "12px",
          }}
        >
          {children}
        </Box>
      </Main>
      {/* drawer */}
      <ProfileSidebar
        drawerOpen={openProfile}
        drawerToggle={handleRightDrawerToggle}
        data-testid="profile-sidebar"
      />
    </Box>
  );
}
