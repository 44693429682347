import { io, Socket } from "socket.io-client";

interface ISocketService {
  connect(token: string): Socket | null;
  disconnect(): void;
  send(event: string, data: any): void;
  getSocket(): Socket | null;
}

type SocketServiceInstance = {
  socket: Socket | null;
};

const SocketService: ISocketService & SocketServiceInstance = {
  socket: null,

  connect(token: string): Socket | null {
    if (this.socket?.connected) {
      return this.socket;
    }

    this.disconnect();

    this.socket = io(import.meta.env.VITE_WSS_URL, {
      path: "/socket.io/",
      query: {
        token,
        EIO: 4,
        transport: "websocket",
      },
      transports: ["websocket"],
      forceNew: false,
      reconnection: true,
      reconnectionAttempts: 1,
      reconnectionDelay: 5000,
      timeout: 5000,
    });

    this.socket.on("connect", () => {
      console.log("Socket connected");
    });

    this.socket.on("disconnect", (reason: string) => {
      console.log("Socket disconnected:", reason);
    });

    this.socket.on("connect_error", (error: Error) => {
      console.error("Connection error:", error);
    });

    return this.socket;
  },

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },

  send(event: string, data: any): void {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  },

  getSocket(): Socket | null {
    return this.socket;
  },
};

export default SocketService;
