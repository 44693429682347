import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { SupportTicket, TicketActivityLog, SupportProject, SupportSprint, SprintListResponse } from "types";

interface Ticket {
  title: string;
  content: string;
  priority_code_id?: string;
  assignee_department_id: string;
  estimate_min?: string;
  assignee?: string;
  parent_id?: string;
  project_id: string;
  sprint_id: string;
}
interface Priority {
  id: string;
  code: string;
  order: number;
}
interface Project {
  name: string;
  description: string;
}

interface Sprint {
  name: string;
  start_date: string;
  end_date: string;
}

export const support = createApi({
  reducerPath: "support",
  tagTypes: [
    "DEPARTMENTS",
    "TICKETS",
    "TICKET_STATUSES",
    "BUSINESS_PRIORITIES",
    "IT_PRIORITIES",
    "PROJECTS",
    "SPRINTS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/support`,
  }),
  endpoints: (builder) => ({
    getDepartmentsAndManagers: builder.query<any, null>({
      query: () => ({
        url: `/departments-and-managers`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DEPARTMENTS"],
    }),
    getTickets: builder.query<
      { [status: string]: SupportTicket[] },
      {departments: string[] | null, sprint:string | null }
    >({
      query: ({departments, sprint}) => {
        const params = [];
        if (departments) {
          params.push(
            ...departments.map((department) => `department=${department}`)
          );
        }
        if (sprint) {
          params.push(`sprint_id=${sprint}`);
        }
        const parametersStringList =
          params.length > 0 ? `?${params.join("&")}` : "";
        return {
          url: `/tickets${params ? parametersStringList : ""}`,
          method: "get",
        };
      },
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicketByUser: builder.query<SupportTicket, string>({
      query: (user_id) => ({
        url: `/user/${user_id}/tickets`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicket: builder.query<SupportTicket, string>({
      query: (ticket_id) => ({
        url: `/tickets/${ticket_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicketLogs: builder.query<TicketActivityLog[], string>({
      query: (ticket_name) => ({
        url: `/ticket/${ticket_name}/logs`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicketStatuses: builder.query<{ id: string; name: string }[], null>({
      query: () => ({
        url: `/tickets/statuses`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKET_STATUSES"],
    }),
    getBusinessPriorities: builder.query<Priority[], null>({
      query: () => ({
        url: `/tickets/business-priorities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["BUSINESS_PRIORITIES"],
    }),
    getITPriorities: builder.query<Priority[], null>({
      query: () => ({
        url: `/tickets/it-priorities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["IT_PRIORITIES"],
    }),

    getRequestQualities: builder.query<{ name: string }[], null>({
      query: () => ({
        url: `/request-qualities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["BUSINESS_PRIORITIES"],
    }),

    createTicket: builder.mutation<SupportTicket, Ticket>({
      query: (payload) => {
        return {
          url: `/tickets`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    editTicket: builder.mutation<
      any,
      Partial<SupportTicket> & { ticket_id: string }
    >({
      query: ({ ticket_id, ...payload }) => {
        return {
          url: `/tickets/${ticket_id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    addDocumentToTicket: builder.mutation<
      SupportTicket,
      { ticket_id: string; files: File[] }
    >({
      query: ({ ticket_id, files }) => {
        const formData = new FormData();
        files.map((file) => formData.append(`files`, file));
        return {
          url: `/tickets/${ticket_id}/files`,
          method: "POST",
          data: formData,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    deleteDocumentFromTicket: builder.mutation<
      SupportTicket,
      { ticket_id: string; files: string[] }
    >({
      query: ({ ticket_id, files }) => {
        return {
          url: `/tickets/${ticket_id}/files`,
          method: "DELETE",
          data: files,
        };
      },
    }),
    createProject: builder.mutation<SupportProject, Project>({
      query: (payload) => {
        return {
          url: `/projects`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["PROJECTS"],
    }),
    getProjects: builder.query<Pick<SupportProject, "id" | "name">[], null>({
      query: () => ({
        url: `/projects`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["PROJECTS"],
    }),
    createSprint: builder.mutation<SupportSprint, Sprint>({
      query: (payload) => {
        return {
          url: `/sprints`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["SPRINTS"],
    }),
    getSprints: builder.query<SprintListResponse, null>({
      query: () => ({
        url: `/sprints`,
        method: "get",
      }),
      transformResponse: (response) => response,
      providesTags: ["SPRINTS"],
    }),
    getActiveSprint: builder.query<SupportSprint, null>({
      query: () => ({
        url: `/sprints/active`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SPRINTS"],
    }),
  }),
});

export const {
  useAddDocumentToTicketMutation,
  useCreateTicketMutation,
  useDeleteDocumentFromTicketMutation,
  useEditTicketMutation,
  useGetDepartmentsAndManagersQuery,
  useGetTicketByUserQuery,
  useGetTicketQuery,
  useGetTicketStatusesQuery,
  useGetTicketsQuery,
  useGetBusinessPrioritiesQuery,
  useGetRequestQualitiesQuery,
  useGetITPrioritiesQuery,
  useGetTicketLogsQuery,
  useCreateProjectMutation,
  useGetProjectsQuery,
  useCreateSprintMutation,
  useGetSprintsQuery,
  useGetActiveSprintQuery,
  useLazyGetTicketsQuery,
} = support;
