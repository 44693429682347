import {
  DragDropContext,
  type DragStart,
  type DraggableLocation,
  type DropResult,
} from "@hello-pangea/dnd";
import { FormControlLabel, Grid, Stack, styled } from "@mui/material";
import Switch from "components/Common/Switch";
import useWidth from "helpers/useWidth";
import { type HTMLAttributes, useEffect, useRef, useState } from "react";
import KanbanBoardFilter from "./Filters";
import Column from "./column";
import { SELECT_TASK_TYPE, getEntities } from "./data";
import type {
  Column as ColumnProps,
  Entities,
  Id,
  TASK_TYPES,
  Task,
  TaskData,
} from "./types";
import { multiSelectTo as multiSelect, mutliDragAwareReorder } from "./utils";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  userSelect: "none",
  width: "100%",
  overflow: "scroll",
  height: "90vh",
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

type State = {
  entities: Entities;
  selectedTaskIds: Id[];
  draggingTaskId: Id | null;
};
type ContainerProps = {
  main?: HTMLAttributes<HTMLDivElement>;
  column?: HTMLAttributes<HTMLDivElement>;
  task?: HTMLAttributes<HTMLDivElement>;
};
const getTasks = (entities: Entities, columnId: Id): Task[] =>
  entities.columns[columnId].taskIds.map(
    (taskId: Id): Task => entities.tasks[taskId]
  );

const Board = ({
  data,
  type,
  action,
  isDragDisabled = false,
  groupByKey,
  showSummary,
  defaultConsolidate = true,
  containerProps,
  onDragEndAction,
  disableToolbar = false,
  getBoardWidth,
  requiredKeys,
}: {
  data: TaskData<any>[];
  type: TASK_TYPES;
  onDragEndAction?: (column: ColumnProps) => void;
  action?: (taskId: Id) => void;
  isDragDisabled?: boolean;
  groupByKey?: string;
  showSummary?: boolean;
  defaultConsolidate?: boolean;
  containerProps?: ContainerProps;
  disableToolbar?: boolean;
  getBoardWidth?: (width: number) => void;
  requiredKeys?: string[];
}) => {
  const [filteredData, setFilteredData] = useState<typeof data | undefined>(
    data.filter((item) => item.id)
  );

  const [state, setState] = useState<State>({
    entities: getEntities(data, type),
    selectedTaskIds: [],
    draggingTaskId: null,
  });

  const unselectAll = () => {
    setState((prevState) => ({
      ...prevState,
      selectedTaskIds: [],
    }));
  };

  useEffect(() => {
    filteredData &&
      setState((prev) => ({
        ...prev,
        entities: getEntities(filteredData, type),
      }));
  }, [filteredData]);

  useEffect(() => {
    const onWindowClick = (event: MouseEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      unselectAll();
    };

    const onWindowKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === "Escape") {
        unselectAll();
      }
    };

    const onWindowTouchEnd = (event: TouchEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      unselectAll();
    };

    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onWindowKeyDown);
    window.addEventListener("touchend", onWindowTouchEnd);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onWindowKeyDown);
      window.removeEventListener("touchend", onWindowTouchEnd);
    };
  }, []);

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected = state.selectedTaskIds.find(
      (taskId: Id): boolean => taskId === id
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }
    setState((prevState) => ({
      ...prevState,
      draggingTaskId: start.draggableId,
    }));
  };

  const onDragEnd = (result: DropResult) => {
    const destination: DraggableLocation | null = result.destination;
    const source: DraggableLocation = result.source;

    // nothing to do
    if (!destination || result.reason === "CANCEL") {
      setState((prevState) => ({
        ...prevState,
        draggingTaskId: null,
      }));
      return;
    }

    const processed = mutliDragAwareReorder({
      entities: state.entities,
      selectedTaskIds: state.selectedTaskIds,
      source,
      destination,
    });

    setState((prevState) => ({
      ...prevState,
      ...processed,
      draggingTaskId: null,
    }));

    const { columns } = processed.entities;
    const { droppableId: destinationColumnId } = source;
    const resultTask = columns[destinationColumnId];
    //Emit the affected column props
    onDragEndAction && onDragEndAction(resultTask);
  };

  const toggleSelection = (taskId: Id) => {
    const selectedTaskIds: Id[] = state.selectedTaskIds;
    const wasSelected: boolean = selectedTaskIds.includes(taskId);

    const newTaskIds: Id[] = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setState((prevState) => ({
      ...prevState,
      selectedTaskIds: newTaskIds,
    }));
  };

  const toggleSelectionInGroup = (taskId: Id) => {
    const selectedTaskIds: Id[] = state.selectedTaskIds;
    const index: number = selectedTaskIds.indexOf(taskId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setState((prevState) => ({
        ...prevState,
        selectedTaskIds: [...selectedTaskIds, taskId],
      }));
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow: Id[] = [...selectedTaskIds];
    shallow.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      selectedTaskIds: shallow,
    }));
  };

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = (newTaskId: Id) => {
    const updated = multiSelect(
      state.entities,
      state.selectedTaskIds,
      newTaskId
    );

    if (updated == null) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      selectedTaskIds: updated,
    }));
  };

  const entities: Entities = state.entities;
  const selected: Id[] = state.selectedTaskIds;

  const [consolidate, setConsolidate] = useState<boolean>(defaultConsolidate);
  const [showEmpty, setShowEmpty] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref, 500);

  useEffect(() => {
    if (getBoardWidth && ref) getBoardWidth(width);
  }, []);

  return (
    <Stack>
      {!disableToolbar && (
        <Grid container justifyContent="end">
          <Grid item xs={12} lg={12}>
            <KanbanBoardFilter
              data={data}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              requiredFilters={requiredKeys}
            />
          </Grid>
          {type === SELECT_TASK_TYPE.InvoiceStatus && (
            <Grid item xs={12} lg="auto">
              <FormControlLabel
                control={
                  <Switch
                    value={consolidate}
                    onChange={(e, checked: boolean) =>
                      setConsolidate(e.target.checked)
                    }
                  />
                }
                label="Consolidate by Maps"
              />
            </Grid>
          )}
          <Grid item xs={12} lg="auto">
            <FormControlLabel
              control={
                <Switch
                  value={showEmpty}
                  onChange={(e) => {
                    setShowEmpty(e.target.checked);
                    if (e.target.checked)
                      setState({
                        ...state,
                        entities: getEntities(
                          data.filter((item) => item.id),
                          type
                        ),
                      });
                    else
                      setState({
                        ...state,
                        entities: getEntities(data, type),
                      });
                  }}
                />
              }
              label="Hide Empty Columns"
            />
          </Grid>
        </Grid>
      )}
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Container
          id={`kanban-${type}`}
          ref={ref}
          style={{ ...containerProps?.main?.style }}
        >
          {entities.columnOrder.map((columnId: Id) => (
            <Column
              column={entities.columns[columnId]}
              tasks={getTasks(entities, columnId)}
              selectedTaskIds={selected}
              key={columnId}
              draggingTaskId={state.draggingTaskId}
              toggleSelection={toggleSelection}
              toggleSelectionInGroup={toggleSelectionInGroup}
              multiSelectTo={multiSelectTo}
              taskType={type}
              action={action}
              isDragDisabled={isDragDisabled}
              noOfColumns={entities.columnOrder.length}
              groupByKey={groupByKey}
              consolidate={consolidate}
              showSummary={showSummary}
              containerProps={containerProps}
            />
          ))}
        </Container>
      </DragDropContext>
    </Stack>
  );
};

export default Board;
