import type { TFunction } from "i18next";

export function RegistrationDetailsKeyMappings(
  t: TFunction<"translation", undefined>
): Record<string, string> {
  const default_fields: Record<string, any> = {
    name: `${t("company-full-legal-name")}`,
    reg_no: `${t("company-registration-number")}`,
    address_line1: `${t("address")} 1`,
    incorporation_date: `${t("company-incorporation-date")}`,
    city: `${t("city")}`,
    country: `${t("country")}`,
    postcode: `${t("postcode")}`,
  };

  return default_fields;
}
export function AdditionalDetailsKeyMappings(
  isOtherAccountingSoftware: boolean
): Record<string, string> {
  const default_fields: Record<string, string> = {
    accounting_software: "Company Accounting Software",
    annual_revenue: "Annual Revenue",
    number_of_employees: "No. of Employees",
  };
  if (isOtherAccountingSoftware)
    default_fields.other_accounting_software =
      "Please specify your accounting software";
  return default_fields;
}
export function TradeDetailsKeyMappings(): Record<string, string> {
  return {
    average_invoice_amount: "Average Invoice Amount (in USD equivalent)​",
    average_payment_terms: "Average Payment Terms",
  };
}

export function TermSheetInfoKeyMappings(
  isReverseFactoring: boolean
): Record<string, string> {
  const commonFields = {
    setup_fee: "Setup Fee​",
    facility_fee: "Facility Fee",
    customer_maintenance_fee: "Customer Maintenance Fee",
    partner_maintenance_fee: "Partner Maintenance Fee",
  };
  if (isReverseFactoring) {
    return {
      first_invoice_amount: "First Invoice",
      first_invoice_expected_date: "First Invoice Expected Date",
      ...commonFields,
      annualized_discount_rate: "Annualized Discount Rate",
    };
  }
  return {
    repayment_account: "Repayment Account",
    advanced_amount: "Advanced Amount",
    ...commonFields,
  };
}
