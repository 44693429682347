import { Grid, Stack, TableCell, TableRow, useTheme } from "@mui/material";
import { useEditMapRiskParametersMutation } from "api/api-maps";
import { useGetFxRateEndOfMonthQuery } from "api/api-vendorsv2";
import type { InputType } from "components/Common/FormFields/InputField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React from "react";
import FinancialGrowthIndicator from "./FinancialGrowthIndicator";

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}
interface FinancialColumnProps {
  item: [
    string,
    {
      id: string;
      name: string;
      type: string;
      log_id: string;
      format: string;
      data: string | number | string[];
      need_growth_rate?: boolean;
    },
  ];
  mapRiskParams: MapRiskParamValues;
  disabled: boolean;
  accountType: string;
}

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    format: string;
    log_id: string;
    data: string | number | string[];
    need_growth_rate?: boolean;
  };
}

type FINANCIAL_BY_YEAR = {
  [year: string]: MapTemplate;
};

const FinancialColumn: React.FC<FinancialColumnProps> = ({
  item,
  mapRiskParams,
  disabled,
  accountType,
}) => {
  const formikProps = useFormikContext<FINANCIAL_BY_YEAR>();
  const id = React.useContext(MapContext);
  const selectedCurrency = React.useMemo(() => {
    if (mapRiskParams && Object.keys(mapRiskParams).length > 0) {
      return Object.keys(mapRiskParams).map((key) => {
        return key.split("-")[1];
      })[0];
    }
    return "";
  }, [mapRiskParams]);

  const currentYears = React.useMemo(() => {
    if (mapRiskParams && Object.keys(mapRiskParams).length > 0) {
      return Object.keys(mapRiskParams).map((key) => {
        const year_and_currency_str = key.split("-");
        const year_str = year_and_currency_str[0];
        const currency = year_and_currency_str[1];
        return {
          date: moment(year_str, "MM/YYYY").format("YYYY-MM-DD"),
          currency: currency,
          year_currency: key,
        };
      });
    }
    return [];
  }, [mapRiskParams]);

  const [editFinancial] = useEditMapRiskParametersMutation();

  const fxRates = currentYears.map((key) => {
    const { data, isLoading, isError } = useGetFxRateEndOfMonthQuery(
      {
        date: key.date ?? "",
        currency: key.currency,
      },
      { skip: !currentYears.length }
    );
    return { key, data, isLoading, isError };
  });

  const dataFxrate = fxRates.reduce<Record<string, Record<string, any>>>(
    (acc, { key, data }) => {
      if (data) {
        acc[key.year_currency] = { currency: key.currency, rate: data.rate };
      }
      return acc;
    },
    {}
  );

  const getByAuxType = (value: string, auxType: string | undefined) => {
    switch (auxType) {
      case "percentage":
        return `${(Number(value) * 100).toFixed(2)}%`;
      case "money":
        return currencyFormatter({
          amount: Number(value),
          //   currency: value,
        });
      case "decimal":
        return `${Number(value).toFixed(2)}`;
      case "text":
        return value;
      default:
        return value;
    }
  };

  const [key, keyParams] = item;
  const theme = useTheme();
  const CURRENCY_FLAG: Record<string, string> = {
    USD: "🇺🇸",
    EUR: "🇪🇺",
    GBP: "🇬🇧",
    INR: "🇮🇳",
    SGD: "🇸🇬",
    AUD: "🇦🇺",
    HKD: "🇭🇰",
  };

  return (
    <TableRow key={key}>
      <TableCell
        sx={{
          bgcolor: "#a2a6c391",
          borderLeft: `3px solid ${theme.palette.primary.main}`,
          borderRight: `3px solid ${theme.palette.primary.main}`,
        }}
      >
        <LabelViewOnly
          sx={{
            fontWeight: 500,
          }}
          label={key}
          helpText={
            keyParams?.type === "formula"
              ? `This field is calculated using the formula: ${keyParams?.data}`
              : undefined
          }
        />
      </TableCell>
      {Object.keys(formikProps.values).map((year) => {
        const value = (formikProps.values as MapRiskParamValues)?.[year]?.[
          toCamelCase(key)
        ];
        const { type, format } = keyParams;
        const currency = dataFxrate[year]?.currency ?? "USD";
        return (
          <TableCell key={year}>
            <Grid
              container
              xs={12}
              spacing={1}
              sx={{
                bgcolor: "#a2a6c391",
                borderRadius: "4px",
              }}
            >
              <Grid item xs={12} lg={10} m="auto">
                {type === "formula" ? (
                  <LabelViewOnly
                    label={
                      value === undefined ||
                      (typeof value === "string" &&
                        value === "Can not calculate because of no value") ||
                      (typeof value === "string" &&
                        (/\w*Need input for this field\w*/.test(value) ||
                          value === "Can not calculate because of no value"))
                        ? keyParams?.data
                        : `${getByAuxType(value, format)} ${key.includes("Cash Conversion") ? "days" : ""}`
                    }
                  />
                ) : (
                  <Stack spacing={0.5} direction="row">
                    {format === "money" && (
                      <LabelViewOnly label={`${CURRENCY_FLAG[currency]}`} />
                    )}
                    <VariableInput
                      name={`${year}.${toCamelCase(key)}`}
                      label={undefined}
                      disabled={disabled}
                      noHover={disabled}
                      currency={currency}
                      value={
                        (/\w*Need input for this field\w*/.test(value) ||
                          value === "Can not calculate because of no value" ||
                          value === undefined) &&
                        format === "money"
                          ? 0
                          : value
                      }
                      type={format as InputType}
                      handleSave={(value) => {
                        let payload = null;
                        if (type === "currency") {
                          payload = {
                            value: valueCleaner(`${value}`),
                            currency: currency,
                          };
                        } else if (format === "money") {
                          payload = valueCleaner(`${value}`);
                        } else {
                          payload = value;
                        }
                        id &&
                          editFinancial({
                            map_name: id,
                            account_type: accountType as "buyer" | "supplier",
                            data: {
                              map_metadata_id: keyParams?.id,
                              source: year,
                              value: payload,
                            },
                          });
                      }}
                      {...formikProps}
                    />
                  </Stack>
                )}
              </Grid>
              {format === "money" && (
                <Grid item xs={12} sx={{ mb: 1 }} lg={10} m="auto">
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    sx={{
                      bgcolor: "#a2a6c391",
                      borderRadius: "4px",
                      border: `1px dashed ${theme.palette.primary.main}`,
                      p: 1,
                    }}
                  >
                    <LabelViewOnly label={`🇺🇸`} />
                    <LabelViewOnly
                      label={`${currencyFormatter({
                        amount:
                          /\w*Need input for this field\w*/.test(value) ||
                          value === undefined ||
                          value === "Can not calculate because of no value"
                            ? 0
                            : valueCleaner(`${value}`) *
                              (dataFxrate[year]?.rate ?? 1),
                        currency: "USD",
                      })}`}
                    />
                  </Stack>
                </Grid>
              )}
            </Grid>
          </TableCell>
        );
      })}
      {Object.keys(formikProps.values)?.length > 1 && (
        <FinancialGrowthIndicator
          values={formikProps.values as MapRiskParamValues}
          fieldName={toCamelCase(key)}
          needGrowthRate={keyParams?.need_growth_rate ?? false}
        />
      )}
    </TableRow>
  );
};

export default FinancialColumn;
