import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CL_APPROVED,
  CL_REJECTED,
  CL_REQUESTED,
  ONBOARDING,
  TP_ADDED,
  VITE_API_V2_URL,
} from "codes";
import type { ShareholderType } from "components/Map/Sections/Compliance/CreditReport/types";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { setAppState } from "redux/reducers/appStatesReducer";
import type {
  Account,
  AccountRelationship,
  AssociateCompanyDocs,
  Company,
  CountdownInfo,
  CountdownUpdateRequest,
  CreditLimit,
  ILead,
  InviteTradePartners,
  ISFLead,
  PartnerData,
  RegisterCompany,
  RequiredDoc,
  Step,
  Termsheet,
  TermsheetApprover,
  TermsheetComment,
  TermsheetInfo,
  TermsheetInvoice,
  TermSheetListItem,
  TermsheetWithPartner,
  UpdateStep,
  AddDocumentToBillingInvoice
} from "types";
import { companiesrtk } from "./api-company";
import { compliance } from "./api-compliance";
import { maps } from "./api-maps";
import { misc } from "./api-misc";
import { users_v2 } from "./api-users-v2";

interface AccountList {
  id: string;
  status: string;
  profile: string;
  name: string;
  internal_name: string;
  reg_no: string;
  country: string;
  users: number;
  created_at: string;
}

interface CompanyDoc {
  id: string;
  document_type: string;
  url?: string;
  name: string;
  submitted?: string;
  submitted_to_sf?: boolean;
  year?: number | null;
}

interface AccountOnboardingStatusInfo {
  status: string;
  info: string;
}
interface AccountOnboardingStatus {
  details: AccountOnboardingStatusInfo;
  documents: AccountOnboardingStatusInfo;
  bank_details: AccountOnboardingStatusInfo;
  users: AccountOnboardingStatusInfo;
  progress: number;
}
const getAmount = (credit_limit: CreditLimit, status: string): number => {
  switch (status) {
    case CL_REJECTED:
      return credit_limit?.requested_amount;
    case CL_REQUESTED:
      return credit_limit?.requested_amount;
    case CL_APPROVED:
      return credit_limit?.approved_amount;
    default:
      return credit_limit.requested_amount ?? 0;
  }
};

const formatBuyers = (
  buyers: {
    country: string;
    credit_limit: CreditLimit;
    id: string;
    name: string;
    profile: string;
    reg_no: string;
    is_termsheet_signed: boolean;
    onboarding_submitted: boolean;
    internal_name: string;
    termsheet_signed_date: Date;
    product: string;
    is_lead_complete: boolean;
    average_invoice_amount: number;
    average_payment_terms: string;
    number_of_past_paid_transaction: number;
    industry: string;
    goods_category: string;
    annual_revenue: number;
  }[]
): PartnerData[] => {
  return buyers.map((item) => {
    return {
      id: item.id,
      name: item.name,
      termsheetSigned: item.is_termsheet_signed ?? false,
      internal_name: item.internal_name,
      termsheetSignedDate: item.termsheet_signed_date,
      product: item.product,
      reg_no: item.reg_no,
      country: item.country,
      documents_completed: true,
      //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      clAmount: item?.credit_limit
        ? //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          getAmount(item?.credit_limit, item.credit_limit?.status)
        : 0,
      approval: item.credit_limit?.status,
      profile: item.profile,
      credit_limit: item.credit_limit,
      onboardingSubmitted: item.onboarding_submitted,
      is_lead_complete: item.is_lead_complete,
      average_invoice_amount: item.average_invoice_amount,
      average_payment_terms: item.average_payment_terms,
      number_of_past_paid_transaction: item.number_of_past_paid_transaction,
      industry: item.industry,
      goods_category: item.goods_category,
      annual_revenue: item.annual_revenue,
    };
  });
};
interface ILeadTable {
  name: string;
  country: string;
  created_at: Date;
  profile: string;
  id: string;
  sales_owner: string;
  source: string;
}

const formatLeads = (
  data: {
    country: string;
    id: string;
    name: string;
    sales_owner: string;
    source: string;
    created_at: Date;
    profile: string;
    internal_name: string;
  }[]
): ILeadTable[] => {
  return data?.map((item) => ({
    internal_name: item.internal_name,
    id: item.id,
    name: item.name,
    profile: item.profile,
    sales_owner: item.sales_owner,
    source: item.source,
    country: item.country,
    created_at: item.created_at,
  }));
};

interface AddShareholderData {
  entity_id: string;
  entity_type: string;
  shareholder_type: ShareholderType | null;
  shareholder_name: string;
  address: string;
  percentage: number;
  source: string;
}

interface EditShareholderData {
  shareholder_name: string;
  percentage: number;
  address: string;
}

interface EditDirectorData {
  fullname: string;
  function: string;
  address: string;
}

interface AddDirectorData {
  entity_id: string;
  entity_type: string;
  fullname: string;
  function: string;
  address: string;
}

interface AddDocumentToContactData {
  file: File;
  document_type: string;
  document_category: string;
}

interface AddDocumentToDirectorData {
  file: File;
  document_type: string;
  document_category: string;
}
interface UpdateRelationshipSchema {
  average_invoice_amount: number;
  number_of_past_paid_transaction: number;
  average_payment_terms: string;
  any_delays_of_payment_with_buyer: boolean;
  reason_of_any_delays_of_payment_with_buyer: string | null;
  any_credit_note: boolean;
  reason_for_credit_note: string | null;
}

interface UpdatePartnerTermsheet {
  currency?: string;
  average_funding_period?: string;
  advanced_amount?: number;
  annualized_discount_rate?: number;
  average_invoice_size?: number;
  discount_rate_type?: boolean;
  facility_fee?: number;
  setup_fee?: number;
  trade_goods_description?: string;
  first_invoice_amount?: number;
  first_invoice_expected_date?: string;

  customer_maintenance_fee?: number;
  partner_maintenance_fee?: number;
}

interface InternalApprovalTermSheetSubmitRequest {
  comment: string;
  assignee_id: string;
}

interface TermsheetRecallRequest {
  comment: string;
}

interface InternalApproveRequest {
  comment: string;
}

interface InternalRejectRequest {
  comment: string;
}

interface CustomerRejectRequest {
  comment: string;
}

interface TermsheetReassignRequest {
  message: string;
  assignee_approver: string;
}

const formatAccountsResponse = (data: AccountList[]): AccountList[] => {
  return data.map((item) => ({
    id: item.id,
    internal_name: item.internal_name,
    name: item.name,
    status: item.status,
    profile: item.profile,
    reg_no: item.reg_no,
    country: item.country,
    users: item.users,
    created_at: item.created_at,
  }));
};
const ACCOUNTS_V2 = "/accounts";
export const accountrtk = createApi({
  reducerPath: "accountrtk",
  tagTypes: [
    "Company",
    "Buyers",
    "Relationship",
    "ACCOUNT_DOCS",
    "USER_DOCS",
    "LEAD",
    "ACCOUNTS",
    "ACCOUNT",
    "Partners",
    "BILLING_DOCS",
    "ACCOUNT_CREDIT_LIMIT",
    "PARTNER_CONTACTS",
    "SHAREHOLDERS",
    "IDVerification",
    "ACCOUNT_ONBOARDING_STATUS",
    "ADDRESSPROOF",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V2_URL,
  }),
  endpoints: (builder) => ({
    createAccount: builder.mutation<Company, RegisterCompany>({
      query: (payload) => {
        const { client = false, ...data } = payload;
        const isClient = Boolean(client);
        return {
          url: `${ACCOUNTS_V2}${isClient ? "?mode=client" : ""}`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["ACCOUNTS", "Company"],
      transformResponse: (response) => response?.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const isClient = Boolean(arg.client);
          if (isClient) {
            dispatch(users_v2.util.invalidateTags(["Users", "CompanyUsers"]));
          }
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    updateAccount: builder.mutation<
      Account,
      Partial<Account> & { type?: string }
    >({
      query(payload) {
        //eslint-disable-next-line unused-imports/no-unused-vars
        const { id, type, ...data } = payload;
        return {
          url: `${ACCOUNTS_V2}/${id}`,
          method: "PUT",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["ACCOUNTS", "ACCOUNT_ONBOARDING_STATUS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (arg.type === "LEAD")
            dispatch(accountrtk.util.invalidateTags(["LEAD"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    submitAccount: builder.mutation<
      string,
      {
        accountId: string;
        data: {
          is_submitted?: boolean;
          agree_to_terms?: boolean;
          agree_to_privacy_policy?: boolean;
          partner_id?: string;
        };
      }
    >({
      query: (payload) => {
        const { accountId, data } = payload;
        return {
          url: `${ACCOUNTS_V2}/${accountId}/submit-onboarding`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["ACCOUNTS", "Relationship"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const isPartner = Boolean(arg?.data?.partner_id);
          if (!isPartner)
            dispatch(companiesrtk.util.invalidateTags(["Company"]));
          if (isPartner) dispatch(accountrtk.util.invalidateTags(["Partners"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    getAccounts: builder.query<AccountList[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}`,
        method: "get",
      }),
      transformResponse: (response) => formatAccountsResponse(response.data),
      providesTags: ["ACCOUNTS"],
    }),
    getAccountByName: builder.query<Account, string>({
      query: (internal_name) => ({
        url: `${ACCOUNTS_V2}/name/${internal_name}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["ACCOUNT"],
    }),
    getAccountRelationship: builder.query<
      AccountRelationship,
      { companyId: string; partnerId: string }
    >({
      query: ({
        companyId,
        partnerId,
      }: {
        companyId: string;
        partnerId: string;
      }) => ({
        url: `${ACCOUNTS_V2}/${companyId}/partners/${partnerId}/relationships`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["Relationship"],
    }),
    modifyAccountRelationship: builder.mutation<
      any,
      {
        companyId: string;
        partnerId: string;
        data: UpdateRelationshipSchema;
      }
    >({
      query: ({ companyId, partnerId, data }) => {
        return {
          url: `${ACCOUNTS_V2}/${companyId}/partners/${partnerId}/relationships`,
          method: "put",
          data,
        };
      },
      invalidatesTags: ["Relationship"],
    }),
    patchAccountRelationship: builder.mutation<
      any,
      {
        companyId: string;
        partnerId: string;
        data: Partial<UpdateRelationshipSchema>;
      }
    >({
      query: ({ companyId, partnerId, data }) => {
        return {
          url: `${ACCOUNTS_V2}/${companyId}/partners/${partnerId}/relationships`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: ["Relationship"],
    }),
    getPartnerContacts: builder.query<
      any,
      { companyId: string; partnerId: string }
    >({
      query: ({
        companyId,
        partnerId,
      }: {
        companyId: string;
        partnerId: string;
      }) => ({
        url: `${ACCOUNTS_V2}/${companyId}/partners/${partnerId}/contacts`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["PARTNER_CONTACTS"],
    }),
    updateSubmitStatus: builder.mutation<
      void,
      {
        companyId: string;
        partnerId: string;
        data: {
          is_submitted?: boolean;
          agree_to_terms?: boolean;
          agree_to_privacy_policy?: boolean;
        };
      }
    >({
      query(payload) {
        const { companyId, partnerId, data } = payload;
        return {
          url: `/companies/${companyId}/relationships/${partnerId}/submit-status`,
          method: "PUT",
          data,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getUser` will be re-run. `getCompanyUsers` *might*  rerun, if this id was under its results.
      invalidatesTags: () => ["Relationship"],
    }),
    getAccount: builder.query<Account, string>({
      query: (id: string) => ({
        url: `${ACCOUNTS_V2}/${id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: (result) => [
        "ACCOUNTS",
        { type: "ACCOUNTS", id: result?.id },
      ],
    }),

    getBuyers: builder.query<PartnerData[], string>({
      query: (id: string) => ({
        url: `/buyers${id !== "" ? `?company_id=${id}` : ""}`,
        method: "get",
        timeout: 1000,
      }),
      transformResponse: (response) => formatBuyers(response?.data),
      providesTags: ["Buyers"],
    }),
    getPartners: builder.query<PartnerData[], string>({
      query: (id: string) => ({
        url: `${ACCOUNTS_V2}/${id}/partners`,
        method: "get",
        timeout: 1000,
      }),
      transformResponse: (response) => formatBuyers(response?.data),
      providesTags: ["Partners"],
    }),
    getBuyerCompanies: builder.query<Company[], string>({
      query: (id: string) => ({
        url: `/buyers?company_id=${id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["Buyers"],
    }),
    inviteTradePartners: builder.mutation<void, InviteTradePartners>({
      query: (data) => {
        const { company_id, ...payload } = data;
        return {
          url: `${ACCOUNTS_V2}/${company_id}/partners`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["Buyers", "Company", "Partners"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppState(TP_ADDED));
          dispatch(companiesrtk.util.invalidateTags(["Buyers"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    updateTermSheetStatus: builder.mutation<
      void,
      {
        account_id: string;
        partner_id: string;
      }
    >({
      query: (data) => {
        const { account_id, partner_id } = data;
        return {
          url: `${ACCOUNTS_V2}/${account_id}/partners/${partner_id}/update-termsheet-status`,
          method: "POST",
        };
      },
      invalidatesTags: ["Partners", "Relationship"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppState(ONBOARDING));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),

    getAccountingSoftwares: builder.query<string[], void>({
      query: () => ({
        url: `/accounting-softwares`,
        method: "get",
      }),
      transformResponse: (response) =>
        response?.data?.map((industry: string) => {
          return { name: industry };
        }),
    }),
    getGoods: builder.query<string[], string>({
      query: (industry: string) => ({
        url: `/goods/${industry}`,
        method: "get",
      }),
      transformResponse: (response) =>
        response?.data?.map((good: string) => {
          return { name: good };
        }),
    }),
    getAccountSoftware: builder.query<string[], null>({
      query: () => ({
        url: `/accounting-softwares`,
        method: "get",
      }),
      transformResponse: (response) =>
        response?.data?.map((accountingSoftware: string) => {
          return { name: accountingSoftware };
        }),
    }),
    vendorAddress: builder.mutation<
      void,
      {
        iso3: string;
        registrationNumber: string;
        city: string;
        postcode: string;
        address_line1: string;
        address_line2: string;
      }
    >({
      query: (data) => {
        return {
          url: `/vendor-address`,
          method: "POST",
          data,
        };
      },
    }),
    //DOCS
    getDocuments: builder.query<
      {
        complete: boolean;
        data: RequiredDoc[];
      },
      {
        account_id: string;
      }
    >({
      query: ({ account_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/documents/list`,
        method: "get",
      }),
      providesTags: ["ACCOUNT_DOCS"],
    }),
    getDocumentUrl: builder.query<
      {
        id: string;
        name: string;
        url: string;
        document_type: string;
      },
      { account_id: string; document_id: string }
    >({
      query: ({
        account_id,
        document_id,
      }: {
        account_id: string;
        document_id: string;
      }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/documents/${document_id}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
    }),
    getDocumentContent: builder.query<
      string,
      { account_id: string; document_id: string }
    >({
      query: ({
        account_id,
        document_id,
      }: {
        account_id: string;
        document_id: string;
      }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/documents/${document_id}/download`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
    }),
    getAccountDocuments: builder.query<CompanyDoc[], string>({
      query: (accountId) => {
        return {
          url: `${ACCOUNTS_V2}/${accountId}/documents`,
          method: "get",
        };
      },
      transformResponse: (response) => response.data,
      providesTags: ["ACCOUNT_DOCS"],
    }),
    getUserDocs: builder.query<RequiredDoc[], string>({
      query: (country) => {
        return {
          url: `/required-documents?type=user&country=${country}`,
          method: "get",
        };
      },
      providesTags: ["USER_DOCS"],
    }),
    associateDocs: builder.mutation<
      any,
      { id: string; data: AssociateCompanyDocs }
    >({
      query: ({ id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("document_category", data.document_category);
        formData.append("document_type", data.document_type);
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (data.year) formData.append("year", data.year);
        return {
          url: `${ACCOUNTS_V2}/${data.partner_id !== undefined ? data.partner_id : id}/documents`,
          method: "POST",
          data: formData,
        };
      },
      invalidatesTags: ["ACCOUNT_DOCS", "Company", "ACCOUNT_ONBOARDING_STATUS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(companiesrtk.util.invalidateTags(["Company"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    updateSteps: builder.mutation<Step, UpdateStep>({
      query(payload) {
        const { company_id, ...data } = payload;
        return {
          url: `${ACCOUNTS_V2}/${company_id}/update-steps`,
          method: "POST",
          data,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(misc.util.invalidateTags(["STEPS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    deleteDoc: builder.mutation<any, { account_id: string; id: string }>({
      query: ({ account_id, id }) => {
        return {
          url: `${ACCOUNTS_V2}/${account_id}/documents/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "ACCOUNT_DOCS",
        "USER_DOCS",
        "Company",
        "IDVerification",
        "ACCOUNT_ONBOARDING_STATUS",
        "ADDRESSPROOF",
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(companiesrtk.util.invalidateTags(["Company"]));
          dispatch(companiesrtk.util.invalidateTags(["USER_DOCS"]));
          dispatch(compliance.util.invalidateTags(["SCREENING"]));
          dispatch(maps.util.invalidateTags(["MAP_DOCUMENTS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["DIRECTORS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    fetchMemberDocuments: builder.query<
      Array<{ id: string; name: string; url: string; document_type: string }>,
      { id: string; account_id: string }
    >({
      query: ({ id, account_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/contacts/${id}/documents`,
        method: "get",
      }),

      transformResponse: (response) => response.data,
    }),
    fetchMemberPendingDocuments: builder.query<
      {
        complete: boolean;
        data: RequiredDoc[];
      },
      { user_id: string; account_id: string }
    >({
      query: ({
        user_id,
        account_id,
      }: {
        user_id: string;
        account_id: string;
      }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/contacts/${user_id}/pending-documents`,
        method: "get",
      }),
    }),
    getLeads: builder.query<ILeadTable[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/leads`,
        method: "get",
      }),
      providesTags: ["ACCOUNTS"],
      transformResponse: (response) => formatLeads(response.data),
    }),
    getLeadByURI: builder.query<ILead, string>({
      query: (lead_uri) => ({
        url: `${ACCOUNTS_V2}/leads/${lead_uri}`,
        method: "get",
      }),
      providesTags: ["ACCOUNTS"],
      transformResponse: (response) => response.data,
    }),
    addAccountProfile: builder.mutation<
      any,
      { account_id: string; company_type: string }
    >({
      query: ({ account_id, company_type }) => {
        return {
          url: `${ACCOUNTS_V2}/${account_id}/profile`,
          data: { company_type },
          method: "POST",
        };
      },
      invalidatesTags: ["Company"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(companiesrtk.util.invalidateTags(["Company"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    getBuyerCreditLimit: builder.query<CreditLimit, string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/credit-limit`,
        method: "get",
      }),
      providesTags: ["ACCOUNT_CREDIT_LIMIT"],
      transformResponse: (response) => response?.data,
    }),
    getLeadByName: builder.query<Account, string>({
      query: (internal_name) => ({
        url: `${ACCOUNTS_V2}/leads/name/${internal_name}`,
        method: "get",
      }),
      providesTags: ["LEAD"],
      transformResponse: (response) => response?.data,
    }),
    getLeadAccountTermsheetInfo: builder.query<TermsheetInfo, string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/termsheetinfo`,
        method: "get",
      }),
      providesTags: ["LEAD"],
      transformResponse: (response) => response?.data,
    }),
    updateTermsheetCountdown: builder.mutation<
      CountdownInfo,
      { id: string; body: CountdownUpdateRequest }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/termsheet/countdown/${payload.id}`,
        method: "patch",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    getTermsheetList: builder.query<TermSheetListItem[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/termsheet/list`,
        method: "get",
      }),
      providesTags: ["LEAD"],
      transformResponse: (response) => response?.data,
    }),
    getCustomerTemsheetList: builder.query<TermSheetListItem[], string | null>({
      query: (status) => ({
        url: `${ACCOUNTS_V2}/termsheet/customer_termsheet_list?${status ? `status=${status}` : ""}`,
        method: "get",
      }),
      providesTags: ["LEAD"],
      transformResponse: (response) => response?.data,
    }),
    getDashboardTermsheetList: builder.query<TermSheetListItem[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/termsheet/dashboard_list`,
        method: "get",
      }),
      providesTags: ["LEAD"],
      transformResponse: (response) => response?.data,
    }),
    getLeadPartnerTermsheet: builder.query<
      Termsheet,
      {
        lead_id: string;
        partner_id: string;
      }
    >({
      query: (val) => ({
        url: `${ACCOUNTS_V2}/${val.lead_id}/partners/${val.partner_id}/termsheet`,
        method: "get",
      }),
      providesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    getPartnerTermsheet: builder.query<Termsheet, string>({
      query: (partner_id: string) => ({
        url: `${ACCOUNTS_V2}/${partner_id}/termsheet`,
        method: "get",
      }),
      providesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    getTermsheetById: builder.query<TermsheetWithPartner, string>({
      query: (id: string) => ({
        url: `${ACCOUNTS_V2}/termsheet/${id}`,
        method: "get",
      }),
      providesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    getTermsheetByInternalName: builder.query<TermsheetWithPartner, string>({
      query: (internal_name: string) => ({
        url: `${ACCOUNTS_V2}/termsheet/internal/${internal_name}`,
        method: "get",
      }),
      providesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    updateLeadPartnerTermsheet: builder.mutation<
      Termsheet,
      {
        lead_id: string;
        partner_id: string;
        body: UpdatePartnerTermsheet;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.lead_id}/partners/${payload.partner_id}/termsheet`,
        method: "patch",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    getApproversList: builder.query<TermsheetApprover[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/termsheet/approver-list`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTermsheetComments: builder.query<
      TermsheetComment[],
      {
        partner_id: string;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/comments`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["Partners"],
    }),
    termsheetSubmitForInternalApproval: builder.mutation<
      Termsheet,
      {
        partner_id: string;
        body: InternalApprovalTermSheetSubmitRequest;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/send-for-approval`,
        method: "post",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    termsheetSubmitRecall: builder.mutation<
      Termsheet,
      {
        partner_id: string;
        body: TermsheetRecallRequest;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/recall`,
        method: "post",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    generateNewTermsheet: builder.mutation<Termsheet, string>({
      query: (partner_id) => ({
        url: `${ACCOUNTS_V2}/${partner_id}/termsheet/generate`,
        method: "post",
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    termsheetInternalApprove: builder.mutation<
      Termsheet,
      {
        partner_id: string;
        body: InternalApproveRequest;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/internal_approve`,
        method: "post",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    termsheetInternalReject: builder.mutation<
      Termsheet,
      {
        partner_id: string;
        body: InternalRejectRequest;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/internal_reject`,
        method: "post",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    reassignTermsheet: builder.mutation<
      Termsheet,
      {
        partner_id: string;
        body: TermsheetReassignRequest;
      }
    >({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/${payload.partner_id}/termsheet/reassign`,
        method: "put",
        data: payload.body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    customerApprove: builder.mutation<Termsheet, string>({
      query: (partner_id) => ({
        url: `${ACCOUNTS_V2}/${partner_id}/termsheet/customer_approve`,
        method: "post",
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    customerReject: builder.mutation<
      Termsheet,
      { partner_id: string; body: CustomerRejectRequest }
    >({
      query: ({ partner_id, body }) => ({
        url: `${ACCOUNTS_V2}/${partner_id}/termsheet/customer_reject`,
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: (response) => response?.data,
    }),
    exportLeadToSalesforce: builder.mutation<any, string>({
      query: (lead_id) => {
        return {
          url: `/origination/leads/${lead_id}/sf-push`,
          method: "POST",
        };
      },
      invalidatesTags: ["LEAD"],
    }),
    addShareholder: builder.mutation<
      any,
      { account_id: string; data: AddShareholderData }
    >({
      query: ({ account_id, data }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/shareholders`,
        method: "POST",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error adding shareholder:", error);
        }
      },
    }),
    deleteShareholder: builder.mutation<
      any,
      { account_id: string; shareholder_id: string }
    >({
      query: ({ account_id, shareholder_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/shareholders/${shareholder_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting shareholder:", error);
        }
      },
    }),
    editShareholder: builder.mutation<
      any,
      { account_id: string; shareholder_id: string; data: EditShareholderData }
    >({
      query: ({ account_id, shareholder_id, data }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/shareholders/${shareholder_id}`,
        method: "PUT",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error editing shareholder:", error);
        }
      },
    }),
    getDirectors: builder.query<any[], string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors`,
        method: "get",
      }),
      providesTags: ["SHAREHOLDERS", "ADDRESSPROOF"],
      transformResponse: (response) => response?.data,
    }),
    getDirectorDocuments: builder.query<
      any[],
      { account_id: string; director_id: string }
    >({
      query: ({ account_id, director_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors/${director_id}/documents`,
        method: "get",
      }),
      providesTags: ["SHAREHOLDERS", "ADDRESSPROOF"],
      transformResponse: (response) => response?.data,
    }),
    getDirectorRequiredDocuments: builder.query<any[], string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors/required-documents`,
        method: "get",
      }),
      providesTags: ["SHAREHOLDERS", "ADDRESSPROOF"],
      transformResponse: (response) => response?.data,
    }),
    addDirector: builder.mutation<
      any,
      { account_id: string; data: AddDirectorData }
    >({
      query: ({ account_id, data }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors`,
        method: "POST",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error adding director:", error);
        }
      },
    }),
    addDocumentToDirector: builder.mutation<
      any,
      {
        account_id: string;
        director_id: string;
        data: AddDocumentToDirectorData;
      }
    >({
      query: ({ account_id, director_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("document_type", data.document_type);
        formData.append("document_category", data.document_category);
        return {
          url: `${ACCOUNTS_V2}/${account_id}/directors/${director_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["ADDRESSPROOF"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(accountrtk.util.invalidateTags(["ADDRESSPROOF"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error adding ID:", error);
        }
      },
    }),
    editDirector: builder.mutation<
      any,
      { account_id: string; director_id: string; data: EditDirectorData }
    >({
      query: ({ account_id, director_id, data }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors/${director_id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error editing director:", error);
        }
      },
    }),
    deleteDirector: builder.mutation<
      any,
      { account_id: string; director_id: string }
    >({
      query: ({ account_id, director_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/directors/${director_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SHAREHOLDERS"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["SHAREHOLDERS"]));
          dispatch(compliance.util.invalidateTags(["SHAREHOLDERS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting director:", error);
        }
      },
    }),

    addDocumentToContact: builder.mutation<
      any,
      { account_id: string; contact_id: string; data: AddDocumentToContactData }
    >({
      query: ({ account_id, contact_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("document_type", data.document_type);
        formData.append("document_category", data.document_category);
        return {
          url: `${ACCOUNTS_V2}/${account_id}/contacts/${contact_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["IDVerification"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(accountrtk.util.invalidateTags(["IDVerification"]));
          dispatch(users_v2.util.invalidateTags(["IDVerification"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error adding ID:", error);
        }
      },
    }),

    getContactDocuments: builder.query<
      CompanyDoc[],
      { contact_id: string; account_id: string }
    >({
      query: ({ contact_id, account_id }) => ({
        url: `${ACCOUNTS_V2}/${account_id}/contacts/${contact_id}/documents`,
        method: "get",
      }),
      providesTags: ["IDVerification"],
      transformResponse: (response) => response?.data,
    }),
    getContactRequiredDocuments: builder.query<any[], string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/contacts/required-documents`,
        method: "get",
      }),
      providesTags: ["IDVerification"],
      transformResponse: (response) => response?.data,
    }),
    getAccountOnboardingStatus: builder.query<AccountOnboardingStatus, string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/onboarding-status`,
        method: "get",
      }),
      providesTags: (_, __, arg) => [
        "ACCOUNT_ONBOARDING_STATUS",
        { type: "ACCOUNT_ONBOARDING_STATUS", id: arg },
      ],
      transformResponse: (response) => response?.data,
    }),
    getProduct: builder.query<any, string>({
      query: (account_id) => ({
        url: `${ACCOUNTS_V2}/${account_id}/relationships/products`,
        method: "get",
      }),
    }),
    getSFLead: builder.query<{ data: ISFLead }, string>({
      query: (lead_sf_id) => ({
        url: `${ACCOUNTS_V2}/leads/${lead_sf_id}`,
        method: "get",
        transformResponse: (response: { data: ISFLead }) => response?.data,
      }),
    }),
    importSFLead: builder.mutation<{ task_id: string }, string>({
      query: (lead_sf_id) => ({
        url: `${ACCOUNTS_V2}/leads/${lead_sf_id}/import`,
        method: "post",
      }),
      transformResponse: (response) => response?.data,
    }),
    getSFLeadImportProgress: builder.query<
      { progress: number; status: "IN_PROGRESS" | "FAILED" | "COMPLETED" },
      string
    >({
      query: (task_id) => ({
        url: `${ACCOUNTS_V2}/leads/import/progress/${task_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTermsheetBillingList: builder.query<TermsheetInvoice[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/termsheet/billing-list`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getCustomerTermsheetBillingList: builder.query<TermsheetInvoice[], null>({
      query: () => ({
        url: `${ACCOUNTS_V2}/termsheet/customer-billing-list`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTermsheetBill: builder.query<TermsheetInvoice, string>({
      query: (internalName) => ({
        url: `${ACCOUNTS_V2}/termsheet/billing-detail/${internalName}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTermsheetBillById: builder.query<TermsheetInvoice, string>({
      query: (id) => ({
        url: `${ACCOUNTS_V2}/termsheet/customer-billing-detail/${id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    markInvoiceAsPaid: builder.mutation<TermsheetInvoice, string>({
      query: (id) => ({
        url: `${ACCOUNTS_V2}/termsheet/billings/${id}/paid`,
        method: "post",
      }),
      transformResponse: (response) => response?.data
    }),
    getInvoiceRequiredDocuemnts: builder.query<RequiredDoc[], string>({
      query: (invoice_id) => ({
        url: `${ACCOUNTS_V2}/termsheet/billings/${invoice_id}/required-documents`,
        method: "get"
      }),
      transformResponse: (response) => response.data
    }),
    uploadInvoiceProofOfpayment: builder.mutation<CompanyDoc, { invoiceId: string, body: AddDocumentToBillingInvoice }>({
      query: (payload) => {
        const formData = new FormData();
        formData.append("file", payload.body.file);
        formData.append("document_type", payload.body.document_type);
        formData.append("document_category", payload.body.document_category);
        return {
          url: `${ACCOUNTS_V2}/termsheet/billings/${payload.invoiceId}/documents`,
          method: "post",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["BILLING_DOCS"],
      transformResponse: (response) => response.data
    }),
    getInvoiceProofOfPaymentDocs: builder.query<CompanyDoc[], string>({
      query: (invoice_id) => ({
        url: `${ACCOUNTS_V2}/termsheet/billings/${invoice_id}/documents`,
        method: "get",
      }),
      providesTags: ["BILLING_DOCS"],
      transformResponse: (response) => response.data
    }),
    getInvoiceProofOfPaymentDocumentUrl: builder.query<CompanyDoc, {
      invoice_id: string;
      document_id: string
    }>({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/termsheet/billings/${payload.invoice_id}/documents/${payload.document_id}`,
        method: "get",
      }),
      providesTags: ["BILLING_DOCS"],
      transformResponse: (response) => response.data
    }),
    downloadProofOfPaymentDocument: builder.query<string, {
      invoice_id: string;
      document_id: string
    }>({
      query: (payload) => ({
        url: `${ACCOUNTS_V2}/termsheet/billings/${payload.invoice_id}/documents/${payload.document_id}/download`,
        method: "get"
      }),
      transformResponse: (response) => response.data
    })
  }),
});

export const {
  useGetAccountRelationshipQuery,
  useUpdateSubmitStatusMutation,
  useSubmitAccountMutation,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useGetBuyersQuery,
  useGetBuyerCompaniesQuery,
  useGetPartnerContactsQuery,
  useGetGoodsQuery,
  useGetAccountingSoftwaresQuery,
  useInviteTradePartnersMutation,
  useUpdateTermSheetStatusMutation,
  useVendorAddressMutation,
  useGetDocumentsQuery,
  useGetDocumentContentQuery,
  useAssociateDocsMutation,
  useGetUserDocsQuery,
  useDeleteDocMutation,
  useFetchMemberDocumentsQuery,
  useFetchMemberPendingDocumentsQuery,
  useGetLeadsQuery,
  useGetLeadByURIQuery,
  useAddAccountProfileMutation,
  useUpdateStepsMutation,
  useGetPartnersQuery,
  useGetBuyerCreditLimitQuery,
  useGetDocumentUrlQuery,
  useGetAccountDocumentsQuery,
  useModifyAccountRelationshipMutation,
  usePatchAccountRelationshipMutation,
  useGetLeadByNameQuery,
  useGetLeadAccountTermsheetInfoQuery,
  useGetLeadPartnerTermsheetQuery,
  useUpdateLeadPartnerTermsheetMutation,
  useTermsheetSubmitForInternalApprovalMutation,
  useTermsheetSubmitRecallMutation,
  useGenerateNewTermsheetMutation,
  useTermsheetInternalApproveMutation,
  useTermsheetInternalRejectMutation,
  useReassignTermsheetMutation,
  useGetAccountQuery,
  useGetAccountsQuery,
  useGetAccountByNameQuery,
  useDeleteShareholderMutation,
  useAddShareholderMutation,
  useGetDirectorsQuery,
  useGetDirectorDocumentsQuery,
  useGetDirectorRequiredDocumentsQuery,
  useAddDirectorMutation,
  useAddDocumentToDirectorMutation,
  useDeleteDirectorMutation,
  useEditDirectorMutation,
  useEditShareholderMutation,
  useAddDocumentToContactMutation,
  useGetContactRequiredDocumentsQuery,
  useGetContactDocumentsQuery,
  useGetAccountOnboardingStatusQuery,
  useLazyFetchMemberDocumentsQuery,
  useGetProductQuery,
  useGetAccountSoftwareQuery,
  useExportLeadToSalesforceMutation,
  useLazyGetAccountOnboardingStatusQuery,
  useGetSFLeadQuery,
  useImportSFLeadMutation,
  useGetSFLeadImportProgressQuery,
  useGetTermsheetListQuery,
  useGetCustomerTemsheetListQuery,
  useGetApproversListQuery,
  useGetTermsheetCommentsQuery,
  useGetPartnerTermsheetQuery,
  useGetTermsheetByInternalNameQuery,
  useGetTermsheetByIdQuery,
  useCustomerApproveMutation,
  useCustomerRejectMutation,
  useGetDashboardTermsheetListQuery,
  useGetTermsheetBillingListQuery,
  useGetCustomerTermsheetBillingListQuery,
  useGetTermsheetBillQuery,
  useGetTermsheetBillByIdQuery,
  useUpdateTermsheetCountdownMutation,
  useMarkInvoiceAsPaidMutation,
  useUploadInvoiceProofOfpaymentMutation,
  useGetInvoiceProofOfPaymentDocsQuery,
  useGetInvoiceProofOfPaymentDocumentUrlQuery,
  useGetInvoiceRequiredDocuemntsQuery
} = accountrtk;
