import AddIcon from "@mui/icons-material/Add";
import { Box, Slide, Stack, Typography, useTheme } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { alpha } from "@mui/system";
import {
  useCreateProjectMutation,
  useCreateSprintMutation,
  useCreateTicketMutation,
  useGetActiveSprintQuery,
  useGetProjectsQuery,
  useGetSprintsQuery,
  useLazyGetTicketsQuery,
} from "api/api-support";
import { INCOMLEND_INTERNAL_LINK, SUPPORT } from "codes";
import Board from "components/Common/Board";
import Drop from "components/Common/Drop";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateTask from "components/Tasks";
import { Form, Formik, type FormikProps } from "formik";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { OverridableStringUnion, SupportTicket } from "types";
import * as Yup from "yup";
import styles from "./support.module.scss";

export interface Ticket {
  title: string;
  content: string;
  priority_code_id?: string;
  assignee_department_id: string;
  estimate_min?: string;
  project_id: string;
  project?: string;
  sprint_id: string;
  assignee_id?: string;
  assignee?: string;
  sprint: string;
  parent_id?: string;
}

interface Project {
  name: string;
  description: string;
}

interface Sprint {
  name: string;
  start_date: string;
  end_date: string;
}

const formatTickets = (
  data: { [status: string]: SupportTicket[] },
  theme: Theme
): any[] => {
  let result: any[] = [];
  for (let value in data) {
    if (Boolean(data[value]?.length))
      result.push(
        ...data[value].map((item: SupportTicket) => ({
          status: value,
          id: item.internal_name,
          name: item.title,
          data: {
            ...item,
            assigned_to: item.assignee
              ? `${item.assignee?.first_name} ${item.assignee?.last_name}`
              : "Unassigned",
            created_by: `${item.submitted_by?.first_name} ${item.submitted_by?.last_name}`,
            priority: item?.business_priority?.label ?? "No Priority",
            project_name: item.project ? item.project.name : "Unassigned",
          },
          value: item.estimate_min,
          bg: item.parent ? alpha(theme.palette.secondary.light, 0.2) : "",
        }))
      );
    else
      result.push({
        id: "",
        link: "",
        status: value,
        name: "",
        data: {},
        value: 0,
        bg: "",
      });
  }
  return result;
};

const requiredKeys = [
  "assigned_to",
  "created_by",
  "priority",
  "assignee_department",
  "submitter_department",
  "project_name",
];

const SupportPage = () => {
  const { data: activeSprint, isFetching: isFetchingActiveSprint } =
    useGetActiveSprintQuery(null);
  const [currentSprint, setCurrentSprint] = useState<{
    name: string;
    id: string;
  }>({ name: activeSprint?.name ?? "", id: activeSprint?.id ?? "" });
  const [trigger, { data: tickets, isFetching }] = useLazyGetTicketsQuery();
  const { refetch: refetchProjects } = useGetProjectsQuery(null);

  const refetch = () => {
    trigger({ departments: null, sprint: currentSprint.id });
  };

  useEffect(() => {
    if (!currentSprint.id && activeSprint?.id) {
      setCurrentSprint({
        name: activeSprint?.name ?? "",
        id: activeSprint?.id ?? "",
      });
    }
  }, [activeSprint]);

  useEffect(() => {
    if (currentSprint.id) refetch();
  }, [currentSprint.id]);

  const {
    data: sprintList,
    isFetching: isFetchingSprints,
    refetch: refetchSprints,
  } = useGetSprintsQuery(null);
  const { data: sprints, count } = sprintList ?? {};

  const theme = useTheme();
  const isMobile = useIsMobile();

  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [createTicket, { isLoading }] = useCreateTicketMutation();
  const [createProject, { isLoading: isProjectCreationLoading }] =
    useCreateProjectMutation();
  const [createSprint, { isLoading: isSprintCreationLoading }] =
    useCreateSprintMutation();
  const [createType, setCreateType] =
    useState<OverridableStringUnion<"Ticket" | "Sprint" | "Project">>("Ticket");
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const initialValues: Ticket = {
    title: "",
    content: "",
    assignee_department_id: "",
    assignee_id: "",
    project_id: "",
    sprint_id: activeSprint?.id ?? "",
    sprint: activeSprint?.name ?? "",
  };

  const sprintInitialValues: Sprint = {
    name: `Sprint ${count?.total ?? 0}`,
    start_date: "",
    end_date: "",
  };

  const initialValuesProject: Project = {
    name: "",
    description: "",
  };

  const getInitalValues = (type: string) => {
    switch (type) {
      case "Ticket":
        return initialValues;
      case "Sprint":
        return sprintInitialValues;
      case "Project":
        return initialValuesProject;
      default:
        return {};
    }
  };

  const getInitialTouched = (type: string): Record<string, boolean> => {
    switch (type) {
      case "Ticket":
        return {};
      case "Sprint":
        return { name: true };
      case "Project":
        return {};
      default:
        return {};
    }
  };

  const ticketSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .min(4, "Title must be at least 4 characters long"),
    content: Yup.string(),
    assignee_department_id: Yup.string().required(
      "Assignee department is required"
    ),
    assignee_id: Yup.string(),
    project_id: Yup.string(),
    sprint_id: Yup.string().required("Sprint is required"),
  });

  const sprintSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be after start date"),
  });

  const projectSchema = Yup.object().shape({
    name: Yup.string().required("Project name is required"),
    description: Yup.string(),
  });

  const getValidationSchemas = (type: string) => {
    switch (type) {
      case "Ticket":
        return ticketSchema;
      case "Sprint":
        return sprintSchema;
      case "Project":
        return projectSchema;
      default:
        return null;
    }
  };

  const typeSetter = (value: string) => setCreateType(value);

  const createTaskEntity = (formikProps: FormikProps<any>) => {
    switch (createType) {
      case "Sprint":
        return createSprint({
          name: formikProps.values.name,
          start_date: formikProps.values.start_date,
          end_date: formikProps.values.end_date,
        });
      case "Project":
        return createProject({
          name: formikProps.values.name,
          description: formikProps.values.description,
        });
      default:
        return createTicket({
          title: formikProps.values.title,
          content: formikProps.values.content,
          assignee_department_id: formikProps.values.assignee_department_id,
          project_id: formikProps.values.project_id,
          sprint_id: formikProps.values.sprint_id,
          assignee: formikProps.values.assignee_id,
        });
    }
  };

  const sprintSetter = (newSprintName: string) => {
    const sprint_id = sprints?.find(
      (sprint) => sprint.name === newSprintName
    )?.id;
    setCurrentSprint({ name: newSprintName, id: sprint_id ?? "" });
  };

  const onCreateModalOpen = () => {
    if (createType === "Ticket") {
      refetchProjects();
      refetchSprints();
    }
    setOpenCreate(true);
  };

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h3" fontWeight="bold">
                Support Tickets
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                :
              </Typography>
              {isFetchingSprints && isFetchingActiveSprint && (
                <SkeletonLoad bars={1} />
              )}
              {!isFetchingSprints &&
                !isFetchingActiveSprint &&
                sprints &&
                activeSprint &&
                currentSprint && (
                  <Drop
                    name="sprint"
                    keyValue="name"
                    value={
                      currentSprint.name
                        ? currentSprint.name
                        : activeSprint.name
                    }
                    data={sprints.length ? sprints : [{ id: null, name: null }]}
                    setValue={sprintSetter}
                    sx={{ border: "none" }}
                  />
                )}
            </Stack>
          }
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            syncAction: refetch,
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
            alignItems: "center",
          }}
          primary={{
            startIcon: <AddIcon />,
            children: "Create",
            onClick: () => onCreateModalOpen(),
            fullWidth: true,
          }}
          mode="default"
        >
          {isFetching && !tickets?.length && <SkeletonLoad bars={10} />}
          {!isFetching && tickets && (
            <>
              <Board
                data={formatTickets(tickets, theme)}
                type="Ticketing"
                action={(ticketName: string) => {
                  refetchProjects();
                  refetchSprints();
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}${SUPPORT}/${ticketName}`
                  );
                }}
                showSummary={false}
                defaultConsolidate={false}
                requiredKeys={requiredKeys}
              />
              <Formik
                initialValues={getInitalValues(createType)}
                initialTouched={getInitialTouched(createType)}
                onSubmit={console.log}
                enableReinitialize
                validationSchema={getValidationSchemas(createType)}
              >
                {(formikProps) => (
                  <Form>
                    <Modal
                      Header={
                        <Typography fontWeight="bold" fontSize="1.3em">
                          Create:
                        </Typography>
                      }
                      message={
                        <CreateTask type={createType} typeSetter={typeSetter} />
                      }
                      modalHeaderSx={{
                        mb: 2,
                        ".MuiGrid-item:nth-child(1)": {
                          width: "90%",
                          display: "grid",
                          pl: 1.5,
                          alignContent: "center",
                        },
                      }}
                      sx={modalSx}
                      width={isMobile ? "90%" : "40%"}
                      height="auto"
                      modalContentSx={{ height: "auto" }}
                      open={openCreate}
                      modalOverlaySx={modalOverlaySx}
                      modalFooterSx={{ maxWidth: "100%" }}
                      onClose={() => setOpenCreate(false)}
                      primary={{
                        children: "Create",
                        loading:
                          isLoading ||
                          isProjectCreationLoading ||
                          isSprintCreationLoading,
                        onClick: () => {
                          if (Object.keys(formikProps.touched).length) {
                            if (Object.keys(formikProps.errors).length) {
                              let errorMessage: string = "";
                              Object.values(formikProps.errors).forEach(
                                (error) => {
                                  errorMessage += `${error?.toString()}, `;
                                }
                              );
                              toast(errorMessage.slice(0, -2), {
                                type: "error",
                              });
                              return;
                            }
                            createTaskEntity(formikProps)
                              .unwrap()
                              .then(() => {
                                toast(`${createType} created successfully!`, {
                                  type: "success",
                                });
                                setOpenCreate(false);
                                formikProps.resetForm({ values: getInitalValues(createType)})
                              })
                              .catch((err: Error) => {
                                toast(`An error occured: ${err?.message}`, {
                                  type: "error",
                                });
                              });
                          }
                        },
                      }}
                      secondary={{
                        children: "Cancel",
                        onClick: () => setOpenCreate(false),
                        sx: secondaryButtonStyle,
                        variant: "outlined",
                      }}
                      closeButton
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default SupportPage;
