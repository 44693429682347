import { Stack, TextField } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import React from "react";
import DatePicker from "react-datepicker";

interface DateRangeProps {
  from: Date | undefined;
  to: Date | undefined;
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
  maxDate?: Date;
}

const DateRange: React.FC<DateRangeProps> = ({
  from,
  to,
  setStartDate,
  setEndDate,
  maxDate,
}) => {
  const pickerProps = {
    customInput: <TextField fullWidth />,
    withPortal: true,
    placeholderText: "DD/MM/YYYY",
    dateFormat: "dd/MM/yyyy",
    showMonthDropdown: true,
    showYearDropdown: true,
  };
  return (
    <Stack direction="row" spacing={1}>
      <Stack>
        <LabelViewOnly label="From" />
        <DatePicker
          selected={from}
          onChange={(date: any) => setStartDate(date ?? undefined) as any}
          selectsStart
          startDate={from}
          endDate={to}
          maxDate={maxDate}
          {...pickerProps}
        />
      </Stack>

      <Stack>
        <LabelViewOnly label="To" />
        <DatePicker
          selected={to}
          onChange={(date: any) => setEndDate(date ?? undefined) as any}
          selectsEnd
          startDate={from}
          endDate={to}
          {...pickerProps}
        />
      </Stack>
    </Stack>
  );
};

export default DateRange;
