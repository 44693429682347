import SyncIcon from "@mui/icons-material/Sync";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetDocumentsQuery } from "api/api-accounts";
import { SUPPLIER } from "codes";
import InternalDocumentList from "components/Documents/UploadOnboardingDocuments/InternalList";
import toCamelCase from "helpers/camelCase";
import type { RequiredDoc } from "types";

const AccountDocuments = ({
  account_id,
  type,
}: {
  type: string;
  account_id: string;
}) => {
  const theme = useTheme();

  const {
    data: pendingDocs,
    isFetching,
    refetch,
  } = useGetDocumentsQuery(
    {
      account_id,
    },
    { skip: !Boolean(account_id) }
  );
  const flattenedDocListForComparison = pendingDocs?.data
    ?.map((item) => {
      if (item.items && item.items.length) return item.items;
      return item;
    })
    .flat();
  const initialValues =
    flattenedDocListForComparison
      ?.map((item) => item.display_name)
      ?.reduce((a, item) => {
        return Object.assign(a, {
          [toCamelCase(item)]: null,
        });
      }, {}) ?? {};
  const docDictionary: RequiredDoc[] =
    pendingDocs?.data?.map((doc) => ({
      ...doc,
      name: toCamelCase(doc.display_name),
      label: doc.display_name,
      value: doc.display_name,
      items: doc.items.map((sub) => ({
        ...sub,
        name: toCamelCase(sub.display_name),
        label: sub.display_name,
        value: sub.display_name,
      })),
    })) ?? [];

  return (
    <Box>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  background: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1em",
                      p: 2,
                    }}
                  >
                    Required
                  </Typography>
                  <IconButton onClick={refetch}>
                    <SyncIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Stack>
              </Grid>
              <InternalDocumentList
                initialValues={initialValues}
                docs={docDictionary?.filter((doc) => doc.is_mandatory)}
                entity_id={account_id}
                document_category={type}
                partner={false}
                forProfile={
                  type?.toLowerCase() === SUPPLIER?.toLowerCase()
                    ? "seller"
                    : type
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  background: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "1em",
                    p: 2,
                  }}
                >
                  Optional
                </Typography>
              </Grid>
              <InternalDocumentList
                initialValues={initialValues}
                docs={docDictionary?.filter((doc) => !doc.is_mandatory)}
                entity_id={account_id}
                document_category={type}
                enableCount={false}
                partner={false}
                forProfile={
                  type?.toLowerCase() === SUPPLIER?.toLowerCase()
                    ? "seller"
                    : type
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AccountDocuments;
