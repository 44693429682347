import { Sync } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useGetInvitedContactsQuery } from "api/api-it";
import * as React from "react";
import { v4 as randomUUID } from "uuid";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UserManagementTabsProps {
  tabs: React.ReactNode[];
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserManagementTabs({ tabs }: UserManagementTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { refetch: refetchInvitedUsers } = useGetInvitedContactsQuery(null);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="user management tabs"
        >
          <Tab label="User List" {...a11yProps(0)} />
          <Tab
            label={
              <Stack direction="row" spacing={1}>
                <Typography
                  sx={{
                    fontSize: "0.6875rem",
                  }}
                >
                  Invited Users
                </Typography>
                <Sync
                  onClick={refetchInvitedUsers}
                  sx={{ cursor: "pointer" }}
                />
              </Stack>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {tabs?.map((tab, index) => (
        <CustomTabPanel key={randomUUID()} value={value} index={index}>
          {tab}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
