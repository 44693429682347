import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Stack, TableBody, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import type { MAP_PARTY, MAP_USE_CASE } from "api/api-maps";
import toProperCase from "helpers/propercase";
import React from "react";
import TemplatesDrawer from "./ViewTemplates";

interface MapItemTemplate {
  use_case: MAP_USE_CASE;
  version: string;
  party: MAP_PARTY;
  ipl_product: string;
  template_name: string;
}

const MapItemsTable: React.FC<{
  mapItems: [string, MapItemTemplate][];
  currentTemplateMapItems: [string, MapItemTemplate][];
}> = ({ mapItems, currentTemplateMapItems }) => {
  const items = mapItems.map(([_, value]) => value);
  const currentItems = currentTemplateMapItems.map(([_, value]) => value);

  const getMatchedItem = (
    item: MapItemTemplate
  ): MapItemTemplate | undefined => {
    return currentItems?.find((currentItem) => {
      return (
        currentItem.ipl_product === item.ipl_product &&
        currentItem.use_case === item.use_case &&
        currentItem.party === item.party
      );
    });
  };
  const columns = ["Item", "Template name", "Version"];
  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState<
    [MapItemTemplate | undefined, MapItemTemplate | undefined]
  >([undefined, undefined]);

  const versionParser = (version: string) => {
    return parseFloat(version?.replace(".", ""));
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, boxShadow: "0px 0px 10px 0px #e0e0e0" }}
        aria-label="versions table"
        size="small"
      >
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell
                key={item}
                align={item === "Version" ? "right" : "left"}
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "error.main",
                  color: "white.main",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            backgroundColor: "#ffffff",
            border: "1px solid #e0e0e0",
          }}
        >
          {items.map((item, index) => {
            const current = getMatchedItem(item);
            const IS_COMPARING =
              (current ? versionParser(current?.version) : 0) -
                versionParser(item.version) !==
              0;
            return (
              <TableRow
                key={`${item?.ipl_product}-${item?.use_case}-${item?.party}-${item.version}`}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": {
                    backgroundColor: "#a2a6c338",
                    border: "2px solid #a2a6c3",
                    borderRadius: 1,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setOpen(true);
                  setTemplates([current, IS_COMPARING ? item : undefined]);
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      color: current?.version ? "primary" : "#2e7d32",
                      fontWeight: current?.version ? 500 : "bold",
                    }}
                  >
                    {`${toProperCase(item?.ipl_product ?? "")}-${toProperCase(item?.use_case ?? "")}${item?.party ? `(${toProperCase(item?.party ?? "")})` : ""}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: current?.version ? "primary" : "#2e7d32",
                      fontWeight: current?.version ? 500 : "bold",
                    }}
                  >
                    {item.template_name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems="center"
                    justifyContent={
                      versionParser(currentItems[index]?.version) >
                      versionParser(item.version)
                        ? "space-between"
                        : "flex-end"
                    }
                  >
                    {current?.version && (
                      <Typography
                        sx={{
                          color: current?.version ? "primary" : "#2e7d32",
                          fontWeight: current?.version ? 500 : "bold",
                        }}
                      >
                        {current?.version}
                      </Typography>
                    )}
                    {current?.version && IS_COMPARING && (
                      <DoubleArrowIcon
                        color={
                          (current ? versionParser(current?.version) : 0) >
                          versionParser(item.version)
                            ? "error"
                            : "success"
                        }
                      />
                    )}
                    {IS_COMPARING && (
                      <Typography
                        sx={{
                          color:
                            versionParser(currentItems[index]?.version) >
                            versionParser(item.version)
                              ? "#C10230"
                              : "#2e7d32",
                          fontWeight: "bold",
                        }}
                      >
                        {item?.version}
                      </Typography>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TemplatesDrawer
        open={open}
        setOpen={setOpen}
        templates={templates}
        setTemplates={setTemplates}
      />
    </TableContainer>
  );
};
export default MapItemsTable;
