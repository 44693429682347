import { Stack, Typography, useTheme } from "@mui/material";
import { useGetMapSummaryQuery } from "api/api-maps";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { MapContext } from "pages/maps/map";
import React from "react";
import AccountTypeDocuments from "./AccountTypeDocuments";

// The main purpose of this screen is to give a quick indication of:
// - What has been provided so far
// - What mandatory document(s) are missing

const MapDocuments: React.FC = () => {
  const id = React.useContext(MapContext);
  const theme = useTheme();
  const { data: mapData, isLoading } = useGetMapSummaryQuery(id ?? "", {
    skip: !id,
  });

  const { buyer_internal_name, seller_internal_name } = mapData || {};
  return (
    <Layout title="Map Documents">
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        {isLoading && <SkeletonLoad bars={5} />}
        {!isLoading && (
          <Stack spacing={2} width={"100%"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1em",
                p: 2,
                bgcolor: theme.palette.primary.main,
                borderRadius: 1,
                color: theme.palette.background.default,
              }}
            >
              Supplier - KYC Documents
            </Typography>
            {seller_internal_name && (
              <AccountTypeDocuments
                account_internal_name={seller_internal_name}
              />
            )}
          </Stack>
        )}
        {isLoading && <SkeletonLoad bars={5} />}
        {!isLoading && (
          <Stack spacing={2} width={"100%"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1em",
                p: 2,
                bgcolor: theme.palette.primary.main,
                borderRadius: 1,
                color: theme.palette.background.default,
              }}
            >
              Buyer - Track Records
            </Typography>
            {buyer_internal_name && (
              <AccountTypeDocuments
                account_internal_name={buyer_internal_name}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export default MapDocuments;
