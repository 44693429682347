import CriticalIcon from "assets/issue-icons/critical.svg?react";
import LowIcon from "assets/issue-icons/low.svg?react";
import MediumIcon from "assets/issue-icons/medium.svg?react";

import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { SupportSubTickets } from "types";
import { alpha } from "@mui/system";
import { INCOMLEND_INTERNAL_LINK, SUPPORT } from "codes";
import history from "helpers/history";
import type { MouseEventHandler } from "react";

function Icon(status?: string, style?: React.CSSProperties) {
  switch (status) {
    case "High":
      return <CriticalIcon style={style} />;
    case "Medium":
      return <MediumIcon style={style} />;
    case "Low":
      return <LowIcon style={style} />;

    default:
      return <LowIcon />;
  }
}

const SubTicket = ({ ticket, last }: { ticket: SupportSubTickets, last: boolean }) => {
  const theme = useTheme();

  const onClick: MouseEventHandler = (e) => {
    e.preventDefault()
    history.push(
      `${INCOMLEND_INTERNAL_LINK}${SUPPORT}/${ticket.internal_name}`
    );
  }

  return (
    <Stack onClick={onClick}>
      <Grid
        container
        xs={12}
        sx={{
          width: "100%",
          p: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",

          ":hover": {
            backgroundColor: alpha(theme.palette.secondary.light, 0.1),
            cursor: "pointer"
          }
        }}
        alignItems="center"
      >
        <Grid item xs={5} md={2} zeroMinWidth>
          <Typography noWrap>{ticket.internal_name} </Typography>
        </Grid>
        <Grid item xs={3} md={7} textAlign="start" zeroMinWidth>
          <Typography noWrap>{ticket.title}</Typography>
        </Grid>
        <Grid container xs={1} md={1} justifyContent="end" alignItems="center" zeroMinWidth>
          <Tooltip
            children={
              <Avatar sx={{
                bgcolor: ticket.assignee ? theme.palette.primary.main : alpha(theme.palette.secondary.light, 0.5),
                width: "24px",
                height: "24px",
                fontSize: "inherit",
                padding: 1
              }}>
                {ticket.assignee?.first_name?.charAt(0) ?? "U"}
                {ticket.assignee?.last_name?.charAt(0) ?? "A"}
              </Avatar>
            }
            title={ticket.assignee ? `${ticket.assignee?.first_name} ${ticket.assignee?.last_name}` : "Unassigned"}
          />
        </Grid>
        <Grid item xs={1} md={1} textAlign="center">
          <IconButton sx={{ p: 0 }}>
            <SvgIcon>
              {Icon(ticket.business_priority?.label,
                {
                  width: "1ch",
                  height: "auto",
                }
              )}
            </SvgIcon>
          </IconButton>
        </Grid>
        <Grid item xs={3} md={1} textAlign="start" zeroMinWidth>
          <Typography noWrap>{ticket.status}</Typography>
        </Grid>
      </Grid>
      {!last && (<Divider />)}
    </Stack>
  )
}

const SubTicketList = ({
  tickets,
}: {
  tickets: SupportSubTickets[] | undefined;
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{ borderRadius: 1, padding: "1ch 3ch" }}
    >
      <Stack
        spacing={1}
        sx={{
          py: 2,
          width: "100%"
        }}>
        <Grid container columnSpacing={1} justifyContent="start">
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize="1.1em"
              textAlign="center"
            >
              SUB TASKS
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        {(!tickets || tickets?.length === 0) && (
          <Grid item xs={12}>
            <Typography textAlign="center">
              No sub task created.
            </Typography>
          </Grid>
        )}
        {tickets && (
          <Grid item xs={12}>
            {tickets.map((ticket, index) => <SubTicket ticket={ticket} last={index === tickets.length - 1} />)}
          </Grid>
        )}
      </Stack>
    </Paper>
  );
};
export default SubTicketList;
