import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import {
  Button,
  Grid,
  IconButton,
  Popover,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Companies from "assets/navigationIcons/companies.svg?react";
import { Fragment, useState } from "react";
import { AccountShareholderType, ShareholderType } from "../types";
import EditShareholder from "./EditShareholder";
import ListOfDirectors from "./ListOfDirectors";

const ShareholderDisplay = ({
  account_id,
  entity_id,
  name,
  percentage,
  address,
  type,
  shareholderType,
  sx,
  hasShareholders,
  onClick,
  onEdit,
  onDelete,
  directors,
  isLocked,
}: {
  account_id: string;
  entity_id: string;
  name: string;
  percentage: number;
  address: string;
  type: AccountShareholderType;
  shareholderType: ShareholderType;
  sx?: SxProps;
  hasShareholders?: boolean;
  onClick: (shareholderType: ShareholderType) => void;
  onEdit?: (entity_id: string, name: string, percentage: number) => void;
  onDelete?: () => void;
  directors?: Record<string, string>[];
  isLocked?: boolean;
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openListOfDirectors, setOpenListOfDirectors] = useState(false);
  const [showEditShareholder, setShowEditShareholder] = useState(false);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditShareholder = () => {
    setShowEditShareholder(true);
    if (onEdit) {
      onEdit(entity_id, name, percentage);
    }
  };

  const closeEditShareholder = () => {
    setShowEditShareholder(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "sh-popover" : undefined;

  const AccountDetails = () => (
    <Fragment>
      <Grid item>
        <Tooltip title="List of Directors" followCursor>
          <IconButton onClick={() => setOpenListOfDirectors(true)}>
            <RecentActorsIcon color="warning" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Fragment>
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      maxWidth="200px"
      margin="auto"
      sx={sx}
    >
      {showEditShareholder ? (
        <EditShareholder
          account_id={account_id}
          entity_id={entity_id}
          name={name}
          percentage={percentage}
          address={address}
          onClose={closeEditShareholder}
        />
      ) : (
        <Grid item xs={10} padding={0}>
          <Grid
            container
            sx={{
              padding: "5px",
              borderRadius: "8px",
              display: "flex",
              border: `1px solid ${theme.palette.primary.main}`,
              maxWidth: "200px",
              height: "150px",
              backgroundColor:
                type === "account" ? theme.palette.primary.main : "white",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              {shareholderType === "individual" && (
                <PersonIcon sx={{ fontSize: "2em" }} />
              )}
              {(shareholderType === "corporate" || type === "account") && (
                <Companies
                  style={{
                    fill:
                      type === "account"
                        ? "#FFFFFF"
                        : theme.palette.primary.main,
                    color:
                      type === "account"
                        ? "#FFFFFF"
                        : theme.palette.primary.main,
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Tooltip title={name} followCursor>
                <Typography
                  sx={{
                    color:
                      type === "account" ? "#FFF" : theme.palette.primary.main,
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 800,
                  color:
                    type === "account" ? "#ffffff" : theme.palette.primary.main,
                  fontSize: type === "account" ? "2em" : "1.8em",
                }}
              >
                {percentage}%
              </Typography>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color:
                      type === "account" ? "#FFF" : theme.palette.primary.main,
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {address}
                </Typography>
              </Grid>
            </Grid>
            {(type === "account" ||
              shareholderType === "corporate" ||
              hasShareholders) && (
              <Grid item xs={12}>
                <Button
                  aria-describedby={id}
                  onClick={handleClick}
                  disabled={isLocked}
                >
                  <AddCircleIcon
                    sx={{
                      color:
                        type === "account"
                          ? "#FFF"
                          : theme.palette.primary.main,
                    }}
                  />
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    ".MuiPaper-root": {
                      borderRadius: "10px",
                      padding: "3ch",
                    },
                  }}
                >
                  <Grid item lg={12} xs={12} margin="auto">
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} margin="auto">
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<AddCircleIcon />}
                          onClick={() => onClick("individual")}
                          sx={{
                            height: "5ch",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          disabled={isLocked}
                        >
                          Add Individual Shareholder
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={6} margin="auto">
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<AddCircleIcon />}
                          onClick={() => onClick("corporate")}
                          sx={{
                            height: "5ch",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          disabled={isLocked}
                        >
                          Add Corporate Shareholder
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {type === "account" && (
        <Grid item xs={2}>
          <Grid container spacing={0}>
            <AccountDetails />
          </Grid>
        </Grid>
      )}
      {type !== "account" && (
        <Grid item xs={2}>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <IconButton onClick={handleEditShareholder} disabled={isLocked}>
                <ModeEditIcon />
              </IconButton>
            </Grid>
            {(!hasShareholders || shareholderType === "individual") && (
              <Grid item>
                <IconButton onClick={onDelete} disabled={isLocked}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            )}
            {shareholderType === "corporate" && <AccountDetails />}
          </Grid>
        </Grid>
      )}
      <ListOfDirectors
        entity_id={entity_id}
        open={openListOfDirectors}
        type={type}
        onClose={() => setOpenListOfDirectors(false)}
        directors={directors || []}
        isLocked={isLocked}
      />
    </Grid>
  );
};

export default ShareholderDisplay;
