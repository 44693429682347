import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Item } from "..";
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }: { menu: Item; level: number }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (item: Item): boolean => {
    if (item?.match) return item.match(location.pathname);
    return location.pathname.includes(item.link);
  };
  const isInnerItemActive = (menuItems: Item[]): boolean => {
    return Boolean(menuItems.find((item) => isActive(item)));
  };
  const hasActiveInnerItem = menu?.items && isInnerItemActive(menu?.items);
  const [open, setOpen] = useState(hasActiveInnerItem);
  const handleClick = () => {
    setOpen(!open);
    navigate(menu?.items?.[0]?.link as string);
  };

  // inner-menu only
  const menus = menu.items?.map((item, i) => (
    <NavItem key={item?.title} item={item} level={level + 1} />
  ));

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size="1.3rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: isActive(menu) ? 8 : 6,
        height: isActive(menu) ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${theme.shape.borderRadius}px`,
          mb: 0.5,
          alignItems: "center",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1,
          pl: `${level * 24}px`,
        }}
        selected={hasActiveInnerItem}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !menu.icon ? 18 : 36,
            color: isActive(menu)
              ? menu.iconActiveColor
              : menu.iconInactiveColor,
            justifyContent: "center",
          }}
        >
          {menuIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              color="inherit"
              sx={{ my: "auto" }}
              fontWeight={isActive(menu) ? "bolder" : 500}
            >
              {menu.title}
            </Typography>
          }
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "16px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: `rgba(${theme.palette.primary.mainChannel}/0.1)`,
            },
          }}
          id="inner-menu"
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
