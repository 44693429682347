import { Grid } from "@mui/material";
import Logo from "assets/logo.svg?react";
import { HOME, IS_LOGIN } from "codes";

const LoginHeader = ({
  setCurrentState,
}: {
  setCurrentState: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Grid
      container
      textAlign="center"
      id="auth-header"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={8} lg={7} xl={8} margin="auto">
        <Logo
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCurrentState(IS_LOGIN);
            window.location.href = HOME;
          }}
        />
      </Grid>
    </Grid>
  );
};
export default LoginHeader;
