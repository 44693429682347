import {
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  useTheme,
} from "@mui/material";
import { CL_DEFAULT, CL_STEP, MAX_CL, MIN_CL } from "codes";
import Input from "components/Common/Input";
import { useField, useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { RegistrationFormFields } from "helpers/formFields";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import styles from "./addpartner.module.scss";

const CLInputs = ({
  index,
  isInEligible,
}: {
  index: number;
  isInEligible: boolean;
}) => {
  const [field, meta, helpers] = useField<
    RegistrationFormFields["list"][0]["requested_amount"]
  >(`list.[${index}].requested_amount`);
  const theme = useTheme();
  const isError: boolean = Boolean(
    (field?.value && field?.value < MIN_CL) ||
      (field?.value && field?.value > MAX_CL)
  );
  const formikProps = useFormikContext<RegistrationFormFields>();
  const CURRENCY = "USD";
  const errors: any = formikProps.errors;

  if (isError)
    formikProps.setErrors({
      [field.name]: `Please request your credit limit within ${CURRENCY}${currencyFormatter(
        { amount: MIN_CL, decimalPlace: 0 }
      )} - ${CURRENCY}${currencyFormatter({
        amount: MAX_CL,
        decimalPlace: 0,
      })}`,
    });
  if (!isError && formikProps.errors["list"])
    formikProps.setErrors({
      [field.name]: null,
    });

  const valueCleaner = (val: string): number => {
    return Number(val?.replaceAll(",", ""));
  };

  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const marks = [
    {
      value: MIN_CL,
      label: (
        <Grid item className={styles.left}>{`${currencyFormatter({
          amount: MIN_CL,
          currency: CURRENCY,
          decimalPlace: 0,
        })}`}</Grid>
      ),
    },
    {
      value: MAX_CL,
      label: (
        <Grid item className={styles.right}>{`${currencyFormatter({
          amount: MAX_CL,
          currency: CURRENCY,
          decimalPlace: 0,
        })}`}</Grid>
      ),
    },
  ];

  return (
    <Grid container key={`buy${index}`} spacing={2} marginTop="0.1vh">
      <Grid item xs={12}>
        <Grid container alignItems="top" spacing={2}>
          <Grid item xs={12} lg={3}>
            <Input
              name={`list[${index}].name`}
              label={isMobile ? t("company-full-legal-name") : ""}
              labelClassName={styles.labelClass}
              fullWidth
              disabled={true}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Input
              name={`list[${index}].registration_number`}
              label={isMobile ? `${t("registration-number")}` : ""}
              labelClassName={styles.labelClass}
              disabled={true}
              {...formikProps}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Input
              name={`list[${index}].country`}
              label={isMobile ? `${t("country")}` : ""}
              labelClassName={styles.labelClass}
              disabled={true}
              {...formikProps}
              style={{ width: "100%" }}
            />
            {isInEligible && (
              <FormHelperText error={isInEligible}>
                {`${t("cannot-provide-financing-country")}`}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid
              container
              alignItems="center"
              justifyContent="end"
              spacing={1}
            >
              <Grid item lg={10} xs={5} mr={0.5}>
                <NumericFormat
                  displayType="input"
                  thousandSeparator={true}
                  variant="standard"
                  customInput={TextField}
                  fullWidth
                  sx={{
                    backgroundColor: "white",

                    margin: 0,
                    "&:focus": {
                      border: "#ced4da",
                      backgroundColor: "white",
                    },
                    "&:hover": {
                      backgroundColor: "white",
                    },
                    "&$focused": {
                      backgroundColor: "white",
                    },
                    ".Mui-disabled": {
                      backgroundColor: "#ced4da",
                      color: "#393939",
                      WebkitTextFillColor: "#393939 !important",
                    },
                    ".Mui-focusVisible": {
                      outlineColor: "#393939",
                    },
                  }}
                  InputProps={{
                    inputProps: { min: MIN_CL, max: MAX_CL },
                    startAdornment: isMobile ? (
                      <InputAdornment position="start">
                        {CURRENCY}
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                    endAdornment: isMobile ? (
                      <></>
                    ) : (
                      <InputAdornment position="start">
                        {CURRENCY}
                      </InputAdornment>
                    ),
                    className: isError ? styles.invalidAmount : undefined,
                  }}
                  {...field}
                  onBlur={(e: any) => {
                    helpers.setValue(valueCleaner(e.target.value));
                  }}
                  error={isError}
                  onChange={(e: any) => {
                    helpers.setValue(valueCleaner(e.target.value));
                  }}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {errors?.[field.name]}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={8} xs={8} margin="auto">
                <Slider
                  sx={{
                    color: theme.palette.tertiary.main,
                  }}
                  min={MIN_CL}
                  step={CL_STEP}
                  max={MAX_CL}
                  marks={marks}
                  disabled={isInEligible}
                  defaultValue={isInEligible ? 0 : CL_DEFAULT}
                  aria-labelledby={`list[${index}].requested_amount`}
                  {...field}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
export default CLInputs;
