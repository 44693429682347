// This file provides an overview of present experimental features
export const VITE_EXPERIMENTAL_RISK =
  import.meta.env.VITE_EXPERIMENTAL_RISK === "true";
export const VITE_INVOICES_PAGE = import.meta.env.VITE_INVOICES_PAGE === "true";
export const VITE_BUYER_PAGE = import.meta.env.VITE_BUYER_PAGE === "true";
export const VITE_EXPERIMENTAL_EWALLET =
  import.meta.env.VITE_EXPERIMENTAL_EWALLET === "true";

export const VITE_EXPERIMENTAL_TOTP =
  import.meta.env.VITE_ENV === "local" || import.meta.env.VITE_ENV === "dev";
export const VITE_EXPERIMENTAL_GUIDES = import.meta.env.VITE_ENV !== "main";
export const VITE_EXPERIMENTAL_THEME_SWITCHER =
  import.meta.env.VITE_ENV === "local" || import.meta.env.VITE_ENV === "dev";
export const VITE_EXPERIMENTAL_LEAVES_PAGE =
  import.meta.env.VITE_ENV === "local";

export const VITE_MAP_ORIGINATION =
  import.meta.env.VITE_MAP_ORIGINATION === "true";

export const VITE_EXPERIMENTAL_TERMSHEET =
  import.meta.env.VITE_ENV === "local" || import.meta.env.VITE_ENV === "dev";

export const VITE_ORIGINATION_TAB =
  import.meta.env.VITE_ENV === "local" || import.meta.env.VITE_ENV === "dev";

export const VITE_EXPERIMENTAL_MARKETPLACE =
  import.meta.env.VITE_ENV === "local";
