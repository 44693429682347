import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container, Slide, Stack } from "@mui/material";
import { useGenerateTestMutation } from "api/api-marketing";
import Input from "components/Common/Input";
import Layout from "components/Common/Layout";
import { Form, Formik } from "formik";
import useIsMobile from "hooks/useIsMobile";
import { toast } from "react-toastify";

const TestCampaign = () => {
  const [startTest, { isLoading }] = useGenerateTestMutation();
  const isMobile = useIsMobile();
  return (
    <Slide in>
      <Box>
        <Container>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(value) =>
              startTest(value?.email)
                .unwrap()
                .then(() => toast("test sent", { type: "success" }))
                .catch((err) =>
                  toast(`An error: ${err?.message}`, { type: "error" })
                )
            }
          >
            {() => (
              <Form>
                <Layout
                  title="Campaign Testing (Customer.io)"
                  textHeadingOptions={{
                    fontWeight: "bolder",
                    level: 2,
                    fontSize: isMobile ? "1.2em" : "1.5em",
                    lineHeight: "1em",
                  }}
                  mode="default"
                  headerConfig={{
                    left: 0,
                    mid: 0,
                    right: 8,
                  }}
                  alignItems="end"
                >
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="end"
                    spacing={2}
                  >
                    <Input name="email" label="Enter your test-email" />

                    <LoadingButton
                      variant="contained"
                      fullWidth
                      sx={{ padding: "0.75ch" }}
                      style={{ width: "10%" }}
                      loading={isLoading}
                      type="submit"
                    >
                      Test
                    </LoadingButton>
                  </Stack>
                </Layout>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Slide>
  );
};

export default TestCampaign;
