import { notifications } from "api/api-notifications";
import { useEffect } from "react";
import { useAppDispatch } from "redux/hooks";

const useServiceWorkerListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (Boolean(event.data) && event.data.type === "NEW_NOTIFICATION") {
          dispatch(notifications.util.invalidateTags(["NOTIFICATIONS"]));
        }
      });
    }
  }, [dispatch]);
};

export default useServiceWorkerListener;
