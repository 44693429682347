import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Alert, Grid, Stack, SvgIconTypeMap } from "@mui/material";
import {
  useEditScreeningDirectorMutation,
  usePostDirectorProofMutation,
} from "api/api-compliance";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import { MapContext } from "pages/maps/map";
import React from "react";
import DocumentProof from "./DocumentProof";
import { ScreeningProps, ScreeningPropsShareholderDirector } from "./types";

const COLOR_BY_HIT: Record<
  ScreeningProps["status"],
  SvgIconTypeMap["props"]["color"]
> = {
  "No Hits": "success",
  "True Hits": "error",
  "False Hits": "warning",
  "Hits Cleared": "primary",
};

interface DirectorScreeningProps {
  variation: string;
  accountType: string;
  source: string;
  isLocked: boolean;
}

const DirectorScreening: React.FC<DirectorScreeningProps> = ({
  variation,
  accountType,
  source,
  isLocked,
}) => {
  const mapName = React.useContext(MapContext);
  const formikProps = useFormikContext<{
    initialValuesDirectors: Record<string, ScreeningPropsShareholderDirector>;
  }>();
  const [patchValues] = useEditScreeningDirectorMutation();

  const saveToServer = () => {
    patchValues({
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      director_id:
        formikProps.values?.initialValuesDirectors[toCamelCase(variation)]?.id,
      data: {
        status:
          formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
            ?.screening?.[source]?.status,
        remarks:
          formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
            ?.screening?.[source]?.remarks,
        source: source,
      },
    });
  };

  const [postProofDocument] = usePostDirectorProofMutation();

  const handleProofSubmit = (file: File) => {
    postProofDocument({
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      director_id:
        formikProps.values?.initialValuesDirectors[toCamelCase(variation)]?.id,
      data: {
        file: file,
        source: source,
      },
    });
  };

  const directorId =
    formikProps.values?.initialValuesDirectors[toCamelCase(variation)]?.id;
  const directorName =
    formikProps.values?.initialValuesDirectors[toCamelCase(variation)]?.name;

  const proofs =
    formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
      ?.screening?.[source]?.documents ?? [];

  // Check for missing fields
  const missingFields = [];
  if (
    !formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
      ?.screening?.[source]?.status
  ) {
    missingFields.push("Status");
  }
  if (
    !formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
      ?.screening?.[source]?.remarks
  ) {
    missingFields.push("Remarks");
  }
  if (proofs.length === 0) {
    missingFields.push("Proof");
  }

  return (
    <>
      {missingFields?.length > 0 && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Alert severity="warning">
            Missing fields for director {directorName}:{" "}
            {missingFields?.join(", ")}
          </Alert>
        </Stack>
      )}
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0} mt={1}>
        <Grid item xs={12} lg={3}>
          <Stack direction="column">
            <LabelViewOnly label={variation} />
            <LabelViewOnly
              sx={{ fontWeight: 400 }}
              label={
                formikProps.values?.initialValuesDirectors[
                  toCamelCase(variation)
                ]?.name ?? "N/A"
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <VariableDropdown
            required
            disabled={isLocked}
            noHover={isLocked}
            name={`initialValuesDirectors.${[toCamelCase(variation)]}.screening.${[source]}.status`}
            keyValue="name"
            value={
              formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
                ?.screening?.[source]?.status
            }
            handleSave={(value) => {
              formikProps
                .setFieldValue(
                  `initialValuesDirectors.${[toCamelCase(variation)]}.screening.${[source]}.status`,
                  value
                )
                .then(() => saveToServer());
            }}
            setValue={(value) =>
              formikProps.setFieldValue(
                `initialValuesDirectors.${[toCamelCase(variation)]}.screening.${[source]}.status`,
                value
              )
            }
            data={[
              { name: "No Hits" },
              { name: "True Hits" },
              { name: "False Hits" },
              { name: "Hits Cleared" },
            ]}
            direction="column"
            label="Status"
            icon={
              <FiberManualRecordIcon
                color={
                  COLOR_BY_HIT[
                    formikProps.values?.initialValuesDirectors[
                      toCamelCase(variation)
                    ]?.screening?.[source]?.status as ScreeningProps["status"]
                  ]
                }
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <VariableInput
            required
            disabled={isLocked}
            noHover={isLocked}
            name={`initialValuesDirectors.${[toCamelCase(variation)]}.screening.${[source]}.remarks`}
            label="Remarks"
            type="text"
            value={
              formikProps.values?.initialValuesDirectors[toCamelCase(variation)]
                ?.screening?.[source]?.remarks
            }
            handleSave={(value) => {
              formikProps
                .setFieldValue(
                  `initialValuesDirectors.${[toCamelCase(variation)]}.screening.${[source]}.remarks`,
                  value
                )
                .then(() => saveToServer());
            }}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DocumentProof
            id={directorId}
            accountType={accountType}
            label={`Proof ${directorName}`}
            description=""
            documents={proofs}
            submit={handleProofSubmit}
            isLocked={isLocked}
          />
        </Grid>
      </Stack>
    </>
  );
};

export default DirectorScreening;
