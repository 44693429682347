import { Button, Grid, Typography, useTheme } from "@mui/material";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import { BUYER, SUPPLIER } from "codes";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";

import type { Country } from "types";
import styles from "../addcompany.module.scss";
import SelectorCard from "./SelectorCard";

interface ICompanyTypeSelector {
  onClick: () => void;
}

const CompanyTypeSelector = ({ onClick }: ICompanyTypeSelector) => {
  const formikProps: FormikProps<RegistrationFormFields> = useFormikContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { companyType, crStage } = formikProps.values;
  const isSeller =
    companyType?.toLowerCase() === SUPPLIER.toLowerCase() ||
    companyType?.toLowerCase() === "seller" ||
    companyType === t("supplier");
  const userType = isSeller ? "seller" : BUYER.toLowerCase();
  const disabled =
    formikProps.values.country === "" ||
    formikProps.values.registrationNumber === "" ||
    formikProps.values.companyNameInitial === "";
  const { data: countries } = useGetCountriesByTypeQuery(userType, {
    skip: !Boolean(companyType) || !Boolean(userType),
  });
  const theme = useTheme();

  const selectorCards = [
    {
      name: `${t("supplier")}`,
      bottomColorBand: "10px solid #001E61",
    },
    // {
    //   name: BUYER,
    //   bottomColorBand: "10px solid #C10230",
    // },
  ];

  const setter = (value: string, prev?: string, index?: number) => {
    const selected = countries?.find((item) => item.name === value);
    formikProps.setFieldValue("country", selected?.name, true);
    formikProps.setFieldValue("iso3", selected?.id, true);
  };

  if (crStage === 0)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={isMobile ? 2 : 4}
        marginBottom={isMobile ? "5vh" : 0}
      >
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 1 : 4}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                fontWeight="bold"
                textAlign="center"
                fontSize={isMobile ? "1.4em" : "1.8em"}
              >
                {t("i-am-a")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SelectorCard
                textActiveColor="#ffffff"
                itemSpacing={isMobile ? 2 : 3}
                cards={selectorCards}
                options={[`${t("buyer")}`, `${t("supplier")}`]}
                value={formikProps.values.companyType}
                setFieldValue={formikProps.setFieldValue}
                name="companyType"
                headingProps={{
                  fontSize: isMobile ? "1em" : "1.8em",
                  style: { margin: "auto" },
                }}
                style={{
                  width: isMobile ? "8em" : "13.75em",
                  height: isMobile ? "8em" : "auto",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {Boolean(companyType) && Boolean(countries?.length) && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  textAlign="center"
                  fontSize={isMobile ? "1.2em" : "1.8em"}
                >
                  {t("incorporation-country")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={isMobile ? "start" : "center"}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <Drop
                      multilevel={false}
                      data={countries as Country[]}
                      name="country"
                      value={formikProps.values.country}
                      keyValue="name"
                      placeholder={`${t("select-country")}`}
                      setValue={setter}
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={isMobile ? 1 : 2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <Input
                      labelClassName={styles.labelClass}
                      name="companyNameInitial"
                      label={`${t("company-name")}`}
                      disabled={!formikProps.values["country"]}
                      fullWidth
                      style={{ width: "100%" }}
                      type="text"
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Input
                      labelClassName={styles.labelClass}
                      name="registrationNumber"
                      label={`${t("registration-number")}`}
                      disabled={!formikProps.values["country"]}
                      fullWidth
                      style={{ width: "100%" }}
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item margin="auto" xs={8} lg={3}>
                <Button
                  children={`${t("next")}`}
                  disabled={
                    formikProps.values.country === "" ||
                    formikProps.values.registrationNumber === "" ||
                    formikProps.values.companyNameInitial === ""
                  }
                  onClick={() => onClick()}
                  variant="contained"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  return <></>;
};
export default CompanyTypeSelector;
