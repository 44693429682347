import { Grid } from "@mui/material";
import {
  useAddCountryDocumentMutation,
  useDeleteDocumentMutation,
  useGetCountryDocumentsQuery,
  useGetDocumentUrlQuery,
} from "api/api-legal";
import MainCard from "components/Common/Cards/MainCard";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import type { Item } from "components/Map/Sections/Compliance/CreditReport/types";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface GuidelinesDocUploadProps {
  iso3: string;
}

const GuidelinesDocUpload: React.FC<GuidelinesDocUploadProps> = ({ iso3 }) => {
  const formikProps = useFormikContext();

  const [addDocument, { isLoading: isLoadingSubmission }] =
    useAddCountryDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const {
    data: countryDocuments,
    isLoading,
    refetch,
  } = useGetCountryDocumentsQuery(iso3, {
    skip: !Boolean(iso3),
  });

  const [document_id, setId] = useState<string>();

  const to_upload: Item[] =
    countryDocuments && countryDocuments.length > 0
      ? countryDocuments
          ?.map((doc) => {
            return {
              id: doc.id,
              name: doc.name,
              label: doc.name,
              value: "",
              description: `${iso3} Country guideline`,
              items: [],
              yearly_set_count: 1,
              count_unique_uploaded:
                countryDocuments?.filter((d) => d.document_type === doc.id)
                  .length ?? 0,
            } as Item;
          })
          .filter((item): item is Item => item !== null)
      : [
          {
            name: "Guidelines Document",
            label: "Guidelines Document",
            value: "Guidelines Document",
            items: [],
            id: "",
            description: "",
            yearly_set_count: 1,
            count_unique_uploaded: 0,
          },
        ];

  const { data: documentUrl, refetch: refetchDocUrl } = useGetDocumentUrlQuery(
    {
      iso3: iso3,
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );
  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const fetchDocument = (id: string) => {
    setId(id);
    refetchDocUrl();
    onOpen();
  };
  const handleSubmitContactDocument = async (data: {
    file: File;
    year: number | null;
  }) => {
    addDocument({
      iso3: iso3,
      file: data.file,
    })
      .unwrap()
      .then((response) => {
        toast(`Uploaded ${response.name} successfully}!`, { type: "success" });
      })
      .catch((error: any) => {
        toast(`Failed to upload document: ${error.message}`, { type: "error" });
      });
  };

  const handleDeleteDocument = async (id: string) => {
    deleteDocument({
      iso3: iso3,
      document_id: id,
    })
      .unwrap()
      .then(() => {
        toast("Document deleted successfully!", { type: "success" });
      })
      .catch((error: any) => {
        toast(`Failed to delete document: ${error.message}`, { type: "error" });
      });
  };

  return (
    <MainCard
      title="Country Guidelines Document"
      headerSx={{
        px: 2,
        py: 1,
      }}
      headerConfig={{
        syncAction: () => refetch(),
      }}
      content
      contentSX={{
        px: 2,
        py: 1,
      }}
    >
      <DocumentUploadController
        data={to_upload}
        formikProps={formikProps}
        initialData={countryDocuments ?? []}
        onUpload={(file) => handleSubmitContactDocument(file)}
        onView={(fileName, id) => fetchDocument(id)}
        onDelete={(fileName, id) => handleDeleteDocument(id)}
        isLoadingSubmission={isLoadingSubmission}
        isLoading={isLoading}
        enableCount={false}
      />
      <Modal
        modalOverlaySx={{ borderRadius: "2ch" }}
        width="30%"
        height="100%"
        closeButton
        message={
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              {documentUrl?.url?.includes(".pdf?") ? (
                <Grid item xs={12} id="modal-docview">
                  <DocView uri={documentUrl?.url} staticView />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <img
                    src={documentUrl?.url}
                    alt={documentUrl?.url}
                    width="100%"
                    height="auto"
                    style={{
                      borderRadius: "2ch",
                      margin: "auto",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        open={show}
        onClose={onClose}
      />
    </MainCard>
  );
};

export default GuidelinesDocUpload;
