import { Add, SaveAlt } from "@mui/icons-material";
import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetLeadsQuery } from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateLead from "components/Company/Lead/CreateLead";
import ImportLead from "components/Company/Lead/ImportLead";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

interface ILeadTable {
  country: string;
  id: string;
  name: string;
  sales_owner: string;
  source: string;
  created_at: Date;
  profile: string;
  internal_name: string;
}

const Leads = () => {
  const isMobile = useIsMobile();

  const {
    data: leads,
    isFetching,
    isLoading,
    startedTimeStamp,
    fulfilledTimeStamp,
    refetch,
  } = useGetLeadsQuery(null, {
    refetchOnMountOrArgChange: false,
  });

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();

  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headerLength = Object.keys(leads?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1 : 200;
  const [createLead, setCreateLead] = useState<boolean>(false);
  const [importLead, setImportLead] = useState<boolean>(false);
  const openCreateLeadPage = () => setCreateLead(true);
  const closeCreateLeadPage = () => setCreateLead(false);
  const openImportLeadPage = () => setImportLead(true);
  const closeImportLeadPage = () => setImportLead(false);
  const isCreatorMode = createLead || importLead;

  useEffect(() => {}, [isFetching]);
  const getLabel = (): string => {
    switch (true) {
      case createLead:
        return "Create a Lead";
      case importLead:
        return "Import Lead";
      default:
        return "Leads";
    }
  };
  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={getLabel()}
          subtitle={
            (import.meta.env.VITE_ENV === "local" ||
              import.meta.env.VITE_ENV === "dev") &&
            !isCreatorMode &&
            `Development mode only - Data fetched in: ${
              ((fulfilledTimeStamp ?? 0) - (startedTimeStamp ?? 0)) / 1000
            }s`
          }
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 4,
            mid: 0,
            right: isCreatorMode ? 2 : 4,
            syncAction: isCreatorMode ? undefined : refetch,
            xs: {
              left: isCreatorMode ? 7 : 6,
              mid: 0,
              right: isCreatorMode ? 5 : 6,
            },
            alignItems: "center",
          }}
          primary={
            !importLead
              ? {
                  onClick: createLead
                    ? closeCreateLeadPage
                    : openCreateLeadPage,
                  isCancel: createLead,
                  startIcon: createLead ? undefined : <Add />,
                  children: createLead ? "Cancel" : "Lead",
                  variant: "contained",
                  fullWidth: true,
                }
              : undefined
          }
          secondary={
            !createLead
              ? {
                  onClick: importLead
                    ? closeImportLeadPage
                    : openImportLeadPage,
                  fullWidth: true,
                  startIcon: importLead ? undefined : <SaveAlt />,
                  children: importLead ? "Cancel" : "Import Lead",
                  color: importLead ? "error" : "primary",
                  variant: importLead ? "contained" : "outlined",
                }
              : undefined
          }
          mode="default"
        >
          {createLead && (
            <CreateLead
              onEnd={(internal_name) => {
                closeCreateLeadPage();
                if (internal_name)
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}${LEADS}/${internal_name}`
                  );
              }}
            />
          )}
          {importLead && <ImportLead />}
          {!createLead && !importLead && (
            <Grid item xs={12} margin="5px">
              <Grid item xs={12} ref={ref}>
                {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
                {!isFetching &&
                  leads !== undefined &&
                  Boolean(leads?.length) && (
                    <DataTablev2
                      data={leads}
                      defaultColumnWidth={defaultColumnWidth}
                      rowsPerPage={rowsPerPage}
                      customColumns={{
                        country: {
                          country: "Country",
                          type: "text",
                        },
                        internal_name: {
                          internal_name: "Lead ID",
                          type: "text",
                          link: {
                            href: (params: ILeadTable) =>
                              `${INCOMLEND_INTERNAL_LINK}/leads/${params.internal_name}`,
                            target: "_self",
                          },
                        },
                        profile: { profile: "Lead Type", type: "text" },
                        sales_owner: {
                          sales_owner: "Sales Owner",
                          type: "text",
                        },
                        source: { source: "Source", type: "text" },
                        name: {
                          name: "Account",
                          type: "text",
                          minWidth: 230,
                        },
                        created_at: {
                          created_at: "Created At",
                          type: "date",
                          renderCell: ({ value }: { value: string }) => (
                            <Typography>
                              {value
                                ? moment(value)
                                    .utc()
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : "-"}
                            </Typography>
                          ),
                        },
                      }}
                      sx={{
                        table: {
                          minWidth: "100% !important",
                        },
                      }}
                    />
                  )}
                {!Boolean(leads?.length) && <NoData text="No Leads found" />}
              </Grid>
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default Leads;
