import { useEffect, useState } from "react";
import type { Account } from "types";

export const leadTermsheetInfoFilled = (account: Account) => (
  account?.setup_fee !== undefined &&
  account?.setup_fee !== null &&
  account?.setup_fee !== 0 &&
  account?.facility_fee !== undefined &&
  account?.facility_fee !== null &&
  account?.facility_fee !== 0 &&
  account?.customer_maintenance_fee !== undefined &&
  account?.customer_maintenance_fee !== null &&
  account?.customer_maintenance_fee !== 0 &&
  account?.partner_maintenance_fee !== undefined &&
  account?.partner_maintenance_fee !== null &&
  account?.partner_maintenance_fee !== 0 &&
  (account.account_product === "factoring"
    ? account?.advanced_amount !== undefined &&
    account?.advanced_amount !== null &&
    account?.advanced_amount !== 0 &&
    account?.repayment_account !== undefined &&
    account?.repayment_account !== null &&
    account?.repayment_account !== ""
    : account?.first_invoice_amount !== undefined &&
    account?.first_invoice_amount !== null &&
    account?.first_invoice_amount !== 0 &&
    account?.first_invoice_expected_date !== undefined &&
    account?.first_invoice_expected_date !== null &&
    account?.annualized_discount_rate !== undefined &&
    account?.annualized_discount_rate !== null &&
    account?.annualized_discount_rate !== 0)
)

const useLeadStatus = (account: Account) => {
  const [isLeadRegistrationFilledUp, setisLeadRegistrationFilledUp] =
    useState(false);
  const [isLeadAdditionalFilledUp, setisLeadAdditionalFilledUp] =
    useState(false);
  const [isLeadTermSheetInfoFilledUp, setisLeadTermSheetInfoFilledUp] =
    useState(false);
  const [isCLRequestDisabled, setisCLRequestDisabled] = useState(false);

  useEffect(() => {
    setisLeadRegistrationFilledUp(
      account?.name !== "" &&
        account?.name !== undefined &&
        account?.name !== null &&
        account?.reg_no !== "" &&
        account?.reg_no !== undefined &&
        account?.reg_no !== null &&
        account?.address_line1 !== "" &&
        account?.address_line1 !== undefined &&
        account?.address_line1 !== "" &&
        account?.country !== "" &&
        account?.country !== undefined &&
        account?.country !== null &&
        account?.city !== "" &&
        account?.city !== undefined &&
        account?.city !== null &&
        account?.postcode !== "" &&
        account?.postcode !== undefined &&
        account?.postcode !== null &&
        account?.incorporation_date !== null &&
        account?.incorporation_date !== undefined &&
        account?.shipping_address?.address_line1 !== "" &&
        account?.shipping_address?.address_line1 !== undefined &&
        account?.shipping_address?.address_line1 !== null &&
        account?.shipping_address?.city !== "" &&
        account?.shipping_address?.city !== undefined &&
        account?.shipping_address?.city !== null &&
        account?.shipping_address?.postcode !== "" &&
        account?.shipping_address?.postcode !== undefined &&
        account?.shipping_address?.postcode !== null
    );
    setisLeadAdditionalFilledUp(
      account?.industry !== "" &&
        account?.goods_category !== "" &&
        account?.annual_revenue !== undefined &&
        account?.annual_revenue !== 0 &&
        account?.number_of_employees !== "" &&
        account?.number_of_employees !== undefined &&
        account?.number_of_employees !== null &&
        account?.accounting_software !== "" &&
        account?.accounting_software !== undefined &&
        account?.accounting_software !== null
    );
    setisCLRequestDisabled(
      account?.reg_no === "" ||
      account?.reg_no === undefined ||
      account?.reg_no === null ||
      account?.annual_revenue === undefined ||
      account?.annual_revenue === null ||
      account?.annual_revenue === 0
    );
    setisLeadTermSheetInfoFilledUp(leadTermsheetInfoFilled(account));
  }, [account]); 

  return [
    isLeadRegistrationFilledUp,
    isLeadAdditionalFilledUp,
    isLeadTermSheetInfoFilledUp,
    isCLRequestDisabled,
  ];
};
export default useLeadStatus;
