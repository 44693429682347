import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import { useGetAccountQuery, useGetLeadPartnerTermsheetQuery } from "api/api-accounts";
import { INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import type { PartnerData } from "types";
import LeadPartnerTabs from "./LeadPartnerTabs";
import { type LeadSections } from "./LeadSectionTabs";
import StatusDisplay from "components/Termsheets/StatusDisplay";
import { VITE_EXPERIMENTAL_TERMSHEET } from "experimental_features";

export default function LeadPartnerAccordion({
  sync,
  partner,
  partnerId
}: {
  sync: () => void;
  partner: PartnerData;
  partnerId?: string;
}) {
  const formikProps = useFormikContext<LeadSections>();
  const { partners, ...lead } = formikProps.values;
  const {
    data: account,
    isFetching,
    refetch,
  } = useGetAccountQuery(partner?.id ?? "", {
    skip: !Boolean(partner?.id),
    refetchOnMountOrArgChange: true,
  });

  const {
    data: termsheet,
    isFetching: isFetchingTermsheet,
    refetch: refetchTermsheet
  } = useGetLeadPartnerTermsheetQuery({
    lead_id: lead.id,
    partner_id: partner.id ?? ""
  })

  return (
    <Accordion
      defaultExpanded={partnerId === "" ? true : partnerId === partner.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Link
            fontWeight="bold"
            variant="h4"
            href={`${INCOMLEND_INTERNAL_LINK}/leads/${partner?.internal_name}`}
          >
            {partner?.name}
          </Link>

          <IconButton onClick={(e) => {
            e.stopPropagation()
            refetch()
            refetchTermsheet()
          }}>
            <SyncIcon />
          </IconButton>
          {VITE_EXPERIMENTAL_TERMSHEET && (
            isFetchingTermsheet
              ? <SkeletonLoad bars={1} />
              : <StatusDisplay
                status={termsheet?.termsheet_status ?? "PENDING_EXTERNAL"}
                style={{ width: "fit-content" }}
              />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && account && (
          <LeadPartnerTabs
            lead={lead}
            partner={account}
            partnerDetails={partner}
            onEnd={() => {
              sync();
              refetch();
            }}
            isCurrentPartner={partnerId === partner.id}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
