import RemoveIcon from "@mui/icons-material/Remove";
import { Chip, FormHelperText, Grid, useTheme } from "@mui/material";
import DateInput from "components/Common/DateInput";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import React, { useState } from "react";
import { CustomColumn } from "../..";
import styles from "../../datatablev2.module.scss";
import { FILTER_TYPE_TILL } from "../filterTypes";

const SingleDateFilter = ({
  range,
  label,
  filters,
  setFilters,
  customColumns,
  disabled,
}: {
  range: Date[];
  label: string;
  filters: Record<string, any>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  customColumns: CustomColumn;
  disabled?: boolean;
}) => {
  const [minimumDate, setMinimumDate] = useState<Date | undefined>(
    filters[label]?.keywords[0] ?? range[0]
  );
  const [maximumDate, setMaximumDate] = useState<Date | undefined>(
    filters[label]?.keywords[1] ?? range[1]
  );
  const theme = useTheme();
  const customColumnsExist = customColumns && !isObjectEmpty(customColumns);
  const isMobile = useIsMobile();

  const clear = () => {
    setMinimumDate(range[0]);
    setMaximumDate(range[1]);
    const revertFilters = { ...filters };
    delete revertFilters[label];
    setFilters(revertFilters);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item lg={10} xs={12}>
        <LabelViewOnly
          label={
            customColumnsExist && customColumns?.[label]?.[label]
              ? customColumns?.[label]?.[label]
              : label
          }
          sx={{
            fontSize: "1em",
            color: theme.palette.primary.main,
            textTransform: "capitalize",
            letterSpacing: "0.01em",
            lineHeight: "1.5em",
          }}
        />
      </Grid>
      {disabled && <FormHelperText error>Invalid Date Range</FormHelperText>}
      {filters[label]?.keywords && (
        <Grid item lg={2} xs={1.5}>
          <Chip label="Clear" onDelete={clear} onClick={clear} />
        </Grid>
      )}
      <Grid item lg={8} xs={12}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={4} xs={12} margin="auto">
            <DateInput
              disabled={disabled}
              label="Minimum Date"
              labelClassName={styles.labelClass}
              wrapperClassName={styles.datePickerWrapper}
              style={{ width: isMobile ? "100%" : "80%" }}
              value={minimumDate}
              setValue={setMinimumDate}
              onChangeCommitted={(date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    [label]: {
                      comparator: FILTER_TYPE_TILL,
                      keywords: [date, maximumDate],
                    },
                  };
                });
              }}
            />
          </Grid>
          <Grid item lg={1} xs={3}>
            <RemoveIcon sx={{ textAlign: "center" }} />
          </Grid>
          <Grid item lg={4} xs={12} margin="auto">
            <DateInput
              label="Maximum Date"
              disabled={disabled}
              labelClassName={styles.labelClass}
              wrapperClassName={styles.datePickerWrapper}
              style={{ width: isMobile ? "100%" : "80%" }}
              value={maximumDate}
              setValue={setMaximumDate}
              onChangeCommitted={(date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    [label]: {
                      comparator: FILTER_TYPE_TILL,
                      keywords: [minimumDate, date],
                    },
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SingleDateFilter;
