import { Email, Warning } from "@mui/icons-material";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useEditCreditReportMutation,
  useGetComplianceProvidersQuery,
  useGetComplianceTemplatesQuery,
  useGetCreditReportDocumentsQuery,
  useGetCreditReportQuery,
} from "api/api-compliance";
import { useGetMapSummaryQuery } from "api/api-maps";
import { SUPPLIER } from "codes";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik, type FormikProps } from "formik";
import toCamelCase from "helpers/camelCase";
import isObjectEmpty from "helpers/isObjectEmpty";
import useAccountData from "hooks/useAccountData";
import useCreditReportTableValueGenerator from "hooks/useCreditReportTableValueGenerator";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React from "react";
import { toast } from "react-toastify";
import type { MapSummary } from "types";
import CreditReportDocuments from "./CreditReportDocuments";
import Discrepancies from "./Discrepancies";
import InputComponent from "./InputComponent";
import type { FormValues } from "./types";
import useGetDiscrepancies from "./useGetDiscrepancies";

interface ReportComparisonProps {
  accountType: "supplier" | "buyer";
  isLocked: boolean;
}
interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}
interface CRITERIA_TYPE extends Record<string, any> {
  [key: string]: MapTemplate;
}

const ReportComparison: React.FC<ReportComparisonProps> = ({
  accountType,
  isLocked,
}) => {
  const theme = useTheme();
  const mapId = React.useContext(MapContext);
  const itemType = "credit_report";
  const {
    data: creditReportTemplate,
    isLoading: templateLoading,
    refetch: refetchTemplate,
  } = useGetComplianceTemplatesQuery(itemType);
  const {
    data: creditReportValues,
    isLoading: valuesLoading,
    isFetching: isFetchingCRValues,
    refetch: refetchValues,
  } = useGetCreditReportQuery(
    { map_name: mapId ?? "", account_type: accountType },
    { skip: !mapId }
  );

  const { data: mapData } = useGetMapSummaryQuery(mapId ?? "", {
    skip: !mapId,
  });
  const getAccountId = (mapData: MapSummary) => {
    if (accountType?.toLowerCase() === SUPPLIER?.toLowerCase()) {
      return mapData?.seller_id;
    } else {
      return mapData?.buyer_id;
    }
  };
  const accountId = mapData && getAccountId(mapData);

  const [editMapTransaction] = useEditCreditReportMutation();
  const { accountInput, isLoading: mapLoading } = useAccountData({
    accountType,
    mapId: mapId ?? "",
  });

  const { data: providers = [], isLoading: isLoadingProviders } =
    useGetComplianceProvidersQuery();

  const { data: creditReportDocuments, refetch: refetchDocuments } =
    useGetCreditReportDocumentsQuery(accountId ?? "", {
      skip: !Boolean(accountId),
    });

  const UPLOADED_REPORTS_FOR_PROVIDERS =
    creditReportDocuments?.map((item) => item.document_type?.toLowerCase()) ??
    [];
  const providersToDisplay = providers.filter((provider) =>
    UPLOADED_REPORTS_FOR_PROVIDERS?.includes(provider?.toLowerCase())
  );
  const CREDIT_REPORTS_ENUM = [...providersToDisplay, "finalversion"];

  const criteriaReportFormat = CREDIT_REPORTS_ENUM?.map((source) =>
    toCamelCase(source)
  );

  const [transactionCriteria] = useCreditReportTableValueGenerator(
    criteriaReportFormat,
    creditReportValues,
    creditReportTemplate
  );

  const formatValue = (value: any) => {
    if (value instanceof Date) {
      return moment(value).format("DD/MM/YYYY");
      // return value?.toLocaleDateString();
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

  const ifProviderExistsInData = (provider: string): boolean =>
    providersToDisplay
      ?.map((item) => item?.toLowerCase())
      .includes(provider?.toLowerCase());

  const initialValues: FormValues = {
    ...(transactionCriteria as CRITERIA_TYPE),
    verification: Object.fromEntries(
      Object.entries(creditReportTemplate ?? {}).map(([key]) => [
        toCamelCase(key),
        false,
      ])
    ),
    clearDiscrepancy: Object.fromEntries(
      Object.entries(creditReportTemplate ?? {}).map(([key]) => [
        toCamelCase(key),
        false,
      ])
    ),
  };

  const filteredCreditReportValues: any = Object.entries(
    creditReportValues ?? {}
  )
    .filter(
      ([provider, value]) =>
        ifProviderExistsInData(provider) || provider === "finalversion"
    )
    .reduce(
      (acc, [provider, value]) => {
        acc[provider] = value;
        return acc;
      },
      {} as Record<string, any>
    );

  const discrepancies: string[] = useGetDiscrepancies(
    filteredCreditReportValues,
    accountInput
  );

  const sendEmail = (valuesVerification: Record<string, any>) => {
    const checkedRows = Object.entries(valuesVerification)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    const email = "##########";
    const subject = "Credit Report Verification";
    const body = `The following rows need to be verified:%0D%0A- ${checkedRows.join("%0D%0A- ")}`;

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  const handleFinalVersionChange = (
    formikProps: FormikProps<any>,
    key: string,
    value: any
  ) => {
    formikProps.setFieldValue(`finalversion.${toCamelCase(key)}`, value);
  };
  const syncAction = () => {
    refetchTemplate();
    refetchValues();
    refetchDocuments();
  };

  return (
    <Layout
      title="Credit Reports"
      headerConfig={{ left: 12, mid: 0, right: 0, syncAction }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {(formikProps) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {accountId && (
                  <CreditReportDocuments
                    account_id={accountId}
                    onDocumentDeleted={syncAction}
                    isLocked={isLocked}
                  />
                )}
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendEmail(formikProps.values.verification)}
                  disabled={
                    !Boolean(
                      Object.values(formikProps.values.verification).filter(
                        Boolean
                      ).length
                    ) || isLocked
                  }
                >
                  <Email />
                  Send Email
                </Button>
              </Grid>
              <Discrepancies discrepancies={discrepancies} />
              <Grid item xs={12}>
                {valuesLoading && <SkeletonLoad bars={10} />}
                {!valuesLoading && (
                  <Table component={Paper}>
                    <TableHead
                      sx={{
                        borderBottom: `3px solid ${theme.palette.error.main}`,
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography fontSize="1em" fontWeight="bold">
                            Field Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="1em" fontWeight="bold">
                            As per Account Input
                          </Typography>
                        </TableCell>
                        {isLoadingProviders && <SkeletonLoad bars={10} />}
                        {!isLoadingProviders &&
                          providersToDisplay?.map((provider) => (
                            <TableCell key={provider}>
                              <Typography fontSize="1em" fontWeight="bold">
                                {provider?.toUpperCase()}
                              </Typography>
                            </TableCell>
                          ))}
                        <TableCell>
                          <Typography fontSize="1em" fontWeight="bold">
                            Final version
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="1em" fontWeight="bold">
                            Request Verification
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {templateLoading && <SkeletonLoad bars={10} />}
                    {!templateLoading && creditReportTemplate && (
                      <TableBody>
                        {Object.entries(creditReportTemplate)?.map(
                          ([key, keyParams]) => {
                            const rowHasDiscrepancy =
                              discrepancies.includes(key);
                            return (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography
                                    fontSize="1em"
                                    fontWeight="bold"
                                    sx={{
                                      color: rowHasDiscrepancy
                                        ? theme.palette.error.main
                                        : "inherit",
                                    }}
                                  >
                                    {key}
                                    {rowHasDiscrepancy && (
                                      <Warning
                                        sx={{
                                          marginLeft: 1,
                                          verticalAlign: "middle",
                                        }}
                                      />
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {mapLoading && <SkeletonLoad bars={10} />}
                                  {!mapLoading && (
                                    <Typography
                                      fontSize="1em"
                                      fontWeight="bold"
                                    >
                                      {accountInput &&
                                        formatValue(accountInput[key])}
                                    </Typography>
                                  )}
                                </TableCell>
                                {valuesLoading && <SkeletonLoad bars={10} />}
                                {!valuesLoading &&
                                  !isObjectEmpty(creditReportValues ?? {}) &&
                                  Object.entries(creditReportValues ?? {})
                                    ?.filter(([provider, value]) =>
                                      ifProviderExistsInData(provider)
                                    )
                                    ?.map(([provider, p_value], index) => {
                                      const value =
                                        formikProps.values?.[provider]?.[
                                          toCamelCase(key)
                                        ];

                                      const fieldName = `${provider}.${toCamelCase(key)}`;
                                      return (
                                        <TableCell
                                          key={provider}
                                          sx={{
                                            color: rowHasDiscrepancy
                                              ? theme.palette.error.main
                                              : "inherit",
                                          }}
                                        >
                                          {valuesLoading ? (
                                            <SkeletonLoad bars={1} />
                                          ) : (
                                            <InputComponent
                                              isLocked={isLocked}
                                              type={keyParams?.type}
                                              name={fieldName}
                                              value={formatValue(value)}
                                              data={
                                                keyParams?.data
                                                  ? (keyParams?.data as string[])
                                                  : []
                                              }
                                              handleSave={(value) => {
                                                formikProps.setFieldValue(
                                                  fieldName,
                                                  value
                                                );
                                                mapId &&
                                                  editMapTransaction({
                                                    map_name: mapId,
                                                    account_type: accountType,
                                                    data: {
                                                      map_metadata_id:
                                                        keyParams.id,
                                                      source: provider,
                                                      value: value,
                                                    },
                                                  })
                                                    .unwrap()
                                                    .then(() =>
                                                      toast.success(
                                                        `Updated ${key} successfully!`
                                                      )
                                                    )
                                                    .catch((error: any) =>
                                                      toast.error(
                                                        `Failed to upload ${key}`
                                                      )
                                                    );
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                <TableCell
                                  sx={{
                                    color: rowHasDiscrepancy
                                      ? theme.palette.error.main
                                      : "inherit",
                                  }}
                                >
                                  {valuesLoading ? (
                                    <SkeletonLoad bars={1} />
                                  ) : (
                                    <InputComponent
                                      isLocked={isLocked}
                                      type={keyParams?.type}
                                      name={`finalversion.${toCamelCase(key)}`}
                                      value={formatValue(
                                        formikProps.values?.["finalversion"]?.[
                                          toCamelCase(key)
                                        ]
                                      )}
                                      data={
                                        keyParams?.data
                                          ? (keyParams?.data as string[])
                                          : []
                                      }
                                      handleSave={(value) => {
                                        handleFinalVersionChange(
                                          formikProps,
                                          key,
                                          value
                                        );
                                        mapId &&
                                          editMapTransaction({
                                            map_name: mapId,
                                            account_type: accountType,
                                            data: {
                                              map_metadata_id: keyParams.id,
                                              source: "finalversion",
                                              value,
                                            },
                                          });
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  <CheckboxField
                                    label=""
                                    name={`verification.${key}`}
                                    value={
                                      formikProps.values.verification[
                                        toCamelCase(key)
                                      ]
                                    }
                                    getFieldValue={(value) => {
                                      formikProps.setFieldValue(
                                        `verification.${key}`,
                                        value
                                      );
                                    }}
                                    disabled={isLocked}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    )}
                  </Table>
                )}
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default ReportComparison;
