import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import {
  DEFAULT_ROWS,
  INCOMLEND_INTERNAL_LINK,
  PRODUCTS,
  LEADS,
  TERMSHEETS_LINK,
  REJECTED,
  TERMSHEET_CANCELLED,
  INVOICE_CANCELLED,
} from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";
import StatusDisplay, {
  type TermSheetViewingRole,
} from "components/Termsheets/StatusDisplay";
import {
  useGetCustomerTemsheetListQuery,
  useGetTermsheetListQuery,
} from "api/api-accounts";
import type { Account, TermSheetListItem } from "types";
import history from "helpers/history";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useAppSelector } from "redux/hooks";
import { PERMISSIONS } from "permissions";
import moment from "moment";

const Termsheets = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const { data: curr_user, isFetching: isFetchingUser } =
    useGetCurrentUserQuery();
  const defaultColumnWidth = width ? (width / 11) * 1.2 : 200;
  const settings = curr_user?.settings ?? {};
  const isInternal = curr_user?.is_internal;
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const internalRole: TermSheetViewingRole = USER_PERMISSIONS.includes(
    PERMISSIONS.termsheet_approver
  )
    ? "HOD"
    : "RM";
  const currentRole: TermSheetViewingRole = isInternal
    ? internalRole
    : "CUSTOMER";
  const toBeHidden = [
    "id",
    "account_relationship_id",
    "created_at",
    "comment",
    "expiry_info",
    "last_update",
  ];
  const toBeHiddenForRM = [...toBeHidden, "submitted_by"];
  const textSx = {
    fontSize: "1.1em!important",
    lineHeight: "2em",
    height: "1.8em!important",
    overflow: "hidden",
    maxWidth: "76%",
    textOverflow: "ellipsis",
    minWidth: isMobile ? "100%" : "10vw",
  };

  const getProductLabel = (product: string) => {
    if (product === PRODUCTS.factoring) return "Factoring";
    if (product === PRODUCTS.reverse_factoring) return "Reverse Factoring";
    return null;
  };

  const getHiddenColums = () => {
    if (currentRole === "CUSTOMER") {
      return [...toBeHidden, "internal_name", "lead"];
    }
    if (currentRole === "RM") {
      return toBeHiddenForRM;
    }
    return toBeHidden;
  };

  const {
    data: termsheetsList,
    isFetching,
    refetch,
  } = useGetTermsheetListQuery(null, {
    skip: !isInternal,
  });
  const {
    data: customerTermsheetsList,
    refetch: customerRefetch,
    isFetching: customerIsFetching,
  } = useGetCustomerTemsheetListQuery(null, {
    skip: isInternal,
  });
  const termsheetsListToDisplay = isInternal
    ? termsheetsList
    : customerTermsheetsList;
  const isFetchingTermsheetData = isInternal ? isFetching : customerIsFetching;
  return (
    <Slide in direction="left">
      <Box flexGrow={1}>
        <Layout
          title="Termsheets"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: () => {
              isInternal ? refetch() : customerRefetch();
            },
            left: 12,
            mid: 0,
            right: 0,
          }}
          mode="default"
          alignItems="center"
        >
          <Grid item xs={12} ref={ref}>
            <Grid container spacing={1}>
              {isFetchingTermsheetData && <SkeletonLoad bars={10} />}
              {!isFetchingTermsheetData &&
                !isFetchingUser &&
                termsheetsListToDisplay && (
                  <DataTablev2
                    rowsPerPage={rowsPerPage}
                    defaultColumnWidth={defaultColumnWidth}
                    data={termsheetsListToDisplay?.map((termsheet) => {
                      const { comment, submitted_by, last_update } = termsheet;
                      return {
                        ...termsheet,
                        last_updated_by:
                          comment?.created_by ?? submitted_by ?? "",
                        last_updated_on:
                          comment?.created_at ?? last_update ?? null,
                        comments: comment?.comments ?? "",
                      };
                    })}
                    toBeHidden={getHiddenColums()}
                    customColumns={{
                      internal_name: {
                        internal_name: "Termsheet ID",
                        type: "text",
                        minWidth: 230,
                        link: {
                          href: (params: TermSheetListItem) => {
                            if (
                              [
                                REJECTED,
                                TERMSHEET_CANCELLED,
                                INVOICE_CANCELLED,
                              ].includes(params.termsheet_status)
                            ) {
                              return `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${params?.internal_name}`;
                            } else {
                              return `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params?.lead?.internal_name}?partner=${params?.partner?.id}`;
                            }
                          },
                          target: "_self",
                        },
                      },
                      lead: {
                        lead: "Customer",
                        type: "text",
                        minWidth: 230,
                        renderCell: ({ value }: { value: Account }) => (
                          <Typography sx={textSx}>{value?.name}</Typography>
                        ),
                      },
                      partner: {
                        partner: "Partner",
                        type: "text",
                        minWidth: 230,
                        renderCell: ({ value }: { value: Account }) => (
                          <Typography
                            sx={isInternal ? textSx : { ...textSx, textDecoration: "underline" }}
                          >
                            {value?.name}
                          </Typography>
                        ),
                        link: isInternal
                          ? undefined
                          : {
                              href: (params: TermSheetListItem) => {
                                return `${TERMSHEETS_LINK}/${params?.id}`;
                              },
                              target: "_self",
                            },
                      },
                      product: {
                        product: "Product",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Typography sx={textSx}>
                            {getProductLabel(value)}
                          </Typography>
                        ),
                      },
                      submitted_by: {
                        submitted_by: "Submitted By",
                        type: "text",
                      },
                      termsheet_status: {
                        termsheet_status: "Status",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Grid
                            container
                            justifyContent={isMobile ? "left" : "center"}
                          >
                            <Grid item xs={12}>
                              <StatusDisplay status={value} />
                            </Grid>
                          </Grid>
                        ),
                      },
                      last_updated_by: {
                        last_updated_by: "Last Updated By",
                        type: "text",
                      },
                      last_updated_on: {
                        last_updated_on: "Last Updated On",
                        type: "text",
                        renderCell: ({ value }: { value: string }) => {
                          return (
                            <Typography sx={textSx}>
                              {value
                                ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                                : "None"}
                            </Typography>
                          );
                        },
                      },
                      comments: {
                        comments: "Comments",
                        minWidth: 300,
                        type: "text",
                        renderCell: ({ value }: { value: string }) => (
                          <Typography
                            sx={textSx}
                            color={value ? "primary" : "grey"}
                          >
                            {value.length ? value : "None"}
                          </Typography>
                        ),
                      },
                    }}
                    onRowClicked={(row) => {
                      const params = new URLSearchParams();
                      if (isInternal) {
                        if (
                          [
                            REJECTED,
                            TERMSHEET_CANCELLED,
                            INVOICE_CANCELLED,
                          ].includes(row.termsheet_status)
                        ) {
                          history.push(
                            `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${row?.internal_name}`
                          );
                        } else {
                          params.set("partner", row?.partner?.id);
                          history.push(
                            `${INCOMLEND_INTERNAL_LINK}${LEADS}/${row?.lead?.internal_name}?${params.toString()}`
                          );
                        }
                      } else history.push(`${TERMSHEETS_LINK}/${row?.id}`);
                    }}
                  />
                )}
              {!isFetchingTermsheetData &&
                (!termsheetsListToDisplay ||
                  termsheetsListToDisplay?.length === 0) && (
                  <NoData text="No termsheets found" sx={{ margin: "auto" }} />
                )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default Termsheets;
