// @flow

import type {
  Column,
  Entities,
  InvoiceTask,
  TASK_TYPES,
  Task,
  TaskData,
  TaskMap,
} from "./types";

export const SELECT_TASK_TYPE: { [task_type: string]: TASK_TYPES } = {
  InvoiceStatus: "InvoiceStatus",
  Ticketing: "Ticketing",
};

const getTasks = (data: TaskData<any>[], type: TASK_TYPES) => {
  if (type === SELECT_TASK_TYPE.InvoiceStatus)
    return data.map(
      (item: any): InvoiceTask => ({
        id: `${item.id}`,
        content: item.data,
        value: item.value,
        supplier_buyer_map: item.data.supplier_buyer_map,
        bg: item.bg,
      })
    );
  return data.map(
    (item: any): Task => ({
      id: `${item.id}`,
      content: item.data,
      value: item.value,
      bg: item.bg,
    })
  );
};

const getTaskMap = (tasks: any[]): TaskMap =>
  tasks.reduce((previous: TaskMap, current: Task): TaskMap => {
    previous[current.id] = current;
    return previous;
  }, {});

const getColumn = (data: TaskData<any>[], columnName: string): Column => ({
  id: columnName,
  title: columnName,
  taskIds: data
    .filter((item) => item.status === columnName)
    .map((item) => item.id),
});

const getUniqueColumns = (data: TaskData<any>[]): string[] => [
  ...new Set(data.map((item) => item.status)),
];
export const getEntities = (
  data: TaskData<any>[],
  type: TASK_TYPES
): Entities => {
  return {
    columnOrder: getUniqueColumns(data),
    columns: getUniqueColumns(data).reduce(
      (a, columnName) => ({ ...a, [columnName]: getColumn(data, columnName) }),
      {}
    ),
    tasks: getTaskMap(getTasks(data, type)),
  };
};
