import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetAccountingSoftwaresQuery,
  useUpdateAccountMutation,
} from "api/api-accounts";
import { useGetIndustriesQuery } from "api/api-company";
import { BUYER, NEW_USER, PRESALES, REGISTERED } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Goods from "components/Company/Goods";
import { Formik } from "formik";
import valueCleaner from "helpers/valueCleaner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import styles from "../company.module.scss";

import type { Account } from "types";
import { AdditionalDetailsKeyMappings } from "./validationSchema";

const LeadPartnerAdditionalInformation = ({
  partner,
}: {
  partner: Account;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [updateCompany, { isLoading }] = useUpdateAccountMutation();
  const { country } = partner;
  const APP_STATE = useAppSelector((state) => state.appState.value);
  const { data: industries, isFetching: isFetchingIndustries } =
    useGetIndustriesQuery();
  const {
    data: accountingSoftwares,
    isFetching: isFetchingAccountingSoftware,
  } = useGetAccountingSoftwaresQuery();
  const additionalDetailskeyMappings = AdditionalDetailsKeyMappings();

  const IS_POST_SALES = (state: string) =>
    ![NEW_USER, REGISTERED, PRESALES].includes(state);

  return (
    <Formik initialValues={partner} onSubmit={() => {}}>
      {(formikProps) => {
        const setterAccountingSoftware = (value: string) => {
          formikProps.setTouched({ accounting_software: true }, true);
          formikProps.setFieldValue("accounting_software", value, true);
        };
        const setterNumberOfEmployees = (value: string) => {
          formikProps.setTouched({ number_of_employees: true }, true);
          formikProps.setFieldValue("number_of_employees", value, true);
        };

        const hasMissingFields = Boolean(
          Object.keys(additionalDetailskeyMappings).filter(
            (item) => !(formikProps.values as Record<string, any>)[item]
          ).length && formikProps.values.profile === BUYER.toLowerCase()
        );
        const { values, dirty } = formikProps;
        return (
          <MainCard
            border
            content
            boxShadow
            sx={{ minWidth: "60vw" }}
            shadow={theme.shadows[16]}
            title={
              <Typography fontWeight="bold" variant="h3">
                Additional Details
              </Typography>
            }
            footer={
              <Grid xs={12} lg={2} ml="auto" my={1} justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                  disabled={!dirty}
                  onClick={() => {
                    updateCompany({
                      ...formikProps.values,
                      annual_revenue: valueCleaner(
                        `${formikProps.values.annual_revenue}`
                      ),
                      country,
                      type: "LEAD",
                    })
                      .unwrap()
                      .then(() => {
                        toast(
                          `Successfully saved Additional Information for ${formikProps?.values?.name}`,
                          {
                            type: "success",
                          }
                        );
                        formikProps.resetForm({ values });
                      })
                      .catch((error) => {
                        const errorFile = error?.message;
                        toast(`Something went wrong : ${errorFile}`, {
                          type: "error",
                        });
                      });
                  }}
                >
                  {t("save")}
                </LoadingButton>
              </Grid>
            }
          >
            <Stack spacing={1}>
              {hasMissingFields && (
                <Alert severity="error">
                  <AlertTitle>Missing Fields</AlertTitle>
                  <Stack
                    spacing={1}
                    direction="row"
                    flexWrap="wrap"
                    display="flex"
                    rowGap="1ch"
                  >
                    {Object.keys(additionalDetailskeyMappings)
                      .filter(
                        (item) =>
                          !(formikProps.values as Record<string, any>)[item]
                      )
                      .map((error) => (
                        <Chip
                          label={`${additionalDetailskeyMappings[error]}`}
                        />
                      ))}
                  </Stack>
                </Alert>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Grid container>
                    {industries && industries?.length && (
                      <Grid item xs={12}>
                        <>
                          {isFetchingIndustries && <SkeletonLoad bars={1} />}
                          {!isFetchingIndustries && (
                            <Drop
                              label="Industry"
                              required
                              labelClassName={styles.labelClass}
                              name="industry"
                              style={{ width: "100%" }}
                              keyValue="name"
                              data={industries}
                              placeholder="Select Industry"
                              {...formikProps}
                            />
                          )}
                        </>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Goods
                        industry={formikProps.values.industry ?? ""}
                        disabled={false}
                        formikProps={formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {IS_POST_SALES(APP_STATE) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      {accountingSoftwares && accountingSoftwares?.length && (
                        <>
                          {isFetchingAccountingSoftware && (
                            <SkeletonLoad bars={1} />
                          )}
                          {!isFetchingAccountingSoftware && (
                            <Drop
                              labelClassName={styles.labelClass}
                              label="Company Accounting Software"
                              required
                              name="accounting_software"
                              style={{ width: "100%" }}
                              keyValue="name"
                              data={accountingSoftwares}
                              setValue={setterAccountingSoftware}
                              placeholder="Select Accouting Software"
                              {...formikProps}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                    {formikProps.values.accounting_software === "Others" && (
                      <Grid item lg={6} xs={12}>
                        <Input
                          labelClassName={styles.labelClass}
                          name="other_accounting_software"
                          required
                          label="Please specify your accounting software"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12} lg={6}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="annual_revenue"
                    label="Annual Revenue (in USD equivalent)"
                    required
                    type="money"
                    textfieldProps={{
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">USD</InputAdornment>
                        ),
                      },
                    }}
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>
                {IS_POST_SALES(APP_STATE) && (
                  <Grid item xs={12} lg={6}>
                    <Drop
                      labelClassName={styles.labelClass}
                      label="No. of Employees"
                      required
                      name="number_of_employees"
                      style={{ width: "100%" }}
                      keyValue="name"
                      value={formikProps.values.number_of_employees}
                      data={[
                        {
                          name: "Self-employed",
                          value: "Self-employed",
                        },
                        {
                          name: "1-10 employees",
                          value: "1-10 employees",
                        },
                        {
                          name: "11-50 employees",
                          value: "11-50 employees",
                        },
                        {
                          name: "51-200 employees",
                          value: "51-200 employees",
                        },
                        {
                          name: "201-500 employees",
                          value: "201-500 employees",
                        },
                        {
                          name: "501-1000 employees",
                          value: "501-1000 employees",
                        },
                        {
                          name: "1001-5000 employees",
                          value: "1001-5000 employees",
                        },
                        {
                          name: "5001-10,000 employees",
                          value: "5001-10,000 employees",
                        },
                        {
                          name: "10,000+ employees",
                          value: "10,000+ employees",
                        },
                      ]}
                      setValue={setterNumberOfEmployees}
                      placeholder="Select Range"
                      {...formikProps}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          </MainCard>
        );
      }}
    </Formik>
  );
};
export default LeadPartnerAdditionalInformation;
