import equal from "fast-deep-equal";
import toCamelCase from "helpers/camelCase";
import isObjectEmpty from "helpers/isObjectEmpty";
import objectCamelcaser from "helpers/objectCamelcaser";
import { useEffect, useMemo, useState } from "react";

interface PARAM_VALUES {
  [source: string]: {
    [field_name: string]: any;
  };
}

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface CRITERIA_TYPE {
  [key: string]: Record<string, any>;
}

const useCreditReportTableValueGenerator = (
  CRITERIA_ENUM: string[],
  values?: PARAM_VALUES,
  template?: MapTemplate
) => {
  const INITIAL_VALUES_AWAITING_API = useMemo(() => {
    return CRITERIA_ENUM.reduce((acc, current) => {
      acc[current] = Object.keys(template ?? {}).reduce(
        (innerAcc, item) => {
          innerAcc[toCamelCase(item)] = undefined;
          return innerAcc;
        },
        {} as Record<string, any>
      );
      return acc;
    }, {} as CRITERIA_TYPE);
  }, [CRITERIA_ENUM, template]);

  const generateInitialValues = useMemo(() => {
    return (valueObject?: PARAM_VALUES): CRITERIA_TYPE => {
      if (valueObject && !isObjectEmpty(valueObject)) {
        return Object.entries(INITIAL_VALUES_AWAITING_API).reduce(
          (acc, [CALL_TYPE, FIELDS]) => {
            if (Object.prototype.hasOwnProperty.call(valueObject, CALL_TYPE)) {
              const CURRENT_VALUE_OF_FIELDS = valueObject[CALL_TYPE];
              const CONSOLIDATED_FIELDS = {
                ...FIELDS,
                ...objectCamelcaser(CURRENT_VALUE_OF_FIELDS),
              };

              acc[CALL_TYPE] = Object.entries(CONSOLIDATED_FIELDS).reduce(
                (innerAcc, [key, value]) => {
                  innerAcc[key] = value?.name;
                  return innerAcc;
                },
                {} as Record<string, any>
              );
            } else {
              acc[CALL_TYPE] = FIELDS;
            }
            return acc;
          },
          {} as CRITERIA_TYPE
        );
      }
      return INITIAL_VALUES_AWAITING_API;
    };
  }, [INITIAL_VALUES_AWAITING_API]);

  const [backgroundPerCriteria, setBackgroundPerCriteria] =
    useState<CRITERIA_TYPE>(INITIAL_VALUES_AWAITING_API);

  useEffect(() => {
    const generatedValues = generateInitialValues(values);
    if (!equal(generatedValues, backgroundPerCriteria))
      setBackgroundPerCriteria(generatedValues);
  }, [template, values, generateInitialValues]);

  return [backgroundPerCriteria] as const;
};

export default useCreditReportTableValueGenerator;
