import {
  ClickAwayListener,
  Stack,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Input from "../Input";
import styles from "./vi.module.scss";

import { type FormikProps } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";

import { type InputFieldProps } from "../FormFields/InputField";
import LabelViewOnly from "../FormFields/LabelViewOnly";
import QuillEditor from "../Quill";
import UtilityBar from "../UtilityBar";

const VariableDiv = styled("div")<{ open: boolean; noHover?: boolean }>`
  ${({ open }) => (open ? `padding-bottom: 16px` : `padding-bottom: 0`)};
  padding-left: 8px;
  padding-top: 4px;
  ${({ open }) => (open ? `height: 0; padding: 0` : `height: auto`)};
  ${({ open }) => (open ? `width: 0` : `width: 100%`)};
  border-radius: 6px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme, noHover }) =>
      noHover ? "initial" : alpha(theme.palette.secondary.main, 0.5)};
    cursor: ${({ noHover }) => (noHover ? "auto" : "text")};
  }
`;

type T = InputFieldProps;
const VariableInput = (
  props: T & {
    handleSave?: (value: T["value"]) => void;
    richText?: boolean;
    currency?: string;
    noHover?: boolean;
  } & Partial<FormikProps<any>>
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const handleClose = () => {
    props?.handleSave &&
      props.value !== undefined &&
      props?.handleSave(props.value);
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [toggle, setToggle] = useState<boolean>(false);

  const { helpText, currency = "USD", ...rest } = props;
  const label =
    props.type === "money"
      ? currencyFormatter({ amount: Number(props.value), currency })
      : props.value || props.placeholder
        ? `${props.value || props.placeholder}`
        : "None";

  return (
    <div
      onClick={handleClick}
      data-testid={`variable-input-container-${props?.name}`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleClick && handleClick(e);
        }
      }}
    >
      {!toggle && (
        <>
          {props.label && (
            <LabelViewOnly
              labelClassName={`${styles.labelClass} ${rest?.labelClassName ?? ""}`}
              label={props.label}
              helpText={helpText}
              sx={{
                letterSpacing: "0.01em !important",
                fontWeight: "bold",
                paddingLeft: "4px",
                "&:hover": {
                  cursor: "default",
                },
              }}
            />
          )}

          <VariableDiv
            open={toggle}
            id={`variable-input-${props?.name}`}
            onClick={() => !props?.noHover && setToggle(true)}
            noHover={props?.noHover}
          >
            {!props.richText && (
              <LabelViewOnly
                {...rest}
                label={label}
                sx={{
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                  color: Boolean(props.value)
                    ? theme.palette.primary.main
                    : `#5a5a5a !important`,
                  cursor: "text",
                  fontWeight: Boolean(props.value)
                    ? "500 !important"
                    : `300 !important`,
                  letterSpacing: "0.01em !important",
                  textTransform: "initial",
                  ...props?.sx,
                }}
              />
            )}
            {props.richText && !props.value && (
              <LabelViewOnly
                {...rest}
                label={props.placeholder}
                sx={{
                  ...props.sx,
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                  color: `#5a5a5a !important`,
                  cursor: "text",
                  fontWeight: `300 !important`,
                  letterSpacing: "0.01em !important",
                  textTransform: "initial",
                }}
              />
            )}
            {props.richText && props.value && (
              <Stack
                data-testid={`variable-input-richText-stack-${props?.name}`}
                dangerouslySetInnerHTML={{ __html: props.value }}
                id="variable-input-richText-stack"
                sx={{
                  img: {
                    width: "100%",
                    height: "auto",
                  },
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                }}
              />
            )}
          </VariableDiv>
        </>
      )}

      {toggle && (
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="start"
            alignItems="end"
            width="100%"
            id="variable-input-stack"
          >
            {!props?.richText && <Input {...rest} style={{ width: "80%" }} />}
            {props?.richText && (
              <QuillEditor
                value={`${rest?.value ?? ""}`}
                onChange={(value: any) => {
                  rest?.setFieldValue && rest?.setFieldValue(rest?.name, value);
                }}
                placeholder={props?.placeholder}
              />
            )}
            <UtilityBar
              anchorEl={anchorEl}
              setToggle={setToggle}
              id={`variable-utility-${props?.name}`}
              handleSave={props?.handleSave}
              value={props.value}
            />
          </Stack>
        </ClickAwayListener>
      )}
    </div>
  );
};
export default VariableInput;
