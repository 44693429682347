import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useUpdateTermsheetCountdownMutation } from "api/api-accounts";
import DateInput from "components/Common/DateInput";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import InputViewOnlyField from "components/Common/FormFields/InputViewOnly";
import { Formik, type FormikProps } from "formik";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface CounterSettings {
  is_disabled: boolean;
  new_date?: Date;
}

interface Props {
  id: string;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  date: Date;
  defaultDays: number;
}
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#001F54",
  width: "100%"
}));
export default function CountdownSettingsDrawer({
  id,
  drawerOpen,
  setDrawerOpen,
  date,
  defaultDays = 15
}: Props) {
  const theme = useTheme();

  const [updateCoutdown, { isLoading }] = useUpdateTermsheetCountdownMutation()

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      setDrawerOpen(false);
    };

  const [newDate, setNewDate] = useState(date)
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const target = (newDate ?? date).getTime();
    const difference = target - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const onClick = (props: FormikProps<CounterSettings>) => {
    const new_date = props.values.new_date;
    updateCoutdown({
      id,
      body: {
        expiry_date: moment(new_date ?? date).format("YYYY-MM-DD"),
        is_active: !props.values.is_disabled,
      }
    })
      .unwrap()
      .then(() => {
        toast.success("Expiry updated successfully.");
        setDrawerOpen(true)
      })
      .catch((err) => toast.error(err.message))
  }

  const setDefault = (props: FormikProps<CounterSettings>) => {
    const expiry_date = moment().add(defaultDays, 'd').toDate();
    props.setFieldValue('new_date', expiry_date, true);
    setNewDate(expiry_date);
    props.setTouched({ new_date: true }, true);
  }

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  const [disableTimer, setDisableTimer] = useState(false);

  useEffect(() => {
    if (disableTimer) return; // Skip the timer logic if the timer is disabled
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, [date, disableTimer, newDate]);

  const initialValues: CounterSettings = {
    is_disabled: false,
    new_date: undefined
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {(formikProps) => (
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer()}>
            <Container sx={{ width: "100%", height: "100%", pt: 2 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Stack justifyContent="space-between" spacing={2}>
                  <Typography variant="h3">Countdown Settings</Typography>
                  <Divider sx={{ height: 2 }} />
                </Stack>
                <Container
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  {/* Disable Timer Checkbox */}
                  <CheckboxField
                    name="is_disabled"
                    label="Disable Timer"
                    value={formikProps.values.is_disabled}
                    onChange={() => {
                      setDisableTimer(!formikProps.values.is_disabled);
                      if (!disableTimer) {
                        formikProps.resetForm({ values: initialValues });
                      } else {
                        setDefault(formikProps);
                      }
                      formikProps.setFieldValue(
                        "is_disabled",
                        !formikProps.values.is_disabled
                      );
                    }}
                  />
                  {/* Current Date */}
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <InputViewOnlyField
                      disabled={true}
                      label="Current"
                      value={moment(date).format("DD/MM/YYYY")}
                    />
                  </Box>

                  {/* New Date Input */}
                  <Box sx={{ mb: 3, alignItems: "center", width: "100%" }}>
                    <DateInput
                      name="new_date"
                      label="New"
                      value={formikProps.values.new_date}
                      setFieldValue={formikProps.setFieldValue}
                      datepickerProps={{
                        value: formikProps.values.new_date
                          ? moment(formikProps.values.new_date).format(
                              "DD/MM/YYYY"
                            )
                          : "",
                      }}
                      onChangeCommitted={(date) => setNewDate(date)}
                      disabled={formikProps.values.is_disabled}
                    />
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => setDefault(formikProps)}
                      disabled={formikProps.values.is_disabled}
                    >
                      Set to default
                    </CustomButton>
                  </Box>
                  <Divider sx={{ height: 2 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      mt: 4,
                      mb: 4,
                      backgroundColor:
                        timeLeft.days < 5
                          ? theme.palette.error.main
                          : theme.palette.primary.main,
                      borderRadius: 2,
                      padding: 2,
                      color: "#FFFFFF",
                    }}
                  >
                    <Typography variant="h4">
                      {`${timeLeft.days.toString().padStart(2, "0")} : ${timeLeft.hours.toString().padStart(2, "0")} : ${timeLeft.minutes.toString().padStart(2, "0")} : ${timeLeft.seconds.toString().padStart(2, "0")}`}{" "}
                    </Typography>
                    <Typography variant="body2">
                      Days&nbsp;&nbsp; Hrs&nbsp;&nbsp; Mins&nbsp;&nbsp; Secs
                    </Typography>
                  </Box>
                </Container>
                <Stack spacing={2} sx={{ mb: 2 }}>
                  <Divider sx={{ height: 2 }} />
                  <Stack
                    justifyContent="flex-end"
                    direction="column"
                    spacing={2}
                  >
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      onClick={() => onClick(formikProps)}
                      loading={isLoading}
                    >
                      Save
                    </LoadingButton>
                    <Button
                      variant="contained"
                      fullWidth
                      children="Cancel"
                      sx={{
                        width: "full",

                        backgroundColor: theme.palette.error.main,
                        ":hover": {
                          backgroundColor: "#7f0825",
                        },
                      }}
                      onClick={() => setDrawerOpen(false)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Drawer>
        )}
      </Formik>
    </div>
  );
}
