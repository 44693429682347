import { Box, Grid } from "@mui/material";
import { useGetAUMQuery } from "api/api-operations";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function DailyAUM() {
  const { data: dailyAUM, isFetching } = useGetAUMQuery(null);

  if (isFetching && !dailyAUM) {
    return <SkeletonLoad bars={10} />;
  }

  // Transform sorted stats into series data
  const seriesData = dailyAUM?.map((item) => ({
    name: item.supplier_text,
    y: Number(item.usd_daily_fx_advance_amount) || 0,
  }));

  const total_usd_daily_fx_advance_amount_all_invoices =
    (dailyAUM ?? [])?.reduce(
      (acc, curr) => acc + parseFloat(curr.usd_daily_fx_advance_amount),
      0
    ) / 1000000;

  const screenWidth = window.innerWidth;

  // Define Highcharts options
  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      height: 800,
    },
    title: {
      text: "AUM For Daily Report meeting",
    },

    subtitle: {
      useHTML: true,
      //get sum of all AUM
      text: `$${total_usd_daily_fx_advance_amount_all_invoices.toFixed(2)}M`,
      floating: true,
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: screenWidth >= 1514 ? "3em" : "1rem",
        color: "black",
        fontWeight: "bold",
      },
      y: screenWidth >= 1514 ? -50 : -160,
    },
    plotOptions: {
      pie: {
        crisp: true,
        center: ["50%", "50%"],
      },
      series: {
        stacking: "normal",
        dataLabels: [
          {
            enabled: true,
            distance: 10,
            formatter: function () {
              return `
              <div>
              <span>${((this as any)?.y / 1000000).toFixed(2)}M</span>
              <span>(${(this as any)?.percentage.toFixed(2)}%)</span>
              </div>
              `;
            },
            style: {
              fontSize: "0.9em",
              color: "black",
            },
          },
        ] as any[], //cannot avoid this type assertion because of the prop 'distance'
        showInLegend: true,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "AUM",
        colorByPoint: true,
        type: "pie",
        innerSize: "45%",
        data: seriesData,
      } as any, //cannot avoid this type assertion because of the prop 'innerSize'
    ],
  };

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && !dailyAUM && (
        <NoData text="No daily AUM data to be displayed" />
      )}
      {!isFetching && dailyAUM && (
        <Grid container>
          <Grid item xs={12} id="aum-chart-container" margin="auto">
            {dailyAUM?.length === 0 && (
              <NoData text="No daily AUM data to be displayed" />
            )}
            {dailyAUM?.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                allowChartUpdate
                containerProps={{
                  className: "__aum_chart",
                  style: {
                    margin: "auto",
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default DailyAUM;
