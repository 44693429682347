import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ForumIcon from "@mui/icons-material/Forum";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { SxProps } from "@mui/material/styles";
import { CSSProperties } from "react";
import type { TicketActivityLog as TALog } from "types";

const ACTIVITY_ICON: {
  [type: string]: React.ReactNode;
} = {
  INSERT: <ForumIcon color="primary" />,
  UPDATE: <EditIcon color="secondary" />,
  DELETE: <DeleteIcon color="secondary" />,
};

const ChangeLogHeader = ({ log, field, sx }: { log: TALog, field?: string, sx?: SxProps }) => (
  <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={sx}>
    <Stack direction="row" spacing={1}>
      <Typography textAlign="start">
        {ACTIVITY_ICON[log.operation]}
      </Typography>
      <Typography textAlign="start" fontWeight="bold">
        {log.user.first_name} {log.user.last_name}
      </Typography>
    </Stack>
    <Typography textAlign="start">
      {
        log.operation === "INSERT"
          ? "created the ticket on"
          : `updated ${field} on`
      }
    </Typography>
    <Typography textAlign="start" fontWeight="bold">
      {log.date}
    </Typography>
  </Stack>
)

const TicketActivityLog = ({
  activities,
  minHeight = "60vh",
  logHeight = "35em"
}: {
  activities: TALog[] | undefined;
  minHeight?: CSSProperties["minHeight"];
  logHeight?: CSSProperties["maxHeight"];
}) => {
  const theme = useTheme();
  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "1ch 3ch",
        borderRadius: 1,
      }}
    >
      <Stack spacing={1} py={2}>
        <Grid container columnSpacing={1} justifyContent="start">
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize="1.1em"
              textAlign="center"
            >
              ACTIVITY LOG
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              children={<SyncIcon sx={{ ":hover": { cursor: "pointer" } }} />}
              title="Sync latest changes"
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          rowSpacing={1}
          sx={{
            overflow: "scroll",
            mb: 2,
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            minHeight,
          }}
        >
          {!activities && (
            <Grid item xs={12}>
              <Typography textAlign="center">
                No activity logged
              </Typography>
            </Grid>
          )}
          {activities && Boolean(activities.length) && (
            <Grid item xs={12} sx={{ maxHeight: logHeight }}>
              {activities.map((activity) => (
                <Grid
                  container
                  alignItems="center"
                >
                  <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: { xs: "start", sm: "center" } }}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                      {activity.user.first_name?.charAt(0)}
                      {activity.user.last_name?.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} sm={11} spacing={1}>
                    {activity.operation === "INSERT" && <ChangeLogHeader log={activity} sx={{ ml: { sm: 2, md: 0 } }} />}
                    {activity.operation !== "INSERT" && activity.changes?.map((change) => (
                      <Stack spacing={1} sx={{ ml: { sm: 2, md: 0 } }}>
                        <ChangeLogHeader log={activity} field={change.field} />
                        <Stack direction="row" spacing={1} textAlign="center">
                          <Typography textAlign="center" fontWeight="bold">
                            {typeof change?.old === "string" ? change?.old : change?.old ? "Object" : "None"}
                          </Typography>
                          <Typography textAlign="center">
                            {"->"}
                          </Typography>
                          <Typography textAlign="center" fontWeight="bold">
                            {typeof change?.new === "string" ? change?.new : change?.new ? "Object" : "None"}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Grid>
                  <Grid item xs={12} my={2}><Divider /></Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Stack>
    </Paper>
  );
};
export default TicketActivityLog;
