import SyncIcon from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Typography } from "@mui/material";
import { useGetBankAccountsQuery } from "api/api-banks";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import NoData from "components/Common/NoData";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import { useRef } from "react";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { Account, BankAccount } from "types";

const BankAccountManagement = ({
  company,
  partner_id,
}: {
  company: Account;
  partner_id?: string;
}) => {
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const {
    data: bankAccounts,
    refetch: refetchBanks,
    isLoading,
  } = useGetBankAccountsQuery(company?.id, {
    skip: !Boolean(company?.id),
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headerLength = Object.keys(bankAccounts?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="end">
      <Grid item xs={2}>
        <LoadingButton
          variant="outlined"
          fullWidth
          sx={secondaryButtonStyle}
          onClick={refetchBanks}
          loading={isLoading}
          startIcon={<SyncIcon />}
        >
          Sync
        </LoadingButton>
      </Grid>
      <Grid item xs={12} ref={ref}>
        {bankAccounts && Boolean(bankAccounts?.length) ? (
          <DataTablev2
            defaultColumnWidth={defaultColumnWidth}
            key={bankAccounts?.length}
            data={bankAccounts}
            customColumns={{
              id: { id: "Bank ID", type: "text" },
              bank_name: { bank_name: "Bank Name", type: "text" },
              bank_account_number: {
                bank_account_number: "Bank Account Number",
                type: "text",
              },
              beneficiary_name: {
                beneficiary_name: "Beneficiary Name",
                type: "text",
              },
              currency: { currency: "Currency", type: "text" },
              country: { country: "Country", type: "text" },
              swift_code: { swift_code: "Swift Code", type: "text" },
              iban: {
                iban: "IBAN",
                type: "text",
                renderCell: (row: BankAccount) => {
                  return (
                    <Typography
                      sx={{
                        color: row?.iban ? "inherit" : "gray",
                      }}
                    >
                      {row.iban ?? "None"}
                    </Typography>
                  );
                },
              },
            }}
            toBeHidden={["sf_id", "bank_address"]}
            onRowClicked={(field: any) =>
              history.push(`/banks/${field.id}`, {
                company_id: company.id,
                partner_id,
              })
            }
            rowsPerPage={rowsPerPage}
            sx={{
              table: {
                minWidth: "100% !important",
              },
            }}
          />
        ) : (
          <NoData
            text="No banks found"
            style={{ margin: "1em auto", width: "70vw" }}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default BankAccountManagement;
