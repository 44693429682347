import { Chip, Grid, Tooltip } from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import type { CustomColumn } from "..";
import {
  FILTER_TYPE_IS_BETWEEN,
  FILTER_TYPE_ONLY,
  FILTER_TYPE_TILL,
} from "./filterTypes";

const FilterChip = ({
  filters,
  clearFilters,
  customColumns,
}: {
  filters: Record<string, any>;
  clearFilters: () => void;
  customColumns: CustomColumn;
}) => {
  const isMobile = useIsMobile();

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        margin="auto"
        spacing={0.5}
      >
        {Object.entries(filters).map((item) => {
          const { comparator, keywords } = item[1];
          const key = customColumns?.[item[0]]?.[item[0]]
            ? customColumns?.[item[0]]?.[item[0]]
            : item[0];
          let compares = comparator;
          let content = "";

          switch (comparator) {
            case FILTER_TYPE_IS_BETWEEN:
              compares = "is between";
              content = `${keywords}`?.split(",")?.join(" and ");
              break;
            case FILTER_TYPE_TILL:
              compares = "from";
              content = `${keywords}`
                ?.split(",")
                ?.map((val: any) => moment(val).format("L"))
                ?.join(" till ");
              break;
            case FILTER_TYPE_ONLY:
              compares = "is";
              content = `${keywords}`;
              break;
            default: {
              const arrayContent = `${keywords?.join(",")}`;
              content = arrayContent;
              break;
            }
          }

          return (
            <Grid item maxWidth={isMobile ? "100%" : "25%"} key={key}>
              <Tooltip title={`${key} ${compares} ${content}`}>
                <Chip
                  label={`${key} ${compares} ${content}`}
                  variant="filled"
                />
              </Tooltip>
            </Grid>
          );
        })}

        <Grid item maxWidth={isMobile ? "100%" : "25%"} key="clear">
          <Tooltip title="Clear Filters">
            <Chip
              label="Clear Filters"
              variant="outlined"
              onDelete={clearFilters}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FilterChip;
