import React from "react";
import {
  useGetAllDepartmentsQuery,
  useGetInternalUsersQuery,
} from "api/api-it";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import { useGetProjectsQuery, useGetSprintsQuery } from "api/api-support";
import { Grid, Stack } from "@mui/material";

interface Ticket {
  title: string;
  content: string;
  assignee_department_id: string;
  assignee_id?: string;
  project_id: string;
  sprint_id: string;
}

const CreateTicket = () => {
  const formikProps = useFormikContext<Ticket>();

  const { data: departments, isFetching: isFetchingDepartments } =
    useGetAllDepartmentsQuery(null);
  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsQuery(null);
  const { data: sprintList, isFetching: isFetchingSprints } =
    useGetSprintsQuery(null);
  const { data: sprints } = sprintList ?? {}

  const { data: internalUsers, isFetching: isFetchingInternalUsers } =
    useGetInternalUsersQuery(null);

  const setter = (value: string) => {
    const department_id = departments?.find((dep) => dep.name === value)?.id;
    formikProps.setFieldValue("assignee_department_id", department_id, true);
  };

  const projectSetter = (value: string) => {
    const project_id = projects?.find((pro) => pro.name === value)?.id;
    formikProps.setFieldValue("project_id", project_id, true);
  };

  const sprintSetter = (value: string) => {
    const sprint_id = sprints?.find((sprint) => sprint.name === value)?.id;
    formikProps.setFieldValue("sprint_id", sprint_id, true);
  };

  const assigneeSetter = (value: string) => {
    const assignee_id = internalUsers?.find(
      (user) => `${user.first_name} ${user.last_name}` === value
    )?.contact_id;
    formikProps.setFieldValue("assignee_id", assignee_id, true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <Input name="title" label="Title" required {...formikProps} />
          <Input name="content" label="Content" {...formikProps} multiline />
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        {isFetchingDepartments && <SkeletonLoad bars={1} />}
        {!isFetchingDepartments && departments && (
          <Drop
            name="department_id"
            label="Assignee Department"
            keyValue="name"
            data={departments}
            setValue={setter}
            required
            {...formikProps}
          />
        )}

        {isFetchingInternalUsers && <SkeletonLoad bars={1} />}
        {!isFetchingInternalUsers && internalUsers && (
          <Drop
            name="assignee"
            label="Assignee"
            keyValue="name"
            data={internalUsers.map((user) => ({
              id: user.contact_id,
              name: `${user.first_name} ${user.last_name}`,
            }))}
            setValue={assigneeSetter}
            {...formikProps}
          />
        )}

        {isFetchingProjects && <SkeletonLoad bars={1} />}
        {!isFetchingProjects && projects && (
          <Drop
            name="project"
            label="Project"
            keyValue="name"
            data={projects.length ? projects : [{ id: null, name: null }]}
            setValue={projectSetter}
            {...formikProps}
          />
        )}

        {isFetchingSprints && <SkeletonLoad bars={1} />}
        {!isFetchingSprints && sprints && (
          <Drop
            name="sprint"
            label="Sprint"
            keyValue="name"
            data={sprints.length ? sprints : [{ id: null, name: null }]}
            setValue={sprintSetter}
            required
            {...formikProps}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default CreateTicket;
