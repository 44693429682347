import * as React from "react";
const SvgSofr = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 1080 1080", xmlSpace: "preserve", width: 18.844, height: 18.844, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1 0 0 1 540 540)", id: "7d4b8549-f069-4442-bda5-a64ec6d4efe0" }, /* @__PURE__ */ React.createElement("rect", { style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "transparent",
  fillRule: "nonzero",
  opacity: 1,
  visibility: "hidden"
}, vectorEffect: "non-scaling-stroke", x: -540, y: -540, rx: 0, ry: 0, width: 1080, height: 1080 })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1 0 0 1 540 540)", id: "7d357b49-0281-40af-9bcd-c0255428620e" }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(10.54 0 0 10.54 508.43 507.81)" }, /* @__PURE__ */ React.createElement("g", { style: {}, vectorEffect: "non-scaling-stroke" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.33 0 0 1.33 -22.6 5.1)", style: {}, id: "text26", clipPath: "url(#CLIPPATH_3)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "CLIPPATH_3" }, /* @__PURE__ */ React.createElement("path", { transform: "matrix(1 0 0 -1 3.03 -5.15) translate(-59.46, -88.5)", id: "path18", d: "M 17.21183 17.21183 L 101.70477 17.21183 L 101.70477 159.78813 L 17.21183 159.78813 Z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("text", { xmlSpace: "preserve", fontFamily: "Times New Roman", fontSize: 80.79297638, fontStyle: "normal", fontWeight: "normal", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: -22.47, y: 25.38 }, "S"))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.33 0 0 1.33 -14.92 2.22)", style: {}, id: "text40", clipPath: "url(#CLIPPATH_4)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "CLIPPATH_4" }, /* @__PURE__ */ React.createElement("path", { transform: "matrix(1 0 0 -1 0.35 -2.41) translate(-62.54, -87.92)", id: "path32", d: "M 45.585 59.60508 L 79.48944 59.60508 L 79.48944 116.24367000000001 L 45.585 116.24367000000001 Z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("text", { xmlSpace: "preserve", fontFamily: "Times New Roman", fontSize: 31.65159035, fontStyle: "normal", fontWeight: "normal", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: -11.43, y: 9.94 }, "O"))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.33 0 0 1.33 18.39 -22.3)", style: {}, id: "text54", clipPath: "url(#CLIPPATH_5)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "CLIPPATH_5" }, /* @__PURE__ */ React.createElement("path", { transform: "matrix(1 0 0 -1 0 4.14) translate(-87.17, -99.76)", id: "path46", d: "M 61.76439 55.61048 L 112.57915 55.61048 L 112.57915 143.91435 L 61.76439 143.91435 Z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("text", { xmlSpace: "preserve", fontFamily: "Copperplate", fontSize: 57.9699707, fontStyle: "normal", fontWeight: "normal", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: -17.71, y: 18.21 }, "F"))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.33 0 0 1.33 31.81 20.23)", style: {}, id: "text68", clipPath: "url(#CLIPPATH_6)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "CLIPPATH_6" }, /* @__PURE__ */ React.createElement("path", { transform: "matrix(1 0 0 -1 -0.46 -3.04) translate(-96.78, -75.05)", id: "path60", d: "M 72.29161 33.81285 L 121.27581 33.81285 L 121.27581 116.28616 L 72.29161 116.28616 z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("text", { xmlSpace: "preserve", fontFamily: "Times New Roman", fontSize: 46.6545105, fontStyle: "normal", fontWeight: "normal", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: -15.56, y: 14.66 }, "R"))))));
export default SvgSofr;
