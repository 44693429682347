import { Add, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  useGenerateTaxInvoiceMutation,
  useGetTaxInvoiceQuery,
} from "api/api-operations";
import { BUYER, SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDate from "components/Common/VariableDate";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import React, { useState } from "react";
import TaxInvoicePreview from "../Preview";

import { useGetCountriesQuery } from "api/api-misc";
import Input from "components/Common/Input";
import VariableDropdown from "components/Common/VariableDropdown";
import { downloadDivAsPdf } from "helpers/downloadDivAsPdf";
import { toast } from "react-toastify";

interface TaxInvoiceFees {
  amount_exclude_gst: number;
  description: string;
  quantity: number;
  unit_price: number;
}

interface Payee {
  customer_name: string;
  customer_address: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}
interface TaxInvoiceData {
  sf_tax_invoice_id: string;
  sf_tax_invoice_name: string;
  invoice_reference_number: string;
  invoice_date: Date;
  transaction_type: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  invoice_amount_sgd_equiv: number;
  total_amount: number;
  total_amount_sgd_equiv: number;
  gst_amount: number;
  gst_amount_sgd_equiv: number;
  tax_invoice_type: string;
  sf_supplier_buyer_map_name: string;
  supplier_invoice_reference_number: string;
  buyer: Payee;
  supplier: Payee;
  tax_invoice_fees: TaxInvoiceFees[];
}

const CreateTaxInvoiceStageDetails: React.FC<{
  sf_invoice_name: string;
  sf_tax_invoice_id: string;
  invoiceId: string;
  onEnd: () => void;
}> = ({ sf_invoice_name, sf_tax_invoice_id, invoiceId, onEnd }) => {
  const { data: taxInvoice = {} as TaxInvoiceData, isLoading } =
    useGetTaxInvoiceQuery(
      {
        sf_invoice_name,
        sf_tax_invoice_id,
      },
      {
        skip: sf_invoice_name === "" || sf_tax_invoice_id === "",
      }
    );
  const { data: countries } = useGetCountriesQuery();
  const [generate, { isLoading: isGenerating }] =
    useGenerateTaxInvoiceMutation();

  const getClient = (payee: string) => {
    if (payee === "Charge the Supplier") {
      return taxInvoice.supplier;
    } else {
      return taxInvoice.buyer;
    }
  };

  const CLIENT_TYPE: { [charging_mode: string]: string } = {
    "Charge the Supplier": SUPPLIER,
    "Charge the Buyer": BUYER,
  };

  const print = () => {
    const printContents = document.getElementById("tax-invoice")?.innerHTML;
    if (!printContents) return;

    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    const printStyle = `
    <style>
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          background-color: #ededed;
        }
      }
    </style>
  `;

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      // Copy current head and body content to the iframe
      doc.write(`
        <html>
          <head>
            <title>Tax Invoice - ${taxInvoice.sf_tax_invoice_id}</title>
            ${document.head.innerHTML}
            ${printStyle}
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
      doc.close();

      // Ensure iframe's window has the styles applied
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();
    }

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  const [shrinkFactor, setShrinkFactor] = useState(1);
  const adjustGridWidth = () => {
    return 10 - shrinkFactor * 10;
  };
  const exchange_rate =
    taxInvoice?.exchange_rate === 0 ? 1 : taxInvoice?.exchange_rate;

  return (
    <Formik
      initialValues={
        {
          ...taxInvoice,
          exchange_rate,
          payee: "Charge the Supplier",
          clientDetails: getClient("Charge the Supplier"),
          balanceDue: 0,
          paidAmount: 0,
        } as TaxInvoiceData & {
          payee: string;
          clientDetails: Payee;
          balanceDue: number;
          paidAmount: number;
        }
      }
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        const totalAmount = formikProps.values.tax_invoice_fees?.reduce(
          (acc, fee) => {
            return acc + valueCleaner(`${fee.unit_price}`) * fee.quantity;
          },
          0
        );

        const invoiceAmt = totalAmount;
        const invoiceAmtSgdEquiv =
          invoiceAmt * formikProps.values.exchange_rate;
        const gstAmount =
          totalAmount * (formikProps.values.tax_rate_percentage / 100);
        const totalAmountInclGst = totalAmount + gstAmount;
        const totalAmountSgdEquiv =
          totalAmount * formikProps.values.exchange_rate;
        const gstAmountSgdEquiv = gstAmount * formikProps.values.exchange_rate;
        const totalAmountInclGstSgdEquiv =
          totalAmountInclGst * formikProps.values.exchange_rate;

        return (
          <Form>
            {isLoading && !taxInvoice && <SkeletonLoad bars={10} />}
            {!isLoading && taxInvoice && (
              <Stack spacing={2}>
                <MainCard
                  title={
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={6}>
                        <Typography variant="h3">
                          {`SF Map ID: ${formikProps.values.sf_supplier_buyer_map_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Drop
                          name="payee"
                          value={formikProps.values.payee}
                          fullWidth
                          label=""
                          keyValue="name"
                          data={[
                            { name: "Charge the Supplier", id: "1" },
                            { name: "Charge the Buyer", id: "2" },
                          ]}
                          setValue={(value) => {
                            formikProps.setFieldValue("payee", value);
                            formikProps.setFieldValue(
                              "clientDetails",
                              getClient(value)
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  }
                  content
                  contentSX={{
                    overflow: "auto",
                  }}
                  children={
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      sx={{
                        bgcolor: "background.default",
                        minHeight: "700pt",
                        borderRadius: 1,
                        pb: 2,
                      }}
                    >
                      <Grid item xs={12} lg={4 + adjustGridWidth()}>
                        <Grid container spacing={1}>
                          <Grid item lg={12} xs={12}>
                            <Accordion defaultExpanded>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="invoice-content"
                                id="invoice-header"
                                sx={{
                                  bgcolor: "background.default",
                                  borderRadius: 1,
                                  boxShadow: 1,
                                  fontWeight: 700,
                                }}
                              >
                                Invoice Details
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid item lg={12} xs={12}>
                                  <VariableDate
                                    name="invoice_date"
                                    value={formikProps.values.invoice_date}
                                    label="Invoice Date"
                                    setFieldValue={(field, value) => {
                                      return formikProps.setFieldValue(
                                        field,
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="invoice_reference_number"
                                    value={
                                      formikProps.values
                                        .invoice_reference_number
                                    }
                                    label="Invoice Reference Number"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "invoice_reference_number",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <VariableInput
                                    name="supplier_invoice_reference_number"
                                    value={
                                      formikProps.values
                                        .supplier_invoice_reference_number
                                    }
                                    label="Supplier Invoice Reference Number"
                                    handleSave={(value) => {
                                      formikProps.setFieldValue(
                                        "supplier_invoice_reference_number",
                                        value
                                      );
                                    }}
                                  />
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="fees-content"
                                id="fees-header"
                                sx={{
                                  bgcolor: "background.default",
                                  borderRadius: 1,
                                  boxShadow: 1,
                                  fontWeight: 700,
                                }}
                              >
                                Fees Breakdown
                              </AccordionSummary>
                              <AccordionDetails>
                                {formikProps.values.tax_invoice_fees?.map(
                                  (fee, index) => (
                                    <Grid
                                      container
                                      key={fee?.description?.slice(0, 5)}
                                      spacing={1}
                                      sx={{
                                        bgcolor: "background.default",
                                        borderRadius: 1,
                                        my: 1,
                                        boxShadow: 1,
                                        p: 1,
                                      }}
                                    >
                                      <Grid item lg={12} xs={12}>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 700,
                                            }}
                                          >
                                            Item {index + 1}
                                          </Typography>
                                          <Button
                                            color="error"
                                            variant="contained"
                                            onClick={() => {
                                              formikProps.setFieldValue(
                                                "tax_invoice_fees",
                                                formikProps.values.tax_invoice_fees.filter(
                                                  (_, i) => i !== index
                                                )
                                              );
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        </Stack>
                                      </Grid>
                                      <Grid item lg={12} xs={12}>
                                        <Divider />
                                      </Grid>
                                      <Grid item lg={12} xs={12}>
                                        <VariableInput
                                          name={`tax_invoice_fees[${index}].description`}
                                          value={fee.description}
                                          label="Description"
                                          handleSave={(value) => {
                                            formikProps.setFieldValue(
                                              `tax_invoice_fees[${index}].description`,
                                              value
                                            );
                                          }}
                                        />
                                      </Grid>
                                      <Grid item lg={6} xs={12}>
                                        {" "}
                                        <VariableInput
                                          name={`tax_invoice_fees[${index}].quantity`}
                                          value={fee.quantity}
                                          label="Quantity"
                                          type="number"
                                          handleSave={(value) => {
                                            formikProps.setFieldValue(
                                              `tax_invoice_fees[${index}].quantity`,
                                              value
                                            );
                                            formikProps.setFieldValue(
                                              `tax_invoice_fees[${index}].amount_exclude_gst`,
                                              valueCleaner(
                                                `${fee.unit_price}`
                                              ) * (value as number)
                                            );
                                          }}
                                        />
                                      </Grid>
                                      <Grid item lg={6} xs={12}>
                                        <VariableInput
                                          name={`tax_invoice_fees[${index}].unit_price`}
                                          value={fee.unit_price}
                                          type="money"
                                          label="Unit Price"
                                          handleSave={(value) => {
                                            formikProps.setFieldValue(
                                              `tax_invoice_fees[${index}].unit_price`,
                                              valueCleaner(`${value}`)
                                            );
                                            formikProps.setFieldValue(
                                              `tax_invoice_fees[${index}].amount_exclude_gst`,
                                              fee.quantity *
                                                valueCleaner(
                                                  `${value as number}`
                                                )
                                            );
                                          }}
                                        />
                                      </Grid>
                                      <Grid item lg={12} xs={12}>
                                        <Divider />
                                      </Grid>
                                      <Grid item lg={12} xs={12}>
                                        <Typography
                                          fontWeight={700}
                                          textAlign="end"
                                        >
                                          Amount (Excl GST)
                                        </Typography>
                                        <Typography textAlign="end">
                                          {currencyFormatter({
                                            amount:
                                              valueCleaner(
                                                `${fee.unit_price}`
                                              ) * fee.quantity,
                                            currency: "USD",
                                            decimalPlace: 2,
                                          })}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Grid item lg={3} xs={12} ml="auto">
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<Add />}
                                    fullWidth
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        "tax_invoice_fees",
                                        [
                                          ...(formikProps.values
                                            .tax_invoice_fees || []),
                                          {
                                            amount_exclude_gst: 0,
                                            description: "",
                                            quantity: 0,
                                            unit_price: 0,
                                          },
                                        ]
                                      );
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="fees-content"
                                id="fees-header"
                                sx={{
                                  bgcolor: "background.default",
                                  borderRadius: 1,
                                  boxShadow: 1,
                                  fontWeight: 700,
                                }}
                              >
                                {`Client Details (${CLIENT_TYPE[formikProps.values.payee]})`}
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.customer_name"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.customer_name
                                      }
                                      label="Name"
                                      handleSave={(value) => {
                                        formikProps.setFieldValue(
                                          "clientDetails.customer_name",
                                          value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.customer_address"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.customer_address
                                      }
                                      label="Address"
                                      handleSave={(value) => {
                                        formikProps.setFieldValue(
                                          "clientDetails.customer_address",
                                          value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.city"
                                      value={
                                        formikProps.values.clientDetails?.city
                                      }
                                      label="City"
                                      handleSave={(value) => {
                                        formikProps.setFieldValue(
                                          "clientDetails.city",
                                          value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.postal_code"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.postal_code
                                      }
                                      label="Postal Code"
                                      handleSave={(value) => {
                                        formikProps.setFieldValue(
                                          "clientDetails.postal_code",
                                          value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    {countries && (
                                      <VariableDropdown
                                        data={countries}
                                        keyValue="name"
                                        name="clientDetails.country"
                                        value={
                                          formikProps.values.clientDetails
                                            ?.country
                                        }
                                        label="Country"
                                        setValue={(value) => {
                                          formikProps.setFieldValue(
                                            "clientDetails.country",
                                            value
                                          );
                                        }}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.contact_name"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.contact_name
                                      }
                                      label="Contact Name"
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.contact_email"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.contact_email
                                      }
                                      label="Contact Email"
                                      type="email"
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.contact_phone_number"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.contact_phone_number
                                      }
                                      label="Contact Phone Number"
                                      type="phone"
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <VariableInput
                                      name="clientDetails.alternative_email"
                                      value={
                                        formikProps.values.clientDetails
                                          ?.alternative_email
                                      }
                                      type="email"
                                      label="Alternative Email"
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Divider>Transaction Particulars</Divider>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="transaction_type"
                              value={formikProps.values.transaction_type}
                              label="Transaction Type"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="currency"
                              value={formikProps.values.currency}
                              label="Currency"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="tax_rate_percentage"
                              value={formikProps.values.tax_rate_percentage}
                              label="Tax Rate %"
                              type="number"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="exchange_rate"
                              value={formikProps.values.exchange_rate}
                              label="Exchange Rate"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="invoice_amount"
                              value={invoiceAmt?.toFixed(2)}
                              label="Invoice Amt (USD)"
                              type="money"
                              disabled
                              noHover
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              name="invoice_amount_sgd_equiv"
                              value={invoiceAmtSgdEquiv?.toFixed(2)}
                              label="Invoice Amt (SGD)"
                              type="money"
                              currency="SGD"
                              disabled
                              noHover
                            />
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Divider>Overrides</Divider>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <Typography fontWeight={700}>
                                Paid Amount (USD)
                              </Typography>
                              <Input
                                value={formikProps.values.paidAmount}
                                type="money"
                                name="paidAmount"
                                label={undefined}
                                onChange={(e) => {
                                  formikProps.setFieldValue(
                                    "paidAmount",
                                    e.target.value
                                  );
                                  formikProps.setFieldValue(
                                    "balanceDue",
                                    totalAmountInclGst -
                                      valueCleaner(`${e.target.value}`)
                                  );
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <Typography fontWeight={700}>
                                Balance Due
                              </Typography>
                              <VariableInput
                                value={formikProps.values.balanceDue}
                                type="money"
                                name="balanceDue"
                                label={undefined}
                                disabled
                                noHover
                              />
                            </Stack>
                          </Grid>

                          <Grid item lg={12} xs={12}>
                            <Divider>Total</Divider>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Typography
                              fontWeight={600}
                              sx={{ mb: 1 }}
                              variant="h4"
                              textAlign="right"
                            >
                              Total Amount
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                textAlign: "right",
                              }}
                            >
                              {currencyFormatter({
                                amount: totalAmount,
                                currency: "USD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                textAlign: "right",
                              }}
                            >
                              {currencyFormatter({
                                amount: totalAmountSgdEquiv,
                                currency: "SGD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Typography
                              fontWeight={600}
                              sx={{ mb: 0.5 }}
                              variant="h5"
                              textAlign="right"
                            >
                              GST Amount
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              }}
                              textAlign="right"
                            >
                              {currencyFormatter({
                                amount: gstAmount,
                                currency: "USD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              }}
                              textAlign="right"
                            >
                              {currencyFormatter({
                                amount: gstAmountSgdEquiv,
                                currency: "SGD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Divider>Grand Total</Divider>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <Typography
                              fontWeight={600}
                              sx={{ mb: 1 }}
                              variant="h4"
                              textAlign="right"
                            >
                              Total Amount (Incl GST)
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                textAlign: "right",
                              }}
                            >
                              {currencyFormatter({
                                amount: totalAmountInclGst,
                                currency: "USD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                textAlign: "right",
                              }}
                            >
                              {currencyFormatter({
                                amount: totalAmountInclGstSgdEquiv,
                                currency: "SGD",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={0.2}>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ height: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={10} lg={7.7 - adjustGridWidth()}>
                        <TaxInvoicePreview
                          invoice={formikProps.values}
                          invoiceId={invoiceId}
                          paidAmount={formikProps.values.paidAmount}
                          balanceDue={formikProps.values.balanceDue}
                          shrinkFactor={shrinkFactor}
                        />
                      </Grid>
                    </Grid>
                  }
                  footer={
                    <Grid container spacing={1} justifyContent="end">
                      <Grid item xs={12} lg={3}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="success"
                          onClick={() => {
                            generate({
                              customer: formikProps.values.clientDetails,
                              invoice_date: formikProps.values.invoice_date,

                              invoice_reference_number:
                                formikProps.values.invoice_reference_number,
                              sf_tax_invoice_id:
                                formikProps.values.sf_tax_invoice_id,
                              currency: formikProps.values.currency,
                              tax_rate_percentage:
                                formikProps.values.tax_rate_percentage,
                              exchange_rate: formikProps.values.exchange_rate,
                              invoice_amount: formikProps.values.invoice_amount,
                              gst_amount: parseFloat(gstAmount.toFixed(2)),
                              total_amount: totalAmountInclGst,
                              balance_due: parseFloat(
                                formikProps.values.balanceDue.toFixed(2)
                              ),
                              tax_invoice_type:
                                formikProps.values.tax_invoice_type,
                              transaction_type:
                                formikProps.values.transaction_type,
                              tax_invoice_fees:
                                formikProps.values.tax_invoice_fees,
                              supplier_invoice_reference_number:
                                formikProps.values
                                  .supplier_invoice_reference_number,
                              sf_invoice_name: invoiceId,
                            })
                              .unwrap()
                              .then(() => {
                                toast.success(
                                  `Tax Invoice ${formikProps.values.invoice_reference_number} Generated Successfully`
                                );
                                downloadDivAsPdf({
                                  id: "tax-invoice",
                                  fileName: `Tax Invoice - ${taxInvoice.supplier_invoice_reference_number}.pdf`,
                                });
                                onEnd();
                              })
                              .catch((error) => {
                                toast.error(
                                  `Error Generating Tax Invoice : ${error?.message}`
                                );
                              });
                          }}
                        >
                          Generate
                        </Button>
                      </Grid>
                    </Grid>
                  }
                />
              </Stack>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateTaxInvoiceStageDetails;
