import { Stack } from "@mui/material";
import { useGetMonthlyInvoiceStatsQuery } from "api/api-operations";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AvailableInvoiceChart from "components/DashboardInternal/AvailableInvoiceChart";
import DailyAUM from "components/DashboardInternal/DailyAUM";
import DailyInvoiceChart from "components/DashboardInternal/DailyInvoiceChart";
import MonthlyInvoiceChart from "components/DashboardInternal/MonthlyInvoiceChart";

function CommonInternalDashboard() {
  const { data: monthlyInvoiceStats, isFetching } =
    useGetMonthlyInvoiceStatsQuery(null);

  if (isFetching) return <SkeletonLoad bars={10} />;
  if (!monthlyInvoiceStats) return <NoData text="No Data to be displayed" />;
  return (
    <MainCard
      title="Invoice Statistics"
      headerSx={{
        py: 1,
        px: 2,
      }}
      contentSX={{
        margin: "auto",
        backgroundColor: "grey.300",
      }}
    >
      <Stack spacing={2} p={2}>
        <MainCard
          sx={{
            bgcolor: "background.default",
          }}
        >
          <MonthlyInvoiceChart />
        </MainCard>
        <MainCard
          sx={{
            bgcolor: "background.default",
          }}
        >
          <DailyInvoiceChart />
        </MainCard>
        <MainCard
          sx={{
            bgcolor: "background.default",
          }}
        >
          <AvailableInvoiceChart />
        </MainCard>
        <MainCard
          contentSX={{
            bgcolor: "background.default",
          }}
        >
          <DailyAUM />
        </MainCard>
      </Stack>
    </MainCard>
  );
}

export default CommonInternalDashboard;
