import React, { createContext, useContext, type ReactNode } from "react";
import type { CLDetails } from "types";

const CLContext = createContext<CLDetails | undefined>(undefined);

export const useCLContext = (): CLDetails => {
  const context = useContext(CLContext);
  if (!context) {
    throw new Error("useCLContext must be used within a CLContextProvider");
  }
  return context;
};

type CLContextProviderProps = { children: ReactNode; value: CLDetails };

export const CLContextProvider: React.FC<CLContextProviderProps> = ({
  children,
  value,
}) => {
  return <CLContext.Provider value={value}>{children}</CLContext.Provider>;
};
