import { Divider, Grid, ListItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import { TNC_URL } from "codes";
import useWidth from "helpers/useWidth";

import { lazy, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./docview.module.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const TermsOfUse = lazy(() => import("pages/terms-of-use"));
const Privacy = lazy(() => import("pages/privacy-policy"));

const SignupTNC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [option, setOption] = useState<number>(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const width = ref ? (useWidth(ref) ?? 400) : 400;
  const height = ref?.current?.clientHeight ?? 400;
  const Screens: Record<number, any> = {
    0: (
      <Grid container>
        <Grid item xs={12}>
          <Document
            file={TNC_URL}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
            className={styles.doc}
          >
            <Page
              width={width}
              pageNumber={pageNumber}
              height={height / 2}
              renderMode="canvas"
              scale={1}
              className={styles.page}
            />
          </Document>
        </Grid>
      </Grid>
    ),
    1: <Privacy />,
    2: <TermsOfUse />,
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        overflow: "hidden",
        height: { xs: "100%", lg: "60vh" },
        flexDirection: { xs: "column-reverse", lg: "row" },
      }}
    >
      <List component="nav" sx={{ width: { xs: "100%", lg: "25%" } }}>
        <ListItem onClick={() => setOption(0)}>
          <Typography fontWeight={option === 0 ? "bold" : 400}>{`${t(
            "terms-and-conditions"
          )}`}</Typography>
        </ListItem>
        <Divider />
        <ListItem onClick={() => setOption(1)}>
          <Typography fontWeight={option === 1 ? "bold" : 400}>{`${t(
            "privacy-policy"
          )}`}</Typography>
        </ListItem>
        <Divider />
        <ListItem onClick={() => setOption(2)}>
          <Typography fontWeight={option === 2 ? "bold" : 400}>{`${t(
            "terms-of-use"
          )}`}</Typography>
        </ListItem>
      </List>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} ref={ref}>
          {Screens[option]}
        </Container>
      </Box>
    </Box>
  );
};

export default SignupTNC;
