import { Grid, Paper, useTheme } from "@mui/material";
import {
  useEditScorecardHeaderMutation,
  useGetScorecardHeaderQuery,
} from "api/api-maps";
import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { mapSummaryDefaults } from "../defaults";
import styles from "../maps.module.scss";
import { INSURERS, PRODUCTS } from "./constants";

const ScorecardHeader: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const theme = useTheme();

  const id = useContext(MapContext);
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetScorecardHeaderQuery(id ?? "", { skip: !id });
  const [editMapSummary] = useEditScorecardHeaderMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    id &&
      editMapSummary({
        map_name: id,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  if (isLoading) return <SkeletonLoad bars={10} />;

  return (
    <Formik
      initialValues={{
        ...mapSummaryDefaults,
        ...mapData,
      }}
      onSubmit={console.log}
    >
      {(formikProps) => (
        <Form>
          {!isLoading && mapData && (
            <Paper
              sx={{
                width: { xs: "100%", lg: "100%" },
                margin: "auto",
                padding: { xs: 2, lg: 4 },
                borderRadius: 2,
                border: `2px solid ${theme.palette.secondary.main}80`,
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} lg={6}>
                  <DateInput
                    labelClassName={styles.labelClass}
                    name="score_card_date"
                    label="Date"
                    style={{
                      width: isMobile ? "96%" : "98%",
                    }}
                    wrapperClassName={styles.datePickerWrapper}
                    setFieldValue={formikProps.setFieldValue}
                    error={formikProps.errors.score_card_date as string}
                    value={
                      formikProps.values.score_card_date
                        ? new Date(formikProps.values.score_card_date)
                        : undefined
                    }
                    setValue={(value) =>
                      editor(
                        "score_card_date",
                        moment(value as any).format("YYYY-MM-DD"),
                        "Scorecard Date"
                      )
                    }
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Drop
                    label="IPL Product"
                    name="score_card_product"
                    keyValue="name"
                    data={PRODUCTS}
                    value={formikProps.values.score_card_product}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Drop
                    label="Credit Insurer(s)"
                    name="credit_insurer"
                    keyValue="name"
                    data={INSURERS}
                    value={formikProps.values.credit_insurer}
                    fullWidth
                    setValue={(value) => {
                      formikProps.setFieldValue(
                        "credit_insurer",
                        value as string
                      );
                      editor("credit_insurer", value, "Credit Insurer");
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Drop
                    label="Type"
                    name="score_card_type"
                    value={formikProps.values.score_card_type}
                    keyValue="name"
                    data={[
                      { name: "New", value: "New" },
                      { name: "Monitoring", value: "Monitoring" },
                      { name: "Trigger", value: "Trigger" },
                    ]}
                    fullWidth
                    setValue={(value) => {
                      formikProps.setFieldValue(
                        "score_card_type",
                        value as string
                      );
                      editor("score_card_type", value, "Type of Scoring");
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DateInput
                    labelClassName={styles.labelClass}
                    name="previous_monitoring_date"
                    label="Previous Monitoring Date"
                    style={{
                      width: isMobile ? "96%" : "98%",
                    }}
                    wrapperClassName={styles.datePickerWrapper}
                    value={
                      formikProps.values.previous_monitoring_date
                        ? new Date(formikProps.values.previous_monitoring_date)
                        : undefined
                    }
                    setFieldValue={formikProps.setFieldValue}
                    setValue={(value) =>
                      editor(
                        "previous_monitoring_date",
                        moment(value as any).format("YYYY-MM-DD"),
                        "Previous Monitoring Date"
                      )
                    }
                    error={
                      formikProps.errors.previous_monitoring_date as string
                    }
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DateInput
                    labelClassName={styles.labelClass}
                    name="next_monitoring_date"
                    label="Next Monitoring Date"
                    style={{
                      width: isMobile ? "96%" : "98%",
                    }}
                    wrapperClassName={styles.datePickerWrapper}
                    value={
                      formikProps.values.next_monitoring_date
                        ? new Date(formikProps.values.next_monitoring_date)
                        : undefined
                    }
                    setFieldValue={formikProps.setFieldValue}
                    setValue={(value) =>
                      editor(
                        "next_monitoring_date",
                        moment(value as any).format("YYYY-MM-DD"),
                        "Next Monitoring Date"
                      )
                    }
                    error={formikProps.errors.next_monitoring_date as string}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ScorecardHeader;
