import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneIcon from "@mui/icons-material/Done";
import { IconButton, Popper, Stack, styled, Tooltip } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

const StyledStack = styled(Stack)(({ theme }) => ({
  position: "relative",
  zIndex: 1400,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "10px",
  padding: "4px",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.secondary.main,
  height: "4ch",
}));

interface Props {
  anchorEl: HTMLButtonElement | null;
  id: string;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave?: (value: any) => void;
  value?: any;
}

const UtilityBar: React.FC<Props> = ({
  anchorEl,
  setToggle,
  handleSave,
  value,
  id,
}) => {
  const handleClose = () => {
    handleSave && value && handleSave(value);
    setToggle(false);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(`${value}`);
    toast("Copied to clipboard!", { type: "success", autoClose: 100 });
  };
  const handleClear = () => {
    handleSave && value && handleSave(undefined);
    setToggle(false);
  };

  return (
    <Popper
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: 1099 }}
      placement="bottom-end"
      disablePortal
    >
      <StyledStack direction="row" spacing={1}>
        <Tooltip title="Save">
          <IconButton onClick={handleClose}>
            <DoneIcon color="success" sx={{ fontSize: "0.9em" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear">
          <IconButton onClick={handleClear}>
            <DeleteForeverIcon color="error" sx={{ fontSize: "0.9em" }} />
          </IconButton>
        </Tooltip>
      </StyledStack>
    </Popper>
  );
};

export default UtilityBar;
