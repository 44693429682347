import { Stack } from "@mui/material";
import { useGetDashboardTermsheetListQuery } from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";
import TermsheetToDo from "components/Termsheets/TermsheetToDo";
import { VITE_EXPERIMENTAL_TERMSHEET } from "experimental_features";
import React from "react";
import InsurerCommunication from "./InsurerCommunication";
import InsurerStatsOverviewData from "./InsurerStatsOverviewData";

const InsurerStatsDashboard: React.FC = () => {
  const {
    data: termsheetTodos,
    isFetching: isFetchingTermsheetTodos,
    refetch: refetchTermsheetTodos,
  } = useGetDashboardTermsheetListQuery(null);

  return (
    <Stack spacing={4}>
      <InsurerStatsOverviewData />
      {VITE_EXPERIMENTAL_TERMSHEET && isFetchingTermsheetTodos && (
        <SkeletonLoad bars={5} />
      )}
      {!isFetchingTermsheetTodos &&
        termsheetTodos &&
        VITE_EXPERIMENTAL_TERMSHEET && (
          <TermsheetToDo
            termsheetTodos={termsheetTodos}
            syncAction={refetchTermsheetTodos}
          />
        )}
      <InsurerCommunication />
    </Stack>
  );
};

export default InsurerStatsDashboard;
