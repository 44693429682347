import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  type AccordionProps,
  type SxProps
} from "@mui/material";
import type { Theme } from "@mui/material/styles";
import {
  useGenerateNewTermsheetMutation,
  useGetLeadPartnerTermsheetQuery,
  useReassignTermsheetMutation,
  useTermsheetInternalApproveMutation,
  useTermsheetInternalRejectMutation,
  useTermsheetSubmitForInternalApprovalMutation,
  useTermsheetSubmitRecallMutation,
  useUpdateLeadPartnerTermsheetMutation
} from "api/api-accounts";
import { BUYER, CL_APPROVED, PRODUCTS, SELLER, TERMSHEET_STATUS } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import DayCounter from "components/DayCounter/DayCounter";
import { Formik, useFormikContext } from "formik";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import type { Account, PartnerData, Termsheet } from "types";
import CountdownSettingsDrawer from "./CountdownSettings";
import LeadPartnerTermSheetHistory from "./LeadPartnerTermSheetHistory";
import TermSheetPreview from "./LeadTermSheetPreview";
import TermsheetModal from "./TermSheetModal";
import { leadTermsheetInfoFilled } from "./utils/useLeadStatus";
import usePartnerStatus from "./utils/usePartnerStatus";
import useTermsheetStatus from "./utils/useTermsheetStatus";

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  "&.MuiAccordion-root:before": {
    backgroundColor: "transparent"
  },
}))

const TermSheetForm = ({ partner, disabled = false }: { partner: Account, disabled: boolean }) => {

  const formikProps = useFormikContext<Termsheet>()
  const isPartnerBuyer = partner.profile === BUYER?.toLowerCase();

  const funding_periods = [
    { label: "120 days", value: "120" },
    { label: "90 days", value: "90" },
    { label: "75 days", value: "75" },
    { label: "60 days", value: "60" },
    { label: "45 days", value: "45" },
    { label: "30 days", value: "30" },
  ]

  const setterFundingPeriod = (value: string) => {
    formikProps.setTouched({ average_funding_period: true }, true);
    formikProps.setFieldValue(
      "average_funding_period",
      value && value !== ""
        ? funding_periods.find((val) => val.label === value)?.value
        : ""
    )
  }

  const advancedAmounts = [
    { label: "100%", value: 100 },
    { label: "97%", value: 97 },
    { label: "95%", value: 95 },
    { label: "90%", value: 90 },
    { label: "80%", value: 80 },
  ]

  const setterAdvancedAmount = (value: string) => {
    formikProps.setTouched({ advanced_amount: true }, true);
    formikProps.setFieldValue(
      "advanced_amount",
      value && value !== "" ? advancedAmounts.find((val) => val.label === value)?.value : ""
    )
  }

  const currencies = [
    { name: "EUR" },
    { name: "USD" },
    { name: "SGD" },
    { name: "GBP" },
    { name: "AUD" },
  ]

  return (
    <Stack spacing={1}>
      {/* Receivable details */}
      <StyledAccordion
        defaultExpanded
        sx={{ borderRadius: "10px" }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="termsheetdetail-content"
          id="termsheetdetail-header"
        >
          <Typography fontWeight="bold" variant="h5">
            RECEIVABLE DETAILS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ mb: 2 }} />
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Stack spacing={1} direction="row">
                <Drop
                  name="currency"
                  label="Currency"
                  keyValue="name"
                  data={currencies}
                  style={{ width: "100%" }}
                  required
                  disabled={disabled}
                  {...formikProps}
                />
                <Input
                  name="average_invoice_size"
                  label="Average Invoice Size"
                  type="money"
                  sx={{ width: "100%" }}
                  required
                  disabled={disabled}
                  {...formikProps}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction="row">
                <Drop
                  name="average_funding_period"
                  label="Average Funding Period"
                  keyValue="label"
                  data={funding_periods}
                  placeholder="Select average funding period"
                  value={
                    formikProps.values.average_funding_period !== ""
                      ? funding_periods.find((item) => item.value === formikProps.values.average_funding_period)?.label
                      : ""
                  }
                  disabled={disabled}
                  setValue={setterFundingPeriod}
                />

                {!isPartnerBuyer && (
                  <Drop
                    name="advanced_amount"
                    label="Advanced Amount"
                    keyValue="label"
                    data={advancedAmounts}
                    value={
                      formikProps.values.advanced_amount !== ""
                        ? advancedAmounts.find((item) => item.value === formikProps.values.advanced_amount)?.label
                        : ""
                    }
                    setValue={setterAdvancedAmount}
                    disabled={disabled}
                    placeholder="Select Advanced Amount"
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Input
                name="trade_goods_description"
                label="Trade Goods Description"
                sx={{
                  width: "100%",
                  height: "11vh",
                  textarea: {
                    overflow: "scroll !important"
                  }
                }}
                multiline
                disabled={disabled}
                {...formikProps}
              />
            </Grid>
          </Grid>

        </AccordionDetails>
      </StyledAccordion>

      {/* Cost details*/}
      <StyledAccordion
        defaultExpanded
        sx={{ borderRadius: "10px" }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="costdetails-content"
          id="costdetails-header"
        >
          <Typography fontWeight="bold" variant="h5">
            COST DETAILS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ mb: 2 }} />
          <Stack spacing={1}>
            <Input
              name="annualized_discount_rate"
              label={formikProps.values.discount_rate_type ? "Annualized Discount Rate" : "Spread Discount Rate"}
              type="decimal"
              fullWidth
              sx={{
                ".MuiFormControl-root": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  "&::after": {
                    marginLeft: "10px",
                    content: '"%"',
                  }
                },
              }}
              disabled={disabled}
              {...formikProps}
            />
            <FormControl disabled={disabled} sx={{ mt: 1, mb: 0.5, p: 0.5 }}>
              <RadioGroup
                aria-labelledby="discount_rate_type"
                name="discount_rate_type"
                value={formikProps.values.discount_rate_type}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    "discount_rate_type",
                    e.target.value === "true"
                  );
                }}
                sx={{ flexWrap: "nowrap" }}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Fixed"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Floating"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </AccordionDetails>
      </StyledAccordion>

      {/* Credit Limit */}
      {isPartnerBuyer && (
        <StyledAccordion
          defaultExpanded
          sx={{ borderRadius: "10px" }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="creditlimit-content"
            id="creditlimit-header"
          >
            <Typography fontWeight="bold" variant="h5">
              CREDIT LIMIT
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ mb: 2 }} />
            <Input
              name="credit_limit"
              label="Credit limit"
              type="money"
              sx={{ width: "100%" }}
              disabled
              value={formikProps.values.credit_limit?.approved_amount}
            />
          </AccordionDetails>
        </StyledAccordion>

      )}

      {/* first invoice */}
      {isPartnerBuyer && (
        <StyledAccordion
          defaultExpanded
          sx={{ borderRadius: "10px" }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="firstinvoice-content"
            id="firstinvoice-header"
          >
            <Typography fontWeight="bold" variant="h5">
              FIRST INVOICE
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ mb: 2 }} />
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <Input
                type="money"
                label="First invoice amount"
                name="first_invoice_amount"
                sx={{ width: "100%" }}
                disabled={disabled}
                {...formikProps}
              />
              <DateInput
                name="first_invoice_expected_date"
                label="First invoice expected date"
                disabled={disabled}
                {...formikProps}
                value={
                  formikProps.values.first_invoice_expected_date !== undefined
                    && formikProps.values.first_invoice_expected_date !== ''
                    ? new Date(formikProps.values.first_invoice_expected_date)
                    : undefined
                }
              />
            </Stack>
          </AccordionDetails>
        </StyledAccordion>
      )}

      <LeadPartnerTermSheetHistory partner_id={partner.id} />
    </Stack>
  )
}

const LeadPartnerTermSheet = ({
  navigateTab,
  partner,
  partnerDetails,
  lead,
}: {
  navigateTab: (index: number) => void;
  partner: Account;
  lead: Account;
  partnerDetails: PartnerData;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isPartnerBuyer = partner.profile === BUYER?.toLowerCase();

  const {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
    isPartnerCreditLimitApproved,
  } = usePartnerStatus(partnerDetails);

  const isLeadCreditLimitApproved =
    (
      lead.credit_limit !== null &&
      lead.credit_limit !== undefined &&
      lead.credit_limit?.status === CL_APPROVED &&
      lead.credit_limit?.approved_amount !== null &&
      lead.credit_limit?.approved_amount !== undefined
    );
  const isLeadTermsheetInfoFilledUp =
    (lead.account_product === PRODUCTS.factoring && lead.profile === BUYER.toLowerCase())
    || (lead.account_product === PRODUCTS.reverse_factoring && lead.profile === SELLER.toLowerCase())
    || leadTermsheetInfoFilled(lead);

  const partnerFormCompleted = isPartnerAdditionalInformationFilledUp &&
    (!isPartnerBuyer || isPartnerTradeDetailsInformationFilledUp) &&
    (!isPartnerBuyer || isPartnerCreditLimitApproved)
  const leadFormCompleted = (isPartnerBuyer || isLeadCreditLimitApproved) && isLeadTermsheetInfoFilledUp;

  const disabled = !(partnerFormCompleted && leadFormCompleted);

  if (disabled) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          <Stack spacing={1}>
            <AlertTitle sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
              Termsheet (Please complete the Details to proceed)
            </AlertTitle>
            <Divider />
            <Typography fontSize="1.5em">
              Please ensure that the
              {!partnerFormCompleted && (
                <>
                  {!isPartnerAdditionalInformationFilledUp && (<Button onClick={() => navigateTab(0)}> Additional Information </Button>)}
                  {isPartnerBuyer && !isPartnerTradeDetailsInformationFilledUp && (<Button onClick={() => navigateTab(1)}>Trade Details</Button>)}
                  {isPartnerBuyer && !isPartnerCreditLimitApproved && (<Button onClick={() => navigateTab(2)}>Credit Limit</Button>)}
                  regarding <strong>{partner?.name}</strong>
                </>
              )}
              {!leadFormCompleted && (
                <>
                  {!partnerFormCompleted && (" and")}
                  {!isLeadTermsheetInfoFilledUp && (<Button onClick={() => { /* TODO: Termsheet Info tab */ }}> Term Sheet Info </Button>)}
                  {!isPartnerBuyer && !isLeadCreditLimitApproved && (<Button onClick={() => { /* TODO: navigate to Accout/CreditLimit */ }}>Credit Limit</Button>)}
                  regarding <strong>{lead?.name}</strong>
                </>
              )} are completed to
              continue to termsheet as per the business requirements.
            </Typography>
          </Stack>
        </Alert>
      </Grid>
    );
  }

  const { data: termsheet, isFetching, refetch } = useGetLeadPartnerTermsheetQuery({
    lead_id: lead.id,
    partner_id: partnerDetails.id
  })

  const [updatePartnerTearmsheet, { isLoading: updatePartnerTermSheetLoading }] = useUpdateLeadPartnerTermsheetMutation()
  const [termsheetSubmitForInternalApproval, { isLoading: termsheetSubmitLoading }] = useTermsheetSubmitForInternalApprovalMutation()
  const [termsheetSubmitRecall, { isLoading: termsheetSubmitRecallLoading }] = useTermsheetSubmitRecallMutation()
  const [generateTermsheet, { isLoading: generateTermsheetLoading }] = useGenerateNewTermsheetMutation()
  const [termsheetInternalApprove, { isLoading: termsheetInternalApproveLoading }] = useTermsheetInternalApproveMutation()
  const [termsheetInternalReject, { isLoading: termsheetInternalRejectLoading }] = useTermsheetInternalRejectMutation()
  const [reassignTermsheet, { isLoading: reassignTermsheetLoading }] = useReassignTermsheetMutation()

  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );
  const { action_disabled } = useTermsheetStatus(termsheet, lead, USER_PERMISSION)

  const canEdit = USER_PERMISSION.includes(PERMISSIONS.termsheet_editor);
  const canApprove = USER_PERMISSION.includes(PERMISSIONS.termsheet_approver);
  const canEditTimer = canApprove
    && (
      termsheet?.termsheet_status === TERMSHEET_STATUS.PENDING_EXTERNAL
      || termsheet?.termsheet_status === TERMSHEET_STATUS.CANCELLED
    );
  const showCounter = termsheet?.termsheet_status === TERMSHEET_STATUS.PENDING_EXTERNAL
    || termsheet?.termsheet_status === TERMSHEET_STATUS.CANCELLED;

  const getExpiry = () => {
    if (!termsheet?.expiry_info?.expiry_date) {
      return new Date()
    }
    return moment(termsheet?.expiry_info?.expiry_date).toDate();
  }

  const [showReassign, setShowReassign] = useState<boolean>(false);
  const [showSubmitForApproval, setShowSubmitForApproval] = useState<boolean>(false);
  const [showRecall, setShowRecall] = useState<boolean>(false);
  const [showInternalRejectModal, setShowInternalRejectModal] = useState<boolean>(false);
  const [showInternalApproveModal, setShowInternalApproveModal] = useState<boolean>(false);
  const [showCounterDrawer, setShowCounterDrawer] = useState<boolean>(false)

  const loadingButtonSx: SxProps<Theme> = {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  }

  const initialValues: Partial<Termsheet> = {
    ...termsheet,
    currency: termsheet?.currency ?? "USD",
    average_invoice_size: termsheet?.average_invoice_size ?? "",
    average_funding_period: termsheet?.average_funding_period ?? "90",
    advanced_amount: termsheet?.advanced_amount ?? 90,
    annualized_discount_rate: termsheet?.annualized_discount_rate ?? lead?.annualized_discount_rate ?? 5,
    discount_rate_type: termsheet?.discount_rate_type ?? lead?.discount_rate_type ?? true,
    credit_limit: termsheet?.credit_limit ?? partnerDetails?.credit_limit,
    trade_goods_description: termsheet?.trade_goods_description ?? "",
    first_invoice_amount: termsheet?.first_invoice_amount ?? "",
    first_invoice_expected_date: termsheet?.first_invoice_expected_date ?? "",

    facility_fee: termsheet?.facility_fee ?? lead?.facility_fee,
    setup_fee: termsheet?.setup_fee ?? lead?.setup_fee,
    customer_maintenance_fee: termsheet?.customer_maintenance_fee ?? lead.customer_maintenance_fee,
    partner_maintenance_fee: termsheet?.partner_maintenance_fee ?? lead.partner_maintenance_fee
  }

  return (
    <>
      {
        isFetching ? (
          <SkeletonLoad bars={5} />
        ) : (
          <Formik initialValues={initialValues} onSubmit={() => { }}>
            {(formikProps) => (
              <MainCard
                border
                content
                title={
                  <Grid container direction="row" justifyContent="end" spacing={1}>
                    {canEdit && (
                      <>
                        {termsheet?.termsheet_status === TERMSHEET_STATUS.REJECTED && (
                          <Grid item xs={12} lg={2} zeroMinWidth>
                            <LoadingButton
                              variant="outlined"
                              fullWidth
                              data-testid="create-new"
                              sx={loadingButtonSx}
                              loading={generateTermsheetLoading}
                              onClick={() => {
                                generateTermsheet(partner?.id)
                                  .unwrap()
                                  .then(() => toast.success(`Successfully created new termsheet`))
                                  .catch((err) => toast.error(`${err.message}`))
                              }}
                            >Create</LoadingButton>
                          </Grid>
                        )}
                        <Grid item xs={12} lg={2} zeroMinWidth>
                          <LoadingButton
                            variant="outlined"
                            fullWidth
                            data-testid="recall-termsheet"
                            loading={termsheetSubmitRecallLoading}
                            disabled={action_disabled.recall}
                            sx={{
                              color: theme.palette.error.main,
                              borderColor: theme.palette.error.main,
                              ...loadingButtonSx
                            }}
                            onClick={() => setShowRecall(true)}
                          >Recall</LoadingButton>
                          <TermsheetModal
                            open={showRecall}
                            setOpen={setShowRecall}
                            title={`You are recalling the term sheet of ${partner.name}.`}
                            primary={{
                              children: "Recall",
                              color: 'error',
                              onClick: ({ comments }) => {
                                termsheetSubmitRecall({
                                  partner_id: partner.id,
                                  body: { comment: comments ?? "" }
                                })
                                  .unwrap()
                                  .then(() => toast.success(`Successfully recalled the termsheet.`))
                                  .catch((err) => toast.error(`${err.message}`))
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={2} zeroMinWidth>
                          <LoadingButton
                            variant="outlined"
                            fullWidth
                            data-testid="submit-termsheet"
                            loading={termsheetSubmitLoading}
                            disabled={action_disabled.submitForApproval}
                            onClick={() => setShowSubmitForApproval(true)}
                            sx={loadingButtonSx}
                          >Submit for approval</LoadingButton>
                          <TermsheetModal
                            open={showSubmitForApproval}
                            setOpen={setShowSubmitForApproval}
                            title={`You are submitting the term sheet of ${partner.name} for internal approval..`}
                            drop={{ label: "Assigned Approver" }}
                            primary={{
                              children: "Confirm",
                              onClick: ({ assignee_id, comments }) => {
                                termsheetSubmitForInternalApproval({
                                  partner_id: partner.id,
                                  body: {
                                    comment: comments ?? "",
                                    assignee_id: assignee_id ?? ""
                                  }
                                })
                                  .unwrap()
                                  .then(() => toast.success(`Successfully submitted ${partner.name} termsheet`))
                                  .catch((err) => toast.error(`${err.message}`))
                              }
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    {canApprove && (
                      <>
                        <Grid item xs={12} lg={2} zeroMinWidth>
                          <LoadingButton
                            variant="outlined"
                            fullWidth
                            data-testid="reassign-approver"
                            loading={reassignTermsheetLoading}
                            sx={{
                              color: theme.palette.error.main,
                              borderColor: theme.palette.error.main,
                              ...loadingButtonSx
                            }}
                            disabled={action_disabled.reassignApprover}
                            onClick={() => { setShowReassign(true) }}
                          >Reassign Approver</LoadingButton>
                          <TermsheetModal
                            open={showReassign}
                            setOpen={setShowReassign}
                            title={`You are reassigning the term sheet approver of ${partner.name}`}
                            drop={{ label: "Reassign approval to " }}
                            primary={{
                              children: "Reassign",
                              onClick: ({ assignee_id, comments }) => {
                                reassignTermsheet({
                                  partner_id: partner.id,
                                  body: {
                                    assignee_approver: assignee_id ?? "",
                                    message: comments ?? ""
                                  }
                                })
                                  .unwrap()
                                  .then(() => toast.success(`Successfully reassigned the termsheet`))
                                  .catch((err) => toast.error(`${err.message}`))
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={2} zeroMinWidth>
                          <LoadingButton
                            variant="outlined"
                            fullWidth
                            data-testid="internal-reject"
                            loading={termsheetInternalRejectLoading}
                            sx={{
                              color: theme.palette.error.main,
                              borderColor: theme.palette.error.main,
                              ...loadingButtonSx
                            }}
                            disabled={action_disabled.internalReject}
                            onClick={() => setShowInternalRejectModal(true)}
                          >Reject</LoadingButton>
                          <TermsheetModal
                            open={showInternalRejectModal}
                            setOpen={setShowInternalRejectModal}
                            title={`You are rejecting the term sheet of ${partner.name}. Term sheet will be logged into TS histroy...`}
                            primary={{
                              children: "Reject",
                              color: 'error',
                              onClick: ({ comments }) => {
                                termsheetInternalReject({
                                  partner_id: partner.id,
                                  body: {
                                    comment: comments ?? "",
                                  }
                                })
                                  .unwrap()
                                  .then(() => toast.success(`Successfully rejected the termsheet`))
                                  .catch((err) => toast.error(`${err.message}`))
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={2} zeroMinWidth>
                          <LoadingButton
                            variant="outlined"
                            fullWidth
                            data-testid="internal-approve"
                            sx={loadingButtonSx}
                            loading={termsheetInternalApproveLoading}
                            disabled={action_disabled.internalApprove}
                            onClick={() => setShowInternalApproveModal(true)}
                          >Approve</LoadingButton>
                          <TermsheetModal
                            open={showInternalApproveModal}
                            setOpen={setShowInternalApproveModal}
                            title={`You are approving the term sheet of ${partner.name}. Term sheet will be sent to customer approval...`}
                            primary={{
                              children: "Approve",
                              onClick: ({ comments }) => {
                                termsheetInternalApprove({
                                  partner_id: partner.id,
                                  body: {
                                    comment: comments ?? ""
                                  }
                                })
                                  .unwrap()
                                  .then(() => toast.success(`Successfully approved the termsheet`))
                                  .catch((err) => { toast.error(`${err.message}`) })
                              }
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                }
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={5}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ height: "100%" }}>
                      <MainCard
                        content
                        contentSX={{
                          m: 0,
                          p: 1,
                        }}
                        sx={{
                          border: "none !important",
                          boxShadow: "none !important",
                        }}
                        footerSx={{ mt: 1 }}
                        footer={canEdit && (
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6} ml="auto" justifyContent="center">
                              <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled={action_disabled.save}
                                onClick={() => {
                                  formikProps.resetForm({
                                    values: {
                                      ...termsheet,
                                      currency: "",
                                      average_invoice_size: "",
                                      average_funding_period: "",
                                      advanced_amount: "",
                                      annualized_discount_rate: "",
                                      discount_rate_type: true,
                                      credit_limit: termsheet?.credit_limit ?? partnerDetails?.credit_limit,
                                      trade_goods_description: "",
                                      first_invoice_amount: "",
                                      first_invoice_expected_date: ""
                                    }
                                  })
                                }}
                              >Clear All</Button>
                            </Grid>
                            <Grid item xs={12} lg={6} justifyContent="center">
                              <LoadingButton
                                variant="contained"
                                fullWidth
                                data-testid="save-termsheet"
                                loading={updatePartnerTermSheetLoading}
                                disabled={action_disabled.save}
                                onClick={() => {
                                  let {
                                    currency,
                                    average_invoice_size,
                                    average_funding_period,
                                    advanced_amount,
                                    annualized_discount_rate,
                                    discount_rate_type,
                                    facility_fee,
                                    setup_fee,
                                    trade_goods_description,
                                    first_invoice_amount,
                                    first_invoice_expected_date,
                                    customer_maintenance_fee,
                                    partner_maintenance_fee
                                  } = formikProps.values
                                  first_invoice_expected_date =
                                    first_invoice_expected_date
                                      ? moment(first_invoice_expected_date).utc().format("YYYY-MM-DD")
                                      : undefined;
                                  average_invoice_size =
                                    average_invoice_size
                                      ? valueCleaner(`${average_invoice_size}`)
                                      : undefined;
                                  first_invoice_amount =
                                    first_invoice_amount
                                      ? valueCleaner(`${first_invoice_amount}`)
                                      : undefined;
                                  annualized_discount_rate =
                                    annualized_discount_rate
                                      ? valueCleaner(`${annualized_discount_rate}`)
                                      : undefined;
                                  updatePartnerTearmsheet({
                                    lead_id: lead.id,
                                    partner_id: partner.id,
                                    body: {
                                      currency,
                                      average_invoice_size: average_invoice_size || undefined,
                                      average_funding_period,
                                      advanced_amount,
                                      annualized_discount_rate,
                                      discount_rate_type,
                                      facility_fee,
                                      setup_fee,
                                      trade_goods_description,
                                      first_invoice_amount: first_invoice_amount || undefined,
                                      first_invoice_expected_date: first_invoice_expected_date || undefined,
                                      customer_maintenance_fee,
                                      partner_maintenance_fee
                                    }
                                  }).unwrap()
                                    .then(() => {
                                      toast.success(`Successfully updated termsheet for ${partner.name}`)
                                      formikProps.resetForm({ values: formikProps.values })
                                    }).catch((error) => {
                                      toast.error(`Something went wrong : ${error.message}`)
                                    })
                                }}
                              >save</LoadingButton>
                            </Grid>
                          </Grid>
                        )}
                      >
                        <TermSheetForm
                          partner={partner}
                          disabled={action_disabled.form}
                        />
                      </MainCard>
                      <Stack>
                        <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} sx={{ m: 1 }} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Stack spacing={1}>
                      {showCounter && (
                        <Grid item xs={12} zeroMinWidth>
                          <DayCounter
                            minDay={5}
                            date={getExpiry()}
                            isDrawer={canEditTimer}
                            setDrawerOpen={setShowCounterDrawer}
                            drawerOpen={showCounterDrawer}
                            direction={{ xs: 'column-reverse', lg: 'row-reverse' }}
                            disabled={!(termsheet?.expiry_info?.is_active ?? true)}
                          />
                        </Grid>
                      )}
                      <TermSheetPreview
                        termsheet={formikProps.values}
                        lead={lead}
                        partner={partner}
                        creditLimit={isPartnerBuyer ? partnerDetails.credit_limit?.approved_amount : lead.credit_limit?.approved_amount}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                {termsheet?.expiry_info && canEditTimer && (
                  <CountdownSettingsDrawer
                    id={termsheet?.expiry_info?.id}
                    drawerOpen={showCounterDrawer}
                    setDrawerOpen={setShowCounterDrawer}
                    date={getExpiry()}
                    defaultDays={15}
                  />
                )}
              </MainCard>
            )}
          </Formik>
        )
      }
    </>
  );
};

export default LeadPartnerTermSheet;
