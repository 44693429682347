import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
// Define a type for the slice state
interface ExperimentalFeatureState {
  [feature_name: string]: {
    value: boolean;
    condition: string;
  };
}

// Define the initial state using that type
const initialState: { value: ExperimentalFeatureState } = {
  value: {
    VITE_INVOICES_PAGE: {
      value: import.meta.env.VITE_INVOICES_PAGE === "true",
      condition: `import.meta.env.VITE_INVOICES_PAGE === "true"`,
    },
    VITE_BUYER_PAGE: {
      value: import.meta.env.VITE_BUYER_PAGE === "true",
      condition: `import.meta.env.VITE_BUYER_PAGE === "true"`,
    },
    VITE_EXPERIMENTAL_EWALLET: {
      value: import.meta.env.VITE_EXPERIMENTAL_EWALLET === "true",
      condition: `import.meta.env.VITE_EXPERIMENTAL_EWALLET === "true"`,
    },

    VITE_EXPERIMENTAL_TOTP: {
      value:
        import.meta.env.VITE_ENV === "local" ||
        import.meta.env.VITE_ENV === "dev",
      condition: `import.meta.env.VITE_ENV === "local" ||
      import.meta.env.VITE_ENV === "dev"`,
    },
    VITE_EXPERIMENTAL_THEME_SWITCHER: {
      value:
        import.meta.env.VITE_ENV === "local" ||
        import.meta.env.VITE_ENV === "dev",
      condition: `import.meta.env.VITE_ENV === "local" ||import.meta.env.VITE_ENV === "dev"`,
    },
    VITE_EXPERIMENTAL_LEAVES_PAGE: {
      value: import.meta.env.VITE_ENV === "local",
      condition: `import.meta.env.VITE_ENV === "local"`,
    },
  },
};

export const experimentalFeaturesSlice = createSlice({
  name: "experimentalFeatures",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    setEFState: (
      state,
      action: PayloadAction<{
        [feature_name: string]: { value: boolean; condition: string };
      }>
    ) => {
      state.value = { ...state.value, ...action.payload };
    },
    clearEFState: () => initialState,
  },
});

export const { setEFState, clearEFState } = experimentalFeaturesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEFState = (state: RootState) =>
  state.experimentalFeatures.value;

export default experimentalFeaturesSlice.reducer;
