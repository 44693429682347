import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import Facebook from "assets/socialmedia/facebook.svg?react";
import Linkedin from "assets/socialmedia/linkedin.svg?react";
import Twitter from "assets/socialmedia/twitter.svg?react";
import Youtube from "assets/socialmedia/youtube.svg?react";
import Footer from "components/Footer";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SocialMedia = ({ drawerOpen }: { drawerOpen?: boolean }) => {
  const direction = !drawerOpen ? "column" : "row";
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const theme = useTheme();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container alignItems="center" id="sm-container" pt={1} pb={2}>
      <Grid item xs={10} margin="0 auto">
        <Grid
          container
          spacing={0.9}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          {drawerOpen && (
            <Grid item paddingTop="0 !important" xs={3}>
              <Typography variant="h5">{`${t("follow-us")}:`}</Typography>
            </Grid>
          )}
          <Grid item xs={9} margin="auto" padding="0 !important">
            {drawerOpen ? (
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="start"
                direction={direction}
              >
                <Grid item xs={3}>
                  <Facebook
                    data-name="facebook-icon"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/Incomlend",
                        "_blank"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Twitter
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://twitter.com/incomlend", "_blank")
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Linkedin
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/incomlend",
                        "_blank"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Youtube
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/@incomlend1954",
                        "_blank"
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <IconButton
                aria-label="sm-popover"
                aria-describedby="sm-popover"
                onClick={open ? handlePopoverClose : handlePopoverOpen}
              >
                <ConnectWithoutContactIcon
                  sx={{ fill: theme.palette.background.default }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {drawerOpen && (
        <Grid item xs={12} margin="0 auto">
          <Footer inDashboard textAlign="center" />
        </Grid>
      )}

      {/* SM icons */}
      <Menu
        id="sm-popover"
        open={open}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1,
              ml: 3,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "drawer-switcher-popover",
          sx: { display: "flex", flexDirection: "row" },
        }}
      >
        <MenuItem onClick={handlePopoverClose} sx={{ fontWeight: 800 }}>
          <Facebook
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/Incomlend", "_blank")
            }
          />
        </MenuItem>
        <MenuItem onClick={handlePopoverClose} sx={{ fontWeight: 800 }}>
          <Twitter
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://twitter.com/incomlend", "_blank")
            }
          />
        </MenuItem>
        <MenuItem onClick={handlePopoverClose} sx={{ fontWeight: 800 }}>
          <Linkedin
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/incomlend",
                "_blank"
              )
            }
          />
        </MenuItem>
        <MenuItem onClick={handlePopoverClose} sx={{ fontWeight: 800 }}>
          <Youtube
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.youtube.com/@incomlend1954", "_blank")
            }
          />
        </MenuItem>
      </Menu>
    </Grid>
  );
};
export default SocialMedia;
