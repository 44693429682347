import { Settings } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { useGetMapSummaryQuery } from "api/api-maps";
import ClientCall from "components/Risk/calls";
import Configuration from "components/Risk/Configuration";
import RiskScoring from "components/Risk/Scoring";
import Transactions from "components/Risk/Transactions";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`risk-tabpanel-${index}`}
      aria-labelledby={`risk-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 3, py: 1 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Risk = () => {
  const [value, setValue] = useState(0);
  const id = React.useContext(MapContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const IS_RISK_TEAM = USER_PERMISSIONS?.includes(PERMISSIONS.map_access_risk);
  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="map-tabs"
          variant="fullWidth"
        >
          <Tab label="Supplier Call" {...a11yProps(0)} />
          <Tab label={"Buyer Call"} {...a11yProps(1)} />
          <Tab label="Transaction Details" {...a11yProps(2)} />
          <Tab label="Risk Scoring" {...a11yProps(3)} />
          {IS_RISK_TEAM && (
            <Tab
              label="Configuration"
              {...a11yProps(4)}
              icon={<Settings />}
              iconPosition="end"
            />
          )}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <ClientCall accountType="supplier" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ClientCall accountType="buyer" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Transactions />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <RiskScoring />
      </CustomTabPanel>
      {IS_RISK_TEAM && (
        <CustomTabPanel value={value} index={4}>
          <Configuration />
        </CustomTabPanel>
      )}
    </Box>
  );
};

export default Risk;
