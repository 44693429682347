import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import toProperCase from "helpers/propercase";
import React from "react";
import type { Account } from "types";

interface ExportLeadDataContainerProps {
  lead: Account;
  hasTradePartners: boolean;
}
const ExportLeadDataContainer: React.FC<ExportLeadDataContainerProps> = ({
  lead,
  hasTradePartners,
}) => {
  const {
    account = [],
    contact = [],
    partners = {},
  } = lead?.missing_values_before_sf_export || {};

  const sections = [
    {
      name: "Lead Account",
      values: account,
      isImportable: !Boolean(account?.length),
    },
    {
      name: "Lead Contact",
      values: contact,
      isImportable: !Boolean(contact?.length),
    },
    {
      name: "Lead Partners",
      values: partners,
      isImportable: !Boolean(Object.keys(partners)?.length) && hasTradePartners,
    },
  ];

  const AccountContactEligibility = ({ fields }: { fields: string[] }) => (
    <Stack spacing={1} width="100%">
      {fields?.length > 0 && (
        <Typography fontWeight="bold" textAlign="center">
          Missing Fields
        </Typography>
      )}
      {fields &&
        fields?.map((item) => (
          <Chip
            label={item}
            color="error"
            variant="outlined"
            key={item}
            sx={{
              fontWeight: "bold",
              fontSize: "1em",
              width: "100%",
            }}
            size="small"
          />
        ))}
    </Stack>
  );

  const getHeight = (isImportable: boolean) => {
    if (isImportable) return "auto";
    if (!hasTradePartners) return "auto";
    return "80%";
  };
  return (
    <Stack width="100%" spacing={2} direction="row">
      {sections.map((section) => (
        <MainCard
          headerSx={{ py: 1, px: 3 }}
          content
          contentSX={{
            py: 2,
            px: section?.name !== "Lead Partners" ? 3 : 1,
            height: getHeight(section?.isImportable),
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}
          title={`${section?.name} Details`}
          footer={
            <Typography
              color="white.main"
              fontWeight="bold"
              textAlign="center"
              width="100%"
            >
              {section?.isImportable
                ? "Ready for Export"
                : "Cannot export without all the required fields"}
            </Typography>
          }
          footerSx={{
            bgcolor: section?.isImportable ? "success.main" : "error.main",
          }}
        >
          {section?.name !== "Lead Partners" ? (
            section?.isImportable ? (
              <Typography fontWeight={800} fontSize="1em">
                {section?.name} - Ready For Export
              </Typography>
            ) : (
              <AccountContactEligibility fields={section.values as string[]} />
            )
          ) : (
            <Stack spacing={1} width="100%">
              {!hasTradePartners && (
                <NoData
                  text="No Trade Partners - Ineligible for Export"
                  sx={{
                    height: "100%",
                    fontSize: "1em",
                  }}
                />
              )}
              <List>
                {hasTradePartners &&
                  Object.keys(section.values).map((key, index) => {
                    return (
                      <ListItem
                        sx={{
                          alignItems: "start",
                          px: 0,
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            minWidth: 40,
                          }}
                        >
                          <Avatar
                            sx={{
                              bgcolor: "primary.main",
                              width: 32,
                              height: 32,
                            }}
                            variant="rounded"
                            sizes="small"
                          >
                            {index + 1}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Partner - ${key}`}
                          primaryTypographyProps={{
                            fontWeight: "800",
                            fontSize: "1.2em",
                          }}
                          secondary={
                            <Stack
                              key={key}
                              spacing={1}
                              width="100%"
                              direction="row"
                            >
                              {Object.entries(
                                (section.values as Record<string, any>)[
                                  key
                                ] as Record<string, string[]>
                              ).map(([sectionName, fields]) => {
                                if (fields?.length > 0)
                                  return (
                                    <Stack sx={{ mb: 2 }}>
                                      <Typography
                                        fontWeight="bold"
                                        textAlign="center"
                                      >
                                        {toProperCase(sectionName)}
                                      </Typography>
                                      <AccountContactEligibility
                                        fields={fields}
                                      />
                                    </Stack>
                                  );
                                return <></>;
                              })}
                            </Stack>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Stack>
          )}
        </MainCard>
      ))}
    </Stack>
  );
};

export default ExportLeadDataContainer;
