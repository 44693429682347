import SyncIcon from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Tooltip, Typography } from "@mui/material";
import { CL_APPROVED, MARKETING_ADMIN, REJECTED_BY_SALES } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";

import {
  useContactCreditLimitCreatorMutation,
  useGetCreditLimitQuery,
  useGetCreditLimitTimelineQuery,
  useRequestCreditLimitMutation,
} from "api/api-origination";
import { PERMISSIONS } from "permissions";
import React from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import CLBadges from "./CLBadges";

interface CLDetailBodyHeaderProps {
  setShowChangeStatusModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      status:
        | {
            type:
              | "request"
              | "reject"
              | "contact_creator"
              | "approve"
              | "resubmit";
            cl_id: string;

            buyerName: string;
            id: string;
          }
        | undefined;
    }>
  >;
  setShowIncomlendReviewModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
    }>
  >;
}

const CLDetailBodyHeader: React.FC<CLDetailBodyHeaderProps> = ({
  setShowChangeStatusModal,
  setShowIncomlendReviewModal,
}) => {
  const { id } = useParams();
  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);

  const {
    data: creditLimit,
    isFetching: isFetchingCLR,
    refetch,
  } = useGetCreditLimitQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const { refetch: refetchTimeline } = useGetCreditLimitTimelineQuery(
    id ?? "",
    {
      skip: !Boolean(id),
    }
  );
  const [requestCreditLimit, { isLoading: isLoadingRequestCL }] =
    useRequestCreditLimitMutation();

  const [contactCreditLimitCreator, { isLoading: isLoadingContactCreator }] =
    useContactCreditLimitCreatorMutation();

  const isApproved = (status: string) => status === CL_APPROVED;
  const isRejected = (status: string) => status === REJECTED_BY_SALES;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={{ xs: 1 }}
    >
      <Grid item lg={6} xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              fontWeight="bold"
              fontSize={isMobile ? "1em" : "1.5em"}
              variant="h3"
            >
              {id}
            </Typography>
          </Grid>

          <Grid item>
            <Tooltip
              children={
                <SyncIcon
                  onClick={() => {
                    refetch();
                    refetchTimeline();
                  }}
                />
              }
              title="Sync latest changes"
            />
          </Grid>
        </Grid>
      </Grid>
      {isMobile && <CLBadges />}
      <Grid item lg={6} xs={12}>
        <Grid container spacing={2} justifyContent="end">
          <Grid item lg={6} xs={12}>
            {isFetchingCLR && <SkeletonLoad bars={1} />}
            {!isFetchingCLR &&
              creditLimit &&
              !creditLimit.coface_found &&
              USER_PERMISSION.includes(PERMISSIONS.get_insurer) && (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  disabled={
                    isRejected(creditLimit?.internalStatus) ||
                    isApproved(creditLimit?.internalStatus) ||
                    APP_PERMISSION === MARKETING_ADMIN
                  }
                  loading={isLoadingRequestCL}
                  onClick={() =>
                    creditLimit?.id &&
                    setShowChangeStatusModal({
                      visible: true,
                      status: {
                        id: creditLimit?.id,
                        cl_id: id ?? "",
                        buyerName: creditLimit.buyerName,
                        type: "request",
                      },
                    })
                  }
                >
                  Request Coface
                </LoadingButton>
              )}
          </Grid>

          <Grid item lg={6} xs={12}>
            {isFetchingCLR && <SkeletonLoad bars={1} />}
            {!isFetchingCLR && creditLimit && (
              <LoadingButton
                fullWidth
                variant="outlined"
                color="primary"
                loading={isLoadingContactCreator}
                disabled={
                  isApproved(creditLimit?.internalStatus) ||
                  APP_PERMISSION === MARKETING_ADMIN
                }
                onClick={() =>
                  creditLimit?.id &&
                  setShowIncomlendReviewModal({
                    visible: true,
                  })
                }
              >
                Incomlend Review
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && <CLBadges />}
    </Grid>
  );
};

export default CLDetailBodyHeader;
