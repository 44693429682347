import { Stack, Typography, useTheme } from "@mui/material";
import { useEditSummariesMutation } from "api/api-compliance";
import VariableInput from "components/Common/VariableInput";
import { Formik } from "formik";
import { MapContext } from "pages/maps/map";
import { useContext } from "react";

const Summary = ({
  title,
  subject,
  data,
  readonly = true,
}: {
  title: string;
  subject: string;
  data: any;
  readonly?: boolean;
}) => {
  const mapName = useContext(MapContext);

  const [editSummaries] = useEditSummariesMutation();

  const initialValues = {
    summary: data,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        const { summary } = formikProps.values;
        const theme = useTheme();
        const inputModeStyles = {
          p: 2,
          bgcolor: theme.palette.primary.main,
          borderRadius: 1,
          color: theme.palette.background.default,
        };
        return (
          <Stack spacing={1}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1em",
                ...(!readonly ? inputModeStyles : {}),
              }}
            >
              {title}
            </Typography>
            <VariableInput
              sx={{
                ".MuiInputBase-root": {
                  height: "15vh",
                  backgroundClip: "border-box",
                  borderRadius: "10px",
                  textarea: {
                    height: "13vh !important",
                    overflow: "scroll !important",
                  },
                },
              }}
              disabled={readonly}
              noHover={readonly}
              name="summary"
              placeholder={readonly ? "Not described" : "Enter summary..."}
              label=""
              handleSave={(value) => {
                mapName &&
                  editSummaries({
                    map_name: mapName,
                    data: {
                      summary: value as string,
                      subject: subject,
                    },
                  });
              }}
              value={summary ?? ""}
              multiline
              style={{ width: "100%" }}
              fullWidth
            />
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Summary;
