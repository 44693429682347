import { Info } from "@mui/icons-material";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useGetSFLeadQuery } from "api/api-accounts";
import MainCard from "components/Common/Cards/MainCard";
import React from "react";

interface ImportLeadDataContainerProps {
  section: string;
  fields: string[];
  sfLeadId?: string;
  PERMITTED_PRODUCT_TYPES?: string[];
  sfLead?: any;
}
const ImportLeadDataContainer: React.FC<ImportLeadDataContainerProps> = ({
  section,
  fields,
  sfLeadId,
  PERMITTED_PRODUCT_TYPES,
  sfLead,
}) => {
  const { data } = useGetSFLeadQuery(sfLeadId ?? "", {
    skip: !sfLeadId && Boolean(sfLead),
  });

  const lead = data?.data ?? sfLead;
  const IS_IMPORTABLE =
    fields?.every((field) => lead?.[field]) &&
    PERMITTED_PRODUCT_TYPES?.includes(lead?.Product_type__c);

  return (
    <MainCard
      headerSx={{ py: 1, px: 3 }}
      content
      contentSX={{
        py: 2,
        px: 3,
        minHeight: "20vh",
        width: "23vw",
      }}
      title={`${section} Details`}
      footer={
        <Typography
          color="white.main"
          fontWeight="bold"
          textAlign="center"
          width="100%"
        >
          {IS_IMPORTABLE
            ? "Ready for Import"
            : "Cannot import without all the required fields"}
        </Typography>
      }
      footerSx={{
        bgcolor: IS_IMPORTABLE ? "success.main" : "error.main",
      }}
    >
      <Stack spacing={2}>
        {fields?.map((field) => {
          const IS_FILLED = PERMITTED_PRODUCT_TYPES?.includes(lead?.[field]);
          return (
            <Stack key={field} direction="row" spacing={2}>
              <Typography fontWeight="bold">{field}</Typography>
              {field !== "Product_type__c" && (
                <Typography
                  sx={{
                    border: 2,
                    borderStyle: "solid",
                    borderColor: lead?.[field] ? "success.main" : "error.main",
                    padding: "1px 2ch",
                    borderRadius: 10,
                    maxWidth: "90%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {lead?.[field] ?? "Field not found in Lead"}
                </Typography>
              )}
              {field === "Product_type__c" &&
                Boolean(PERMITTED_PRODUCT_TYPES?.length) && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      border: 2,
                      borderStyle: "solid",
                      borderColor: IS_FILLED ? "success.main" : "error.main",
                      padding: "1px 2ch",
                      borderRadius: 10,
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                      }}
                    >
                      {IS_FILLED ? lead?.[field] : `Invalid Product Type`}
                    </Typography>
                    <Tooltip
                      title={
                        <div>
                          <span>
                            {`Permitted Product Types: ${PERMITTED_PRODUCT_TYPES?.join(" & ")}`}
                          </span>
                          <br />
                          <br />
                          <span>
                            {`${IS_FILLED ? "" : `Provided Product Type: ${lead?.[field]}`}`}
                          </span>
                        </div>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </Stack>
                )}
            </Stack>
          );
        })}
      </Stack>
    </MainCard>
  );
};

export default ImportLeadDataContainer;
