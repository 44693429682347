import { DoubleArrow } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, Typography } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import React from "react";

interface Versions {
  mapVersion: string;
  templateVersion: string;
}
const UpgradeMap: React.FC<Versions> = ({ mapVersion, templateVersion }) => {
  const versionParser = (version: string) => {
    return parseFloat(version?.replace(".", ""));
  };
  const CAN_UPGRADE =
    versionParser(templateVersion) > versionParser(mapVersion);

  return (
    <MainCard
      contentSX={{
        px: 4,
        py: 2,
      }}
      content
      title={
        <Typography
          sx={{
            fontWeight: "bold",
            borderRadius: 1,
            mt: 2,
            ml: 1,
            fontSize: "1.2em",
          }}
        >
          Upgrade Available
        </Typography>
      }
      headerSx={{ py: 1, px: 2 }}
      footer={
        <Stack alignItems="flex-end" width="100%">
          <LoadingButton
            variant="contained"
            color={CAN_UPGRADE ? "success" : "error"}
          >
            Upgrade
          </LoadingButton>
        </Stack>
      }
    >
      <Typography>
        A map version update is available. This might potentially break data in
        your existing map. Please take a backup of your data before you proceed.
      </Typography>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        justifyContent={CAN_UPGRADE ? "center" : "flex-end"}
        sx={{
          bgcolor: "#a2a6c338",
          outline: "1px dashed #a2a6c3",
          width: "13%",
          textAlign: "center",
          p: 1,
          borderRadius: 1,
          mt: 1,
        }}
      >
        <Typography>{mapVersion}</Typography>
        {versionParser(mapVersion) - versionParser(templateVersion) !== 0 && (
          <DoubleArrow
            color={
              versionParser(mapVersion) > versionParser(templateVersion)
                ? "error"
                : "success"
            }
          />
        )}
        {versionParser(mapVersion) - versionParser(templateVersion) !== 0 && (
          <Typography
            sx={{
              color: !CAN_UPGRADE ? "#C10230" : "#2e7d32",
              fontWeight: "bold",
            }}
          >
            {templateVersion}
          </Typography>
        )}
      </Stack>
    </MainCard>
  );
};

export default UpgradeMap;
