import { Divider, Grid, InputAdornment, Typography } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import { MapGeneral } from "components/Risk/defaults";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import styles from "../../maps.module.scss";

const InsuranceDetails = () => {
  const formikProps = useFormikContext<MapGeneral>();
  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={12}>
        <Typography fontWeight="bold">Total Credit Limit</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Insurers"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={currencyFormatter({
            amount: formikProps.values.totalCLbyInsurers,
            currency: formikProps.values.currency,
          })}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <Input
          labelClassName={styles.labelClass}
          name="totalAvailableCreditLimit"
          label="Approved by Credit Committee"
          placeholder="Total Credit Limit approved by Credit Committee"
          textfieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  {formikProps.values.currency}
                </InputAdornment>
              ),
            },
          }}
          type="money"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <LabelViewOnly
          label="Available"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={currencyFormatter({
            amount: formikProps.values.totalAvailableCreditLimit,
            currency: formikProps.values.currency,
          })}
        />
      </Grid>
    </Grid>
  );
};
export default InsuranceDetails;
