import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
  type AccordionProps
} from "@mui/material";
import { useGetTermsheetCommentsQuery } from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";
import moment from "moment";
import type { TermsheetComment } from "types";

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  "&.MuiAccordion-root:before": {
    backgroundColor: "transparent"
  },
}))

const getText = (action: string, comment: TermsheetComment) => {
  const timestamp = moment(comment.created_at).format("DD/MM/YY HH:mm")
  switch (action) {
    case "IN_PROGRESS": return `Recalled on ${timestamp}`;
    case "REASSIGNED_TERMSHEET": return `Reassigned on ${timestamp}`;
    case "PENDING_INTERNAL": return `Submitted for internal approval on ${timestamp}`;
    case "REJECTED_INTERNAL": return `Rejected internal approval on ${timestamp}`;
    case "PENDING_EXTERNAL": return `Submitted for customer approval on ${timestamp}`;
    case "APPROVED": return `Customer Approved term sheet on ${timestamp}`
    case "REJECTED": return `Customer Rejected term sheet on ${timestamp}`
    default: return `${timestamp}`;
  }
}

const LeadPartnerTermSheetHistory = ({
  partner_id
}: {
  partner_id: string;
}) => {
  const theme = useTheme()
  const { data: comments, isFetching, refetch: refreshComments } = useGetTermsheetCommentsQuery({ partner_id }, {
    skip: !Boolean(partner_id)
  })
  return (
    <StyledAccordion
      defaultExpanded
      sx={{ borderRadius: "10px" }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="termsheet-history-content"
        id="termsheet-history-id"
      >
        <Stack spacing={2} direction={"row"}>
          <Typography
            fontWeight={600}
            fontSize="1.1em"
            textAlign="center"
          >
            TS History
          </Typography>
          <Tooltip
            children={
              <SyncIcon
                onClick={(e) => {
                  e.stopPropagation()
                  refreshComments()
                }}
                sx={{ ":hover": { cursor: "pointer" } }}
              />
            }
            title="Sync latest changes"
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Divider sx={{ mb: 2 }} />
        <Stack spacing={1}>
          <Grid
            container
            width={"100%"}
            spacing={1}
            sx={{
              overflow: "scroll",
              mb: 2,
              "::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
            justifyContent="start"
          >
            {isFetching && (
              <SkeletonLoad bars={2} />
            )}
            {!isFetching && comments?.length === 0 && (
              <Grid item xs={12}>
                <Typography sx={{ minHeight: "60vh" }} textAlign="center">
                  No history recorded
                </Typography>
              </Grid>
            )}
            {!isFetching && comments?.length !== 0 && (
              <Grid item xs={12}>
                <Grid container rowSpacing={2}>
                  {comments?.map((item) => (
                    <>
                      <Grid item xs={2} sx={{ display: "flex", justifyContent: "start" }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                          {`${item.contact?.split(" ")?.[0]?.charAt(0)}${item.contact?.split(" ")?.[1]?.charAt(0)}`}
                        </Avatar>
                      </Grid>
                      <Grid item xs={10}>
                        <Stack direction={"column"} alignItems={"start"}>
                          <Typography fontSize={"1.0em"}>{item.contact}</Typography>
                          <Typography fontSize={"0.8em"}>{item.comments}</Typography>
                          <Typography fontSize={"0.7em"} color={theme.palette.secondary.light}>{getText(item.action, item)}</Typography>
                        </Stack>
                      </Grid>
                    </>

                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Stack>

      </AccordionDetails>
    </StyledAccordion>
  );
};
export default LeadPartnerTermSheetHistory;
