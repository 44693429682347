import LoadingButton from "@mui/lab/LoadingButton";
import { Card, Grid, Paper } from "@mui/material";
import { useSubscribeCargoMutation } from "api/api-vendorsv2";
import container from "assets/aerial-view-container-cargo-ship-sea.jpg";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { addShipmentId } from "redux/reducers/cargoReducer";
import styles from "./cargo-tracking.module.scss";

const CargoTracking = () => {
  const dispatch = useAppDispatch();
  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const initialValues = {
    shipmentId: "",
    scac: "",
    transportDocumentId: "",
    transportDocumentType: "BL",
  };
  const [subscribeCargo, { isLoading }] = useSubscribeCargoMutation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <Form>
            <Paper
              elevation={10}
              sx={{
                backgroundImage: `url(${container})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "auto",
                padding: { xs: 1, lg: 3 },
              }}
            >
              <Card
                sx={{
                  width: { xs: "100%", lg: "75%" },
                  margin: "auto",
                  padding: { xs: 2, lg: 4 },
                  boxShadow:
                    "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)",
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={{ xs: 1, lg: 2 }}
                >
                  <Grid item xs={12}>
                    <Input
                      name="shipmentId"
                      label="Shipment ID"
                      placeholder="Shipment ID"
                      labelClassName={styles.labelClass}
                      helpText="The unique identifier of each shipment to track throughout its lifecycle."
                      fullWidth
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Input
                      name="scac"
                      label="SCAC"
                      placeholder="Enter SCAC"
                      helpText="The Standard Carrier Alpha Code (or SCAC Code) is a unique two to four-letter code used to identify vessel operating common carriers (VOCC) in computer systems and shipping documents."
                      labelClassName={styles.labelClass}
                      fullWidth
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Input
                      name="transportDocumentId"
                      label="Transport Document ID"
                      placeholder="Transport Document ID"
                      fullWidth
                      helpText="Transport Document ID. Input the available transport document number. Can be a Bill of Lading, a Booking Reference, or a Container Number."
                      labelClassName={styles.labelClass}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Drop
                      label="Transport Document Type"
                      helpText="Transport document eg: Bill of Lading, Booking Reference, Container Number."
                      name="transportDocumentType"
                      keyValue="name"
                      data={[
                        { name: "Bill of Lading", value: "BL" },
                        { name: "Booking Reference", value: "BK" },
                        { name: "Container Number", value: "CN" },
                      ]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} marginLeft="auto">
                    <LoadingButton
                      color="error"
                      variant="contained"
                      loading={isLoading}
                      fullWidth
                      disabled={
                        Boolean(formikProps.values.shipmentId === "") ||
                        Boolean(formikProps.values.scac === "") ||
                        Boolean(formikProps.values.transportDocumentId === "")
                      }
                      onClick={() => {
                        subscribeCargo({
                          accountId: USER_ROLE?.id,
                          scac: formikProps.values.scac,
                          tdId: formikProps.values.transportDocumentId,
                          tdType: formikProps.values.transportDocumentType,
                        })
                          .unwrap()
                          .then((res) => {
                            dispatch(addShipmentId(res.shipment_id));
                            toast(
                              `Tracking activated for ${res.shipment_id} successfully!`,
                              { type: "success" }
                            );
                          })
                          .catch((e) => toast(e?.message, { type: "error" }));
                      }}
                    >
                      Track Your Shipment
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Card>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CargoTracking;
