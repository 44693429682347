import InfoIcon from "@mui/icons-material/Info";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Checkbox,
  type CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  NativeSelect,
  type OutlinedTextFieldProps,
  Stack,
  TextField,
  type TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import type { SxProps } from "@mui/material/styles";
import { useField } from "formik";

import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

import DateInput from "components/Common/DateInput";
import at from "helpers/at";
import countrycodes from "helpers/phone-lib.json";
import splitStringWithCountryCode from "helpers/splitStringWithCountryCode";
import styles from "./form-field.module.scss";

type InputMode = "none" | "text" | "email" | "numeric" | "decimal";
export type InputType =
  | InputMode
  | "password"
  | "date"
  | "money"
  | "checkbox"
  | "file"
  | "phone"
  | "number";
export interface InputFieldProps {
  /**Name of field */
  name: string;
  /**Label for the input */
  label: string | React.ReactNode;
  /**Input type of field */
  type?: InputType;
  /**Custom class */
  className?: string;
  /**Readonly value */
  value?: string | number | boolean | Date;
  /**Suggestive text */
  placeholder?: string;

  /**If `yes` the field becomes multi-line*/
  multiline?: boolean;
  rows?: TextFieldProps["rows"];
  /**Allow negative numbers */
  allowNegative?: boolean;

  fullWidth?: boolean;

  autoComplete?: "on" | "off";
  /**Applies only for text inputs */
  textfieldProps?: TextFieldProps;

  tabIndex?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  decimalScale?: any;
  sx?: SxProps;
  onChange?: TextFieldProps["onChange"];
  onCheckboxChange?: CheckboxProps["onChange"];
  /**Date format `eg: DD-MM-YYYY` */
  dateFormat?: string;
  labelClassName?: string;
  labelClassSx?: SxProps;
  labelRequiredClassName?: string;
  labelRequiredClassSx?: SxProps;
  helpText?: string;
  variant?: TextFieldProps["variant"];
  required?: boolean;
}

const InputField = (props: InputFieldProps) => {
  const {
    type = "text",
    name,
    label,
    className = "",
    style,
    value,
    disabled,
    placeholder,
    multiline,
    rows,
    allowNegative,
    fullWidth,
    textfieldProps,
    tabIndex,
    variant = "outlined",
    onChange = () => {},
    onCheckboxChange,
    autoComplete = "on",
    dateFormat = "YYYY-MM-DD",
    labelClassName = "",
    labelRequiredClassName = "",
    sx,
    helpText,
    required,
    labelClassSx,
    labelRequiredClassSx,
  } = props;
  let setValue: (field: string, value: any, shouldValidate?: any) => void;

  const [field, meta, helpers] = useField(name);
  const [touched, error] = at(meta, ["touched", "error"]);
  const isError: boolean = touched && error && true;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const { onBlur } = field;

  const Label = () => (
    <Stack
      alignItems="center"
      justifyContent="start"
      spacing={0.5}
      direction="row"
      mb={0.5}
    >
      <Typography fontWeight="bold">{label && `${label}`}</Typography>
      {required && <Typography color="error">*</Typography>}
      {Boolean(helpText) && (
        <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
          <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
        </Tooltip>
      )}
      <InputLabel />
    </Stack>
  );

  switch (type) {
    case "date":
      return (
        <DateInput
          labelClassName={labelClassName}
          wrapperClassName={styles.datePickerWrapper}
          name={name}
          label={label as string}
          value={value || field.value}
          setValue={helpers.setValue}
          error={error}
        />
      );
    case "money":
      return (
        <FormControl className={className} error={isError} style={style}>
          {label && <Label />}
          <NumericFormat
            inputProps={{ tabIndex, "data-testid": `${type}-field-${name}` }}
            onBlur={onBlur}
            name={name}
            InputProps={textfieldProps?.["InputProps"]}
            customInput={TextField}
            decimalScale={props.decimalScale || 2}
            fixedDecimalScale={true}
            thousandSeparator
            value={value || field.value}
            variant={variant as OutlinedTextFieldProps["variant"]}
            sx={sx}
            margin="dense"
            allowNegative={allowNegative}
            disabled={disabled}
            error={isError}
            fullWidth={fullWidth}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => [onChange(e), field.onChange(e)]}
            type="text"
          />
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    case "checkbox":
      return (
        <Grid container spacing={1} alignItems="center" justifyContent="start">
          <Grid item>
            <FormControlLabel
              className={labelClassName}
              control={
                <Checkbox
                  name={name}
                  onBlur={onBlur}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => [
                    onCheckboxChange
                      ? onCheckboxChange(event, checked)
                      : field.onChange(event),
                  ]}
                  value={Boolean(field.value)}
                  inputProps={{ tabIndex }}
                  className={className}
                  disabled={disabled}
                  checked={Boolean(field.value)}
                  sx={sx}
                />
              }
              label={label}
              sx={{ marginRight: 0 }}
            />
          </Grid>
          {Boolean(helpText) && (
            <Tooltip
              title={<Grid container>{helpText}</Grid>}
              enterTouchDelay={0}
            >
              <Grid item>
                <InfoIcon style={{ marginTop: "6px" }} />
              </Grid>
            </Tooltip>
          )}
        </Grid>
      );
    case "file":
      return (
        <FormControl
          className={className}
          error={isError}
          data-testid={`${type}-field-${name}`}
          style={style}
        >
          {label && <Label />}
          <Button
            variant="contained"
            className={styles.fileUpload}
            component="label"
          >
            <div className={`${styles.uploadBtnPlaceholder}`}>
              Select jpg,png or pdf
            </div>
            <div className={`${styles.uploadBtn}`}>Choose File</div>
            <input
              type="file"
              accept="application/pdf,image/x-png,image/jpeg,image/jpg"
              hidden
              onChange={(e) => {
                onChange(e);
                const selectedFile: File = (e?.target?.files?.[0] ??
                  []) as File;
                helpers.setValue(selectedFile);
              }}
            />
          </Button>
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    case "number":
      return (
        <FormControl className={className} error={isError} style={style}>
          {label && <Label />}
          <TextField
            inputProps={{ tabIndex, "data-testid": `${type}-field-${name}` }}
            onBlur={onBlur}
            onChange={field.onChange}
            {...textfieldProps}
            sx={sx}
            placeholder={placeholder}
            value={value || field.value}
            disabled={disabled}
            error={isError}
            name={name}
            type="number"
            variant={variant}
            margin="dense"
            fullWidth={fullWidth}
          />
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    case "phone":
      const countryCode = splitStringWithCountryCode(
        value || field.value || "+65",
        countrycodes
      ).countryCode;
      const country = countrycodes.find(
        (item) => item.code === countryCode
      )?.name;
      return (
        <FormControl
          className={className}
          error={isError}
          data-testid={`${type}-field-${name}`}
          style={style}
        >
          {label && <Label />}
          <TextField
            autoComplete={autoComplete as any}
            inputProps={{ tabIndex }}
            onBlur={onBlur}
            onChange={field.onChange}
            value={value || field.value || "+65"}
            disabled={disabled}
            InputProps={{
              ...props.textfieldProps?.["InputProps"],
              startAdornment: (
                <Tooltip title={country}>
                  <InputAdornment
                    position="start"
                    sx={{ maxWidth: "20%", paddingLeft: 1 }}
                  >
                    <NativeSelect
                      defaultValue="+65"
                      onChange={(e) => {
                        helpers.setValue(e.target.value);
                      }}
                      inputProps={{
                        name: "countryCode",
                        id: "native-countryCode",
                      }}
                      value={countryCode}
                    >
                      {Boolean(countrycodes.length) &&
                        countrycodes.map((item) => (
                          <option key={item.name} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </NativeSelect>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
            {...textfieldProps}
            sx={{
              ...sx,
              ".MuiInputAdornment-root": {
                ".MuiInputBase-root": {
                  "&:hover": {
                    borderBottom: "none !important",
                  },
                  "&::after": {
                    borderBottom: "none !important",
                  },
                  "&::before": {
                    borderBottom: "none !important",
                  },
                },
                "&:focus-visible": {
                  outline: "none",
                },
              },
            }}
            name={name}
            rows={rows}
            error={isError}
            multiline={multiline}
            placeholder={placeholder}
            type="text"
            variant={variant}
            margin="dense"
            fullWidth={fullWidth}
          />

          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    case "password":
      return (
        <FormControl
          className={className}
          error={isError}
          data-testid={`${type}-field-${name}`}
          style={style}
        >
          {label && <Label />}
          <TextField
            inputProps={{ tabIndex }}
            onBlur={onBlur}
            onChange={field.onChange}
            {...textfieldProps}
            multiline={multiline}
            error={isError}
            name={name}
            autoComplete={autoComplete}
            value={value || field.value}
            placeholder={placeholder}
            sx={sx}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {value || field.value ? (
                      <VisibilityOff tabIndex={-1} />
                    ) : (
                      <Visibility tabIndex={-1} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={disabled}
            type={showPassword ? "text" : "password"}
            variant={variant}
            margin="dense"
            fullWidth={fullWidth}
          />
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    case "email":
      return (
        <FormControl
          className={className}
          error={isError}
          data-testid={`${type}-field-${name}`}
          style={style}
        >
          {label && <Label />}
          <TextField
            inputProps={{ tabIndex }}
            onBlur={onBlur}
            onChange={field.onChange}
            {...textfieldProps}
            sx={sx}
            rows={rows}
            name={name}
            error={isError}
            multiline={multiline}
            value={(value || field.value)?.trim()}
            placeholder={placeholder}
            disabled={disabled}
            type="email"
            variant={variant}
            margin="dense"
            fullWidth={fullWidth}
          />
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    default:
      return (
        <FormControl
          className={className}
          error={isError}
          data-testid={`${type}-field-${name}`}
          style={style}
        >
          {label && <Label />}
          <TextField
            inputProps={{ tabIndex }}
            onBlur={onBlur}
            onChange={field.onChange}
            {...textfieldProps}
            sx={sx}
            name={name}
            rows={rows}
            error={isError}
            multiline={multiline}
            value={value || field.value}
            placeholder={placeholder}
            disabled={disabled}
            type="text"
            variant={variant}
            margin="dense"
            fullWidth={fullWidth}
          />
          {isError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
  }
};
export default InputField;
