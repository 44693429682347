import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const env = import.meta.env.VITE_ENV;
const debug = Boolean(env === "local" || env === "dev");

const getResources = (translations: Record<string, any>) => {
  const { english, hindi, french, chinese, bangla } = translations;
  return {
    en: {
      translation: english,
    },
    hi: {
      translation: hindi,
    },
    fr: {
      translation: french,
    },
    ch: {
      translation: chinese,
    },
    bn: {
      translation: bangla,
    },
  };
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

const onlineI18n = (translations: Record<string, any>) => {
  i18n.options.resources = getResources(translations);
  return i18n;
};
export default onlineI18n;
