import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useDeleteMapDetailsBatchMutation,
  useGetMapRiskParametersQuery,
  useGetMapSummaryQuery,
  useGetTemplatesV2Query,
  type MAP_PARTY,
} from "api/api-maps";
import { SUPPLIER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import isObjectEmpty from "helpers/isObjectEmpty";
import moment from "moment";
import { MapContext, MapCurrentVersion } from "pages/maps/map";
import React, { useEffect, useState } from "react";
import FinancialColumn from "./FinancialColumn";
import YearPicker from "./YearPicker";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    format: string;
    log_id: string;
    data: string | number | string[];
    need_growth_rate?: boolean;
  };
}

type FINANCIAL_BY_YEAR = {
  [year: string]: MapTemplate;
};

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}

const Financials = ({
  accountType,
  disabled,
}: {
  accountType: string;
  disabled: boolean;
}) => {
  const item_type = "financial";
  const id = React.useContext(MapContext);

  const {
    data: mapRiskParams,
    isLoading: isLoadingMapRiskParams,
    refetch: refetchValues,
  } = useGetMapRiskParametersQuery(
    {
      map_name: id ?? "",
      account_type: accountType as "supplier" | "buyer",
      item_type,
    },
    { skip: !id }
  );

  const [deleteBatchMapRiskData] = useDeleteMapDetailsBatchMutation();

  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const currentMapVersion = React.useContext(MapCurrentVersion);

  const { data: financialsTemplate, isLoading: isLoadingTemplate } =
    useGetTemplatesV2Query(
      {
        party: accountType?.toLowerCase() as MAP_PARTY,
        use_case: "financial",
        ipl_product: mapData?.ipl_product_id ?? "",
        map_version: currentMapVersion,
      },
      {
        skip: !mapData?.ipl_product_id,
      }
    );
  const companyName = `${
    accountType === SUPPLIER?.toLowerCase()
      ? mapData?.seller_name
      : mapData?.buyer_name
  } (in USD)`;

  const theme = useTheme();

  const generateInitialValues = (
    valueObject: MapRiskParamValues,
    initialYear?: string,
    financials?: MapTemplate
  ): MapRiskParamValues => {
    if (!isObjectEmpty(valueObject)) {
      return Object.entries(valueObject).reduce((acc, [key, value]) => {
        if (typeof value === "object" && value !== null) {
          // If the value is an object, transform its keys to camelCase
          acc[key] = Object.entries(value).reduce(
            (innerAcc, [innerKey, innerValue]) => {
              innerAcc[toCamelCase(innerKey)] = innerValue;
              return innerAcc;
            },
            {} as Record<string, any>
          );
        } else {
          // Otherwise, keep the value as is
          acc[key] = value;
        }
        return acc;
      }, {} as MapRiskParamValues);
    }
    if (initialYear !== undefined)
      return {
        [initialYear]: financials
          ? Object.keys(financials).map((item) => ({
              [toCamelCase(item)]: undefined,
            }))
          : {},
      };
    return {};
  };

  const yearSort = (a: string, b: string) => {
    const yearA = a?.trim().split("-")[0];
    const yearB = b?.trim().split("-")[0];
    return (
      moment(yearB, "MM/yyyy").valueOf() - moment(yearA, "MM/yyyy").valueOf()
    );
  };

  const [financialsByYear, setFinancialsByYear] = useState<FINANCIAL_BY_YEAR>(
    () => {
      const initialValues = mapRiskParams
        ? generateInitialValues(mapRiskParams)
        : ({} as FINANCIAL_BY_YEAR);
      if (Object.keys(initialValues).length) {
        return Object.entries(initialValues)
          .sort(([a], [b]) => yearSort(a, b))
          .reduce((acc, [key, value]) => {
            acc[`${key?.trim()}`] = value;
            return acc;
          }, {} as FINANCIAL_BY_YEAR);
      }
      return initialValues;
    }
  );

  const sortAndCompile = (data: { [x: string]: MapTemplate }) => {
    const keyValueArray = Object.entries(data)
      // Sort the array by keys (month, years) in descending order
      .sort(([a], [b]) => yearSort(a, b))
      // Convert the sorted array back to financials Object
      .reduce((acc, [key, value]) => {
        //preserve descending order
        acc[`${key?.trim()}`] = value;
        return acc;
      }, {} as FINANCIAL_BY_YEAR);

    setFinancialsByYear(keyValueArray);
  };

  const addYear = (year: string) => {
    const unsortedObject = {
      ...financialsByYear,
      [year]: financialsTemplate?.template as MapTemplate,
    };
    sortAndCompile(unsortedObject);
  };

  useEffect(() => {
    mapRiskParams && setFinancialsByYear(generateInitialValues(mapRiskParams));
  }, [isLoadingMapRiskParams]);

  const [open, setOpen] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    if (mapRiskParams && Object.keys(mapRiskParams).length > 0) {
      return Object.keys(mapRiskParams)[0].split("-")[1];
    }
    return "USD";
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (year?: string, currency?: string) => {
    setOpen(false);
    if (!year || !currency) return;
    if (year) {
      addYear(`${year}-${currency}`);
    }
    if (currency) setSelectedCurrency(currency);
  };

  const handleBatchDelete = (item: string) => {
    let data: {
      map_metadata_id: string;
      source: string;
    }[] = [];
    if (mapRiskParams !== undefined && !isObjectEmpty(mapRiskParams[item])) {
      Object.entries(mapRiskParams[item]).forEach(([field_name]) => {
        data.push({
          map_metadata_id:
            (financialsTemplate?.template as MapTemplate)[field_name]?.id ?? "",
          source: item,
        });
      });
      deleteBatchMapRiskData({
        map_name: id ?? "",
        account_type: accountType as "supplier" | "buyer",
        data: data,
      });
    }
  };

  return (
    <Grid item xs={12}>
      {(isLoadingMapRiskParams || isLoadingTemplate) && (
        <SkeletonLoad bars={10} />
      )}
      {!(isLoadingMapRiskParams || isLoadingTemplate) &&
        financialsTemplate &&
        mapRiskParams && (
          <Formik
            initialValues={financialsByYear}
            onSubmit={console.log}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form>
                  <TableContainer>
                    <Table component={Paper} size="small">
                      <TableHead
                        sx={{
                          borderBottom: `3px solid ${theme.palette.error.main}`,
                        }}
                      >
                        <TableRow
                          sx={{
                            bgcolor: "#a2a6c391",
                            borderLeft: `1px solid ${theme.palette.error.main}`,
                            borderRight: `1px solid ${theme.palette.error.main}`,
                            borderTop: `3px solid ${theme.palette.error.main}`,
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: `1px solid ${theme.palette.error.main}`,
                              borderLeft: `3px solid ${theme.palette.error.main}`,
                            }}
                          >
                            <Typography fontWeight="bold">
                              {companyName}
                            </Typography>
                          </TableCell>
                          {Object.keys(formikProps.values).map((item) => {
                            return (
                              <TableCell key={item}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Typography fontWeight="bold">
                                    {moment(item, "MM/yyyy").format("MMM-YYYY")}
                                  </Typography>
                                  <Tooltip
                                    title="Delete Record"
                                    placement="right-end"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        const newValues = {
                                          ...formikProps.values,
                                        };
                                        delete newValues[item];
                                        formikProps.setValues(newValues);
                                        sortAndCompile(newValues);
                                        handleBatchDelete(item);
                                      }}
                                      disabled={disabled}
                                    >
                                      <Delete
                                        color={disabled ? "disabled" : "error"}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                            );
                          })}
                          {Object.keys(formikProps.values)?.length > 1 && (
                            <TableCell>
                              <Typography fontWeight="bold">Growth</Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Button
                                variant="contained"
                                onClick={handleClickOpen}
                                startIcon={<Add />}
                                disabled={disabled}
                              >
                                YEAR
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!financialsTemplate && <SkeletonLoad bars={10} />}
                        {financialsTemplate &&
                          Object.entries(financialsTemplate?.template).map(
                            (item) => {
                              return (
                                <FinancialColumn
                                  item={item}
                                  mapRiskParams={mapRiskParams}
                                  disabled={disabled}
                                  accountType={accountType}
                                />
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <YearPicker open={open} onClose={handleClose} />
                </Form>
              );
            }}
          </Formik>
        )}
    </Grid>
  );
};
export default Financials;
