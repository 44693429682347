import { Button, Stack } from "@mui/material";
import { useAddNewPermissionMutation } from "api/api-it";
import Input from "components/Common/Input";
import { Formik } from "formik";
import { toast } from "react-toastify";

const AddPermission = ({ handleClose }: { handleClose: () => void }) => {
  const [addNewPermission] = useAddNewPermissionMutation();
  return (
    <Formik initialValues={{ permission: "" }} onSubmit={console.log}>
      {(formikProps) => {
        return (
          <Stack
            spacing={2}
            direction="row"
            alignItems="end"
            justifyContent="center"
            flexGrow={1}
          >
            <Input
              name="permission"
              label=""
              placeholder="Type permission name"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ maxHeight: "5em" }}
              onClick={() => {
                const { permission } = formikProps.values;
                if (permission)
                  addNewPermission([permission])
                    .then(() => {
                      handleClose();
                      toast("Permission added", { type: "success" });
                    })
                    .catch((err) => {
                      toast(`An error occured: ${err?.message}`, {
                        type: "error",
                      });
                    });
              }}
            >
              Add
            </Button>
          </Stack>
        );
      }}
    </Formik>
  );
};
export default AddPermission;
