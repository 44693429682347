import {
  Avatar,
  Chip,
  Grid,
  Stack,
  Typography,
  useTheme,
  type Theme,
} from "@mui/material";
import { useGetAccountByNameQuery } from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Icons from "components/Company/Registration/SelectorCard/Cards";
import toProperCase from "helpers/propercase";
import CLDisplay from "../CLDisplay";
import { useCLContext } from "./CLContextProvider";

const CLBadges = () => {
  const theme = useTheme();
  const creditLimit = useCLContext();
  const { buyerInternalName } = creditLimit;
  const { data: account, isLoading } = useGetAccountByNameQuery(
    buyerInternalName ?? "",
    {
      skip: !buyerInternalName,
    }
  );

  const ProductIcon = ({ product }: { product: string }) => {
    switch (product) {
      case "factoring":
        return (
          <Icons.SupplierIcon active={true} activeColor="white" size="15px" />
        );
      case "reverse_factoring":
        return (
          <Icons.BuyerIcon active={true} activeColor="white" size="15px" />
        );
      default:
        return (
          <Icons.BuyerIcon active={true} activeColor="white" size="15px" />
        );
    }
  };
  const getBackgroundColor = (theme: Theme, product: string) => {
    switch (product) {
      case "factoring":
        return "primary";
      case "reverse_factoring":
        return "error";
      default:
        return "primary";
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item xs={6} lg={2}>
          {!creditLimit && <SkeletonLoad bars={1} />}
          {creditLimit && <CLDisplay status={creditLimit?.internalStatus} />}
        </Grid>
        <Grid item>
          <Chip
            label={`Lead Source: ${toProperCase(creditLimit?.source ?? "")}`}
            variant="outlined"
            sx={{
              fontWeight: "bold",
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              px: 2,
            }}
            size="small"
          />
        </Grid>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && account && (
          <Grid item>
            <Chip
              variant="filled"
              size="small"
              color={getBackgroundColor(theme, account?.account_product ?? "")}
              avatar={
                <Avatar
                  color="primary"
                  sx={{
                    bgcolor: `${getBackgroundColor(theme, account?.account_product ?? "")}.main`,
                  }}
                >
                  <ProductIcon product={account?.account_product ?? ""} />
                </Avatar>
              }
              label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#ffffff",
                      fontWeight: 700,
                      paddingBottom: "0.3em",
                    }}
                  >
                    {account?.account_product ?? ""}
                  </Typography>
                </Stack>
              }
              sx={{
                fontWeight: 700,
                ".MuiChip-label": {
                  paddingTop: "4px",
                },
                px: 2,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default CLBadges;
