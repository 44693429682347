import { Warning } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Grid,
  Stack,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useApproveCreditCommitteeMutation } from "api/api-maps";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Modal from "components/Common/Modal";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import useIsMobile from "hooks/useIsMobile";
import { MapContext } from "pages/maps/map";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import type { MapApprover } from "types";

interface ApproverContact extends MapApprover {
  contact_id: string;
}
interface CreditCommitteeApprover {
  [department: string]: ApproverContact;
}

interface HeadApproversFieldProps {
  buyer: string;
  supplier: string;
  department: string;
}

const getBgColor = (status: string) => {
  switch (status) {
    case "Approved":
      return "#9FCE63";
    case "Rejected":
      return "#FFEBEE";
    default:
      return "orange";
  }
};

const ApprovalField = styled(Typography)<{ status: string; theme?: Theme }>(
  ({ theme, status }) => ({
    backgroundColor: getBgColor(status),
    color: theme.palette.white.main,
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
  })
);

const HeadApproversField: React.FC<HeadApproversFieldProps> = ({
  department: key,
  buyer,
  supplier,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const id = useContext(MapContext);
  const formikProps = useFormikContext<CreditCommitteeApprover>();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [modifyApproval, { isLoading: isLoadingApprovalModification }] =
    useApproveCreditCommitteeMutation();
  const shouldDisableButton = (
    currentDecision: string,
    assign_contact_id?: string
  ): boolean => {
    if (currentDecision === "Approved" || currentDecision === "Rejected")
      return true;
    if (assign_contact_id === undefined) return true;
    return currentUser?.id !== assign_contact_id;
  };

  const [showApprovalModal, setShowApprovalModal] = useState({
    visible: false,
    type: "",
    current_user: "",
  });

  const DEPARTMENT_MAPPER: Record<string, string> = {
    ceo_ipl: "CEO IPL",
    ceo_icpl: "CEO ICPL",
    funding: "Head of Funding",
  };
  const URL_MAPPER: Record<string, string> = {
    ceo_ipl: "ceo-ipl",
    ceo_icpl: "ceo-icpl",
    funding: "head-funding",
  };
  const PHASE: Record<string, string> = {
    approve: "Approve",
    reject: "Reject",
  };
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{DEPARTMENT_MAPPER[key]}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              color: formikProps.values[toCamelCase(key)]?.approver_name
                ? "primary.main"
                : "info.main",
              fontWeight: formikProps.values[toCamelCase(key)]?.approver_name
                ? 600
                : 500,
            }}
          >
            {formikProps.values[toCamelCase(key)]?.approver_name ?? "None"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <LoadingButton
              variant="contained"
              color="success"
              disabled={shouldDisableButton(
                formikProps.values[toCamelCase(key)]?.approval_status,
                formikProps.values[toCamelCase(key)]?.contact_id
              )}
              onClick={() =>
                setShowApprovalModal({
                  visible: true,
                  type: "approve",
                  current_user:
                    formikProps.values[toCamelCase(key)]?.approver_name,
                })
              }
            >
              Approve
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              disabled={shouldDisableButton(
                formikProps.values[toCamelCase(key)]?.approval_status,
                formikProps.values[toCamelCase(key)]?.contact_id
              )}
              onClick={() =>
                setShowApprovalModal({
                  visible: true,
                  type: "reject",
                  current_user:
                    formikProps.values[toCamelCase(key)]?.approver_name,
                })
              }
            >
              Reject
            </LoadingButton>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{
              color: formikProps.values[toCamelCase(key)]?.comments
                ? "primary.main"
                : "info.main",
              fontWeight: formikProps.values[toCamelCase(key)]?.comments
                ? 600
                : 500,
            }}
          >
            {formikProps.values[toCamelCase(key)]?.comments ?? "None"}
          </Typography>
        </TableCell>
      </TableRow>
      <Modal
        Header={
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Warning color="warning" />
            <Typography
              fontWeight="bold"
            >{`${PHASE[showApprovalModal?.type]} ${id}`}</Typography>
          </Stack>
        }
        message={
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Stack spacing={0.5} direction="row" flexWrap="wrap">
                <Typography>{`You are about to ${showApprovalModal?.type} the `}</Typography>
                <Typography
                  fontWeight="bold"
                  color="error"
                >{`${id}, `}</Typography>
                <Typography>{`${supplier} selling to ${buyer}.`}</Typography>
                <Typography>An email will be sent out to all the members of the Credit Committee, following this action.</Typography>
                <Typography>Do you want to proceed?</Typography>
              </Stack>
              <LabelViewOnly label="Approver" />
              <Typography>{`${formikProps.values[key]?.approver_name}`}</Typography>
              <VariableInput
                label="Approval Remarks"
                name={`${key}.comments`}
                placeholder="Enter Remarks (if any)"
                value={formikProps.values[key]?.comments}
                multiline
                fullWidth
                style={{ width: "100%" }}
                {...formikProps}
              />
            </Stack>
          </Grid>
        }
        sx={modalSx}
        width={isMobile ? "90%" : "40%"}
        height="auto"
        modalContentSx={{ height: "auto" }}
        open={showApprovalModal?.visible}
        modalOverlaySx={modalOverlaySx}
        modalFooterSx={{
          maxWidth: "100%",
          justifyContent: "space-between",
        }}
        onClose={() =>
          setShowApprovalModal({ visible: false, type: "", current_user: "" })
        }
        closeButton
        primary={{
          variant: "contained",
          fullWidth: true,
          children: "Confirm",
          loading: isLoadingApprovalModification,
          onClick: () =>
            id &&
            modifyApproval({
              map_name: id,
              role: URL_MAPPER[key],
              data: {
                approver: formikProps.values[key].contact_id,
                approval_status:
                  showApprovalModal?.type === "approve"
                    ? "Approved"
                    : "Rejected",
                comments: formikProps.values[key].comments ?? "",
              },
            })
              .unwrap()
              .then(() => {
                setShowApprovalModal({
                  visible: false,
                  type: "",
                  current_user: "",
                });
                toast.success("Map Approved");
              })
              .catch(() => {
                setShowApprovalModal({
                  visible: false,
                  type: "",
                  current_user: "",
                });
                toast.error("Error Approving Map");
              }),
        }}
        secondary={{
          variant: "outlined",
          fullWidth: true,
          color: "error",
          children: `Cancel`,
          onClick: () =>
            setShowApprovalModal({
              visible: false,
              type: "",
              current_user: "",
            }),
        }}
      />
    </>
  );
};

export default HeadApproversField;
