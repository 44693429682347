import {
  Button,
  Divider,
  Grid,
  Paper,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import Search from "components/Common/Search";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SearchCompany } from "types";

const CompanySelector = ({
  onClick,
  data,
  country,
  registrationNumber,
  companyName,
  retry,
  isOwnCompany = true,
  sx,
  onCancel,
}: {
  onClick: (item: SearchCompany) => void;
  data: SearchCompany[];
  country: string;
  registrationNumber: string;
  companyName: string;
  retry: () => void;
  isOwnCompany?: boolean;
  sx?: SxProps;
  onCancel: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  useEffect(() => {
    document.getElementById("idenfy-results")?.scrollIntoView(true);
  }, [document.getElementById("idenfy-results")]);
  const { t } = useTranslation();
  const [filteredCompanies, setFilteredCompanies] =
    useState<SearchCompany[]>(data);
  if (!data.length)
    return (
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="1em !important">
            {`${t("company-not-found", {
              type: isOwnCompany ? "" : `${t("buyer")}`,
            })}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="1em !important">
            {`${t("double-check-company-name")}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="1em !important">
            {`${t("cannot-provide-financing", {
              whom: isOwnCompany ? "" : "for the deals with this buyer ",
            })}`}
            {isOwnCompany ? "." : `${t("or-try-to-add-another-buyer")}.`}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} margin="auto">
          <Button variant="contained" fullWidth onClick={retry}>
            {`${t("return-to-your-search-criteria")}`}
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <Grid item xs={12} sx={sx}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            fontWeight="bold"
            style={{ fontSize: isMobile ? "1em" : "1.5em" }}
            variant="h3"
          >
            {t("select-your-company")}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={8} margin="auto">
          <Typography
            textAlign="center"
            sx={{ fontSize: isMobile ? "1em" : "1.2em" }}
          >
            {t("companies-associated-to-your-criteria", {
              number: data.length,
              type: isOwnCompany ? "" : `${t("buyer")}`,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        maxWidth={isMobile ? "100%" : "50vw"}
        spacing={2}
        padding={isMobile ? 1 : 2}
        margin="auto"
      >
        <Grid item xs={12}>
          <Grid container alignItems="end" justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: isMobile ? "1em" : "1.5em" }}>
                    {t("your-data")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bolder",
                      fontSize: isMobile ? "1em" : "1.2em",
                    }}
                  >
                    {`${companyName} - ${registrationNumber}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bolder",
                  fontSize: isMobile ? "1em" : "1.2em",
                }}
              >
                {country}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} id="idenfy-divider">
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} id="searchbar">
          <Search
            onChange={(searchString) => {
              const filtration = data?.filter((company) =>
                company?.name
                  ?.toLowerCase()
                  .includes(searchString?.toLowerCase())
              );
              setFilteredCompanies(filtration);
            }}
          />
        </Grid>
        <Grid
          item
          id="idenfy-results"
          xs={12}
          maxHeight="40vh"
          sx={{ overflowY: "scroll" }}
          paddingRight="1ch"
        >
          {filteredCompanies &&
            filteredCompanies?.map((item) => (
              <Paper
                key={item.name}
                elevation={2}
                sx={{
                  padding: isMobile ? "1ch" : "2ch",
                  cursor: "pointer",
                  margin: "1.5ch 0",
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.default,
                  },
                }}
                onClick={() => onClick(item)}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={isMobile ? 2 : 0}
                >
                  <Grid item lg={6} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.registration_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.city}, {country}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.address_line_1}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.address_line_2}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                          {item.postal_code}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={2} margin="auto">
        <Button color="error" variant="contained" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
export default CompanySelector;
