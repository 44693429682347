import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { SalesOpportunity } from "types";

interface CreditCommiteeStatuses {
  opportunity_id: string;
  opportunity_link: string;
  opportunity_name: string;
  buyer_call_date: string;
  buyer_credit_report: string;
  buyer_financials: boolean;
  buyer_risk_call: { stage: string; status: boolean };
  eta: string;
  onboarding_documents: { stage: string; status: boolean };
  supplier_credit_report: string;
  supplier_risk_call: boolean;
  supplier_risk_call_date: string;
  termsheet_signed_date: string;
  welcome_call_date: string;
  welcome_call_status: string;
  screening: { stage: string; status: boolean };
  buyer: string;
  supplier: string;
  insurer: string;
  credit_limit_amount: number;
  credit_report_requested_date: string;
  sales_owner: string;
  product: string;
  invoice_currency: string;
  insurance_currency: string;
  funding_comments: string;
  sales_comments: string;
}
const supplierCreditReportGreenStatus: string[] = [
  "Credit Report Received",
  "Onboarded",
  "Submitted to Amicorp",
];
const creditReportOrangeStatus: string[] = [
  "waiting documents",
  "dropped",
  "underlying structure",
  "Credit Report Ordered",
];
const buyerCreditReportGreenStatus: string[] = [
  "Credit Report Received",
  "Credit Report Requested",
  "Under Review",
  "Onboarded",
];

const getProgress = (item: CreditCommiteeStatuses): number => {
  //key indicators

  const termsheetSigned =
    item.termsheet_signed_date !== "" ||
    item.termsheet_signed_date !== undefined
      ? 20
      : 0;
  // eslint-disable-next-line no-extra-boolean-cast
  const welcomeCallDone = Boolean(item.welcome_call_status) ? 15 : 0;
  let credit = 0;
  if (
    supplierCreditReportGreenStatus?.includes(item?.supplier_credit_report) &&
    buyerCreditReportGreenStatus?.includes(item?.buyer_credit_report)
  ) {
    credit = 15;
  } else if (
    supplierCreditReportGreenStatus?.includes(item?.supplier_credit_report) ||
    buyerCreditReportGreenStatus?.includes(item?.buyer_credit_report)
  ) {
    credit = 10;
  } else if (
    creditReportOrangeStatus?.includes(item?.supplier_credit_report) ||
    creditReportOrangeStatus?.includes(item?.buyer_credit_report)
  ) {
    credit = 7.5;
  }
  const creditReportCollected = credit;
  const onboardingDocumentsCollected = item?.onboarding_documents.status
    ? 15
    : 0;
  // eslint-disable-next-line no-extra-boolean-cast
  const screeningDone = Boolean(item?.screening?.status) ? 15 : 0;
  const riskCallDone =
    item?.buyer_risk_call?.status && item.supplier_risk_call ? 20 : 0;

  return (
    termsheetSigned +
    welcomeCallDone +
    creditReportCollected +
    onboardingDocumentsCollected +
    screeningDone +
    riskCallDone
  );
};

const transformCCResponse = (
  data: CreditCommiteeStatuses[]
): SalesOpportunity[] => {
  return data.map((item) => ({
    salesOpportunityId: {
      name: item?.opportunity_name,
      link: item?.opportunity_link,
    },
    etaMonth: item?.eta,
    supplier: item?.supplier,
    buyer: item?.buyer,
    insurer: item?.insurer,
    creditLimitProvidedByInsurer: item?.credit_limit_amount,
    termsheetSignedDate: item?.termsheet_signed_date,
    welcomeCallDate: item?.welcome_call_date,
    welcomeCallStatus: item?.welcome_call_status,
    creditReportRequestedDate: item?.credit_report_requested_date,
    supplierCreditReport: item?.supplier_credit_report,
    buyerCreditReport: item?.buyer_credit_report,
    onboardingDocuments: item?.onboarding_documents,
    screening: item?.screening,
    supplierRiskCall: item?.supplier_risk_call,
    supplierRiskCallDate: item?.supplier_risk_call_date,
    buyerRiskCall: item?.buyer_risk_call,
    buyerRiskCallDate: item?.buyer_call_date ?? "",
    buyerFinancials: item?.buyer_financials,
    salesOwner: item?.sales_owner,
    progress: getProgress(item),
    product: item?.product,
    invoiceCurrency: item?.invoice_currency,
    insuranceCurrency: item?.insurance_currency,
    fundingComments: item?.funding_comments,
    salesComments: item?.sales_comments,
  }));
};
export const creditCommittee = createApi({
  reducerPath: "creditCommittee",
  tagTypes: ["STATUS"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/cc`,
  }),
  endpoints: (builder) => ({
    getCCOnboardingStatuses: builder.query<SalesOpportunity[], null>({
      query: () => ({ url: "/credit-committee-status", method: "get" }),
      transformResponse: (response) => transformCCResponse(response?.data),
      providesTags: ["STATUS"],
    }),
  }),
});

export const { useGetCCOnboardingStatusesQuery } = creditCommittee;
