import { Divider, Grid, Typography, useTheme } from "@mui/material";
import Global from "assets/global.svg?react";
import GreenTick from "assets/green-tick.svg?react";
import Logo from "assets/logo.svg?react";
import NonRecourse from "assets/nonRecourse.svg?react";
import Turnaround from "assets/turnaround.svg?react";
import { HOME } from "codes";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import styles from "./infostyles.module.scss";

const InfoPanel = () => {
  const { push } = history;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={{ xs: 0, lg: 2 }}
      sx={{
        margin: "auto",
      }}
      className={styles.infopanel}
      minHeight="90vh"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            maxWidth: "100%",
            padding: "0 0 0 1ch",
          }}
        >
          {!isMobile && (
            <Grid item xs={12}>
              <Logo onClick={() => push(HOME)} width="16vw" height="auto" />
            </Grid>
          )}
          <Grid item xs={12} margin={{ xs: "1ch 0 0 0", lg: "6ch 0 0 0" }}>
            <Grid container height="auto">
              <Grid
                item
                xs={11}
                lg={10}
                margin={{ xs: "auto", lg: "0 0 auto auto" }}
              >
                <Grid container spacing={{ xs: 1, lg: 4 }}>
                  <Grid item xs={12}>
                    <Grid container spacing={{ xs: 0, lg: 1 }}>
                      <Grid item xs={12}>
                        <Typography
                          fontWeight="bolder"
                          margin="auto"
                          className={styles.headingText}
                        >
                          Step up your business game.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          fontWeight="bold"
                          fontSize="1.1em"
                          className={styles.subheading}
                        >
                          Apply for the invoice financing program.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} lg={1.5}>
                        <NonRecourse />
                      </Grid>
                      <Grid item xs={10} lg={10.5}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              color={theme.palette.error.main}
                              fontWeight="bolder"
                              className={styles.heading}
                            >
                              {t("non-recourse-financing")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={8} flexWrap="wrap">
                            <Typography className={styles.textBody}>
                              {t("no-chase")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} lg={1.5}>
                        <Turnaround />
                      </Grid>
                      <Grid item xs={10} lg={10.5}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              color={theme.palette.error.main}
                              fontWeight="bolder"
                              className={styles.heading}
                            >
                              {t("quick-turnaround")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={8} flexWrap="wrap">
                            <Typography className={styles.textBody}>
                              {t("your-export-receivables-paid")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} lg={1.5}>
                        <Global />
                      </Grid>
                      <Grid item xs={10} lg={10.5}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              color={theme.palette.error.main}
                              fontWeight="bolder"
                              className={styles.heading}
                            >
                              {t("50-countries")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={8} flexWrap="wrap">
                            <Typography className={styles.textBody}>
                              {t("20-industries")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} lg={1.5}></Grid>
                      {/* Bottom green ticks */}
                      <Grid item xs={10} lg={10.5}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <GreenTick fill="#008001" />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{ fontWeight: 500 }}
                                      className={styles.textBody}
                                    >
                                      {t("no-collateral-needed")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <GreenTick />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{ fontWeight: 500 }}
                                      className={styles.textBody}
                                    >
                                      {t("off-balance-sheet")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <GreenTick />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{ fontWeight: 500 }}
                                      className={styles.textBody}
                                    >
                                      {t("financing-from")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default InfoPanel;
