import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Badge, IconButton, type AvatarProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface ImageUploadProps extends AvatarProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  viewOnly?: boolean;
}

export default function ImageUpload(props: ImageUploadProps) {
  const { onChange = () => {}, viewOnly = false } = props;
  return (
    <IconButton
      component="label"
      role={undefined}
      tabIndex={-1}
      disableFocusRipple={viewOnly}
      disableRipple={viewOnly}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          viewOnly ? null : (
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: "primary.main",
                outline: "2px solid white",
                marginLeft: "1rem",
                marginTop: "1rem",
              }}
            >
              <EditIcon />
            </Avatar>
          )
        }
      >
        <Avatar {...props} />
      </Badge>
      {!viewOnly && (
        <VisuallyHiddenInput type="file" onChange={onChange} multiple />
      )}
    </IconButton>
  );
}
