import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useGetBankAccountsQuery } from "api/api-banks";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AddBankAccount from "components/Onboard/Bank/AddBankAccount";
import useWidth from "helpers/useWidth";
import { useEffect, useRef, useState } from "react";

const BankAccountsList = ({ companyId }: { companyId: string }) => {
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const {
    data: bankAccounts,
    refetch: refetchBanks,
    isFetching,
  } = useGetBankAccountsQuery(companyId, {
    skip: !companyId,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const [additionState, setAdditionState] = useState(false);

  useEffect(() => {
    if (bankAccounts && !Boolean(bankAccounts?.length)) setAdditionState(true);
    if (additionState) refetchBanks();
  }, [bankAccounts, additionState]);

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref, 1000) : 1000;
  const headerLength = Object.keys(bankAccounts?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.5 : 200;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "70vw",
        [theme.breakpoints.down("sm")]: {
          minWidth: "90vw",
        },
      }}
    >
      <Grid item xs={12} mt={2} px={3}>
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && bankAccounts && Boolean(bankAccounts?.length) ? (
          <DataTablev2
            key={bankAccounts?.length}
            data={bankAccounts}
            customColumns={{
              id: { id: "Bank ID", type: "text" },
              bank_name: { bank_name: "Bank Name", type: "text" },
              bank_account_number: {
                bank_account_number: "Bank Account Number",
                type: "text",
              },
              beneficiary_name: {
                beneficiary_name: "Beneficiary Name",
                type: "text",
              },
              currency: {
                currency: "Currency",
                type: "text",
                minWidth: "100px",
              },
              country: {
                country: "Country",
                type: "text",
              },
              swift_code: {
                swift_code: "Swift Code",
                type: "text",
                renderCell: ({ value }: { value: string }) => {
                  return (
                    <Typography
                      color={value ? "primary" : "gray"}
                      fontStyle={value ? "normal" : "italic"}
                    >
                      {value ?? "None"}
                    </Typography>
                  );
                },
              },
              iban: {
                iban: "IBAN",
                type: "text",
                renderCell: ({ value }: { value: string }) => {
                  return (
                    <Typography
                      color={value ? "primary" : "gray"}
                      fontStyle={value ? "normal" : "italic"}
                    >
                      {value ?? "None"}
                    </Typography>
                  );
                },
              },
            }}
            toBeHidden={["sf_id", "bank_address"]}
            rowsPerPage={rowsPerPage}
            defaultColumnWidth={defaultColumnWidth}
          />
        ) : (
          <AddBankAccount
            setAdditionState={setAdditionState}
            account_id={companyId}
          />
        )}
      </Grid>
    </Box>
  );
};
export default BankAccountsList;
