import { TableCell, useTheme } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import React from "react";

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}

interface FinancialGrowthIndicatorProps {
  values: MapRiskParamValues;
  fieldName: string;
  needGrowthRate: boolean;
}

const FinancialGrowthIndicator: React.FC<FinancialGrowthIndicatorProps> = ({
  values,
  fieldName,
  needGrowthRate,
}) => {
  const numbers = Object.values(
    Object.entries(values)
      ?.map(([year, value]) => {
        const financial = value[fieldName] as unknown as
          | string
          | number
          | undefined;

        const STRIPPED_PERIOD_VALUE = moment(
          year?.trim()?.split("-")[0],
          "MM/yyyy"
        )?.valueOf();

        return financial === undefined ||
          (typeof financial === "string" &&
            financial === "Can not calculate because of no value") ||
          (typeof financial === "string" &&
            financial.startsWith("Need input for this field"))
          ? { [STRIPPED_PERIOD_VALUE]: 0 }
          : { [STRIPPED_PERIOD_VALUE]: Number(valueCleaner(`${financial}`)) };
      })
      .reduce(
        (acc, curr) => {
          const year = Number(Object.keys(curr)[0]);
          const value = Object.values(curr)?.[0] ?? 0;
          acc[year] = value;
          return acc;
        },
        {} as Record<number, number>
      )
  );

  const calculateGrowth = (values: number[]): number => {
    if (values.length < 2) return 0;
    const latestValue = values[0];
    const previousValue = values[1];
    const difference = latestValue - previousValue;
    return (difference / (previousValue > 0 ? previousValue : 1)) * 100;
  };

  const theme = useTheme();

  const getGrowthIndication = (growth: number): any[] => {
    switch (true) {
      case growth > 0:
        return [`▲`, theme.palette.success.main, growth];
      case growth === 0:
        return [``, theme.palette.info.main, growth];
      case growth < 0:
        return [`▼`, theme.palette.error.main, growth];
      default:
        return [``, theme.palette.info.main, growth];
    }
  };
  const [symbol, color, growth] = getGrowthIndication(calculateGrowth(numbers));

  return (
    <TableCell sx={{ textAlign: "left" }}>
      {needGrowthRate && (
        <LabelViewOnly
          label={`${symbol} ${growth?.toFixed(2) ?? 0}%`}
          sx={{ color, minWidth: 80 }}
        />
      )}
    </TableCell>
  );
};

export default FinancialGrowthIndicator;
