import LoadingButton from "@mui/lab/LoadingButton";
import {
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useAddRemoveGroupPermissionsMutation } from "api/api-it";
import { useFormikContext } from "formik";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import toProperCase from "helpers/propercase";
import * as React from "react";
import { toast } from "react-toastify";

interface AdminRole {
  [permission_name: string]: boolean;
}

interface AdminRoles extends Record<string, AdminRole> {
  ACCOUNT_ADMIN: AdminRole;
  MARKETING_ADMIN: AdminRole;
  OPERATION_ADMIN: AdminRole;
  ORIGINATION_ADMIN: AdminRole;
  RISK_ADMIN: AdminRole;
  ORIGINATION_MEMBER: AdminRole;
  MARKETING_MEMBER: AdminRole;
  IT_ADMIN: AdminRole;
  IT_MEMBER: AdminRole;
  RISK_MEMBER: AdminRole;
  COMPLIANCE_ADMIN: AdminRole;
  COMPLIANCE_MEMBER: AdminRole;
  FUNDING_ADMIN: AdminRole;
  FUNDING_MEMBER: AdminRole;
  FINANCE_ADMIN: AdminRole;
  FINANCE_MEMBER: AdminRole;
  OPERATION_MEMBER: AdminRole;
  ACCOUNT_MEMBER: AdminRole;
  LEGAL_ADMIN: AdminRole;
  LEGAL_MEMBER: AdminRole;
  GROUP_TEST: AdminRole;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  permissions: AdminRole;
}

export default function PermissionChangerDrawer({
  open,
  setOpen,
  title,
  permissions,
}: Props) {
  const [addRemoveGroupPermissions] = useAddRemoveGroupPermissionsMutation();
  const [permissionSet, setPermissionSet] = React.useState(permissions);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
      if (!open) {
        setPermissionSet(permissions);
      }
    };

  const formikProps = useFormikContext<{ allGroups: AdminRoles }>();
  const { dirty, values, setFieldValue } = formikProps;

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Container sx={{ width: "100%", height: "100%", pt: 2 }}>
          <Stack justifyContent="space-between" sx={{ height: "100%" }}>
            <Stack justifyContent="space-between" spacing={2}>
              <Typography variant="h3">
                {`${capitalizeFirstLetter(toProperCase(title))}`}
              </Typography>
              <Divider
                sx={{
                  height: 2,
                }}
              />
            </Stack>
            <Container
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {Object.entries(permissions).map(
                ([permissionName, permissionValue]) => {
                  return (
                    <Grid item xs={12} key={permissionName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`allGroups.${title}.${permissionName}`}
                            value={permissionValue}
                            checked={permissionValue}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setFieldValue(`allGroups`, {
                                ...values.allGroups,
                                [title]: {
                                  ...values.allGroups[title],
                                  [permissionName]: checked,
                                },
                              });
                              setPermissionSet({
                                ...permissionSet,
                                [permissionName]: checked,
                              });
                            }}
                          />
                        }
                        label={permissionName}
                      />
                    </Grid>
                  );
                }
              )}
            </Container>
            <Stack
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Divider
                sx={{
                  height: 2,
                }}
              />
              <Stack justifyContent="flex-end" direction="row" spacing={2}>
                <Typography>
                  {`${Object.entries(permissionSet).filter(([_, value]) => value).length} / ${
                    Object.entries(permissionSet).length
                  } selected`}
                </Typography>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  disabled={!dirty}
                  onClick={() =>
                    addRemoveGroupPermissions({
                      group_name: title,
                      data: Object.entries(values.allGroups[title]).map(
                        ([permission_name, is_active]) => ({
                          permission_name,
                          is_active,
                        })
                      ),
                    })
                      .unwrap()
                      .then(() =>
                        toast(`Group permissions updated: ${title}`, {
                          type: "success",
                        })
                      )
                      .catch((err) =>
                        toast(`An error occured: ${err?.message}`, {
                          type: "error",
                        })
                      )
                  }
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Drawer>
    </div>
  );
}
