import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CURRENCIES } from "codes";
import React from "react";

interface FXRate {
  currency_from: string;
  currency_to: string;
  rate: number;
  date: string;
}

interface FxTableProps {
  fxRates: FXRate[];
}

const FxTable: React.FC<FxTableProps> = ({ fxRates }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fxRates.map((fxRate) => (
            <TableRow key={fxRate.currency_from}>
              <TableCell>
                {CURRENCIES[fxRate.currency_from].flag} {fxRate.currency_from}
              </TableCell>
              <TableCell>
                {CURRENCIES[fxRate.currency_to].flag} {fxRate.currency_to}
              </TableCell>
              <TableCell>{fxRate.rate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FxTable;
