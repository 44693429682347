import {
  Avatar,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetInvoiceStatsQuery } from "api/api-operations";
import SFIcon from "assets/salesforce.svg?react";
import Battery from "components/Battery";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { currencyFormatter } from "helpers/currencyFormatter";
import moment from "moment";
import type { InvoiceStatus as IInvoiceStatus } from "types";

const InvoiceStatusDetail = (data: IInvoiceStatus) => {
  const theme = useTheme();
  const link = `${import.meta.env.VITE_SF_BASE_URL}${data.id}`;
  const { data: invoiceStats, isFetching: isFetchingInvoiceStats } =
    useGetInvoiceStatsQuery(data.id);

  return (
    <Stack spacing={{ lg: 1, xs: 0 }}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container justifyContent="space-between" alignItems="start">
        <Grid item lg={8.5} xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ mb: "2vh !important" }}>
                  <Typography
                    style={{
                      background:
                        data.color === ""
                          ? theme.palette.error.main
                          : data.color,
                      padding: "1ch 0.5ch",
                      borderRadius: "10px",
                      color: "white",
                      fontWeight: "600",
                      fontSize: "1em",
                      textAlign: "center",
                      width: "100%",
                    }}
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                      },
                    }}
                  >
                    {data.status}
                  </Typography>
                </Grid>
                {data.id && (
                  <Link
                    underline="always"
                    onClick={() => window.open(`${link}`, "_blank")}
                    sx={{ cursor: "pointer", wordBreak: "break-word" }}
                  >
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <SFIcon width={50} height="auto" />
                      </Grid>
                      <Grid item> View on Salesforce</Grid>
                    </Grid>
                  </Link>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography fontWeight="bold">Buyer</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Avatar
                        variant="square"
                        sx={{
                          bgcolor: `${data.color}`,
                          width: "1.3em",
                          height: "1.3em",
                          fontSize: "1.3em",
                          mb: { lg: "3px", md: "1px" },
                        }}
                      >
                        {" "}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography
                        fontSize="1.3em"
                        sx={{
                          textDecoration: "underline !important",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            `${import.meta.env.VITE_SF_BASE_URL}${data.buyer_id}`,
                            "_blank"
                          )
                        }
                      >
                        {data.buyer_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Supplier</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize="1.3em"
                    sx={{
                      textDecoration: "underline !important",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `${import.meta.env.VITE_SF_BASE_URL}${data.supplier_id}`,
                        "_blank"
                      )
                    }
                  >
                    {`${data.supplier_name}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Real Face Value</Typography>
                <Typography
                  fontWeight="bold"
                  fontSize="1.3em"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.6em !important",
                    },
                  }}
                >
                  {currencyFormatter({
                    amount: data.real_face_value,
                    currency: data.ccy,
                  })}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Requested Amount</Typography>
                <Typography
                  fontSize="1.3em"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.6em !important",
                    },
                  }}
                >
                  {currencyFormatter({
                    amount: data.requested_amount,
                    currency: data.ccy,
                  })}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">
                  Requested Amount (USD Equiv.)
                </Typography>
                <Typography
                  fontSize="1.3em"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.6em !important",
                    },
                  }}
                >
                  {currencyFormatter({
                    amount: data?.requested_amount_usd,
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Insurer</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="1.3em">{`${
                    data.insurer ?? "Insurer Not Found"
                  }`}</Typography>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Product</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="1.3em">{`${
                    data.product ?? "Product Not Found"
                  }`}</Typography>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Typography fontWeight="bold">
                    Expected Repayment Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="1.3em">
                    {`${
                      data.expected_payment_date
                        ? moment(data.expected_payment_date).format("DD/MM/YY")
                        : "Not Found"
                    }`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item margin="auto">
          <Stack
            sx={{
              bgcolor: theme.palette.background.default,
              borderRadius: 1,
              px: 0.5,
              py: 1,
              boxShadow: theme.shadows[1],
            }}
          >
            {isFetchingInvoiceStats && !invoiceStats && (
              <SkeletonLoad bars={3} />
            )}
            {!isFetchingInvoiceStats && invoiceStats && (
              <Stack spacing={1}>
                <Battery
                  value={
                    invoiceStats?.funding_power?.total_limit -
                    invoiceStats?.funding_power?.total_limit_used
                  }
                  total={invoiceStats?.funding_power?.total_limit}
                  title="Funding Power"
                />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <Typography fontWeight="bold">Funding Comments</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize="1.2em"
            textAlign={data.funding_comments === null ? "center" : "left"}
            sx={{
              border: "1px solid #d6d4d4",
              borderRadius: "6px",
              padding: "2px",
              height: "15vh",
              overflowY: "scroll",
            }}
          >
            {`${
              data.funding_comments !== null
                ? data.funding_comments
                : "-- No Comments --"
            }`}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default InvoiceStatusDetail;
