import { useTheme } from "@mui/material";
import { useGetAllGroupMenusQuery } from "api/api-it";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts, { type PointMarkerOptionsObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsTreeGraph from "highcharts/modules/treegraph";
import HighchartsTreeMap from "highcharts/modules/treemap";
import React, { Fragment, useRef } from "react";

interface Props {
  // Define your component's props here
}

const MenuTreeGraph: React.FC<Props> = (props) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  HighchartsTreeMap(Highcharts);
  HighChartsTreeGraph(Highcharts);
  const { data: group_menus, isLoading } = useGetAllGroupMenusQuery(null);
  const theme = useTheme();
  const { primary, secondary, tertiary } = theme.palette;

  const options: Highcharts.Options = {
    title: {
      text: "Menu Tree Graph",
    },
    chart: {
      height: window.innerHeight * 5,
      width: window.innerWidth * 0.5,
    },
    series: [
      {
        type: "treegraph",
        clip: false,
        data: group_menus,
        tooltip: {
          pointFormat: "{point.name}",
        },
        marker: {
          symbol: "callout",
          width: 150,
          lineColor: primary.main,
        } as PointMarkerOptionsObject,
        dataLabels: {
          style: {
            whiteSpace: "nowrap",
            borderRadius: 20,
          },
        },
        label: {
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#ffffff",
          },
        },
        levels: [
          {
            level: 1,
            layoutAlgorithm: "squarified",
            color: primary.main,
            borderWidth: 2,
            dataLabels: {
              align: "left",
              verticalAlign: "middle",
            },
          },
          {
            level: 2,
            layoutAlgorithm: "squarified",
            color: secondary.main,
            borderColor: "black",
            borderWidth: 2,
            dataLabels: {
              align: "left",
              verticalAlign: "middle",
            },
          },
          {
            level: 3,
            layoutAlgorithm: "squarified",
            color: tertiary.main,
            borderColor: "black",
            borderWidth: 2,
            borderDashStyle: "Dash",
            dataLabels: {
              align: "right",
              verticalAlign: "middle",
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            },
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <Fragment>
      {isLoading && <SkeletonLoad bars={10} />}

      {!isLoading && group_menus && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          containerProps={{
            style: { height: "100%" },
          }}
          allowChartUpdate
        />
      )}
    </Fragment>
  );
};

export default MenuTreeGraph;
