import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { Contact } from "types";

interface InvitedContact extends Record<string, any> {
  contact_id: string;
  contact_name: string;
  invite_token: string;
  status: string;
  invited_by: string;
  invited_at: string;
  invite_accepted_at: string;
  contact_type: "internal" | "external";
}

interface RetrievedInvitedContact extends InvitedContact {
  contact: Contact;
}

export const auth = createApi({
  reducerPath: "auth",
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}`,
  }),
  endpoints: (builder) => ({
    captcha: builder.mutation<
      any,
      {
        key: string;
        secret: string;
        token: string;
      }
    >({
      query(payload) {
        return {
          url: `/captcha`,
          method: "post",
          data: payload,
        };
      },
      transformResponse: (response) => response?.data,
    }),
    register: builder.mutation<
      any,
      {
        first_name: string;
        last_name: string;
        title: string;
        email: string;
        password: string;
        mobile: string;
        accepted_terms: boolean;
        opted_marketing_updates: boolean;
      }
    >({
      query(payload) {
        return {
          url: `/register`,
          method: "post",
          data: payload,
        };
      },
    }),
    registerInvitedUser: builder.mutation<
      any,
      {
        first_name: string;
        last_name: string;
        title: string;
        email: string;
        password: string;
        mobile: string;
        accepted_terms: boolean;
        opted_marketing_updates: boolean;
      }
    >({
      query(payload) {
        return {
          url: `/register?invited=true`,
          method: "post",
          data: payload,
        };
      },
    }),
    login: builder.mutation<
      {
        otp_required: boolean;
        verification_required?: boolean;
        access_token?: string;
        type?: string;
        ttl?: number;
        challenge_name?: string;
        session?: string;
      },
      {
        email: string;
        password: string;
      }
    >({
      query(payload) {
        return {
          url: `/login`,
          method: "post",
          data: payload,
        };
      },
      transformResponse: (response) => response?.data,
    }),
    confirmAccount: builder.mutation<
      any,
      {
        email: string;
        code: string;
      }
    >({
      query(payload) {
        return {
          url: `/confirm-account`,
          method: "post",
          data: payload,
        };
      },
    }),
    resetPassword: builder.mutation<
      any,
      {
        email: string;
      }
    >({
      query(payload) {
        return {
          url: `/reset-password`,
          method: "post",
          data: payload,
        };
      },
    }),
    confirmResetPassword: builder.mutation<
      any,
      {
        email: string;
        code: string;
        password: string;
      }
    >({
      query(payload) {
        return {
          url: `/confirm-reset-password`,
          method: "post",
          data: payload,
        };
      },
    }),
    resendConfirmationOtp: builder.mutation<
      any,
      {
        email: string;
      }
    >({
      query(payload) {
        return {
          url: `/resend-confirmation-otp`,
          method: "post",
          data: payload,
        };
      },
    }),
    logout: builder.mutation<any, void>({
      query() {
        return {
          url: `/logout`,
          method: "post",
        };
      },
    }),
    verifyTotp: builder.mutation<
      any,
      {
        email: string;
        session: string;
        code: string;
      }
    >({
      query(payload) {
        return {
          url: `/verify-totp`,
          method: "post",
          data: payload,
        };
      },
    }),
    registerTotp: builder.query<Record<string, string>, null>({
      query() {
        return {
          url: `/register-totp`,
          method: "get",
        };
      },
    }),
    validateTotpRegistration: builder.mutation<any, string>({
      query(value) {
        return {
          url: `/validate-totp-registration`,
          method: "post",
          data: { code: value },
        };
      },
    }),
    setTotpPreference: builder.mutation<any, { enabled: boolean }>({
      query(payload) {
        return {
          url: `/set-totp-preference`,
          method: "post",
          data: payload,
        };
      },
    }),
    resendOtp: builder.mutation<any, { email: string }>({
      query(payload) {
        return {
          url: `/resend-confirmation-otp`,
          method: "post",
          data: payload,
        };
      },
    }),
    getInvitedContactByToken: builder.query<RetrievedInvitedContact, string>({
      query: (token) => {
        return {
          url: `/invited-contacts/contact?token=${token}`,
          method: "get",
        };
      },
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useCaptchaMutation,
  useRegisterMutation,
  useConfirmAccountMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useResendConfirmationOtpMutation,
  useLogoutMutation,
  useVerifyTotpMutation,
  useRegisterTotpQuery,
  useValidateTotpRegistrationMutation,
  useSetTotpPreferenceMutation,
  useRegisterInvitedUserMutation,
  useResendOtpMutation,
  useGetInvitedContactByTokenQuery,
} = auth;
