import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { type IShareholder } from "components/Map/Sections/Compliance/CreditReport/types";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type {
  CreditLimit,
  MapSummary,
  RiskCategories,
  TradePresentation,
} from "types";
interface MapRelationship {
  name: string;
  country: string;
  id: string;
}
type ScoreType = "Low" | "Medium Low" | "Medium" | "Medium High" | "High";
interface Map {
  map_id: string;
  relationship_id: string;
  buyer: MapRelationship;
  seller: MapRelationship;
  next_monitoring_date: Date;
  score: ScoreType;
}
interface MapTable {
  map_id: string;
  buyer: string;
  seller: string;
  relationship_id: string;
  score: ScoreType;
  next_monitoring_date: Date;
}

interface AccountComment {
  created_at: Date;
  financial_comments: string;
  background_comments: string;
  background_extra_info: string;
}

interface ApproveMapModule {
  approver: string;
  approval_status: string;
  comments: string;
}

interface Approval {
  approver_name: string;
  approval_date: Date | undefined;
  approval_status: string;
  comments: string;
}

interface ComplianceApproval extends Approval {
  contact_id: string;
}

interface ComplianceApprovals {
  onboarding_officer_decision: ComplianceApproval;
  compliance_officer_decision: ComplianceApproval;
  head_compliance_decision: ComplianceApproval;
  ceo_decision: ComplianceApproval;
  [key: string]: ComplianceApproval;
}

interface CreditCommitteeApprovals {
  ceo_ipl: ComplianceApproval;
  ceo_icpl: ComplianceApproval;
  funding: ComplianceApproval;
  [key: string]: ComplianceApproval;
}

interface IEvaluation {
  id: string;
  name: string;
  value: string;
  type: string;
}

interface InvestmentCardAccount {
  name: string;
  country: string;
  summary: string;
}

interface InvestmentCardAccountFeatures {
  revenue: string;
  net_profit_margin: string;
  operating_profit_margin: string;
  total_equity: string;
  debt_to_equity: string;
  current_ratio: string;
}

interface InvestmentCardAccountCall {
  name: string;
  ipl_participants: string[];
  interviewees: string[];
  product: string;
  date: Date;
  call_summary: string;
  relationship_summary: string;
  supplier_financial: string;
  track_record: string;
}
interface InvestmentCard {
  deal_summary: {
    summary: string;
    supplier: InvestmentCardAccount;
    buyer: InvestmentCardAccount;
    product: string;
    currency: string;
  };
  deal_features: {
    insurance: string;
    track_record: string;
    order_size: string;
    advanced_amount: string;
    payment_term: string;
  };
  finance_features: {
    supplier_features: InvestmentCardAccountFeatures;
    buyer_features: InvestmentCardAccountFeatures;
  };
  supplier_call: InvestmentCardAccountCall;
  buyer_call: InvestmentCardAccountCall;
}

const formatMapsForTable = (data: Map[]) =>
  data.map((item) => ({
    ...item,
    buyer: item.buyer.name,
    seller: item.seller.name,
  }));
export type MAP_TYPE = "background" | "financial" | "transactions";
export type MAP_USE_CASE = "background" | "financial" | "transactions";
export type MAP_PARTY = "buyer" | "supplier" | "relationship";

interface Versions {
  current_version: string;
  version_history: string[];
}
interface MapTemplate_V2 {
  item_template_name: string;
  version: string;
  template: {
    [field_name: string]: {
      id: string;
      name: string;
      type: string;
      log_id: string;
      format: string;
      data: string | number | string[];
      need_growth_rate?: boolean;
    };
  };
}
interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    format: string;
    data: string | number | string[];
    need_growth_rate?: boolean;
  };
}
interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}

interface IncomlendProduct {
  id: string;
  product_name: string;
  category_name: string;
}

interface MapItemTemplate {
  use_case: MAP_USE_CASE;
  version: string;
  party: MAP_PARTY;
  ipl_product: string;
  template_name: string;
}
interface MapTemplatesDisplay {
  created_at: Date;
  items: Record<string, MapItemTemplate>;
  version: string;
}

interface Logo {
  id: string;
  name: string;
  url: string;
  document_type: string;
  document_type_name: string;
  submitted_at: string;
  submitted_to_sf: boolean;
  year: boolean;
}

export const maps = createApi({
  reducerPath: "maps",
  tagTypes: [
    "TEMPLATES",
    "TABLE_VALUES",
    "TRANSACTION_TABLE_VALUES",
    "SUMMARY_TABLE_VALUES",
    "SCORE_CARD",
    "MAP_DOCUMENTS",
    "INSURERS",
    "ACCOUNT_COMMENTS",
    "RELATIONSHIP_COMMENTS",
    "SCORECARD_HEADER",
    "SCORE_CARD_PDF",
    "SHAREHOLDERS",
    "EVALUATION",
    "SCORE_CARD_PDF",
    "RISK_MAP_APPROVERS",
    "COMPLIANCE_MAP_APPROVERS",
    "LEGAL_MAP_APPROVALS",
    "CREDIT_COMMITEE_APPROVERS",
    "CURRENT_MAP_VERSION",
    "CURRENT_TEMPLATE_VERSION",
    "MAP_ITEM_VERSIONS_LIST",
    "CURRENT_TEMPLATE_VERSIONS",
    "CURRENT_TEMPLATE_ITEM_VERSIONS",
    "TRADE_PRESENTATION",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/maps`,
  }),
  endpoints: (builder) => ({
    getMaps: builder.query<MapTable[], null>({
      query: () => ({ url: "", method: "get" }),
      transformResponse: (response) => formatMapsForTable(response?.data),
    }),
    getMapVersion: builder.query<Versions, string>({
      //zhe
      query: (map_name) => ({
        url: `/${map_name}/versions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CURRENT_MAP_VERSION"],
    }),
    getTemplateVersions: builder.query<Versions, null>({
      //zhe
      query: () => ({
        url: `/template/versions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CURRENT_TEMPLATE_VERSIONS"],
    }),
    getTemplateVersion: builder.query<Versions, null>({
      //zhe
      query: () => ({
        url: `/template/versions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CURRENT_TEMPLATE_VERSION"],
    }),
    getCurrentMapItemTemplates: builder.query<MapTemplatesDisplay, string>({
      query: (version) => ({
        url: `/template/versions/${version}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CURRENT_TEMPLATE_ITEM_VERSIONS"],
    }),
    getMapItemTemplates: builder.query<MapTemplatesDisplay, string>({
      query: (version) => ({
        url: `/template/versions/${version}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTemplates: builder.query<
      Record<string, string>[],
      {
        party: MAP_PARTY;
        use_case: MAP_USE_CASE;
        ipl_product: string;
      }
    >({
      query: ({ party, use_case, ipl_product }) => ({
        url: `/template/${use_case}/${ipl_product}/${party}/versions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["MAP_ITEM_VERSIONS_LIST"],
    }),
    getTemplatesV2: builder.query<
      MapTemplate_V2,
      {
        party: MAP_PARTY;
        use_case: MAP_USE_CASE;
        ipl_product: string;
        map_version?: string;
      }
    >({
      //zhe
      query: ({ party, use_case, ipl_product, map_version }) => ({
        url: `/template/${use_case}/${ipl_product}/${party}${map_version ? `?map_version=${map_version}` : ""}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TEMPLATES"],
    }),
    getMapItemTemplateByVersion: builder.query<
      MapTemplate,
      {
        item_name: string;
        version: string;
      }
    >({
      query: ({ item_name, version }) => ({
        url: `/template/${item_name}/${version}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    editMapRiskParameters: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TABLE_VALUES"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
          dispatch(maps.util.invalidateTags(["EVALUATION"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting director:", error);
        }
      },
    }),
    getMapRiskParameters: builder.query<
      MapRiskParamValues,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        item_type: MAP_TYPE;
      }
    >({
      query: ({ map_name, account_type, item_type }) => ({
        url: `/${map_name}/${account_type}/${item_type}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TABLE_VALUES"],
    }),
    getMapRiskParametersV2: builder.query<
      //zhe
      MapRiskParamValues,
      {
        map_name: string;
        party: "supplier" | "buyer";
        use_case: MAP_USE_CASE;
      }
    >({
      query: ({ map_name, party, use_case }) => ({
        url: `/${map_name}/${party}/${use_case}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TABLE_VALUES"],
    }),
    getMapTransactionValues: builder.query<
      MapRiskParamValues,
      {
        map_name: string;
      }
    >({
      query: ({ map_name }) => ({
        url: `/${map_name}/relationship/transactions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TRANSACTION_TABLE_VALUES"],
    }),

    editMapTransactionDetails: builder.mutation<
      any,
      {
        map_name: string;
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}/relationship`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRANSACTION_TABLE_VALUES"],
    }),
    getCategories: builder.query<RiskCategories, string>({
      query: (map_name) => ({
        url: `/${map_name}/score-card`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCORE_CARD"],
    }),
    modifyMapField: builder.mutation<
      any,
      {
        map_name: string;
        data: {
          map_metadata_id: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}/score-card`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: ["SCORE_CARD", "SCORE_CARD_PDF"],
    }),
    getMapSummary: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUMMARY_TABLE_VALUES"],
    }),
    getInvestmentCard: builder.query<InvestmentCard, string>({
      query: (map_name) => ({
        url: `/${map_name}/investment-criteria-card`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getScorecardHeader: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCORECARD_HEADER"],
    }),
    editMapSummary: builder.mutation<
      any,
      { map_name: string; data: { [field_name: string]: any } }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}`,
          method: "patch",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SUMMARY_TABLE_VALUES", "SCORE_CARD_PDF"],
    }),
    editScorecardHeader: builder.mutation<
      any,
      { map_name: string; data: { [field_name: string]: any } }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCORECARD_HEADER", "SCORE_CARD_PDF"],
    }),
    getMapDocuments: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["MAP_DOCUMENTS"],
    }),
    getMapInsurers: builder.query<CreditLimit, string>({
      query: (map_name) => ({
        url: `/${map_name}/credit-limit`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INSURERS"],
    }),
    patchMapInsurer: builder.mutation<
      any,
      {
        credit_limit_id: string;
        data: { credit_committee_amount: number };
      }
    >({
      query: ({ credit_limit_id, data }) => {
        return {
          url: `/credit-limits/${credit_limit_id}`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: ["INSURERS"],
    }),
    getAccountComments: builder.query<
      AccountComment,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/summary`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["ACCOUNT_COMMENTS"],
    }),
    editAccountComments: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        data: {
          [field_name: string]: string;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => ({
        url: `/${map_name}/${account_type}/summary`,
        data,
        method: "put",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["ACCOUNT_COMMENTS"],
    }),
    exportMap: builder.query<any, string>({
      query: (map_name) => ({
        url: `/${map_name}/score-card/export`,
        method: "get",
      }),
      transformResponse: (response) => response,
      providesTags: ["SCORE_CARD_PDF"],
    }),
    getShareholdersHierarchy: builder.query<
      IShareholder,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/shareholders`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SHAREHOLDERS"],
    }),
    getEvaluationValues: builder.query<
      IEvaluation[],
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/evaluation`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["EVALUATION"],
    }),
    editEvaluation: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["EVALUATION"],
    }),
    editTemplate: builder.mutation<
      any,
      {
        data: string[];
      }
    >({
      query: ({ data }) => ({
        url: `/template/field-order`,
        data,
        method: "put",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TEMPLATES", "SCORE_CARD"],
    }),
    getRiskMapApprovals: builder.query<Approval, string>({
      query: (map_name) => ({
        url: `/${map_name}/approval/risk`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["RISK_MAP_APPROVERS"],
    }),
    approveRiskMap: builder.mutation<
      any,
      {
        map_name: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/risk`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["RISK_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),
    editApproverRiskMap: builder.mutation<
      any,
      {
        map_name: string;
        data: Partial<ApproveMapModule>;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/risk`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["RISK_MAP_APPROVERS"],
    }),
    getLegalMapApprovals: builder.query<Approval, string>({
      query: (map_name) => ({
        url: `/${map_name}/approval/legal`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["LEGAL_MAP_APPROVALS"],
    }),
    approveLegalMap: builder.mutation<
      any,
      {
        map_name: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/legal`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["LEGAL_MAP_APPROVALS", "SUMMARY_TABLE_VALUES"],
    }),
    getComplianceMapApprovals: builder.query<
      ComplianceApprovals,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COMPLIANCE_MAP_APPROVERS"],
    }),
    editApprover: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        role: string;
        data: Partial<ApproveMapModule>;
      }
    >({
      query: ({ map_name, account_type, role, data }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals/${role}`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["COMPLIANCE_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),
    approveComplianceMap: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        role: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, account_type, role, data }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals/${role}`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["COMPLIANCE_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),

    getCreditCommitteeMapApprovals: builder.query<
      CreditCommitteeApprovals,
      string
    >({
      query: (map_name) => ({
        url: `/${map_name}/approval/credit-committee`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CREDIT_COMMITEE_APPROVERS"],
    }),

    approveCreditCommittee: builder.mutation<
      any,
      {
        map_name: string;
        role: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, role, data }) => ({
        url: `/${map_name}/approval/credit-committee/${role}`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_COMMITEE_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),

    getScreeningDocumentsShareholder: builder.query<
      any,
      { map_name: string; account_type: string; shareholder_id: string }
    >({
      query: ({ map_name, account_type, shareholder_id }) => ({
        url: `/${map_name}/${account_type}/screening/shareholders/${shareholder_id}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["MAP_DOCUMENTS"],
    }),
    addScreeningDocumentShareholder: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        shareholder_id: string;
        data: FormData;
      }
    >({
      query: ({ map_name, account_type, shareholder_id, data }) => ({
        url: `/${map_name}/${account_type}/screening/shareholders/${shareholder_id}/documents`,
        method: "POST",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["MAP_DOCUMENTS"],
    }),
    getIncomlendProducts: builder.query<IncomlendProduct[], string | void>({
      query: (category_name) => ({
        url: `/incomlend-product${category_name != null && category_name !== "" ? `?category=${category_name}` : ""}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    deleteMapDetailsBatch: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
        }[];
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/${map_name}/${account_type}/batch`,
          method: "DELETE",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TABLE_VALUES"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting director:", error);
        }
      },
    }),
    // origination tab endpoints
    getOriginationTab: builder.query<TradePresentation, string>({
      query: (map_name) => ({
        url: `/${map_name}/trade-presentation`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TRADE_PRESENTATION"],
    }),
    getCompanyLogo: builder.query<
      Logo,
      {
        map_name: string;
        account_type: string;
      }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/company-logo`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    addCompanyLogo: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        file: File;
      }
    >({
      query: ({ map_name, account_type, file }) => {
        const data = new FormData();
        data.append("file", file);
        return {
          url: `/${map_name}/${account_type}/company-logo`,
          method: "post",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRADE_PRESENTATION"],
    }),
    editOriginationAccountComments: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        data: {
          [field_name: string]: string;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => ({
        url: `/${map_name}/${account_type}/summary`,
        data,
        method: "put",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRADE_PRESENTATION"],
    }),
    editOriginationTradeComments: builder.mutation<
      any,
      { map_name: string; data: { [field_name: string]: any } }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}`,
          method: "patch",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRADE_PRESENTATION"],
    }),
    editAccountWebsite: builder.mutation<
      any,
      { account_id: string; data: { website: string } }
    >({
      query: ({ account_id, data }) => {
        return {
          url: `/accounts/${account_id}`,
          method: "patch",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRADE_PRESENTATION"],
    }),
  }),
});

export const {
  useGetMapsQuery,
  // useGetMapQuery, no used
  useGetMapVersionQuery,
  useGetTemplatesQuery,
  useGetTemplateVersionQuery,
  useEditMapRiskParametersMutation,
  useGetMapRiskParametersQuery,
  useGetMapTransactionValuesQuery,
  useEditMapTransactionDetailsMutation,
  useGetCategoriesQuery,
  useModifyMapFieldMutation,
  useGetMapSummaryQuery,
  useEditMapSummaryMutation,
  useGetMapDocumentsQuery,
  useGetMapInsurersQuery,
  usePatchMapInsurerMutation,
  useGetAccountCommentsQuery,
  useEditAccountCommentsMutation,
  useGetScorecardHeaderQuery,
  useEditScorecardHeaderMutation,
  useExportMapQuery,
  useGetShareholdersHierarchyQuery,
  useGetEvaluationValuesQuery,
  useEditTemplateMutation,
  useGetRiskMapApprovalsQuery,
  useApproveRiskMapMutation,
  useEditApproverRiskMapMutation,
  useEditEvaluationMutation,
  useGetComplianceMapApprovalsQuery,
  useEditApproverMutation,
  useApproveComplianceMapMutation,
  useGetCreditCommitteeMapApprovalsQuery,
  useApproveCreditCommitteeMutation,
  useAddScreeningDocumentShareholderMutation,
  useGetScreeningDocumentsShareholderQuery,
  useGetLegalMapApprovalsQuery,
  useApproveLegalMapMutation,
  useGetIncomlendProductsQuery,
  useDeleteMapDetailsBatchMutation,
  useGetTemplatesV2Query,
  useGetCurrentMapItemTemplatesQuery,
  useGetMapItemTemplatesQuery,
  useGetTemplateVersionsQuery,
  useGetMapItemTemplateByVersionQuery,
  useGetInvestmentCardQuery,
  useGetOriginationTabQuery,
  useEditOriginationAccountCommentsMutation,
  useEditOriginationTradeCommentsMutation,
  useEditAccountWebsiteMutation,
  useGetCompanyLogoQuery,
  useAddCompanyLogoMutation,
} = maps;
