import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Autocomplete,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import toProperCase from "helpers/propercase";
import unique from "helpers/unique";
import * as React from "react";
import { useState } from "react";
import Search from "../Search";

export default function KanbanBoardFilter<T extends Record<string, any>>({
  data,
  filteredData,
  setFilteredData,
  requiredFilters,
}: {
  data: T[];
  filteredData: T[] | undefined;
  setFilteredData: React.Dispatch<React.SetStateAction<T[] | undefined>>;
  requiredFilters?: string[];
}) {
  const [checked, setChecked] = useState(false);
  const [filterCriterion, setFilterCriterion] = useState<Record<string, string>>({});
  const theme = useTheme();
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const handleDelete = () => {
    setFilterCriterion({});
    setFilteredData(data);
  };
  const sourceData: T = data[0]?.data;

  const shouldIncludekey = (key: string): boolean => {
    const isRequired = requiredFilters?.length ? requiredFilters?.includes(key) : true;
    return typeof sourceData[key] !== "object" && isRequired;
  };

  const handleDataFiltering = (
    filterKey: string,
    filterValue: string,
    isAddFilter: boolean = true
  ) => {
    if (isAddFilter) {
      filterCriterion[filterKey] = filterValue;
    } else {
      delete filterCriterion[filterKey];
    }
    if (Object.keys(filterCriterion).length) {
      setFilteredData(
        data.filter((item) => {
          return Object.keys(filterCriterion).every((key) => {
            return item.data[key] === filterCriterion[key];
          });
        })
      );
    } else {
      setFilteredData(data);
    }
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" maxWidth="99%" spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            tabIndex={-1}
            startIcon={<FilterListIcon />}
            onClick={handleChange}
          >
            Filter
          </Button>
        </Grid>
        <Grid item>
          <Search
            onChange={(searchString) =>
              setFilteredData(
                data.filter((item) =>
                  Boolean(
                    Object.entries(item.data as T).filter(
                      ([k, v]) =>
                        typeof v === "string" &&
                        v?.toLowerCase().includes(searchString?.toLowerCase())
                    ).length
                  )
                )
              )
            }
          />
        </Grid>
      </Grid>
      <Box>
        <Collapse in={checked}>
          <Paper
            sx={{
              width: { xs: "100%", lg: "100%" },
              margin: "auto",
              mt: 1,
              padding: { xs: 2, lg: 2 },
              borderRadius: { xs: 2, lg: 2 },
              border: `1px solid ${theme.palette.secondary.main}80`,
            }}
          >
            <Stack spacing={1}>
              {Object.keys(filterCriterion).length > 0 && (
                <Grid item>
                  <Chip
                    variant="outlined"
                    onDelete={handleDelete}
                    label="Clear Filter"
                  />
                </Grid>
              )}
              <Stack spacing={1}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="start"
                  alignItems="center"
                >
                  {sourceData &&
                    Object.keys(sourceData)
                      .filter((item) => shouldIncludekey(item))
                      .map((item) => {
                        const filterData = data
                          .filter((item) => item.id.length)
                          .map((item) => item.data);
                        const options = unique(
                          filterData.map((obj) => obj[item] ?? "null")
                        );

                        return (
                          <Grid item xs={12} lg={3} key={`${item}-filter`}>
                            <Autocomplete
                              disablePortal
                              id={`${item}-filter-combo-box`}
                              options={options}
                              value={filterCriterion[item] ?? null}
                              onChange={(_, value: any) => {
                                handleDataFiltering(
                                  item,
                                  value,
                                  value && value !== "null"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={toProperCase(item)}
                                />
                              )}
                              inputValue={filterCriterion[item] ?? ""}
                              renderOption={(props, option, { inputValue }) => {
                                const name = option;
                                if (name) {
                                  const matches = match(name, inputValue);
                                  const parts = parse(name, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part) => (
                                          <span
                                            key={part.text}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }
                                return <li {...props}>Not defined</li>;
                              }}
                            />
                          </Grid>
                        );
                      })}
                </Grid>
              </Stack>
              {Object.keys(filterCriterion).length > 0 && (
                <Typography fontWeight="bold">{`${filteredData?.length} results found`}</Typography>
              )}
            </Stack>
          </Paper>
        </Collapse>
      </Box>
    </Box>
  );
}
