import { Grid, Typography, useTheme } from "@mui/material";
import { type IRiskScoring, average } from "components/Risk/defaults";
import { useFormikContext } from "formik";

interface CategoryObject {
  [category: string]: { description: string; score: number };
}

const Assessment = ({
  title,
  party,
}: {
  title: string;
  party: "supplier" | "buyer" | "tradingRelationship";
}) => {
  const theme = useTheme();
  const formikProps = useFormikContext<IRiskScoring>();
  const {
    buyers_assessment_weight,
    suppliers_assessment_weight,
    trading_relationship_weight,
  } = formikProps.values;
  const DEFAULT_SCORING_MATRIX = {
    buyer: buyers_assessment_weight,
    supplier: suppliers_assessment_weight,
    tradingRelationship: trading_relationship_weight,
  };
  function getScore(categoryType: CategoryObject): number[] {
    return (
      Object.values(categoryType ?? {})?.map((item) => item?.score ?? 0) ?? []
    );
  }

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          fontSize: "1em",
          borderRadius: 1,
          background: theme.palette.primary.main,
          color: theme.palette.background.default,
        }}
      >
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              p: 1,
              fontWeight: 700,
              pt: 0.5,
              [theme.breakpoints.down("sm")]: {
                fontSize: "0.8em",
              },
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item pr={1} mt={1} xs={12} lg={6}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              fontSize: "1em",
              bgcolor: theme.palette.background.default,
              borderRadius: 1,
              color: theme.palette.primary.main,
              pt: 0,
            }}
          >
            <Grid item sx={{ p: "2px !important" }}>
              <Typography
                fontWeight="bold"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.8em",
                  },
                }}
              >
                SCORE :{" "}
                {average(getScore((formikProps?.values as any)?.[party]))}{" "}
              </Typography>
            </Grid>
            <Grid item sx={{ p: "2px !important" }}>
              <Typography
                fontWeight="bold"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.8em",
                  },
                }}
              >
                WEIGHTAGE : {DEFAULT_SCORING_MATRIX?.[party] * 100}%
              </Typography>
            </Grid>
            <Grid item sx={{ p: "2px !important" }}>
              <Typography
                fontWeight="bold"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.8em",
                  },
                }}
              >
                TOTAL :{" "}
                {(
                  DEFAULT_SCORING_MATRIX?.[party] *
                  average(getScore((formikProps?.values as any)?.[party]))
                )?.toFixed(1)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Assessment;
