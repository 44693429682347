import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { CountryEligibility, LegalCondition, VersionInfo } from "types";

interface CompanyDoc {
  id: string;
  document_type: string;
  url?: string;
  name: string;
  submitted?: string;
  submitted_to_sf?: boolean;
  year?: number | null;
}

type Law = {
  id: string;
  name: string;
};

interface GoverningDisputeLaw {
  governing_laws: Law[];
  dispute_laws: Law[];
}

const formatConditioning = (data: CountryEligibility[]): CountryEligibility[] =>
  data?.map((item) => ({
    ...item,
    buyer: item.buyer_has_conditions ? "Conditional" : item.buyer,
    seller: item.seller_has_conditions ? "Conditional" : item.seller,
    legal_comments: item.legal_comments ?? "",
  }));
export const legal = createApi({
  reducerPath: "legal",
  tagTypes: ["COUNTRIES", "COUNTRY", "DOCUMENTS", "DRAFTS"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/legal`,
  }),
  endpoints: (builder) => ({
    getEligibilities: builder.query<CountryEligibility[], null>({
      query: () => ({
        url: `/eligibility/countries`,
        method: "get",
      }),
      transformResponse: (response) => formatConditioning(response?.data),
      providesTags: ["COUNTRIES"],
    }),
    getEligibilityByIso: builder.query<CountryEligibility, string>({
      query: (iso3) => ({
        url: `/eligibility/countries/${iso3}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COUNTRY"],
    }),
    getCountryDocuments: builder.query<CompanyDoc[], string>({
      query: (iso3) => ({
        url: `/eligibility/countries/${iso3}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COUNTRY"],
    }),
    getDocumentUrl: builder.query<
      {
        id: string;
        name: string;
        url: string;
        document_type: string;
      },
      { iso3: string; document_id: string }
    >({
      query: ({
        iso3,
        document_id,
      }: {
        iso3: string;
        document_id: string;
      }) => ({
        url: `/eligibility/countries/${iso3}/documents/${document_id}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
    }),
    addCountryDocument: builder.mutation<any, { iso3: string; file: File }>({
      query: ({ iso3, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/eligibility/countries/${iso3}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["COUNTRY"],
    }),
    getDraftEligibilityByVersionId: builder.query<VersionInfo, string>({
      query: (version_id) => ({
        url: `/eligibility/draft/${version_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DRAFTS"],
    }),
    modifyEligibility: builder.mutation<
      any,
      {
        country: string;
        buyer: string;
        seller: string;
        commit_message?: string;
        legal_comments?: string;
      }
    >({
      query: ({ country, ...payload }) => {
        return {
          url: `/eligibility/countries/${country}/draft`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),
    addConditions: builder.mutation<
      any,
      {
        country: string;

        conditions: LegalCondition[];
      }
    >({
      query: ({ country, ...payload }) => {
        return {
          url: `/eligibility/countries/${country}/draft`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),
    modifyConditions: builder.mutation<
      any,
      {
        country: string;
        condition_id: LegalCondition["condition_id"];
        conditions: Partial<LegalCondition>;
      }
    >({
      query: ({ country, condition_id, ...payload }) => {
        return {
          url: `/eligibility/countries/${country}/conditions/${condition_id}`,
          method: "patch",
          data: payload,
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),

    approveDraft: builder.mutation<
      any,
      { country: string; version_id: string }
    >({
      query: ({ country, version_id }) => {
        return {
          url: `/eligibility/countries/${country}/draft/${version_id}/approval`,
          method: "POST",
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),
    getGoverningDisputeLaws: builder.query<GoverningDisputeLaw, null>({
      query: () => ({
        url: `/eligibility/laws`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COUNTRY"],
    }),
    editGoverningDisputeLaw: builder.mutation<
      any,
      {
        name: string;
        type: string;
      }
    >({
      query: ({ name, type }) => {
        return {
          url: `/eligibility/laws`,
          method: "POST",
          data: {
            name: name,
            type: type,
          },
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),
    getRequiredLegalDocuments: builder.query<
      {
        id: string;
        display_name: string;
        module: string;
      }[],
      null
    >({
      query: () => ({
        url: `/required-documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DOCUMENTS"],
    }),
    deleteCondition: builder.mutation<
      any,
      { iso3: string; condition_id: string }
    >({
      query: ({ iso3, condition_id }) => {
        return {
          url: `/eligibility/countries/${iso3}/conditions/${condition_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DRAFTS", "COUNTRY"],
    }),
    deleteDocument: builder.mutation<
      any,
      { iso3: string; document_id: string }
    >({
      query: ({ iso3, document_id }) => {
        return {
          url: `/eligibility/countries/${iso3}/documents/${document_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["COUNTRY"],
    }),
  }),
});

export const {
  useGetEligibilitiesQuery,
  useGetEligibilityByIsoQuery,
  useModifyEligibilityMutation,
  useGetDraftEligibilityByVersionIdQuery,
  useGetGoverningDisputeLawsQuery,
  useEditGoverningDisputeLawMutation,
  useApproveDraftMutation,
  useGetRequiredLegalDocumentsQuery,
  useModifyConditionsMutation,
  useAddConditionsMutation,
  useGetCountryDocumentsQuery,
  useGetDocumentUrlQuery,
  useAddCountryDocumentMutation,
  useDeleteConditionMutation,
  useDeleteDocumentMutation,
} = legal;
